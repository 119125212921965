
  import { reactive, toRefs, getCurrentInstance } from 'vue'
  import { useStore } from 'vuex'
  import useUtils from '@/plugins/utils'
  import useProject from '@/components/source/useProject'
  import { useI18n } from 'vue-i18n'
  import { positionSentence } from '@/plugins/comm'
  import { postReComputePage } from '@/http/api'
  import { message } from 'ant-design-vue'

  export default {
    props: {
      dataSource: {
        type: Array,
        default: () => [],
      },
    },
    setup() {
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const store = useStore()
      const { t } = useI18n()
      const state = reactive({
        columns: [
          {
            title: '句对',
            dataIndex: 'extSentId',
            width: 100,
            slots: { customRender: 'extSentId' },
          },
          {
            title: '内容',
            dataIndex: 'source',
            slots: { customRender: 'source' },
          },
        ],
      })

      const handleClick = (item: any) => {
        //请求译文
        if (store.state.excelProject.ArrMap.has(item.sentId)) {
          positionSentence(item.sentId)
        } else {
          postReComputePage({
            pageSize: store.state.pageSize,
            taskId: store.state.taskDetail.taskId,
            docId: store.state.taskDetail.docId,
            sentId: item.sentId,
          }).then((res) => {
            store.commit('changeKey', {
              pageNum: res.data.data.pageIndex,
            })
            store.commit('excelProject/changeKey', {
              serialNum:'',
              sourceText: '',
              targetText: '',
            })
            store.commit('excelProject/resetFilterValue')
            store
              .dispatch('getSentence', {
                loading: true,
                sentencePosition: item.sentId,
              })
              .then(() => {
                if (store.state.excelProject.ArrMap.has(item.sentId)) {
                  positionSentence(item.sentId)
                } else {
                  message.warning('该句已经被隐藏')
                }
              }) //请求译文
          })
        }
      }
      return {
        t,
        ...toRefs(state),
        handleClick,
      }
    },
  }
