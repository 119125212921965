const replaceObj = {
  '&nbsp;':' ',
  '&#xa0;':' ',
  '':' ',
  '\r':'',
  '':'',
  ';':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':' ',
  '\f':'',
  '\n':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  '':'',
  ' ':" ",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
  " ":"",
}

class FilterString {
  //过滤标签（包括内容）
  filterTag(str: string) {
    let filterStr = str || ''
    //过滤删除标签的内容
    let delReg = /<del(([\s\S])*?)<\/del>/g
    filterStr = filterStr.replace(delReg, '')
    return filterStr
  }
  escapeCharacter(excludes=[]) {
    let strCopy = [...excludes];
    // console.log(strCopy,666)
    let originArr = Object.keys(replaceObj);
    let uniqueArray = [...new Set([...originArr, ...excludes])];
    // console.log(uniqueArray)
    return uniqueArray
  }
  //过滤&nbsp;
  filterEscapeCharacter(str: string,excludes=[]) {
    // console.log(excludes);


    let filterStr = str || ''
    for (let key in replaceObj) {
      // return uniqueArray
      //过滤转义字符
      if (!excludes.includes(key)) {
        filterStr = filterStr.replaceAll(key, replaceObj[key])
      }
    }
    return filterStr
  }
  //过滤占位符标签
  filterImgTag(str: string) {
    let htmlString = str || ''
    //过滤删除标签的内容
    return htmlString.replace(/<img[^>]*>/gi, '')
  }
  //过滤首尾空格以及开头处空格 以及过滤首位换行标签
  filterSpace(pstr: string) {
    let str = pstr || ''
    str = str.replace(/^(\s*<br( \/)?>)*|(<br( \/)?>\s*)*$/gm, '')
    return str.trim()
  }
  //过滤标签 （不包括内容）
  retainImportant(str: string) {
    //保留内容 span标签下是机翻，add标签下是人翻
    let filterStr = this.filterTag(str || '')
    //过滤add标签
    //过滤非自主可实现富文本编辑标签内容
    filterStr = filterStr.replace(/<(?!\/?(sup|i|sub|u|b|img|br)\b)[^>]+>/g, '')
    return filterStr
  }
  //获取检验字符串的内容
  getCheckCommitString(oldStr: string) {
    let str = oldStr ? oldStr : ''
    return this.retainImportant(str)
  }
  //获取最终字符串 带有富文本内容的字符串 （自主编辑）
  getString(oldStr: string) {
    let str = oldStr ? oldStr : ''
    //第一道 过滤删除标签包括内容； 第二道过滤标签 保留内容 ； 第三道过滤首尾空格
    return this.filterSpace(this.retainImportant(str))
  }
  //纯净字符串 不带任何富文本内容
  getPureString(oldStr: string) {
    let str = oldStr ? oldStr : ''
    return this.getString(str).replace(/<.*?>/g, '')
  }
}

const filterStrings = new FilterString()

export default filterStrings
