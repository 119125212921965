<template>
  <a-button
    class="li"
    type="link"
    @click="testingClick"
    :class="{
      disabled: disabled || (checkedDetail.locked && checkedList.length == 0),
    }"
  >
    <icon-font class="font-size-16 icon-box" type="icon-zhijian"></icon-font>
    <div class="li-txt">
      质检
    </div>
  </a-button>
  <div class="Success-Tips" v-if="GPTSuccessVisible" style="width: 420px">
    <div class="left">
      <CheckCircleFilled />
      <span v-if="resultData.firstModifyResult?.sentId">
        质检已完成，质检了{{ resultData.checkedNumber }}句，有{{
          resultData.modifyNumber
        }}句修改建议
      </span>
      <span v-else>
        质检已完成，质检了{{ resultData.checkedNumber }}句，暂无修改建议
      </span>
    </div>
    <div class="right">
      <span
        @click="goFirstSentence"
        v-if="resultData.firstModifyResult?.sentId"
      >
        查看
      </span>
      <span @click="closeModal">关闭</span>
    </div>
  </div>

  <a-modal
    v-model:visible="confirmVisible"
    title="提示"
    okText="确认质检"
    cancelText="取消"
    centered
    @ok="confirmOk"
    @cancel="confirmCancel"
  >
    <div class="modal">
      <div class="title">
        <img src="@/assets/warning_1.png" />
      </div>
      <div class="content">
        已勾选{{ checkedList?.length }}个句段，确认批量质检？
      </div>
      <div class="footer">
        确认后，将对所选句段批量质检
      </div>
    </div>
  </a-modal>
</template>

<script>
  import {
    computed,
    ComputedRef,
    reactive,
    createVNode,
    toRefs,
    ref,
    getCurrentInstance,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import { CheckCircleFilled } from '@ant-design/icons-vue'
  import {positionSentence} from "@/plugins/comm";
  import {
    batchQualityCheck,
    getTwinsLimit,
    postReComputePage,
    qualityCheckResult,
  } from '@/http/api'
  import useUtils from '@/plugins/utils'
  import mitt from '@/plugins/bus'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      CheckCircleFilled,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      let workId = null
      let timer = null
      let visibleTimer = null

      const resultData = ref({})

      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      }

      const checkedList = computed(() => store.state.excelProject.checkedList)

      const state = reactive({
        GPTSuccessVisible: false,
        confirmVisible: false,
      })

      const initCheckedList = () => {
        store.commit('excelProject/changeKey', {
          checkedAll: false,
          checkedList: [],
        })
        store.state.excelProject.docData.sentenceDTOList.forEach((item) => {
          item.checked = false
        })
      }

      const closeModal = () => {
        setTimeout(() => {
          store.dispatch('getSentence', { loading: true })
        }, 300)
        clearTimeout(visibleTimer)
        state.GPTSuccessVisible = false
        resultData.value = {}
      }

      const goFirstSentence = () => {
        state.GPTSuccessVisible = false
        if (resultData.value.firstModifyResult?.sentId) {
          let sentId = resultData.value.firstModifyResult?.sentId;
          if (store.state.excelProject.ArrMap.has(sentId)) {
            store.dispatch('getSentence', { loading: true }).then(() => {
              positionSentence(sentId);
            }) //请求译文
          } else {
            //移除
            store.commit('excelProject/changeKey', {
              groupBy: null,
              groupSort: null
            })
            postReComputePage({
              pageSize: store.state.pageSize,
              taskId: store.state.taskDetail.taskId,
              docId: store.state.taskDetail.docId,
              sentId: sentId,
            }).then((res) => {
              store.commit('excelProject/changeKey', {
                sourceText: '',
                targetText: '',
                serialNum:'',
              })
              store.commit('excelProject/resetFilterValue')
              let pageNum = res.data.data.pageIndex
              store.commit('changeKey', {
                pageNum: pageNum,
              })
              store.dispatch('getSentence', { loading: true }).then(() => {
                positionSentence(sentId);
              }) //请求译文
            })
          }
        }
      }

      const httpGetCheck = () => {
        timer = qualityCheckResult({ requestId: workId }).then((res) => {
          state.GPTSuccessVisible = false
          if (res.data.data) {
            state.GPTSuccessVisible = true
            resultData.value = { ...res.data.data }
            visibleTimer = setTimeout(() => {
              state.GPTSuccessVisible = false
              resultData.value = {}
            }, 10000)
          } else {
            timer = setTimeout(() => {
              httpGetCheck()
            }, 5000)
          }
        })
      }

      const batchQualityWork = (sentIdsArr) => {
        clearTimeout(timer)
        clearTimeout(visibleTimer)
        state.GPTSuccessVisible = false

        let params = {
          sentIds: sentIdsArr,
          docId: store.state.taskDetail.docId,
        }
        batchQualityCheck(params)
          .then((res) => {
            if (res && res.data.data) {
              message.success(
                '智能质检已开始，质检过程需一定时间，完成时会通知'
              )
              workId = res.data.data
              setTimeout(() => {
                httpGetCheck()
              }, 3000)
              initCheckedList()
            }
          })
          .finally(() => {})
      }

      //Gpt批量质检
      const GPTTest = async () => {
        // const { mtId, transMode } = store.state.taskDetail;
        const item = getTableActiveItem()
        let sentIds = item.sentId.toString()
        if (checkedList.value.length > 0) {
          sentIds = checkedList.value
            .map((citem) => {
              return citem.sentId
            })
            .toString()
        }
        if (!sentIds) {
          message.warning('请选中某一句或者勾选句子')
          return
        }

        let sentIdsArr = sentIds.split(',')
        //判断是否有译文 有的话 提示出来

        let sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        let index = sentIdsArr.findIndex((item) => {
          if (
            sentenceDTOList[store.state.excelProject.ArrMap.get(item)]
              .targetPlaceholder
          ) {
            return true
          }
        })
        if (index == -1) {
          message.warning('请选择有译文的句子')
          return
        }

        //打开弹窗做了处理 ，无需判断，只有机翻需要判断
        mitt.emit('openCostModal', {
          costType: 'GptQualityCheck',
          sentIds: sentIdsArr,
          ok: (e) => {
            batchQualityWork(sentIdsArr)
          },
        })
      }

      const testingClick = () => {
        if (checkedList.value.length > 0) {
          state.confirmVisible = true
        } else {
          GPTTest()
        }
      }

      const confirmOk = () => {
        GPTTest()
        state.confirmVisible = false
        // confirmMt();
      }
      const confirmCancel = () => {
        state.confirmVisible = false
      }

      return {
        testingClick,
        confirmOk,
        confirmCancel,
        checkedList,
        goFirstSentence,
        resultData,
        closeModal,
        ...toRefs(state),
        GPTTest,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .Success-Tips {
    width: 500px;
    position: fixed;
    top: 14px;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 0);
    height: 40px;
    background-color: #fff;
    //border: 1px solid #b7eb8f;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    color: #000000d9;
    padding: 0 10px;
    .left {
      ::v-deep(svg) {
        fill: #0ab923;
        margin-right: 4px;
      }
    }
    .right {
      span {
        cursor: pointer;
        color: #00000073;
        margin-left: 10px;
      }
    }
  }
  .modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      color: #1c2129;
      line-height: 22px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      font-size: 14px;
      line-height: 22px;
    }
  }
</style>
