
  import { useStore } from 'vuex'
  import useProject from '@/components/source/useProject'
  import { getFactTargetText } from '@/plugins/comm.js'
  import { message } from 'ant-design-vue'

  export default {
    props: ['item', 'index', 'editType'],
    components: {},
    setup() {
      const store = useStore()
      //taskDetail 用户详情
      const { t, taskDetail, targetMousedown } = useProject()

      //打开备注
      const openbeizhu = (item: any) => {
        if (!store.getters.$isPreviewMode) {
          targetMousedown(item)
          store.commit('rightToolbar/changeKey', {
            toolbarNav: {
              tbNavActiveIndex: '3',
            },
          })
        }
        // //将右侧导航切换到备注页
      }

      const unLock = (item) => {
        if (store.getters.$isPreviewMode) {
          return
        }
        if (
          store.state.taskDetail.allowTranslatorUnLock ||
          store.state.taskDetail.taskClassify == 8
        ) {
          store.dispatch('optionsBar/unLockSentence', item)
        } else {
          message.warning(
            '暂无权限，可联系PM前往项目详情-成员处设置'
          )
        }
      }

      const lock = (item) => {
        //校验权限
        if (store.getters.$isPreviewMode) {
          return
        }
        if (
          store.state.taskDetail.allowTranslatorUnLock ||
          store.state.taskDetail.taskClassify == 8
        ) {
          store.dispatch('optionsBar/lockSentence', item)
        } else {
          message.warning(
            '暂无权限锁定，可联系PM前往统计-重复率分析-报表详情开启'
          )
        }
      }

      //鼠标移动到qa图标 获取qa错误详情
      // const getQaSentence = (item: {
      //   sentId: any;
      //   docId: string;
      //   qaDetail: any;
      //   hasQA: boolean;
      //   id: string;
      // }) => {
      //   if (!item.qaDetail?.length) {
      //     postClassifiedSentenceLog({
      //       sentId: item.sentId,
      //       docId: item.docId
      //     }).then(res => {
      //       item.qaDetail = res.data.data;
      //     });
      //   }
      // };
      return {
        store,
        lock,
        unLock,
        // openSingleQa,
        openbeizhu,
        t,
        taskDetail,
      }
    },
  }
