<template>
  <ElScrollbar
    id="source-preview-container"
    wrap-class="source-preview-container"
    @scroll="scroll"
    ref="previewContainerScroll"
    :style="{
      height: scrollHeight + 'px',
    }"
  >
    <div
      class="source-preview-html-content source-preview-html-body"
      v-if="isSupportHtmlPreview && pageCountHtmlPreview > 0"
    >
      <div class="page-box" v-if="reRenderDom">
        <div
          v-for="item in pageCountHtmlPreview"
          :key="item"
          :id="'source-preview-page-' + item"
          class="source-preview-page-box"
        >
          <img
            class="page-loading-gif"
            src="../../../../../images/loading.gif"
          />
        </div>
      </div>
    </div>
    <div
      class="source-preview-html-content source-preview-html-body"
      v-else-if="isSupportHtmlPreview && pageCountHtmlPreview === 0"
    >
      <div class="page-box">
        <img class="page-loading-gif" src="../../../../../images/loading.gif" />
      </div>
    </div>
    <div
      class="source-preview-html-content source-preview-html-body"
      v-else-if="!isSupportHtmlPreview"
    >
      <div class="no-support">
        <img
          src="https://ali-file-cdn.iol8.com/img/icon/empty.png"
          class="no-support-img"
        />
        <div class="no-support-text">
          该文件格式暂不支持原文预览
        </div>
      </div>
    </div>
    <div
      class="source-preview-html-content source-preview-html-body"
      v-else-if="isSupportHtmlPreview && pageCountHtmlPreview < 0"
    >
      <div class="no-support">
        <img
          src="https://ali-file-cdn.iol8.com/img/icon/empty.png"
          class="no-support-img"
        />
        <div class="no-support-text">
          该文件预览失败
        </div>
      </div>
    </div>
  </ElScrollbar>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import {
    computed,
    nextTick,
    onMounted,
    reactive,
    watch,
    toRefs,
    ref,
    onBeforeMount,
    onBeforeUnmount,
  } from 'vue'
  import { useStore } from 'vuex'
  import useUtils from '@/plugins/utils'
  import { getPreviewInfo, getSentTarget } from '@/http/api.ts'
  import useProject from '@/components/source/useProject'
  import axios from 'axios'
  import mitt from '@/plugins/bus'
  import { ElScrollbar } from 'element-plus'
  export default {
    name: 'wordPreview',
    components: { ElScrollbar },
    props: ['tabsIndex', 'scrollHeight'],
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const srcUrl = ref('')

      const previewContainerScroll = ref(null)

      const updatePreviewContainerScroll = () => {
        previewContainerScroll.value.update()
      }
      // const pageCount = computed(() => {
      //   return store.state.taskDetail.pageCountHtmlPreview;
      // });

      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })
      const state = reactive({
        isRenderPage: {},
        reRenderDom: true,
        isSupportHtmlPreview: true,
        pageCountHtmlPreview: 0,
        docId: '',
        docName: '',
      })

      //键值对
      let sourceWordKeyValueObject = {
        htmlPrefixUrl: '',
        pageToSentence: '',
        sentenceToPage: '',
        htmlHref: '',
      }

      //节流
      const _throttle = (fn, wait) => {
        let timeout = null
        return (...args) => {
          clearTimeout(timeout)
          timeout = setTimeout(() => {
            fn.apply(this, args)
          }, wait)
        }
      }

      const getShadowPageDom = (sentIndex) => {
        return document.getElementById(
          `source-preview-page-${sourceWordKeyValueObject.sentenceToPage[sentIndex]}`
        )?.shadowRoot
      }

      const scrollIntoView = (n) => {
        //只会匹配第一个
        let shadowDom = getShadowPageDom(n)
        let id = `.sent-${n}`
        if (!shadowDom) {
          return
        }
        let sentId = shadowDom.querySelector(`${id}`)

        if (!sentId) {
          return
        }

        let childNode =
          sentId.getElementsByTagName('span')[0] ||
          sentId.getElementsByTagName('tspan')[0] ||
          sentId

        let container = document.querySelector(`.source-preview-container`)
        container.scrollTop = 0

        const { left, top, height } = container.getBoundingClientRect()
        const {
          left: childLeft,
          top: childTop,
        } = childNode.getBoundingClientRect()

        const diffY = Math.abs(top - childTop)
        if (diffY < height / 2) {
          container.scrollTop = 0
        } else {
          container.scrollTop += diffY - height / 2
        }
      }

      const clearIsFocusSentShadow = (oldSentId) => {
        let oldShadowDomRoot = getShadowPageDom(oldSentId)
        if (oldShadowDomRoot) {
          let oldShadowDomRoot = getShadowPageDom(oldSentId)
          const docHtmlContentSentIdFocus = oldShadowDomRoot.querySelectorAll(
            `.is-focus`
          )
          docHtmlContentSentIdFocus.forEach((item) => {
            item.classList.remove('is-focus')
          })
        }
      }

      const setIsFocusSentShadow = (sentId) => {
        let shadowDomRoot = getShadowPageDom(sentId)
        if (shadowDomRoot) {
          let sentStr = `.sent-${sentId}`
          const shadowDomRootSentArr = shadowDomRoot.querySelectorAll(
            `${sentStr}`
          )

          //需要做处理 srt
          let docNameArr = state.docName.split('.')
          //srt 单独处理
          if (docNameArr[docNameArr.length - 1] != 'srt') {
            shadowDomRootSentArr.forEach((item) => {
              if (item.getElementsByTagName('span')?.length) {
                item.getElementsByTagName('span').forEach((item) => {
                  item.classList.add('is-focus')
                })
              } else {
                item.classList.add('is-focus')
              }
            })
          } else {
            shadowDomRootSentArr.forEach((item) => {
              item
                .getElementsByClassName('preview-srt-content')[0]
                ?.classList.add('is-focus')
              // item.classList.add("is-focus");
            })
          }
        }
      }

      //设置原文选中
      const setNewWordSentShadow = (sentId, oldSentId = 0) => {
        if (oldSentId) {
          clearIsFocusSentShadow(oldSentId)
        }

        setIsFocusSentShadow(sentId)
      }

      const shadowScrollIntoView = (n, oldn = 0) => {
        nextTick(() => {
          setNewWordSentShadow(n, oldn)
          scrollIntoView(n)
        })
      }

      const scrollLoadPage = (
        page = [1],
        sentenceIndex = null,
        pageIndex = null
      ) => {
        // if (!store.state.taskDetail.isSupportHtmlPreview || !store.state.taskDetail.pageCountHtmlPreview) {
        //   return
        // }

        page.forEach((item) => {
          axios
            .get(`${sourceWordKeyValueObject.htmlPrefixUrl}-p${item}.html`)
            .then((res) => {
              let reg = /<body[^>]*>([\s\S]+?)<\/body>/i
              let htmlStr = reg.exec(res.data)[1]
              // console.log(htmlStr);
              if (!htmlStr) {
                return
              }
              let frag = document
                .createRange()
                .createContextualFragment(htmlStr)
              let linkStr = res.data.match(/<link[^>]*>/g)
              let linkFrag = document
                .createRange()
                .createContextualFragment(linkStr ? linkStr[0] : ``)

              //解决资源兼容的问题
              linkFrag.childNodes.forEach((item) => {
                if (
                  item.localName == 'link' &&
                  item.href.indexOf(window.location.host) > -1
                ) {
                  item.href = `${sourceWordKeyValueObject.htmlHref}${
                    item.href.split(window.location.host)[1]
                  }`
                }
              })

              //解决资源兼容的问题 srt 不需要处理
              let docNameArr = state.docName.split('.')
              if (docNameArr[docNameArr.length - 1] != 'srt') {
                frag.querySelectorAll(`img`).forEach((item) => {
                  if (item.src.indexOf(window.location.host) > -1) {
                    item.src = `${sourceWordKeyValueObject.htmlHref}${
                      item.src.split(window.location.host)[1]
                    }`
                  }
                })
              }

              frag.querySelectorAll(`.awpage`).forEach((item) => {
                item.style.borderWidth = 0
              })
              //将页码改进 替换下方 将这个函数封装成方法
              let localDom = document.getElementById(
                `source-preview-page-${item}`
              )
              localDom.innerHTML = ''
              let root = localDom.attachShadow({ mode: 'open' })
              root.appendChild(linkFrag)
              let style = `.is-focus {
                              background: #fff0d2 !important;
                            }
                            a {
                              pointer-events: none;
                            }
                          `
              let ele = document.createElement('style')
              ele.innerHTML = style
              root.appendChild(ele)
              root.appendChild(frag)
              setTimeout(() => {
                let pageSectionDom = root.querySelectorAll(`img`)
                pageSectionDom.forEach((sitem) => {
                  let clientWidth = sitem.clientWidth
                  if (clientWidth > 1000) {
                    let zoomNum = 1000 / clientWidth.toFixed(2)
                    sitem.style.transform = `scale(${zoomNum})`
                    sitem.style.transformOrigin = `0 0`
                  }
                })
              }, 0)
              state.isRenderPage[item] = true
              // let params = {
              //   docId: store.state.taskDetail.docId,
              //   //获取当前页有哪些句子；然后把句子id调用接口获取译文进行填充；
              //   sentIndexes: sourceWordKeyValueObject.pageToSentence[item].join(",")
              // };
              // getSentTarget(params).then(res => {
              //   nextTick(() => {
              //     scrollLoadSentence(res.data.data);
              //   });
              // });
              if (sentenceIndex) {
                nextTick(() => {
                  shadowScrollIntoView(sentenceIndex)
                })
              }
              if (pageIndex) {
                nextTick(() => {
                  scrollIntoView(
                    sourceWordKeyValueObject.pageToSentence[pageIndex][0]
                  )
                })
              }
            })
        })
      }

      const scroll = _throttle(() => {
        if (!state.isSupportHtmlPreview || state.pageCountHtmlPreview < 1) {
          return
        }
        //滚动加载
        let localShowIndex = []
        let previewDom = document.getElementsByClassName(
          'source-preview-container'
        )[0]
        let scrollTop = previewDom.scrollTop
        let clientHeight = previewDom.clientHeight
        for (let i = 1; i < state.pageCountHtmlPreview + 1; i++) {
          let previewPageDom = document.getElementById(
            `source-preview-page-${i}`
          )
          let previewPageDomClientHeight = previewPageDom.clientHeight
          let offsetTop = previewPageDom.offsetTop

          if (previewPageDom.innerHTML.indexOf('page-loading-gif') < 0) {
            continue
          }

          if (
            offsetTop + previewPageDomClientHeight > scrollTop &&
            offsetTop + previewPageDomClientHeight < scrollTop + clientHeight
          ) {
            localShowIndex.push(i)
          }
        }

        let difference = localShowIndex.slice(0, 1) //需要加载的页面也就是当前滚动目的地； 只保留当前页以及上下两页其余清除 (看需不需要做)；

        // 根据差集来重新渲染dom
        if (difference.length) {
          let keysArr = Object.keys(state.isRenderPage)
          if (keysArr.length > 7) {
            state.reRenderDom = false
            state.isRenderPage = {}
            nextTick(() => {
              state.reRenderDom = true
              nextTick(() => {
                scrollLoadPage(difference, null, difference[0])
              })
            })
          } else {
            scrollLoadPage(difference)
          }
        }
      }, 1000)

      //需要暴露一个方法 给父组件使用。
      const getLocalDocId = () => {
        return state.docId
      }

      const init = () => {
        state.isSupportHtmlPreview = true
        state.pageCountHtmlPreview = 0
        state.docId = ''
        state.reRenderDom = true
        state.isRenderPage = {}
        sourceWordKeyValueObject = {
          htmlPrefixUrl: '',
          pageToSentence: '',
          sentenceToPage: '',
          htmlHref: '',
          docName: '',
        }
      }

      const updateSourceWordKeyValueObject = async (docId) => {
        return new Promise(async (resolve, reject) => {
          init()
          let result = await getPreviewInfo({ htmlType: 1, docId: docId })
          //获取值 赋值
          let {
            isSupportHtmlPreview,
            pageCountHtmlPreview,
            htmlPrefixUrl,
            pageSentList,
            docName,
          } = result.data.data

          state.isSupportHtmlPreview = isSupportHtmlPreview
          state.pageCountHtmlPreview = pageCountHtmlPreview
          state.docId = docId
          state.docName = docName
          //需要赋值isSupportHtmlPreviewSrc，pageCountHtmlPreview

          if (localStorage.getItem(`${docId}-sourceWordKeyValueObject`)) {
            sourceWordKeyValueObject = JSON.parse(
              localStorage.getItem(`${docId}-sourceWordKeyValueObject`)
            )
          } else {
            for (let key in localStorage) {
              //删除对应的key;
              if (key.indexOf('-sourceWordKeyValueObject') > -1) {
                localStorage.removeItem(key)
                break
              }
            }

            if (!isSupportHtmlPreview) {
              return
            }

            if (pageCountHtmlPreview<1) {
              return
            }

            let data = result.data.data
            // let htmlPrefixUrl = data.htmlPrefixUrl;

            let lastIndex = -1
            for (let i = 0; i < htmlPrefixUrl.length; i++) {
              if (htmlPrefixUrl[i] == '/') {
                lastIndex = i
              }
            }
            let htmlHref = htmlPrefixUrl
            if (lastIndex > -1) {
              htmlHref = htmlPrefixUrl.substring(0, lastIndex)
            }

            let sentenceToPage = {}
            let pageToSentence = {}
            //赋值改动
            pageSentList.forEach((item) => {
              pageToSentence[item.page] = item.sentenceIds
              item.sentenceIds.forEach((citem) => {
                sentenceToPage[citem] = item.page
              })
            })
            sourceWordKeyValueObject = {
              htmlHref,
              sentenceToPage,
              pageToSentence,
              htmlPrefixUrl,
            }
            localStorage.setItem(
              `${docId}-sourceWordKeyValueObject`,
              JSON.stringify(sourceWordKeyValueObject)
            )
          }
          resolve(true)
        })
        // scrollLoadPage();
      }

      // onBeforeMount(async () => {
      //   //不能用store来判断 直接调用接口做；
      //   // if (
      //   //   !store.state.taskDetail.isSupportHtmlPreview ||
      //   //   store.state.taskDetail.pageCountHtmlPreview < 1
      //   // ) {
      //   //   return;
      //   // }
      //
      //   //获取接口，同时也要获取docId。
      // });
      //增加checkedDetail定位函数
      const checkedDetailOnChange = (newDetail, oldDetail) => {
        //需要切换docId。也就是首页加载不能再直接请求了。
        if (!state.isSupportHtmlPreview || !state.pageCountHtmlPreview) {
          return
        }
        let n = newDetail.sentIndex
        let oldn = oldDetail.sentIndex
        //如果QA结果存在 才执行定位；
        // 根据sentId 利用keyValue 获取页码 找出页码dom 然后判断是否含有渲染class 没有就走传递参数 重新渲染滚动
        if (n) {
          //根据n 来清理dom节点
          let pageIndex = sourceWordKeyValueObject.sentenceToPage[n]
          if (!pageIndex) {
            return
          }
          let pageClass = `source-preview-page-${pageIndex}`
          // console.log(pageClass)
          let dom = document.getElementById(pageClass)
          if (dom.innerHTML.indexOf('page-loading-gif') > -1) {
            scrollLoadPage([pageIndex], n)
          } else {
            shadowScrollIntoView(n, oldn)
          }
        }
      }

      onMounted(() => {
        if (checkedDetail.value.sentId) {
          updateSourceWordKeyValueObject(checkedDetail.value.docId).then(
            (res) => {
              nextTick(() => {
                scroll()
              })
            }
          )
        }
        // console.log(store.state.taskDetail);
        // debugger
      })

      watch(checkedDetail, (newDetail, oldDetail) => {
        let { docId } = newDetail
        //需要用docId；//需要检查是否存值 以及docId 是否相同。
        if (state.docId == docId) {
          checkedDetailOnChange(newDetail, oldDetail)
        } else {
          //需要等待执行完成。做成promise；promise 结束才能执行下一步
          updateSourceWordKeyValueObject(docId).then((res) => {
            checkedDetailOnChange(newDetail, oldDetail)
          })
        }

        //原文预览
      })

      return {
        previewContainerScroll,
        updatePreviewContainerScroll,
        getLocalDocId,
        // pageCount,
        srcUrl,
        scroll,
        store,
        ...toRefs(state),
        t,
      }
    },
  }
</script>

<style lang="less">
  #source-preview-container {
    padding: 20px;
    background: #f2f2f2;
    //background: #f6f6f6;
    a {
      color: inherit;
    }
    //修改原译对照加载css的样式
    .page-loading-gif {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      height: 250px;
    }
    .source-preview-html-content {
      transform-origin: center;
      //pointer-events: none;
      .page-box {
        //padding: 0 40px;
        margin: 0 auto;
        position: relative;
        //padding: 0 50px;
        background: #fff;
        //width: 1000px;
        padding: 10px;
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        word-break: break-all;
      }
    }
    .source-preview-page-box {
      border: 1px transparent solid;
    }
    .no-support {
      text-align: center;
      padding-top: 60px;
      .no-support-img {
        width: 160px;
        height: 120px;
      }
      .no-support-text {
        color: #999999;
        font-size: 14px;
      }
    }
  }
</style>
