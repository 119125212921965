<template>
  <div class="commonQA">
    <div class="commonQA-title">
      <div class="left" @click="back">
        <LeftOutlined class="font-size-12" style="color:#000000" />
        返回
      </div>
      <div class="center">
        相同QA
      </div>
      <div class="right">
        &nbsp;
      </div>
    </div>
    <div class="list-title">
      <div class="left">
        {{ checkRule[sameQADetail.checkRule] }}
      </div>
      <div class="right">
        <a-button
          size="small"
          style="border-radius: 12px;font-size: 12px"
          @click="ignoreAll"
          type="primary"
        >
          全部忽略
        </a-button>
      </div>
    </div>
    <div class="list-description">
      <div class="list-description-item">
        <div
          class="content"
          v-if="
            sameQADetail.checkRule == 'RULE_LOWE_CHECK' &&
              sameQADetail.errorType == 'RemoveExtraSpace'
          "
        >
          <div
            v-html="sameQADetail.descriptions"
            class="description target-txt"
          ></div>
        </div>
        <div
          class="content"
          v-else-if="
            sameQADetail.checkRule == 'RULE_LOWE_CHECK' &&
              sameQADetail.errorType == 'UpperFirstLetter'
          "
        >
          <div
            v-html="sameQADetail.descriptions"
            class="description target-txt"
          ></div>
        </div>

        <div
          class="content"
          v-else-if="sameQADetail.checkRule == 'TERM_CONSISTENCY_CHECK'"
        >
          <div class="description">
            术语{{ sameQADetail.original }}漏译，应为：{{
              sameQADetail.suggestionsList[0]
            }}
          </div>
        </div>

        <div class="content" v-else-if="sameQADetail.suggestionsList?.length">
          <label class="source">{{ sameQADetail.original }}</label>
          <CaretRightFilled class="margin-lr-4"></CaretRightFilled>
          <span class="target">{{ sameQADetail.suggestionsList[0] }}</span>
        </div>

        <div class="content" v-else>
          <div
            v-html="$assignValueToSrc(sameQADetail.descriptions)"
            class="description target-txt"
          ></div>
        </div>
      </div>
    </div>
    <ElScrollbar class="ElScrollBar">
      <div class="commonQA-content">
        <div class="list">
          <div
            class="list-item margin-b-8"
            v-for="(item, index) in state.list"
            :key="index"
            @click="toPage(item)"
          >
            <div class="title">
              <div class="left">
                <label class="round"></label>
                <span class="index">句对：{{ item.extSentId }}</span>
              </div>
              <div class="right">
                <span class="text" @click="ignoreQA(item, index)">忽略</span>
              </div>
            </div>
            <div class="description target-txt target-language-text-style" v-html="$assignValueToSrc(item.qaHighlight)"></div>
          </div>
        </div>
      </div>
    </ElScrollbar>
    <div class="text-right pagination">
      <a-pagination
        size="small"
        v-model:current="state.current"
        :total="state.total"
        @change="onPaginationChange"
        :pageSize="20"
      />
    </div>
  </div>
</template>

<script setup>
  import { onMounted, reactive, computed } from 'vue'
  import {
    LeftOutlined,
    CaretRightFilled,
    CaretRightOutlined,
  } from '@ant-design/icons-vue'
  import { useStore } from 'vuex'
  import { positionSentence } from '@/plugins/comm'
  import { getSameQASentListApi, postReComputePage } from '@/http/api'
  import { message } from 'ant-design-vue'
  import { ElScrollbar } from 'element-plus'

  const store = useStore()
  const sameQADetail = computed(() => store.state.rightToolbar.sameQADetail)
  const checkRule = computed(() => store.state.rightToolbar.checkRule)
  const state = reactive({
    list: [],
    current: 1,
  })

  const back = () => {
    //返回的做处理，返回对应的句子即可.
    store.commit('rightToolbar/changeKey', {
      sameQAOpen: false,
    })
    toPage(sameQADetail.value)
  }

  const toPage = (item) => {
    if (store.state.openEditSourceText) {
      return true
    }
    if (store.state.excelProject.ArrMap.has(item.sentId)) {
      positionSentence(item.sentId)
    } else {
      postReComputePage({
        pageSize: store.state.pageSize,
        taskId: store.state.taskDetail.taskId,
        docId: store.state.taskDetail.docId,
        sentId: item.sentId,
      }).then((res) => {
        store.commit('changeKey', {
          pageNum: res.data.data.pageIndex,
        })
        store.commit('excelProject/changeKey', {
          serialNum:'',
          sourceText: '',
          targetText: '',
        })
        store.commit('excelProject/resetFilterValue')
        store.dispatch('getSentence', { loading: true }).then(() => {
          if (store.state.excelProject.ArrMap.has(item.sentId)) {
            positionSentence(item.sentId)
          } else {
            message.warning('该句已经被隐藏')
          }
        }) //请求译文
      })
    }
  }

  const httpGetSameQASentList = () => {
    let sameQADetail = store.state.rightToolbar.sameQADetail
    //做分页查询
    getSameQASentListApi({
      sentenceQALogId: sameQADetail.id,
      pageSize: 20,
      pageIndex: state.current,
    }).then((res) => {
      state.list = res.data.data.data
      state.total = res.data.data.total
      if (state.total == 0 && state.current == 1) {
        //当前列表删除
        message.success('全部忽略成功，自动跳回QA，等待3s后跳回原位置')
        setTimeout(() => {
          back()
        }, 3000)
      } else if (state.list.length == 0 && state.current > 1) {
        state.current = state.current - 1
        httpGetSameQASentList()
      }
    })
    //初始化加载的时候取值。 存到此信息中 ，返回利用取的值
  }

  const ignoreAll = () => {
    store
      .dispatch('rightToolbar/allIgnore', { qaItem: sameQADetail.value })
      .then((res) => {
        httpGetSameQASentList()
      })
    //清除所有state.list
    state.list = []
  }

  const ignoreQA = (item, index) => {
    // console.log(item);
    store
      .dispatch('rightToolbar/ignoreQAError', { qaItem: item })
      .then((res) => {
        httpGetSameQASentList()
      })
    state.list.splice(index, 1)
  }

  const onPaginationChange = (page, pageSize) => {
    // console.log(999)
    httpGetSameQASentList()
  }

  onMounted(() => {
    httpGetSameQASentList()
  })
</script>

<style scoped lang="less">
  .commonQA {
    padding: 0 16px;
    height: 100%;
    background: linear-gradient(
      180deg,
      var(--universal-color-right_toolbar-up) 0%,
      var(--universal-color-right_toolbar-down) 100%
    );
    display: flex;
    flex-direction: column;
    .commonQA-title {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      height: 48px;
      align-items: center;
      .left {
        cursor: pointer;
        font-size: 14px;
        color: #4e5969;
      }
      .center {
        font-size: 14px;
        color: #1c2129;
      }
      border-bottom: 1px solid #e6f1f5;
    }
    .ElScrollBar {
      flex-grow: 1;
    }
    .pagination {
      flex-shrink: 0;
      height: 36px;
      padding-top: 4px;
    }
    .list-title {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .left {
        font-weight: 600;
        color: #1c2129;
      }
      .right {
      }
    }
    .list-description {
      margin-top: 8px;
      margin-bottom: 16px;
      .content {
        .description {
          font-size: 13px;
          line-height: 1.38;
          color: #1c2129;
        }
        .source {
          color: #1c2129;
        }
        .target {
          font-weight: 600;
          color: #00b4fa;
        }
      }
    }
    .commonQA-content {
      .list {
        .list-item {
          cursor: pointer;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(5, 92, 156, 0.04);
          border-radius: 12px;
          padding: 12px 16px;
          border: 1px transparent solid;
          &:hover {
            border-color: #d0e9ff;
          }
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            .left {
              .round {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #4e5969;
                margin-right: 4px;
              }
              .index {
                color: #4e5969;
                font-size: 12px;
              }
            }
            .right {
              .text {
                color: #86909c;
                font-size: 12px;
                margin-left: 12px;
              }
            }
          }
          .content {
            font-size: 13px;
            color: #1c2129;
          }
          .description {
            font-size: 13px;
            color: #1c2129;
            line-height: 1.38;
            word-break: break-all;
            ::v-deep(.qaError) {
              border-bottom: 2px red solid;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
</style>
