<template>
  <div class="recommendSetting">
    <div class="recommendSetting-header">
      <div class="title">
        AI智能识别文件内容，为您推荐最优引擎
        <span class="beta-test">Beta公测版</span>
      </div>
      <div class="title-add">
        推荐是根据海量稿件大数据分析而得，对于综合跨行业的稿件，可能与预期存在差异
      </div>
    </div>
    <div v-show="loading" class="recommendSetting-loading">
      <div class="loading-content">
        <div><img src="./images/loading1.png" /></div>
      </div>
      <div class="loading-text">全力加载中...</div>
    </div>
    <div class="recommendSetting-content" v-show="!loading">
      <div class="content-options">
        <div
          class="options-item"
          v-for="(item, index) in sourceTableData"
          :class="{ 'options-item-selected': item.twinslatorId == checkedId }"
          @click="selectItem(index)"
          :key="index"
        >
          <div class="options-item-content">
            <div class="project">
              {{ item.twinslatorName }}
            </div>
            <div class="language">
              {{ item.languageDirection }} ｜ {{ item.twinslatorName }}
            </div>
            <div class="describe">
              {{ item.description }}
            </div>
            <div class="avgSore">
              <a-rate v-model:value="item.avgScore" disabled />
              <span class="ant-rate-text">{{ item.avgScore }}分</span>
            </div>
            <div class="support">
              <a-space :size="8" align="center">
                <a-space :size="4" align="center">
                  <span>术语替换</span>
                  <img src="./images/support.png" v-if="item.isSupport" />
                  <img src="./images/noSupport.png" v-else />
                </a-space>
                <a-space :size="4" align="center">
                  <span>精准样式</span>
                  <img src="./images/support.png" v-if="item.isPreciseStyle" />
                  <img src="./images/noSupport.png" v-else />
                </a-space>
              </a-space>
            </div>
          </div>
          <div class="options-item-footer">
            <div class="price">{{ item.price }}译力/千字</div>
            <div class="check">
              <img src="./images/checked@2x.png" v-show="item.checked" />
              <img src="./images/noChecked@2x.png" v-show="!item.checked" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="exit-recommend" @click="exitRecommend">
      忽略推荐，我要自己
      <span>选择引擎</span>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, computed } from 'vue'
  import { getTwinslators, getRecommendTwinslators } from '@/http/api'
  import { useStore } from 'vuex'
  import { SearchOutlined } from '@ant-design/icons-vue'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'newSetting',
    components: {
      SearchOutlined,
    },
    setup(props, ctx) {
      const { t } = useI18n()
      const store = useStore()
      //语言方向Map
      const languageMap = new Map()
      //行业方向Map
      const industryMap = new Map()
      //选择的twId
      const checkedId = ref('')
      //源数据
      const sourceTableData = ref([])

      const loading = ref(true)

      store.state.industryList.forEach((item) => {
        industryMap.set(item.industryId, item.industryName)
      })

      store.state.languageList.forEach((item) => {
        languageMap.set(item.languageId, item.languageShortName)
      })

      const selectItem = (index) => {
        sourceTableData.value.forEach((item) => {
          item.checked = false
        })
        sourceTableData.value[index].checked = true
        checkedId.value = sourceTableData.value[index].twinslatorId
      }

      const getSubmitValue = () => {
        return {
          twinslatorId: checkedId.value,
        }
      }

      const exitRecommend = () => {
        ctx.emit('update:settingType', 2)
      }

      onMounted(() => {
        getRecommendTwinslators({ docId: store.state.taskDetail.docId }).then(
          (res) => {
            loading.value = false
            if (res.data.data.list.length == 0) {
              exitRecommend()
              ctx.emit('update:isRecommend', false)
            } else {
              sourceTableData.value = [...res.data.data.list]
              sourceTableData.value.forEach((item) => {
                item.checked = false
                item.languageDirection = '多语种'
                item.industryName = '通用'
                if (item.sourceLangId && item.targetLangId) {
                  item.languageDirection = `${languageMap.get(
                    item.sourceLangId
                  )} - ${languageMap.get(item.targetLangId)}`
                }
                if (item.industryId) {
                  item.industryName = industryMap.get(item.industryId)
                }
              })
            }
          }
        )
      })

      return {
        loading,
        selectItem,
        exitRecommend,
        checkedId,
        getSubmitValue,
        sourceTableData,
        t,
      }
    },
  }
</script>

<style scoped lang="less">
  .recommendSetting {
    .recommendSetting-header {
      margin-bottom: 24px;

      .title {
        //position: relative;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: #333333;
        margin-bottom: 4px;
        .beta-test {
          line-height: 18px;
          text-align: center;
          padding: 0 6px;
          color: white;
          font-size: 9px;
          height: 18px;
          background: linear-gradient(90deg, #0063bd 0%, #00b3f3 100%);
          border-radius: 9px;
          position: relative;
          right: 0;
          top: -9px;
        }
      }

      .title-add {
        text-align: center;
        font-size: 14px;
        color: #bbbbbb;
        line-height: 22px;
      }
    }

    .recommendSetting-loading {
      .loading-content {
        background: url('./images/loadingbg.png');
        background-size: cover;
        width: 800px;
        height: 203px;
        text-align: center;
        padding-top: 10px;

        img {
          width: 160px;
          height: 164px;
        }
      }

      .loading-text {
        margin-top: 18px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }

    .content-options {
      display: flex;
      //justify-content: space-between;

      .options-item {
        position: relative;
        width: 260px;
        box-shadow: 0px 4px 12px 0px rgba(52, 126, 255, 0.4);
        border-radius: 8px;
        border: 2px solid transparent;
        margin-right: 10px;
        cursor: pointer;

        &.options-item-selected {
          border: 2px solid #2a69df;
        }

        .options-item-content {
          //width: 260px;
          width: 100%;
          padding: 32px 20px 0 20px;
          background: url('./images/bg@2x.png');
          background-size: cover;
          height: 190px;

          .project {
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            height: 22px;
            line-height: 22px;
            margin-bottom: 4px;
          }

          .language {
            margin-bottom: 4px;
            color: #ffffff;
            height: 18px;
            font-size: 12px;
            line-height: 18px;
          }

          .describe {
            //margin-bottom: 12px;
            font-size: 12px;
            color: #ffffff;
            height: 36px;
            line-height: 18px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 这里是超出几行省略 */
            overflow: hidden;
          }

          .rate {
            .ant-rate-text {
              color: #333333;
              font-size: 14px;
            }

            margin-bottom: 8px;
          }

          .support {
            color: #666666;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }

        .options-item-footer {
          padding: 0 20px;
          height: 56px;
          line-height: 56px;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          background: white;
          display: flex;
          justify-content: space-between;

          .price {
            font-weight: 600;
            color: #333333;
            font-size: 14px;
          }

          .check {
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    .exit-recommend {
      margin-top: 16px;
      text-align: center;
      height: 22px;
      font-size: 14px;
      color: #999999;
      line-height: 22px;

      span {
        cursor: pointer;
        color: rgba(0, 198, 243, 1);
        text-decoration: underline;
        margin-left: 4px;
      }
    }
  }
</style>
