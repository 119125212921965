<template>
  <a-modal
    v-model:visible="visible"
    title="翻译要求"
    centered
    :width="600"
    wrapClassName="remark-tip-modal"
    :zIndex="99"
    okText="我知道了"
    cancelText="返回"
    @ok="handleOk"
  >
    <div class="remarkTip">
      <div class="remarkTip-content" v-show="descriptionDetail">
        <span>内容：</span>
        {{ descriptionDetail }}
      </div>
      <div class="remarkTip-download" v-if="remarkDownload.length > 0">
        <label>附件：</label>
        <div>
          <span
            @click="download(item)"
            v-for="(item, index) in remarkDownload"
            :key="index"
          >
            {{ item.fileName }}
            <DownloadOutlined />
          </span>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import { DownloadOutlined } from '@ant-design/icons-vue'
  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { $storageUser } from '@/plugins/proxyStorage'
  import { useI18n } from 'vue-i18n'
  import { getProjectDetail } from '@/http/api'
  export default {
    components: {
      DownloadOutlined,
    },
    setup() {
      const { t } = useI18n()
      let visible = ref(false)
      let descriptionDetail = ref('')
      let remarkDownload = ref([])
      const store = useStore()

      const handleOk = () => {
        // console.log(store.state.taskDetail)
        // $storageUser.remarkTip = store.state.taskDetail.docId;
        // 记录当前docId； + translateAsk
        // localStorage.getItem('')
        visible.value = false
        let arr = []
        for (let key in localStorage) {
          //删除对应的key;
          if (key.indexOf('translateAsk') > -1) {
            arr.push(key)
            // localStorage.removeItem(key);
            // break;
          }
        }
        if (arr.length > 10) {
          //全部清空
          arr.forEach((item) => {
            localStorage.removeItem(item)
          })
          //存当前
        }
        localStorage.setItem(
          `translateAsk-${store.state.taskDetail.docId}`,
          'true'
        )
        visible.value = false
      }

      const download = (item) => {
        let url = item.fileUrl || item.accessUrl
        window.open(url)
      }

      onMounted(() => {
        // if ($storageUser.remarkTip == store.state.taskDetail.docId) {
        //   return;
        // } else {
        //   $storageUser.remarkTip = "";
        // }

        // 译员编辑
        // TranslatorEdit,
        //
        //     译员预览
        // TranslatorView,
        //
        //     管理员编辑
        // AdminEdit,
        //
        //     管理员管理（大审校）
        // AdminManage;

        //管理员直接屏蔽
        // if (store.state.taskDetail.webcatPattern != "TranslatorEdit") {
        //   return;
        // }
        if (
          localStorage.getItem(`translateAsk-${store.state.taskDetail.docId}`)
        ) {
          return
        }

        if (store.state.taskDetail.recruitInfo?.requirement) {
          visible.value = true
          const {
            requirement,
            attachmentFiles,
          } = store.state.taskDetail.recruitInfo
          descriptionDetail.value = requirement
          remarkDownload.value = [...attachmentFiles]
        } else if (store.state.taskDetail.webcatPattern == 'TranslatorEdit') {
          getProjectDetail({}).then((res) => {
            if (!res.data.data) {
              return
            }
            //个人任务res.data.data为null 团队内容 res.data.data为对象
            if (
              res.data.data?.description ||
              res.data.data.attachmentList?.length
            ) {
              visible.value = true
              const { description, attachmentList } = res.data.data
              descriptionDetail.value = description
              remarkDownload.value = [...attachmentList]
            }
          })
        }
      })

      return {
        download,
        remarkDownload,
        descriptionDetail,
        visible,
        handleOk,
        t,
      }
    },
  }
</script>

<style lang="less">
  .remark-tip-modal {
    .remarkTip {
      .remarkTip-content {
        span {
          font-weight: bold;
        }
        padding: 4px 0;
        word-break: break-all;
        margin-bottom: 20px;
      }
      .remarkTip-download {
        display: flex;
        margin-bottom: 20px;
        label {
          flex-shrink: 0;
          height: 24px;
          line-height: 24px;
          font-weight: bold;
        }
        div {
          display: flex;
          flex-direction: column;
        }
        span {
          //overflow: hidden;
          //text-overflow: ellipsis;
          //white-space: nowrap;
          //height: 24px;
          word-break: break-all;
          line-height: 24px;
          //display: flex;
          //align-items: center;
          color: #00c6f3;
          cursor: pointer;
        }
        svg {
          margin-left: 8px;
        }
      }
      .footer {
        text-align: right;
      }
    }
  }
</style>
