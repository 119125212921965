import { updateSourceTarget } from '@/http/api'
import filterString from '@/plugins/filterString'
import filterStrings from '@/plugins/filterString'

const state = {
  activeBorder: 'right',
  sentenceList: new Map(),
}
const getters = {}
const mutations = {
  changeKey(state, payload) {
    for (let i in payload) {
      state[i] = null
      state[i] = payload[i]
    }
  },
}
const actions = {
  saveMap({ commit, rootState, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      let params = {
        docId: rootState.taskDetail.docId,
        data: Array.from(state.sentenceList.values()),
      }
      updateSourceTarget({ data: JSON.stringify(params) })
        .then((res) => {
          resolve(res)
          state.sentenceList.clear()
        })
        .catch((rej) => {
          reject(rej)
        })
    })
  },
  resetMap({ commit, rootState, dispatch, state }, params) {
    state.sentenceList.clear()
  },
  //译文结构
  sentTargetCommitMap({ commit, rootState, dispatch, state }, params) {
    params.targetHasChange = true
    let localObj = state.sentenceList.get(params.sentId) || {}
    //译文处增加重复率填充；判断原文是否相同
    state.sentenceList.set(params.sentId, { ...localObj, ...params })

    let sentenceDTOList = rootState.excelProject.docData.sentenceDTOList
    // 提交过来的的实时原文；
    // let originSourcePlaceholder = localObj.hasOwnProperty('sourcePlaceholder') ? localObj.sourcePlaceholder : sentenceDTOList[rootState.excelProject.ArrMap.get(params.sentId)].sourcePlaceholder;
    // // 可能需要更新的译文
    // let renderHTML = filterString.filterImgTag(params.targetPlaceholder);
    //遍历句子进行重复句填充;
    if (params.isSync) {
      sentenceDTOList.forEach((item) => {
        if (item.sentId != params.sentId && item.sourceRepeatHash == params.sourceRepeatHash && item.isSync) {
          //原文编辑更新译文句锁其他重复句的译文框
          item.sourceEditRepeatLock = true
        }
      })
    }
  },
  //原文结构
  sentSourceCommitMap({ commit, rootState, dispatch, state }, params) {
    params.sourceHasChange = true
    let localObj = state.sentenceList.get(params.sentId) || {}
    state.sentenceList.set(params.sentId, { ...localObj, ...params })
    // let sentenceDTOList = rootState.excelProject.docData.sentenceDTOList;

    // if (params.isSync) {
    //   sentenceDTOList.forEach((item) => {
    //     if (item.sentId != params.sentId) {
    //       //原文编辑更新句锁其他重复句 // 原文Hash相同,且为同步状态，则上锁，给标记
    //       if (item.isSync) {
    //         item.locked = true;
    //         item.sourceEditRepeatLock = true;
    //       }
    //     }
    //   })
    // }
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
