
  import { computed, ComputedRef } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { Modal } from 'ant-design-vue'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    setup(props: any, ctx: any) {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })

      const showTermModal = () => {
        if (store.state.taskDetail.termLibrary?.length) {
          store.dispatch('openAddTerm')
        } else {
          Modal.confirm({
            centered: true,
            title: '提示',
            content: '术语存储库未设置或被您删除，请重新设置术语存储库',
            okText: '前往设置',
            cancelText: '取消',
            onOk: () => {
              ctx.emit('opentranslationset', 1)
            },
          })
        }
      }
      return {
        showTermModal,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
