<template>
  <div>
    <a-dropdown v-model:visible="visible" :trigger="['hover']">
      <div class="ant-dropdown-link" @click.prevent style="cursor: pointer">
        <icon-font type="icon-xianshi" class="font-size-18"></icon-font>
        <!--        <img src="../../options.png" style="width:22px;">-->
      </div>
      <template #overlay>
        <div @click="onDropDownClick" class="theme-box padding-20">
          <div class="display-flex">
            <label class="font-size-14 margin-r-20 padding-t-5">外观</label>
            <ul class="skin-ul">
              <li @click="skinValueChange('normal')">
                <div>
                  <img
                    v-if="skinValue == 'normal'"
                    src="../../../../assets/checked.png"
                    width="16"
                    height="16"
                  />
                </div>
                <p>标准</p>
              </li>
              <li @click="skinValueChange('eye')">
                <div>
                  <img
                    v-if="skinValue == 'eye'"
                    src="../../../../assets/checked.png"
                    width="16"
                    height="16"
                  />
                </div>
                <p>护眼</p>
              </li>
            </ul>
            <!-- <a-radio-group
              v-model:value="skinValue"
              size="small"
              @change="skinValueChange"
            >
              <a-radio-button value="normal"
                ><span class="font-size-12">标准</span></a-radio-button
              >
              <a-radio-button value="eye"
                ><span class="font-size-12">护眼</span></a-radio-button
              >
            </a-radio-group> -->
          </div>
          <div
            style="border-bottom: 1px solid #f2f3f5"
            class="margin-tb-20"
          ></div>
          <div class="display-flex align-center">
            <label class="font-size-14 margin-r-20">字号</label>
            <ul class="size-ul">
              <li
                :class="{ active: sizeValue === 'small' }"
                @click="sizeValueChange('small')"
              >
                较小
              </li>
              <li
                :class="{ active: sizeValue === 'normal' }"
                @click="sizeValueChange('normal')"
              >
                标准
              </li>
              <li
                :class="{ active: sizeValue === 'large' }"
                @click="sizeValueChange('large')"
              >
                较大
              </li>
              <li
                :class="{ active: sizeValue === 'huge' }"
                @click="sizeValueChange('huge')"
              >
                更大
              </li>
              <li
                :class="{ active: sizeValue === 'max' }"
                @click="sizeValueChange('max')"
              >
                最大
              </li>
            </ul>
            <!-- <a-radio-group
              v-model:value="sizeValue"
              size="small"
              @change="sizeValueChange"
            >
              <a-radio-button value="small"
                ><span class="font-size-12">较小</span></a-radio-button
              >
              <a-radio-button value="normal"
                ><span class="font-size-12">标准</span></a-radio-button
              >
              <a-radio-button value="large"
                ><span class="font-size-12">较大</span></a-radio-button
              >
              <a-radio-button value="huge"
                ><span class="font-size-12">更大</span></a-radio-button
              >
              <a-radio-button value="max"
                ><span class="font-size-12">最大</span></a-radio-button
              >
            </a-radio-group> -->
          </div>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import {
    defineComponent,
    reactive,
    toRefs,
    createVNode,
    onBeforeMount,
    computed,
    nextTick,
    ref,
  } from 'vue'
  const store = useStore()
  const skinValue = computed({
    set: (val) => {
      store.commit('changeKey', {
        skin: val,
      })
    },
    get: () => store.state.skin,
  })

  const sizeValue = computed({
    set: (val) => {
      store.commit('changeKey', {
        size: val,
      })
    },
    get: () => store.state.size,
  })

  const visible = ref(false)
  const state = reactive({
    skin: {
      normal: {
        '--universal-color-table': '#FFFFFF',
        '--universal-color-hover': '#F1FBFE',
        '--universal-color-border': '#00c6f3',
        '--universal-color-excel': '#F0F8FF',
        '--universal-color-right_toolbar-up': '#F0F8FF',
        '--universal-color-right_toolbar-down': '#F7FBFF',
        '--universal-color-border-hover': '#B3E0EF',
      },
      eye: {
        '--universal-color-table': '#EDFFED',
        '--universal-color-hover': '#E2FCE2',
        '--universal-color-border': '#72D940',
        '--universal-color-excel': '#EDFFED',
        '--universal-color-right_toolbar-up': '#edffed',
        '--universal-color-right_toolbar-down': '#edffed',
        '--universal-color-border-hover': '#B7E7BA',
      },
    },
    size: {
      small: 12,
      normal: 14,
      large: 16,
      huge: 18,
      max: 20,
    },
  })

  const onDropDownClick = () => {}
  const initSkinStyle = (value) => {
    for (let key in state.skin[value]) {
      document.documentElement.style.setProperty(key, state.skin[value][key])
    }
  }

  const initSize = (value) => {
    document.documentElement.style.setProperty(
      '--universal-font-size',
      `${state.size[value]}px`
    )
  }

  const skinValueChange = (e) => {
    skinValue.value = e
    let value = e || e.target.value
    localStorage.setItem('SkinStyle', value)
    initSkinStyle(value)
    // document.documentElement.style.setProperty('--background', value)
  }

  const sizeValueChange = (e) => {
    sizeValue.value = e
    let value = e || e.target.value
    localStorage.setItem('FontSize', value)
    initSize(value)
  }

  onBeforeMount(() => {
    let skin = localStorage.getItem('SkinStyle') || 'normal'
    skinValue.value = skin
    initSkinStyle(skin)
    let size = localStorage.getItem('FontSize') || 'normal'
    sizeValue.value = size
    initSize(size)
  })
</script>

<style scoped lang="less">
  ::v-deep(svg) {
    &:hover {
      color: #00c6f3;
    }
  }
  .theme-box {
    margin-top: 7px;
    margin-right: -116px;
    width: 400px;
    height: 178px;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #f2f3f5;
    .skin-ul {
      margin: 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 12px;
        width: 32px;
        height: 100%;
        line-height: 30px;
        text-align: center;

        cursor: pointer;
        div {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        p {
          margin-top: 4px;
          margin-bottom: 0;
          font-size: 12px;
          color: #86909c;
          line-height: 18px;
        }
        &:first-child {
          div {
            border: 1px solid #e5e6eb;
          }
        }
        &:last-child {
          div {
            background: #edffed;
            border: 1px solid #aeeb90;
          }
        }
      }
    }
    .size-ul {
      margin: 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 12px;
        width: 52px;
        height: 32px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e5e6eb;
        font-size: 14px;
        color: #86909c;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: #1c2129;
          border: 1px solid #4e5969;
        }
      }
    }
  }
</style>
