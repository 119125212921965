
  import { defineComponent, reactive, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'locale',
    setup() {
      const { t, locale } = useI18n()
      const state = reactive({
        lang: localStorage.getItem('locale') || 'zh', //国际化,默认中文
      })
      const changeLocale = (type: any): void => {
        let value = type.target.value
        localStorage.setItem('locale', value)
        locale.value = value
      }
      const changeLang = (type: any) => {
        state.lang = type == 'en' ? 'zh' : 'en'
        localStorage.setItem('locale', state.lang)
        locale.value = state.lang
      }
      return {
        ...toRefs(state),
        t,
        changeLocale,
        changeLang,
      }
    },
  })
