<template>
  <div
    v-if="item.saveFailStatus"
    class="display-flex justify-center align-center"
  >
    <div class="status-mark">
      <img
        src="@/assets/common/nosuccess@2x.png"
        @mousedown="confirmSaveFailInfo(item)"
      />
    </div>
  </div>
  <div class="display-flex justify-center align-center" v-else>
    <!-- 已确认图标 -->
    <div
      @mousedown="submitTargetTextStatus(item, true, $event)"
      class="status-mark"
      v-if="item.isConfirm"
    >
      <img src="@/assets/common/confirm@2x.png" />
    </div>
    <!--新版未确认图标-->
    <div
      @mousedown="submitTargetTextStatus(item, false, $event)"
      class="status-mark"
      v-else
    >
      <img src="@/assets/common/nosuccess@2x.png" />
    </div>
    <targetOrigin :item="item"></targetOrigin>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import useProject from '@/components/source/useProject'
  import { confirmSentences } from '@/http/api'
  import targetOrigin from '@/components/targetOrigin/index.vue'
  import filterStrings from '@/plugins/filterString'

  export default {
    props: {
      item: {
        type: Object,
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    components: {
      targetOrigin,
    },
    setup(props) {
      const store = useStore()
      //taskDetail 用户详情
      const {
        t,
        taskDetail,
        setKeyQueue,
        sendCommitTargetText,
        initKeyQueue,
      } = useProject()
      //当前table选中项
      const tabActiveIndex = computed({
        set: (val) => {
          store.commit('excelProject/changeKey', {
            tabActiveIndex: val,
          })
        },
        get: () => store.state.excelProject.tabActiveIndex,
      })

      const confirmSaveFailInfo = (item, status) => {
        let innerText = filterStrings.getString(
          document.getElementById(item.targetDomId).innerHTML
        )
        store.dispatch('sentCommit', {
          docId: item.docId,
          sentId: item.sentId,
          saveFailStatus: true,
          target: filterStrings.getPureString(innerText),
          targetPlaceholder: innerText,
          sentIndex: item.sentIndex,
        })
        //这里写是为了迅速响应；
        item.isConfirm = true
        item.saveFailStatus = false
      }
      //点击译文状态事件
      const submitTargetTextStatus = (item, status) => {
        if (item.locked) {
          return true
        }
        //原文编辑模式点击也无效
        if (store.state.openEditSourceText) {
          return
        }
        //预览模式点击无用
        if (store.getters.$isPreviewMode) {
          return
        }

        if (
          !store.state.taskDetail.corpusLibrary?.length &&
          store.state.taskDetail.taskClassify  != 11  &&
          !localStorage.getItem(`${store.state.taskDetail.docId}`)
        ) {
          store.commit('modal/changeKey', {
            corpusRemindVisible: true,
          })
          return
        }

        //判断当前点击译文状态项如是否和列表激活项一致
        const isSelectedString =
          tabActiveIndex.value == props.index ? 'Selected' : ''
        //如果不等于Selected的时候 则为点击的是另一句；触发另一句状态提交的钩子就行（走失去焦点事件提交）
        if (status) {
          //如果是true 说明当前句子状态是已经确认所以提交的时候就是未确认
          setKeyQueue(`cancelConfirm${isSelectedString}`)
        } else {
          //如果是false 说明当前句子状态是未确认所以提交的时候就是未确认
          setKeyQueue(`confirm${isSelectedString}`)
        }
        // //如果点击的译文状态和当前编辑时候是同一列
        // if (isSelectedString == "Selected") {
        //   // 提交语句和状态，那边失去焦点事件做了拦截 不会有任何操作
        //   sendCommitTargetText(item, !status);
        //   return;
        // }
        //如果点击的译文状态和当前编辑时候不是同一列
        // if (isSelectedString != "Selected") {
        // 只提交状态 那边正常走失去焦点流程
        let param = {
          docId: store.state.taskDetail.docId,
          isConfirm: !status,
          sentIds: item.sentId,
        }
        item.isConfirm = !status
        //提交语句状态
        confirmSentences(param).then((res) => {})
        return
        // }
      }
      return {
        confirmSaveFailInfo,
        initKeyQueue,
        submitTargetTextStatus,
        t,
        taskDetail,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .status-mark {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 16px;
    }
  }
</style>

<style scoped></style>
