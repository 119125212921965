<template>
  <ElScrollbar class="right_toolbar_scroll">
    <div v-if="logList.length && checkedDetail.sentId" class="log-list">
      <div v-for="(item, index) in logList" class="log-list-item" :key="index">
        <!--          <a-tooltip>-->
        <!--            <template #title>-->
        <!--              <div style="min-width: 120px">-->
        <!--                <div class="font-size-12">-->
        <!--                  更新人：{{ item.createUserName }}-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </template>-->
        <div class="source source-language-text-style">
          <div class="del-source" v-if="item.emptyRow">
            <del>清除了原文译文</del>
          </div>
          <div
            v-else
            class="source-txt log-diffcompare pre-wrap-text"
            v-html="$assignValueToSrc(item.sourceDiffCompare)"
          ></div>
        </div>
        <div
          class="target-txt log-diffcompare pre-wrap-text target-language-text-style"
          v-html="$assignValueToSrc(item.targetDiffCompare)"
        ></div>
        <div class="list-item-footer">
          <div class="apply" v-if="!store.getters.$isPreviewMode">
            <!--预览模式禁止修改译文-->
            <NButton
              @click="apply(item)"
              shape="round"
              size="small"
              v-if="!store.getters.$isPreviewMode"
              :disabled="checkedDetail.locked || disabled"
            >
              应用
            </NButton>
          </div>
          <div class="status">
            <a-tooltip overlayClassName="white-tooltip">
              <template #title>
                <span style="color:#86909C">
                  更新人：{{ item.createUserName }}
                </span>
              </template>
              <div class="createUserName margin-l-12">
                {{ item.createUserName }}
              </div>
            </a-tooltip>
            <span>
              <targetOrigin :item="item"></targetOrigin>
            </span>
            <div class="edit-time">
              {{ dateFormat(item.createTime, 'YYYY-mm-dd HH:MM') }}
            </div>
          </div>
        </div>
        <!--          </a-tooltip>-->
      </div>
    </div>
    <div v-else class="log-list-empty">
      <empty description="暂无数据" />
    </div>
  </ElScrollbar>
</template>

<script>
  import targetOrigin from "@/components/targetOrigin/index.vue";
  import {
    defineComponent,
    computed,
    ref,
    onMounted,
    watch,
    onBeforeMount,
  } from 'vue'
  import empty from '@/components/empty/empty.vue'
  import { useStore } from 'vuex'
  import useTis from '@/components/right_toolbar/tabs/useTip'
  import useUtils from '@/plugins/utils'
  import filterStrings from '@/plugins/filterString'
  import { useI18n } from 'vue-i18n'
  import { getFactTargetText } from '@/plugins/comm.js'
  import {
    RightOutlined,
    InfoCircleOutlined,
    // FormOutlined,
    // DeleteOutlined,
    // ImportOutlined,
    // CaretDownOutlined,
    // QuestionCircleOutlined,
    // QuestionCircleFilled
  } from '@ant-design/icons-vue'

  export default defineComponent({
    components: {
      targetOrigin,
      InfoCircleOutlined,
      empty,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const { apply } = useTis()
      const { dateFormat } = useUtils()
      const activeKey = ref(['1'])
      const retainImportant = (str) => {
        return filterStrings.retainImportant(str)
      }
      //获取当前激活项item
      // const getTableActiveItem = () => {
      //   const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList;
      //   const tabActiveIndex = store.state.excelProject.tabActiveIndex;
      //   return sentenceDTOList[tabActiveIndex];
      // };

      watch(
        () => store.state.checkedDetail.sentId,
        (n) => {
          store.dispatch('rightToolbar/fetchTranslationLogs')
        }
      )

      onBeforeMount(() => {
        store.dispatch('rightToolbar/fetchTranslationLogs')
      })

      return {
        disabled: computed(() => store.state.openEditSourceText),
        t,
        activeKey,
        dateFormat,
        apply,
        store,
        retainImportant,
        logList: computed(() => {
          let newLogList = [...store.state.rightToolbar.logList]
          newLogList.forEach((item) => {
            item.logText = getFactTargetText(item)
          })
          return newLogList
        }),
        checkedDetail: computed(() => store.state.checkedDetail),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .right_toolbar_scroll {
    height: calc(100vh - 100px);
  }
  .log-list-empty {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .log-diffcompare {
    ::v-deep(a) {
      cursor: auto;
    }
    ::v-deep(del) {
      color: #f55c3f;
    }
  }

  .log-list {
    width: 100%;
    //height: 100%;
    //overflow-y: hidden;
    padding: 12px 0;

    .log-list-item {
      padding: 8px 12px;
      background: white;
      box-shadow: 0px 2px 4px 0px rgba(5, 92, 156, 0.04);
      border-radius: 16px;
      //background: #f9f9f9;
      word-break: break-all;
      margin-bottom: 8px;

      .createUserName {
        max-width: 80px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏溢出内容 */
        text-overflow: ellipsis; /* 使用省略号表示截断 */
        font-size: 12px;
        color: #86909c;
      }
      //border-radius: 6px;
      .list-item-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .del-source {
        del {
          font-size: 12px;
          color: #999999;
          line-height: 18px;
        }
      }
      .source {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .source-txt {
          word-break: break-word;
          white-space: pre-wrap;
          line-height: 18px;
          color: #333333;
          font-size: 13px;
        }
        .info {
          flex-shrink: 0;
          flex-basis: 12px;
          margin-left: 12px;
        }
      }

      .target-txt {
        font-size: 13px;
        word-break: break-word;
        white-space: pre-wrap;
        line-height: 18px;
        margin-bottom: 8px;
        color: #86909c;
      }

      .edit-time {
        font-size: 12px;
        color: #bbbbbb;
      }
      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tag {
          display: flex;
          align-items: center;
          margin-right: 8px;

          img {
            width: 32px;
            height: 16px;
          }
        }
      }
    }
  }

  //.ant-collapse {
  //  width: 100%;
  //  background: #fff;
  //
  //  .ant-collapse-content {
  //    border-top: 1px dashed #333;
  //  }
  //
  //  .ant-collapse-item {
  //    border-bottom: 1px solid #669eff;
  //
  //    ::v-deep(.ant-collapse-content-box) {
  //      padding: 0 8px;
  //    }
  //
  //    &:first-child {
  //      border-left: 5px solid #669eff;
  //
  //      ::v-deep(.ant-collapse-header) {
  //        padding: 5px 8px;
  //        background: rgb(228, 237, 246);
  //      }
  //    }
  //  }
  //
  //  .ant-list {
  //    .ant-list-item {
  //      position: relative;
  //      padding: 10px 0;
  //      border-bottom: 1px dashed #333;
  //
  //      &:last-child {
  //        border-bottom: 0;
  //      }
  //    }
  //
  //    ::v-deep(.ant-list-item-meta-title) {
  //      .source {
  //        font-size: 12px;
  //        font-weight: 600;
  //        color: #333;
  //        border-bottom: 1px dashed #ccc;
  //      }
  //
  //      .target {
  //        font-size: 14px;
  //        color: #505050;
  //        word-break: break-all;
  //      }
  //    }
  //
  //    ::v-deep(.ant-list-item-meta-description) {
  //      .content {
  //        font-size: 12px;
  //        font-weight: 0;
  //        line-height: 30px;
  //        color: rgb(80, 80, 80);
  //
  //        .target {
  //          padding-right: 15px;
  //          flex: 0 0 230px;
  //        }
  //
  //        .apply {
  //          flex: 0 0 50px;
  //          color: #00c6f3;
  //          cursor: pointer;
  //          height: 20px;
  //          line-height: 20px;
  //          margin-top: 20px;
  //          display: inline-block;
  //        }
  //
  //        .time {
  //          display: block;
  //          flex: 0 0 180px;
  //          height: 20px;
  //          line-height: 20px;
  //        }
  //
  //        .tag {
  //          display: inline-block;
  //          height: 20px;
  //          line-height: 20px;
  //          position: relative;
  //          top: -1px;
  //          right: 4px;
  //
  //          img {
  //            height: 16px;
  //            width: 24px;
  //          }
  //        }
  //
  //        .author {
  //          display: block;
  //          height: 20px;
  //          line-height: 20px;
  //          flex: auto;
  //        }
  //      }
  //    }
  //  }
  //}
</style>
