<template>
  <CheckOutlined style="color:#0ab923;font-size: 18px" v-show="waiting" />
  <a-button
    :loading="loading"
    :disabled="disabled"
    :size="size"
    :type="type"
    @click="onButtonClick"
    v-show="!waiting"
    :shape="shape"
    class="NButton"
  >
    <slot></slot>
  </a-button>
</template>

<script>
  // import { defineEmits } from 'vue'
  //
  // const emit = defineEmits(['click'])
  import { CheckOutlined } from '@ant-design/icons-vue'
  //用vue2写方便移植
  export default {
    props: ['loading', 'disabled', 'size', 'shape', 'type'],
    name: 'NButton',
    emits: ['click'],
    components: {
      CheckOutlined,
    },
    data: function() {
      return {
        waiting: false,
        timer: null,
      }
    },
    methods: {
      onButtonClick() {
        this.$emit('click')
        this.waiting = true
        this.timer = setTimeout(() => {
          this.waiting = false
        }, 800)
      },
    },
    onBeforeUnmount() {
      clearTimeout(this.timer)
    },
  }
</script>

<style scoped lang="less">
  .NButton {
    background: #f0f8ff;
    color: #00b4fa;
    border-width: 0;
  }
</style>
