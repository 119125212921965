
  import { ref, onBeforeMount } from 'vue'
  import { translationPreview, translationInfo } from '@/http/api'
  import { CloseOutlined } from '@ant-design/icons-vue'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'targetTextView',
    props: ['scrollHeight'],
    components: {
      CloseOutlined,
    },
    setup(props: any, ctx: any) {
      const { t } = useI18n()
      const parent = { ...ctx }
      const targetSrc = ref('')
      const sourceSrc = ref('')
      const windowHeight = ref(0)
      const mode = ref(1)

      const closeTargetView = () => {
        parent.emit('changeTargetTextViewShow')
      }
      const onRadioChange = () => {
        // to do
      }

      const getTranslationInfo = () => {
        translationInfo({}).then((res) => {
          targetSrc.value = res.data.data.translationUrl
          if (
            res.data.data.exportStatus == 0 ||
            res.data.data.exportStatus == 1
          ) {
            setTimeout(() => {
              getTranslationInfo()
            }, 2000)
          }
        })
      }
      const getTranslationPreview = () => {
        translationPreview({ previewType: 3 }).then((res) => {
          targetSrc.value = res.data.data.translationUrl
          sourceSrc.value = res.data.data.originalUrl
          if (!res.data.data.translationUrl) {
            setTimeout(() => {
              getTranslationInfo()
            }, 2000)
          }
        })
      }

      onBeforeMount(() => {
        getTranslationPreview()
        windowHeight.value = document.body.clientHeight - 80
      })
      return {
        targetSrc,
        sourceSrc,
        windowHeight,
        mode,
        closeTargetView,
        onRadioChange,
        t,
      }
    },
  }
