import useUtils from '@/plugins/utils'
import { useStore } from 'vuex'
import { computed, reactive, ComputedRef, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import useProject from '@/components/source/useProject'
import { confirmSentences } from '@/http/api'

export default function targetEditJS() {
  const { appContext } = getCurrentInstance()
  const globalProxy = appContext.config.globalProperties
  const { setFocusEnd, insertHtmlAtCaret } = useUtils();
  const batchDocList = computed(()=>{
    return store.state.batchDocList
  })
  const {
    t,
    taskDetail,
    docData,
    checkedDetail,
  } = useProject()
  const store = useStore()

  //做了两件事点击占位符图片自动将光标引入到前面去，第二件事是记录下光标所在位置，占位符打平功能会导致失去焦点，然后延时还原

  const clickTargetBox = (e, index, item) => {

  }

  const keyupEnter = (e) => {
    // 改造点1。 取消setTimeout
    // 需要等后端改造完毕，然后删除setTimeout代码
    // 译文失去焦点只做提交操作，等回调的时候再利用await
    // 等待结果传递改变译文状态
    // 改造点2。 增加promise 获取机翻，确认译文，清空译文

    // if (e.key == 'Tab') {
    //   let selection = window.getSelection();
    //   let range = selection.getRangeAt(0);
    //   range.insertNode(document.createRange().createContextualFragment(`\n`));
    //   //增加换行符
    //   selection.collapseToEnd();
    //   // document.execCommand('insert')
    //   e.preventDefault();
    // };

    //当前选中项数据
    if (e.shiftKey && e.which == 13) {
      //屏蔽非srt换行
      if (batchDocList.value[checkedDetail.value.docId] != 'srt') {
        e.preventDefault();
      }
      return
    }
    if (e.keyCode === 13) {
      // const list = docData.value.sentenceDTOList;
      // setKeyQueue("enter");
      //上一项数据
      // let localItem = {};
      // let item = null;
      //
      // localItem = list[tabActiveIndex.value];
      //
      // //当前数据
      // //如果触发，只让当前句失去焦点；不做句子移动位置 key 设置为enter 就保证了不会提交 提交操作在下面
      //
      // globalProxy.$getTargetDom(localItem).blur(); //会让失去焦点先执行保证了顺序
      //
      // initKeyQueue();

      //由enter触发切换
      //如果item有数据 则说明不是最后一条 反正则是最后一条
      e.preventDefault()
      return
    }
  }

  return {
    clickTargetBox,
    keyupEnter,
    t,
    taskDetail,
    docData,
    //按键队列
    checkedDetail,
  }
}
