import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21446f74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "targetTextView" }
const _hoisted_2 = { class: "iframe-control" }
const _hoisted_3 = { class: "iframe-window" }
const _hoisted_4 = ["src", "height"]
const _hoisted_5 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_radio_group, {
        value: $setup.mode,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.mode) = $event)),
        onChange: $setup.onRadioChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio_button, { value: 1 }, {
            default: _withCtx(() => [
              _createTextVNode(" 全部原文 ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_radio_button, { value: 2 }, {
            default: _withCtx(() => [
              _createTextVNode(" 全部译文 ")
            ]),
            _: 1
          }),
          _createVNode(_component_a_radio_button, { value: 3 }, {
            default: _withCtx(() => [
              _createTextVNode(" 原译对照 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "onChange"]),
      _createElementVNode("div", {
        class: "iframe-close",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.closeTargetView && $setup.closeTargetView(...args)))
      }, [
        _createVNode(_component_CloseOutlined)
      ])
    ]),
    _createVNode(_component_a_spin, {
      tip: "正在读取译文，请稍等",
      spinning: !$setup.targetSrc && $setup.mode != 1
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("iframe", {
            name: "sourceIframe",
            src: $setup.sourceSrc,
            frameborder: "0",
            scrolling: "auto",
            class: "iframe",
            height: $setup.windowHeight
          }, null, 8, _hoisted_4), [
            [_vShow, $setup.mode == 1 || $setup.mode == 3]
          ]),
          _withDirectives(_createElementVNode("iframe", {
            name: "targetIframe",
            src: $setup.targetSrc,
            frameborder: "0",
            scrolling: "auto",
            class: "iframe",
            height: $setup.windowHeight
          }, null, 8, _hoisted_5), [
            [_vShow, $setup.mode == 2 || $setup.mode == 3]
          ])
        ])
      ]),
      _: 1
    }, 8, ["spinning"])
  ]))
}