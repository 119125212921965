<template>
  <a-modal
    :visible="visible"
    wrapClassName="putTermModal"
    title="编辑术语"
    okText="保存"
    cancelText="取消"
    centered
    @cancel="cancel"
  >
    <p>
      <span class="title">来源：</span>
      <span>《{{ detail.libName }}》</span>
    </p>
    <p>
      <span class="title">原文：</span>
      <span  class="source-language-text-style">{{ detail.source }}</span>
    </p>
    <p>
      <span class="title">译文：</span>
      <a-input
        v-model:value="detail.target"
        placeholder="请输入译文"
        size="small"
        class="target-language-text-style"
      />
    </p>
    <p>
      <span class="title">备注：</span>
      <a-input
        v-model:value="detail.comment"
        placeholder="不超过500字"
        size="small"
        :maxlength="500"
      />
    </p>
    <p>
      <span class="title">更新时间：</span>
      <span>{{ dateFormat(detail.lastModifyTime, 'YYYY-mm-dd HH:MM') }}</span>
    </p>
    <p>
      <span class="title">更新人：</span>
      <span>{{ detail.createUserName }}</span>
    </p>
    <template #footer>
      <div class="display-flex justify-between">
        <div>
          <!--          <a-button @click="openDel">删除术语</a-button>-->
        </div>
        <div>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="putOk">保存</a-button>
        </div>
      </div>
    </template>
  </a-modal>
  <a-modal
    v-model:visible="delVisible"
    title="删除术语"
    okText="确定删除"
    cancelText="取消"
    centered
    @ok="delTerm"
    @cancel="delCancel"
  >
    <div style="text-align: center">
      <a-space direction="vertical" align="center" :size="16">
        <div>
          <img src="../image/warning.png" width="56" />
        </div>
        <div style="color:#333;font-weight: bold;" class="font-size-18">
          确定删除该术语吗
        </div>
      </a-space>
    </div>
    <div style="color:#666666" class="margin-t-16">
      <div class="margin-b-12 display-flex">
        <span style="flex-shrink: 0">术语原文：</span>
        <span class="source-language-text-style">{{ detail.source }}</span>
      </div>
      <div class="display-flex">
        <span style="flex-shrink: 0">术语译文：</span>
        <span class="target-language-text-style">{{ detail.target }}</span>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import useUtils from '@/plugins/utils'
  import useTis from '../useTip'
  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { message, Modal } from 'ant-design-vue'
  import { postDeleteTerm } from '@/http/api'

  export default {
    props: ['visible', 'termDetail', 'delVisible'],
    setup(props, ctx) {
      const { dateFormat } = useUtils()
      const store = useStore()
      const { put } = useTis()

      const detail = ref({})

      const cancel = () => {
        ctx.emit('update:visible', false)
      }

      const putOk = () => {
        put(detail.value).then(() => {
          cancel()
          store.dispatch('excelProject/twinslatorMatch')
        })
      }

      const delCancel = () => {
        ctx.emit('update:delVisible', false)
      }
      const delTerm = () => {
        // console.log(detail.value);
        postDeleteTerm({
          libraryId: detail.value.libId,
          entryId: detail.value.id,
        })
          .then((res) => {
            if (res.data.data) {
              message.success('删除成功')
            } else {
              message.error('删除失败')
            }
          })
          .finally(() => {
            store.dispatch('excelProject/twinslatorMatch')
            delCancel()
            cancel()
          })
      }

      // const openDel = () => {
      //   delVisible.value = true;
      // };

      onMounted(() => {
        detail.value = JSON.parse(JSON.stringify(props.termDetail))
      })

      return {
        delCancel,
        // openDel,
        delTerm,
        dateFormat,
        putOk,
        detail,
        cancel,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .putTermModal {
    p {
      display: flex;
      align-items: center;
      line-height: 32px;
      .title {
        flex: 0 0 100px;
        text-align: right;
        color: #333;
      }
    }
  }
</style>
