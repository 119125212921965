
  import { computed, onMounted, ref } from 'vue'
  import { useStore } from 'vuex'
  import {
    SearchOutlined,
    // CheckOutlined,
    // FullscreenExitOutlined
  } from '@ant-design/icons-vue'
  import { useI18n } from 'vue-i18n'
  import { closeProgress } from '@/http/api'

  export default {
    components: {
      SearchOutlined,
      // CheckOutlined,
      // FullscreenExitOutlined
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      //是否缩小
      let isReduce = ref(true)
      onMounted(() => {
        //如果开始就调用接口进度 更新progress
      })
      //切换缩小和展开
      const changeIsReduce = () => {
        isReduce.value = !isReduce.value
      }
      const close = () => {
        store.dispatch('getSentence')
        closeProgress({}).then(() => {
          store.dispatch('checkProgress') //请求qa预览进度
        })
      }
      const see = () => {
        store.commit('changeKey', {
          qaProgressVisible: true,
        })
      }
      return {
        store,
        isReduce,
        changeIsReduce,
        close,
        see,
        t,
        checkProgressDetail: computed(() => store.state.checkProgressDetail),
      }
    },
  }
