<template>
  <div class="sourceEditHeader">
    <div class="sourceEditHeader-content">
      <a-space :size="20">
        <div
          v-show="store.state.openEditSourceText"
          class="sourceEditHeader-content-item"
        >
          <img src="./images/tips.png" />
          <label>当前处于原文编辑模式</label>
          <span @click="save">保存修改并退出</span>
          <span @click="noSave">不保存并退出</span>
        </div>
      </a-space>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n' //国际化

  import { computed, defineComponent } from 'vue'
  import { Modal, message } from 'ant-design-vue'
  import { positionSentence } from '@/plugins/comm'
  export default defineComponent({
    components: {},
    setup() {
      const { t } = useI18n()
      const store = useStore()
      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex] || {}
      }

      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      const saveAfterPlugin = () => {
        setTimeout(() => {
          store.commit('changeKey', {
            screenLoading: true,
          })
          store
            .dispatch('sourceEdit/saveMap')
            .then((res) => {
              store.commit('sourceEdit/changeKey', {
                activeBorder: 'right',
              })
              store.commit('changeKey', {
                openEditSourceText: false,
              })
              store
                .dispatch('getSentence', { isCloseSource: true, loading: true })
                .then(() => {
                  //此时判断checkedDetail 的sentId是否存在。
                  if (
                    store.state.excelProject.ArrMap.has(
                      checkedDetail.value.sentId
                    )
                  ) {
                    store.commit('changeKey', {
                      checkedDetail: {
                        ...store.state.excelProject.docData.sentenceDTOList[
                          store.state.excelProject.ArrMap.get(
                            checkedDetail.value.sentId
                          )
                        ],
                      },
                    })
                  } else {
                    let tableItem =
                      store.state.excelProject.docData.sentenceDTOList[0] || {}
                    store.commit('changeKey', {
                      checkedDetail: { ...tableItem },
                    })
                  }
                  store.dispatch('excelProject/twinslatorMatch')
                  positionSentence(checkedDetail.value.sentId)
                })
            })
            .catch((rej) => {
              message.warning('保存失败了，请重试')
            })
            .finally(() => {
              store.commit('changeKey', {
                screenLoading: false,
              })
            })
        }, 200)
      }
      const save = () => {
        let sourceEmpty = 0
        store.state.sourceEdit.sentenceList.forEach((item) => {
          if (!item.sourcePlaceholder && item.targetPlaceholder) {
            sourceEmpty++
          }
        })
        if (sourceEmpty > 0) {
          Modal.confirm({
            title: '提示',
            content: `当前文件有${sourceEmpty}句原文为空，译文也将同步被清除`,
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk() {
              saveAfterPlugin()
            },
            onCancel() {},
          })
          return
        }

        saveAfterPlugin()

        //重新调用方法； 遍历当前的map 有没有原文为空的句子
      }

      const noSaveAfterPlugin = () => {
        //给弹窗提示
        setTimeout(() => {
          store.commit('sourceEdit/changeKey', {
            activeBorder: 'right',
          })
          store.dispatch('sourceEdit/resetMap')
          store.commit('changeKey', {
            openEditSourceText: false,
          })
          store.dispatch('getSentence', { isCloseSource: true, loading: true }).then((res) => {
              if (
                store.state.excelProject.ArrMap.has(checkedDetail.value.sentId)
              ) {
                store.commit('changeKey', {
                  checkedDetail: {
                    ...store.state.excelProject.docData.sentenceDTOList[
                      store.state.excelProject.ArrMap.get(
                        checkedDetail.value.sentId
                      )
                    ],
                  },
                })
              } else {
                let tableItem =
                  store.state.excelProject.docData.sentenceDTOList[0] || {}
                  store.commit('changeKey', {
                    checkedDetail: { ...tableItem },
                  })
              }
              store.dispatch('excelProject/twinslatorMatch')
              positionSentence(checkedDetail.value.sentId)
            })
        }, 200)
      }

      const noSave = () => {
        if (store.state.sourceEdit.sentenceList.size > 0) {
          Modal.confirm({
            title: '提示',
            content: '当前有未保存的编辑内容确定不保存',
            centered: true,
            okText: '确认',
            cancelText: '取消',
            onOk() {
              noSaveAfterPlugin()
            },
            onCancel() {},
          })
          return
        }
        noSaveAfterPlugin()
      }

      return {
        save,
        noSave,
        t,
        store,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .sourceEditHeader {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: #00c2f3;
    box-shadow: 0px 2px 4px 0px rgba(2, 73, 89, 0.05);
    .sourceEditHeader-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .sourceEditHeader-content-item {
        height: 48px;
        align-items: center;
        display: flex;
      }
      img {
        width: 24px;
        height: 24px;
      }
      label {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }
      span {
        margin-left: 10px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        padding: 6px 10px;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }
    }
  }
</style>
