import { createApp, createVNode } from 'vue'
import { message,notification  } from 'ant-design-vue'
import levelMessage from '@/components/$Message/levelMessage.vue'
import store from '@/store/index.ts'
import preprocessingMessage from "@/components/$Message/preprocessingMessage.vue"
//判断是否是企业还是个人

window.$levelMessage = (msgInfo) => {
  if (store.state.taskDetail.enterpriseId == 'personal') {
    message.success({
      icon: createVNode('span'),
      content: createVNode(levelMessage, {
        msgInfo: { msg: `${msgInfo.msg}，升级版本可享受更多权益` },
      }),
      duration: 3,
    })
  } else {
    message.warning({
      content: `${msgInfo.msg}，可联系企业管理员升级企业版本`,
      duration: 3,
    })
  }
}
