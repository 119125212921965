import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6411edf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "margin-l-12 document-list" }
const _hoisted_2 = { class: "flex-center" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  key: 1,
  class: "font-size-12 padding-lr-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "document-list-title",
        title: $setup.taskDetail.docName
      }, _toDisplayString($setup.taskDetail.docName), 9, _hoisted_3),
      (!$setup.store.getters.$isPreviewMode)
        ? (_openBlock(), _createBlock(_component_icon_font, {
            key: 0,
            class: "font-size-16",
            type: "icon-zishu"
          }))
        : _createCommentVNode("", true),
      (!$setup.store.getters.$isPreviewMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 翻译字数：" + _toDisplayString($setup.wordCount.translateWordCount || '0') + "/" + _toDisplayString($setup.wordCount.totalWordCount || '0'), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}