import {
  updateBatchTarget,
  updateSourceTarget,
  webcatSentenceUnLock,
} from '@/http/api'
import useUtils from '@/plugins/utils'
import { message, Modal } from 'ant-design-vue'
import filterString from '@/plugins/filterString'
import filterStrings from '@/plugins/filterString'
import useProject from '@/components/source/useProject'
// import {getCurrentInstance} from "vue";
const { setFocusEnd } = useUtils()
import mitt from '@/plugins/bus'
import {positionSentence} from "@/plugins/comm";

const state = {
  excelWidth: 1000,
  shiftKeyStatus: false,
  lastShiftKeyCheckedIndex: -1,
  sourceEditFocus: false,
  allowSaveSnapshot: false,
}
const getters = {}
const mutations = {
  changeKey(state, payload) {
    for (let i in payload) {
      state[i] = payload[i]
    }
  },
}
const actions = {
  unLockSentence({ commit, rootState, dispatch, state }, params) {
    if (rootState.openEditSourceText) {
      return
    }
    const sentenceDTOList = rootState.excelProject.docData.sentenceDTOList
    const tabActiveIndex = rootState.excelProject.tabActiveIndex
    let submitList = []
    let sentIds = ''
    if (params) {
      submitList.push(params)
    } else {
      if (rootState.excelProject.checkedList?.length) {
        submitList = [...rootState.excelProject.checkedList]
      } else {
        const selectedItem = sentenceDTOList[tabActiveIndex]
        submitList.push(selectedItem)
      }
    }

    if (!submitList?.length) {
      return
    }

    //如果需要出弹窗
    if (params && params.isSync) {
      //提示弹窗 // 同时需要比对选中句是否相同
      let isIdenticalSent = params.sentId == rootState.checkedDetail.sentId
      if (isIdenticalSent) {
        rootState.excelProject.keyQueue = 'stop'
      }
      mitt.emit('openSyncLockSentenceModal', {
        type: 'unLockVisible',
        okCallBack(checkValue) {
          sentIds = submitList
            .map((item) => {
              return item.sentId
            })
            .join(',')

          let paramsObj = {
            sentIds,
            isLock: false,
            isCancelSync: checkValue,
          }
          // rootState.screenLoading = true;
          webcatSentenceUnLock(paramsObj)
            .then((res) => {
              let isManage = rootState.taskDetail.taskClassify == 8
              submitList.forEach((item) => {
                item.isSync = !checkValue
                if (isManage && item.isLocked) {
                  //管理员且原始就被锁。只需要解锁不更改来源
                  item.isTranslatorUnLock = true
                } else if (isManage && !item.isLocked) {
                  //管理员且原始就没锁。只需要解锁不更改来源 无需处理。
                } else if (!isManage && item.isLocked) {
                  //普通译员解锁
                  item.locked = false
                  item.isTranslatorUnLock = true
                }
                //判断当前角色是否为管理员
              })
              message.success(`句子解锁成功`)
            })
            .finally(() => {
              //如果是选中和解锁是相同句子，则清空阻塞提交，并且得到焦点; 为了避免 译文同时提交，且取消同步的问题
              if (isIdenticalSent) {
                rootState.excelProject.keyQueue = ''
                setFocusEnd(
                  window.$getTargetDom(params.sentId),
                  true
                )
              }
            })
          rootState.excelProject.checkedList.forEach((item) => {
            item.checked = false
          })
          rootState.excelProject.checkedList = []
        },
        cancelCallBack() {
          if (isIdenticalSent) {
            rootState.excelProject.keyQueue = '';
            window.$getTargetDom(params.sentId).focus();
          }
        },
      })
      return
    } else {
      //如果不需要出弹窗直接锁定的那种
      sentIds = submitList
        .map((item) => {
          return item.sentId
        })
        .join(',')

      let paramsObj = {
        sentIds,
        isLock: false,
      }
      // rootState.screenLoading = true;
      webcatSentenceUnLock(paramsObj).then((res) => {
        let isManage = rootState.taskDetail.taskClassify == 8
        submitList.forEach((item) => {
          if (isManage && item.isLocked) {
            //管理员且原始就被锁。只需要解锁不更改来源
            item.isTranslatorUnLock = true
          } else if (isManage && !item.isLocked) {
            //管理员且原始就没锁。只需要解锁不更改来源 无需处理。
          } else if (!isManage && item.isLocked) {
            //普通译员解锁
            item.locked = false
            item.isTranslatorUnLock = true
          }
          //判断当前角色是否为管理员
        })
        message.success(`句子解锁成功`)
        // dispatch("getSentence",{loading:true},{
        //   root:true
        // })
      })
      rootState.excelProject.checkedList.forEach((item) => {
        item.checked = false
      })
      rootState.excelProject.checkedList = []
    }
  },
  lockSentence({ commit, rootState, dispatch, state }, params) {
    if (rootState.openEditSourceText) {
      return
    }
    const sentenceDTOList = rootState.excelProject.docData.sentenceDTOList
    const tabActiveIndex = rootState.excelProject.tabActiveIndex
    let submitList = []
    let sentIds = ''
    if (params) {
      submitList.push(params)
    } else {
      if (rootState.excelProject.checkedList?.length) {
        submitList = [...rootState.excelProject.checkedList]
      } else {
        const selectedItem = sentenceDTOList[tabActiveIndex]
        submitList.push(selectedItem)
      }
    }

    if (!submitList?.length) {
      return
    }
    sentIds = submitList
      .map((item) => {
        return item.sentId
      })
      .join(',')

    let paramsObj = {
      sentIds,
      isLock: true,
    }
    // rootState.screenLoading = true;
    webcatSentenceUnLock(paramsObj).then((res) => {
      let isManage = rootState.taskDetail.taskClassify == 8
      submitList.forEach((item) => {
        if (isManage && item.isLocked && item.isTranslatorUnLock) {
          //管理员且原始就被锁并且译员解锁 需要重新锁上 来源不变。
          item.isTranslatorUnLock = false
        } else if (isManage && !item.isLocked) {
          //管理员且原始就没锁。需要加锁。直接无脑变红
          item.hasPMOperation = true
          item.isLocked = true
        } else if (!isManage && item.isLocked) {
          //普通译员锁定 不该isLocked
          //如果是普通译员，需要锁定
          item.locked = true
          // taskType=2是审校
          //审校
          if (rootState.taskDetail.taskType == 2) {
            item.locked = false
          }
          item.isTranslatorUnLock = false
        }
        //判断当前角色是否为管理员
      })
      message.success(`句子锁定成功`)
      // console.log(rootState.excelProject.docData.sentenceDTOList)
    })
    rootState.excelProject.checkedList.forEach((item) => {
      item.checked = false
    })
    rootState.excelProject.checkedList = []
  },
  //复制原文
  copySource({ commit, rootState, dispatch, state }, params) {
    //处理批量 or 单句
    if (rootState.excelProject.checkedList?.length) {
      rootState.modal.copySourceVisible = true
    } else {
      const { sentId } = rootState.checkedDetail
      if (!sentId) {
        message.warning('请先选择句子')
        return false
      }
      const sentenceDTOList = rootState.excelProject.docData.sentenceDTOList
      const tabActiveIndex = rootState.excelProject.tabActiveIndex
      let activeItem = sentenceDTOList[tabActiveIndex]
      let dom = document.getElementById(activeItem.targetDomId)
      dom.innerHTML = activeItem.sourcePlaceholder
      setFocusEnd(dom, true)
      dom = null
    }

    // commit("excelProject/changeKey", {
    //     keyQueue: "stop"
    // });
    //开始处理批量操作
  },
  //下一句
  nextSentence({ commit, rootState, dispatch, state }, params) {
    // console.log(rootState);
    const sentenceDTOList = rootState.excelProject.docData.sentenceDTOList
    let tabActiveIndex = rootState.excelProject.tabActiveIndex
    if (tabActiveIndex < sentenceDTOList.length - 1) {
      tabActiveIndex++
      let activeItem = sentenceDTOList[tabActiveIndex]
      commit(
        'changeKey',
        {
          checkedDetail: {
            ...activeItem,
          },
        },
        { root: true }
      )
      commit(
        'excelProject/changeKey',
        {
          tabActiveIndex: tabActiveIndex,
        },
        { root: true }
      )
      let dom = window.$getTargetDom(activeItem.sentId);
      if (rootState.collapseGroup.includes(activeItem.groupParentId)) {
        positionSentence(activeItem.sentId,true)
      } else {
        setFocusEnd(dom, true)
      }
      if (rootState.continuedMt && !filterStrings.filterSpace(dom.innerText)) {
        dispatch(
          'twinslatorMt',
          {
            sentIds: activeItem.sentId,
          },
          { root: true }
        )
      }
    }
  },
  //上一句
  lastSentence({ commit, rootState, dispatch, state }, params) {
    const sentenceDTOList = rootState.excelProject.docData.sentenceDTOList
    let tabActiveIndex = rootState.excelProject.tabActiveIndex
    //如果tabActiveIndex 不为第一句，且列表有数据；
    if (tabActiveIndex > 0 && sentenceDTOList.length) {
      //切到上一句
      tabActiveIndex--
      //获取激活项
      let activeItem = sentenceDTOList[tabActiveIndex]
      //更新选择记录以及值
      commit(
        'changeKey',
        {
          checkedDetail: { ...activeItem },
        },
        { root: true }
      )
      commit(
        'excelProject/changeKey',
        {
          tabActiveIndex: tabActiveIndex,
        },
        { root: true }
      )
      //获取dom节点
      let dom = window.$getTargetDom(activeItem.sentId);
      //获取焦点；
      //判断是否处于折叠
      if (rootState.collapseGroup.includes(activeItem.groupParentId)) {
        positionSentence(activeItem.sentId,true)
      } else {
        setFocusEnd(dom, true)
      }
      //配置连续机翻
      if (rootState.continuedMt && !filterStrings.filterSpace(dom.innerText)) {
        dispatch(
          'twinslatorMt',
          {
            sentIds: activeItem.sentId,
          },
          { root: true }
        )
      }
    }
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
