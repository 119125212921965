
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  export default {
    components: {},
    props: ['disabled'],
    setup() {
      const store = useStore()
      const { t } = useI18n()
      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex] || {}
      }
      const differentHighLightModel: any = computed({
        set: (val) => {
          store.commit('changeKey', {
            continuedMt: false,
          })
          store.commit('excelProject/changeKey', {
            differentHighLightModel: val,
          })
          setTimeout(() => {
            store.dispatch('getSentence', { loading: true }).then(() => {
              let item = getTableActiveItem()
              store.commit('changeKey', {
                checkedDetail: { ...item },
              })
              store.dispatch('excelProject/twinslatorMatch')
            })
          }, 300)
        },
        get: () => {
          return store.state.excelProject.differentHighLightModel
        },
      })
      return {
        store,
        differentHighLightModel,
        t,
      }
    },
  }
