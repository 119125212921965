<template>
  <div>
    <div
        class="qa-list"
        v-for="(item, index) in qaList"
        :key="index"
        :class="{
        'is-active': qaIndex == item.id,
        ignore: item.isIgnore,
      }"
    >
      <div class="qa-list-item" @mousedown.stop="listClick(item)">
        <div class="title display-flex justify-between align-center">
          <div class="left">
            <span class="round"></span>
            <label v-if="type == 'full'">句对：{{ item.extSentId }}</label>
            <label v-else>
              {{ checkRule[item.checkRule] }}
            </label>
          </div>
          <div class="right" v-if="!store.getters.$isPreviewMode">
            <span class="text" @click.stop="ignoreQAError(item)">忽略</span>
            <!--术语漏译，标签错误不要全文忽略-->
            <span
                class="text"
                @click.stop="allTargetIgnore(item)"
                v-if="
                item.checkRule != 'TERM_CONSISTENCY_CHECK' &&
                  item.checkRule != 'FORMAT_CHECK'
              "
            >
              全部忽略
            </span>
            <!--只有拼写检查有-->
            <!--            <span class="text" @click="spellConfirm(item)" v-if="item.checkRule == 'SPELL_CHECK'">拼写无误</span>-->
          </div>
        </div>

        <div class="content" v-if="item.checkRule == 'RULE_LOWE_CHECK' && item.errorType == 'RemoveExtraSpace'">
          <a-tooltip>
            <template #title>
              <button
                  class="design-apply-text-button"
                  @mousedown.stop="replaceErrorWorld(item, 0)"
              >
                点击应用
              </button>
            </template>
            <button
                class="design-apply-button float-tip-dropdown-button"
                @mousedown.stop="replaceErrorWorld(item, 0)"
            >
              删除多余空格
            </button>
          </a-tooltip>
        </div>
        <div
            class="content"
            v-else-if="
            item.checkRule == 'RULE_LOWE_CHECK' &&
              item.errorType == 'UpperFirstLetter'
          "
        >
          <a-tooltip>
            <template #title>
              <button
                  class="design-apply-text-button"
                  @mousedown.stop="replaceErrorWorld(item, 0)"
              >
                点击应用
              </button>
            </template>
            <button
                class="design-apply-button float-tip-dropdown-button"
                @mousedown.stop="replaceErrorWorld(item, 0)"
            >
              首字母大写
            </button>
          </a-tooltip>
        </div>

        <div
            class="content"
            v-else-if="item.checkRule == 'TERM_CONSISTENCY_CHECK'"
        >
          <span class="description">
            术语【{{ item.original }}】漏译，应为：
          </span>
          <a-tooltip>
            <template #title>
              <button
                  class="design-apply-text-button"
                  @mousedown="applyTerm(item, 0)"
              >
                点击插入光标处
              </button>
            </template>
            <button
                class="design-apply-button float-tip-dropdown-button target-language-text-style"
                @mousedown="applyTerm(item, 0)"
            >
              {{ item.suggestionsList[0] }}
            </button>
          </a-tooltip>
        </div>

        <div class="content" v-else-if="item.suggestionsList?.length">
          <span class="source">{{ item.original }}</span>
          <CaretRightOutlined class="margin-lr-6"/>
          <a-tooltip>
            <template #title>
              <button
                  class="design-apply-text-button"
                  @mousedown.stop="replaceErrorWorld(item, 0)"
              >
                点击应用
              </button>
            </template>
            <button
                class="design-apply-button float-tip-dropdown-button"
                @mousedown.stop="replaceErrorWorld(item, 0)"
            >
              <span :class="{'target-language-text-style':item.checkRule == 'SPELL_CHECK'}">{{
                  item.suggestionsList[0]
                }}</span>
              <icon-font
                  type="icon-xiala"
                  style="color:#4E5969;cursor: pointer"
                  v-if="item.suggestionsList.length > 1"
              ></icon-font>
              <div
                  class="float-tip-dropdown"
                  style="z-index: 1"
                  v-if="item.suggestionsList.length > 1"
              >
                <ul class="float-tip-dropdown-ul">
                  <li
                      v-for="(citem, cindex) in item.suggestionsList"
                      :key="cindex"
                  >
                    <button
                        :class="{'target-language-text-style':item.checkRule == 'SPELL_CHECK'}"
                        class="design-apply-text-button font-size-14"
                        @mousedown.stop="menuClick(cindex, item, index)"
                    >
                      {{ citem }}
                    </button>
                  </li>
                </ul>
              </div>
            </button>
          </a-tooltip>
        </div>

        <div class="content" v-else>
          <span
              v-if="item.checkRule == 'FORMAT_CHECK'"
              v-html="$assignValueToSrc(item.descriptions)"
              class="description target-txt target-language-text-style"
          ></span>
          <span v-else v-html="item.descriptions" class="description target-txt"></span>
        </div>

        <div
            class="footer"
            @click="openSameQA(item)"
            v-if="
            item.sameUniqueFeatureSentIdCount && item.sameUniqueFeatureSentIdCount > 1
          "
        >
          <div class="left">
            {{ item.sameUniqueFeatureSentIdCount }}处相同QA
          </div>
          <div class="right">
            <icon-font type="icon-tiaozhuan"></icon-font>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  getCurrentInstance,
  reactive,
  ref,
  toRefs,
  onMounted,
  defineEmits,
} from 'vue'

import {
  CaretRightOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons-vue'
import {useStore} from 'vuex'
import useUtils from '@/plugins/utils'
import {postReComputePage} from '@/http/api'
import {message, Modal} from 'ant-design-vue'
import {positionSentence} from '@/plugins/comm'
import useTis from '@/components/right_toolbar/tabs/useTip'

const checkRule = computed(() => store.state.rightToolbar.checkRule)

const emit = defineEmits(['ignoreClick'])
const props = defineProps({
  qaList: Array,
  type: String,
})
const {setFocusEnd, insertHtmlAtCaret} = useUtils()
const {qaList} = toRefs(props)
const {appContext} = getCurrentInstance()
const globalProxy = appContext.config.globalProperties
const qaIndex = ref(-1)
const store = useStore()
const {del, apply} = useTis()

const getTableActiveItem = () => {
  const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
  const tabActiveIndex = store.state.excelProject.tabActiveIndex
  return sentenceDTOList[tabActiveIndex] || {}
}

//忽略，恢复

const openSameQA = (item) => {
  // listClick(item);
  //同时需要绑定值 存下相同QA值
  store.commit('rightToolbar/changeKey', {
    sameQAOpen: true,
    sameQADetail: {
      ...item,
    },
  })
}

const replaceErrorWorldFunc=(item, key)=>{
  // console.log(item,key)
  if (store.getters.$isPreviewMode) {
    return
  }

  let sentenceItem = store.state.excelProject.docData.sentenceDTOList[store.state.excelProject.ArrMap.get(item.sentId)]
  if (sentenceItem && sentenceItem.locked) {
    message.warning('锁定句禁止做任何操作')
    return
  }
  //获取QAClass
  let domList = document.getElementsByClassName(item.id)
  for (let i = 0; i < domList.length; i++) {
    let dom = domList[0]
    store.commit('excelProject/changeKey', {
      keyQueue: 'stop',
    })

    if (item.errorCode == '3002') {
      let originalStr = dom.innerHTML;
      let replaceStr = originalStr.substring(0,1).toUpperCase();
      dom.innerHTML = replaceStr+originalStr.slice(1);
      // originalStr
      // dom.innerHTML = originalStr.substring(0, 1) + originalStr.slice(1).replace(/.*/, replacementStr);
    } else {
      dom.innerHTML = item.suggestionsList[key]
    }

    // let spliceIndex = qaList.value.findIndex((citem) => {
    //   if (item.id == citem.id) {
    //     return true
    //   }
    // })
    // qaList.value.splice(spliceIndex, 1)
    setTimeout(() => {
      store.commit('excelProject/changeKey', {
        keyQueue: '',
      })
      // setFocusEnd(dom, true)
      if (store.state.collapseGroup.includes(item.groupParentId)) {
        positionSentence(item.sentId,true)
      } else {
        //当前选中句得到焦点，并放置到最后
        setFocusEnd(dom, true)
      }
    }, 200)
  }
}

const replaceErrorWorld = (item, key) => {
  console.log(item,key);
  listClick(item,key);
}

const applyTerm = (titem) => {
  let appItem = {
    target: titem.suggestionsList[0],
    sentId: titem.sentId,
  }
  apply(appItem, 'insert')
  // if (checkedDetail.value.locked) {
  //   message.warning("当前选中句为锁定句，不可插入")
  // }
  // console.log(checkedDetail.value.locked)
}


//将错误高亮出来
const hightLightError = (item) => {
  // 比较译文hash是否相同 ,再决定高亮 ;
  let oldList = document.getElementsByClassName('QA_Error_HighLight')
  for (let i = 0; i < oldList.length; i++) {
    let item = oldList[i]
    item.classList.remove('QA_Error_HighLight');
    i--
  }

  //现在都是class
  let domList = document.getElementsByClassName(item.id)
  for (let i = 0; i < domList.length; i++) {
    let item = domList[i]
    item.classList.add('QA_Error_HighLight')
  }
}

const toSentence = (item) => {
  positionSentence(item.sentId)
  hightLightError(item)
}


const ignoreQAError = (item) => {
  item.isIgnore = true
  store
      .dispatch('rightToolbar/ignoreQAError', {qaItem: item})
      .then((res) => {
      })
  emit('ignoreClick')
}

//全文忽略
const allTargetIgnore = (item) => {
  item.isIgnore = true
  //需要传参
  store.dispatch('rightToolbar/allIgnore', {qaItem: item})
  emit('ignoreClick')
}

//拼写无误
const spellConfirm = (item) => {
  item.isIgnore = true
  //需要传参
  store.dispatch('rightToolbar/spellConfirm', {qaItem: item})
  // emit('ignoreClick')
}

//新版点击定位改成单一了；下拉菜单点击替换也会调用这个方法；
const listClick = (item, type = -1) => {
  qaIndex.value = item.id
  // console.log(item,type)
  //如果要替换/点击的qa句子在当前页；
  // console.log(type)
  if (store.state.excelProject.ArrMap.has(item.sentId)) {
    //定位句子;
    toSentence(item);
    //如果是有需要替换的内容
    if (type > -1) {
      replaceErrorWorldFunc(item, type)
    }
    return
  }

  //如果要替换/点击的qa句子不在当前页；
  postReComputePage({
    pageSize: store.state.pageSize,
    taskId: store.state.taskDetail.taskId,
    docId: store.state.taskDetail.docId,
    sentId: item.sentId,
  }).then((res) => {
    store.commit('changeKey', {
      pageNum: res.data.data.pageIndex,
    })
    store.commit('excelProject/changeKey', {
      serialNum: '',
      sourceText: '',
      targetText: '',
    })
    store.commit('excelProject/resetFilterValue')
    store.dispatch('getSentence', {
          loading: true,
          sentencePosition: item.sentId,
        }).then(() => {
          if (store.state.excelProject.ArrMap.has(item.sentId)) {
            toSentence(item)
            if (type > -1) {
              replaceErrorWorldFunc(item, type)
            }
          } else {
            message.warning('该句已经被隐藏')
          }
        }) //请求译文
  })
}

const menuClick = (cindex, item, index) => {
  //menuClick 是下拉列表中的替换；涉及到跳页替换；
  listClick(item,cindex);
}
</script>

<style scoped lang="less">
.qa-list {
  position: relative;
  margin-bottom: 8px;
  padding: 0 16px;
  border-radius: 12px;
  word-break: break-all;
  border: 1px solid transparent;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(5, 92, 156, 0.04);

  &.ignore {
    display: none;
  }

  .qa-list-item {
    cursor: pointer;
    font-size: 12px;

    .title {
      padding-top: 12px;

      .left {
        color: #4e5969;
        display: flex;
        align-items: center;

        .round {
          display: inline-block;
          width: 4px;
          height: 4px;
          background: #4e5969;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      .right {
        .text {
          color: #86909c;
          font-size: 12px;
          margin-left: 12px;
        }
      }
    }

    .content {
      padding: 8px 0 12px 0;

      .source {
        font-size: 13px;
        color: #1c2129;
      }

      .description {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .footer {
      padding: 6px 0;
      border-top: 1px solid #f2f3f5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #c9cdd4;
      font-size: 12px;
    }
  }

  &:hover {
    border: 1px solid #d0e9ff;
  }

  &.is-active {
    border: 1px solid #d0e9ff;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .qa-error-type-2 {
    display: flex;
    align-items: center;

    ::v-deep(.red) {
      color: red;
    }
  }

  .qa-error-type-1 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
