<template>
  <div class="help-tip">
    <div @click="showModal">
      <a-dropdown :trigger="['hover']" v-model:visible="visible">
        <div class="help-tip-svg">
          <icon-font type="icon-bangzhu" class="font-size-18"></icon-font>
        </div>
        <template #overlay>
          <div class="help-tip-dropdown">
            <div class="left">
              <ul>
                <li
                  :class="{
                    selected:
                      scrollHeight >= item.range[0] &&
                      scrollHeight < item.range[1],
                  }"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="scrollToHeight(item.range[0])"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="right" @scroll="onScroll" id="help-tip-dropdown-right">
              <!-- <img src="./help.jpg" /> -->
              <div class="table-box">
                <p class="font-size-12 color-333 weight-600 margin-b-12">
                  快捷键
                </p>
                <table class="item" border="1">
                  <tbody>
                    <tr class="font-size-13 color-333">
                      <th class="th" style="width: 120px"></th>
                      <th class="th" style="width: 205px">Windows</th>
                      <th class="th" style="width: 205px">Mac</th>
                    </tr>

                    <tr
                      class="font-size-13 color-333"
                      v-for="(item, i) in keyList"
                      :key="i"
                    >
                      <td class="title">{{ item.title }}</td>
                      <td>{{ item.windows }}</td>
                      <td>{{ item.mac }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-box margin-t-20">
                <p class="font-size-12 color-333 weight-600 margin-b-12">
                  标记说明
                </p>
                <table class="item" border="1">
                  <tbody>
                    <tr
                      class="font-size-13 color-333"
                      v-for="(item, i) in markList"
                      :key="i"
                    >
                      <td class="title" style="width: 120px">
                        {{ item.title }}
                      </td>
                      <td style="width: 410px; padding: 0">
                        <div
                          class="td display-flex align-center"
                          v-for="(items, j) in item.list"
                          :key="j"
                        >
                          <div style="width: 60px" class="text-center">
                            <img :src="items.icon" width="16" height="16" />
                          </div>
                          <div
                            style="width: 350px"
                            :style="{ lineHeight: items.lineHeight }"
                            :class="{ 'padding-tb-5': items.lineHeight }"
                            class="padding-lr-20"
                          >
                            {{ items.value }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-box margin-t-20">
                <p class="font-size-12 color-333 weight-600 margin-b-12">
                  原文显示
                </p>
                <table class="item" border="1">
                  <tbody>
                    <tr
                      class="font-size-13 color-333"
                      v-for="(item, i) in sourceList"
                      :key="i"
                    >
                      <td class="title" style="width: 120px">
                        {{ item.title }}
                      </td>
                      <td style="width: 410px">{{ item.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-box margin-t-20">
                <p class="font-size-12 color-333 weight-600 margin-b-12">
                  译文显示
                </p>
                <table class="item" border="1">
                  <tbody>
                    <tr
                      class="font-size-13 color-333"
                      v-for="(item, i) in targetList"
                      :key="i"
                    >
                      <td class="title text-center" style="width: 120px">
                        <img :src="item.icon" height="16" v-if="item.icon">
                      </td>
                      <td style="width: 410px">{{ item.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-box margin-t-20">
                <p class="font-size-12 color-333 weight-600 margin-b-12">
                  正则匹配
                </p>
                <table class="item" border="1">
                  <tbody>
                  <tr
                      class="font-size-13 color-333"
                      v-for="(item, i) in regexList"
                      :key="i"
                  >
                    <td class="title text-center" style="width: 120px">
                      <span>{{item.name}}</span>
                    </td>
                    <td style="width: 410px">
                      <span>{{item.pattern}}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { $storageUser } from '@/plugins/proxyStorage'
  import { useI18n } from 'vue-i18n'
  import {postListRegexExampleApi} from "@/http/api";

  export default {
    components: {},
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const scrollHeight = ref(0)
      const visible = ref(false)
      const list = ref([
        {
          name: '快捷键',
          range: [0, 314],
        },
        {
          name: '标记说明',
          range: [314, 610],
        },
        {
          name: '原文显示',
          range: [610, 810],
        },
        {
          name: '译文显示',
          range: [810, 1000],
        },
        {
          name: '正则匹配',
          range: [1150, 1400],
        }
      ])
      const showModal = () => {}
      const onScroll = (e) => {
        // console.log(e.target.scrollTop)
        scrollHeight.value = e.target.scrollTop
      }
      const scrollToHeight = (e) => {
        document.getElementById('help-tip-dropdown-right').scrollTop = e
      }
      const keyList = ref([
        {
          title: '确认句子',
          windows: 'Enter',
          mac: 'Enter',
        },
        // {
        //   title: "文本换行",
        //   windows: "Alt + Enter",
        //   mac: "Control + Enter",
        // },
        {
          title: '添加术语',
          windows: 'Alt + T',
          mac: 'Control + Option + T',
        },
        {
          title: '查找替换',
          windows: 'Alt + H',
          mac: 'Control + Option + H',
        },
        {
          title: '复制原文',
          windows: 'Alt + →',
          mac: 'Control + Option + →',
        },
        {
          title: '上移',
          windows: 'Alt + ↑',
          mac: 'Control + Option + ↑',
        },
        {
          title: '下移',
          windows: 'Alt + ↓',
          mac: 'Control + Option + ↓',
        },
        {
          title: '撤销',
          windows: 'Alt + Z',
          mac: 'Control + Option + Z',
        },
        {
          title: '恢复',
          windows: 'Alt + Y',
          mac: 'Control + Option + Y',
        },
        {
          title: '查词',
          windows: 'Alt + F',
          mac: 'Control + Option + F',
        },
        {
          title: '锁定句段',
          windows: 'Alt + L',
          mac: 'Control + Option + L',
        },
        {
          title: '解锁句段',
          windows: 'Alt + Shift + L',
          mac: 'Control + Shift + L',
        },
        {
          title: '加粗',
          windows: 'Alt/Ctrl + B',
          mac: 'Control/⌘ + B',
        },
        {
          title: '斜体',
          windows: 'Alt/Ctrl + I',
          mac: 'Control/⌘ + I',
        },
        {
          title: '下划线',
          windows: 'Alt/Ctrl + U',
          mac: 'Control/⌘ + U',
        },
        {
          title: '上标',
          windows: 'Alt + Shift + =',
          mac: 'Control + Shift + =',
        },
        {
          title: '下标',
          windows: 'Alt + =',
          mac: 'Control + =',
        },
      ])
      const markList = ref([
        {
          title: '句段状态',
          list: [
            {
              icon: require('@/assets/common/confirm@2x.png'),
              value: '句段已确认',
            },
            {
              icon: require('@/assets/common/nosuccess@2x.png'),
              value: '句段已编辑，但未确认',
            },
          ],
        },
        {
          title: '译文标记',
          list: [
            {
              icon: require('@/assets/common/bz-icon.png'),
              value: '备注：对句段添加备注说明信息',
            },
            {
              icon: require('@/assets/common/qa@2x.png'),
              value: 'QA提示：译文翻译存在漏译或拼写错误',
            },
            {
              icon: require('@/assets/common/sd-icon.png'),
              value: '重复、100%匹配、非译内容且被锁定的句子',
            },
            {
              icon: require('@/assets/common/js-icon.png'),
              value: '译员解锁的句子',
            },
          ],
        },
        {
          title: '占位标签',
          list: [
            {
              icon: require('@/assets/common/zw-icon.png'),
              value:
                '占位符：即原文中的图片、加粗、斜体、上标、下标、文字颜色、超链接等样式标签',
              lineHeight: '20px',
            },
          ],
        },
      ])
      const sourceList = ref([
        {
          title: '原文重复',
          value: '同一文件中原文有相同的句子视为原文重复',
        },
        {
          title: '交叉文件重复',
          value: '不同文件中原文有相同句子的视为交叉文件重复',
        },
        {
          title: '不重复',
          value:
            '文件中原文仅出现一次的句子，以及原文重复或交叉文件重复中首次出现的第一个句子',
        }
      ])
      const targetList = ref([
        {
          icon: require('@/assets/common/MT@2x.png'),
          value: 'MT：引擎机器翻译结果，蓝色字',
        },
        {
          icon: require('@/assets/common/PE@2x.png'),
          value: 'PE：译后编辑结果，黑色字',
        },
        {
          icon: require('@/assets/common/PF@2x.png'),
          value: 'PF：人工审校结果，橙色字',
        },
        {
          icon: require('@/assets/common/REP@2x.png'),
          value: '重复填充：原文与译文一致根据第一个翻译的译文复制的译文',
        },
        {
          icon: require('@/assets/common/full@2x.png'),
          value: '原文和语料完全一致',
        },
        {
          icon: require('@/assets/common/99@2x.png'),
          value: '原文和语料仅空格不一致，其余一致',
        },
        {
          icon: require('@/assets/common/98-50.png'),
          value: '原文与语料存在相似度，在预翻环节进行的语料填充填入的',
        },
        {
          icon: require('@/assets/common/UT.png'),
          value: '非译内容，整句为网址、邮箱、纯数字+符号、或非源语种等无需翻译的内容',
        },
        {
          icon: require('@/assets/common/PM.png'),
          value: 'PM：项目经理修改的译文结果',
        },
        {
          icon: require('@/assets/common/SRC@2x.png'),
          value: '原文：上传的原文稿件包含原文和译文',
        },
      ])
      const regexList=ref([

      ])
      onMounted(() => {
        // openWindow();
        postListRegexExampleApi().then(res=>{
          // console.log(res)
          let list = res.data.data.list;
          regexList.value = [...list];
        })
      })
      return {
        regexList,
        visible,
        scrollToHeight,
        list,
        scrollHeight,
        onScroll,
        showModal,
        t,
        keyList,
        markList,
        sourceList,
        targetList,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .help-tip {
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .help-tip-svg {
    ::v-deep(svg) {
      &:hover {
        color: #00c6f3;
      }
    }
  }

  .help-tip-dropdown {
    margin-top: 7px;
    margin-right: -146px;
    height: 420px;
    width: 720px;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .left {
      height: 100%;
      border-right: 1px solid #f2f3f5;
      padding: 16px;
      margin-right: 16px;
      ul {
        li {
          text-align: center;
          height: 40px;
          width: 108px;
          font-size: 14px;
          line-height: 40px;
          border-radius: 6px;
          cursor: pointer;
          &.selected {
            background: #f2f3f5;
            font-weight: 600;
            color: #1c2129;
          }
        }
      }
    }
    .right {
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
      padding: 20px 0;
      .table-box {
        .item {
          border: 1px solid #f2f3f5;
          border-bottom: 0;
          .head {
            background: #f8f8f8;
          }
          .title {
            color: #4e5969;
          }
          .text-center {
            text-align: center;
          }
          th,
          td {
            padding: 5px 20px;
            text-align: left;
            border: 1px solid #f2f3f5;
            .td {
              min-height: 32px;
              border-bottom: 1px solid #f2f3f5;
              &:last-child {
                border-bottom: 0;
              }
              div {
                min-height: 32px;
                line-height: 32px;
                &:last-child {
                  border-left: 1px solid #f2f3f5;
                }
              }
            }
          }
          .th {
            background: #f8f8f8;
          }
        }
      }
    }
  }
</style>
