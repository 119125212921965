<template>
  <div class="display-flex justify-center align-center targetOrigin">
    <a-tooltip placement="bottom" v-if="item.targetType == 'COMPLETE_MATCHING'">
      <template #title>
        <span>语料100%匹配</span>
      </template>
      <img src="@/assets/common/full@2x.png" />
    </a-tooltip>
    <a-tooltip
      placement="bottom"
      v-else-if="item.targetType == 'PERCENT_99_MATCHING'"
    >
      <template #title>
        <span>语料99%匹配</span>
      </template>
      <img src="../../assets/common/99@2x.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'MT'">
      <template #title>
        <span>机器翻译</span>
      </template>
      <img src="@/assets/common/MT@2x.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PE'">
      <template #title>
        <span>译后编辑</span>
      </template>
      <img src="@/assets/common/PE@2x.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'REPEAT'">
      <template #title>
        <span>重复</span>
      </template>
      <img src="@/assets/common/REP@2x.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'SOURCE'">
      <template #title>
        <span>原文</span>
      </template>
      <img src="@/assets/common/SRC@2x.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PF'">
      <template #title>
        <span>人工审校</span>
      </template>
      <img src="@/assets/common/PF@2x.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PF1'">
      <template #title>
        <span>人工审校1</span>
      </template>
      <img src="@/assets/common/PF1.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PF2'">
      <template #title>
        <span>人工审校2</span>
      </template>
      <img src="@/assets/common/PF2.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PF3'">
      <template #title>
        <span>人工审校3</span>
      </template>
      <img src="@/assets/common/PF3.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PF4'">
      <template #title>
        <span>人工审校4</span>
      </template>
      <img src="@/assets/common/PF4.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'PM'">
      <template #title>
        <span>项目经理</span>
      </template>
      <img src="@/assets/common/PM.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'NonTranslate'" title="非译内容">
      <img src="@/assets/common/UT.png" />
    </a-tooltip>
    <a-tooltip placement="bottom" v-else-if="item.targetType == 'MatchBlow98'" :title="`语料${item.targetMatchRate}填充`">
      <span class="font-GraublauWeb" style="color:#2DD59C;font-size: 10px" v-if="item.targetMatchRate>75">
        {{item.targetMatchRate}}%
      </span>
      <span class="font-GraublauWeb" style="color:#1CC4C6;font-size: 10px" v-if="item.targetMatchRate<76">
        {{item.targetMatchRate}}%
      </span>
    </a-tooltip>
    <div v-else style="width: 32px;height: 16px"></div>
  </div>
</template>

<script>
  import useProject from '@/components/source/useProject'

  export default {
    props: ['item', 'index'],
    components: {},
    setup() {
      //taskDetail 用户详情
      const { t, taskDetail } = useProject()
      //当前table选中项

      return {
        t,
        taskDetail,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .targetOrigin {
    padding: 0 2px;
    //width:32px;
    img {
      width: 32px;
    }
  }
</style>
