<template>
  <div class="display-flex justify-center align-center">
    <a-tooltip :overlayStyle="{ minWidth: '100px', maxWidth: '400px' }">
      <template #title>
        <div class="confirm-title">
          <div
            v-for="(citem, cindex) in store.state.taskDetail.activityWorkflow"
            :key="cindex"
          >
            <span>{{ citem }}</span>
            <label>
              {{
                item.operationLists.indexOf(confirmMap[citem]) > -1
                  ? '已确认'
                  : '未确认'
              }}
            </label>
            {{ confirmUserName(confirmMap[citem]) }}
          </div>
          <div>
            <span>PM</span>
            <label>{{ item.isConfirm ? '已确认' : '未确认' }}</label>
            <label v-if="item.isConfirm">
              <!--//从Confirm信息中查询 Confirm没有则取当前名字；-->
              {{ confirmUserName('PMConfirm') || `(${store.state.taskDetail.userName})` }}
            </label>
          </div>
        </div>
      </template>
      <div class="confirm-list">
        <div
          class="confirm"
          :class="{ yes: item.operationLists.indexOf(confirmMap[citem]) > -1 }"
          v-for="(citem, cindex) in store.state.taskDetail.activityWorkflow"
          :key="cindex"
        ></div>
        <div class="confirm" :class="{ yes: item.isConfirm }"></div>
      </div>
      <targetOrigin :item="item"></targetOrigin>
    </a-tooltip>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import { useStore } from 'vuex'
  import targetOrigin from '@/components/targetOrigin/index.vue'
  import useProject from '@/components/source/useProject'
  import { confirmSentences } from '@/http/api'

  export default {
    props: ['item'],
    components: {
      targetOrigin,
    },
    setup(props, ctx) {
      const store = useStore()
      const confirmMap = computed(() => {
        return store.state.excelProject.activityWorkflowKey
      })
      const confirmUserName = (type) => {
        let name = ``
        props.item.confirmInfos?.find((citem) => {
          if (type == citem.operationType) {
            name = citem.confirmUserNickname
            return true
          }
        })
        if (!name) {
          return ``
        }
        return `（${name}）`
      }
      return {
        confirmUserName,
        store,
        confirmMap,
      }
    },
  }
</script>

<style lang="less" scoped>
  .confirm-title {
    & > div {
      font-size: 12px;
      display: flex;
      align-items: center;
      span {
        width: 40px;
        //flex-basis: 60px;
        //flex-shrink: 0;
      }
    }
  }
  .confirm-list {
    display: flex;
    justify-content: center;
    .confirm {
      margin: 0 1px;
      width: 4px;
      height: 8px;
      background: #e5e6eb;
      &.yes {
        background: #72d940;
      }
    }
  }
</style>
