<template>
  <div class="verticalTabs">
    <div class="header">
      <icon-font type="icon-zhan" @click="openHorizontal"></icon-font>
    </div>
    <ul>
      <li @click="openRightModel('0')">
        <img src="./tishi.png" />
        <span>提示</span>
        <label
          class="red-tip"
          v-if="
            store.state.excelProject.twinslatorMatch.matchedTerm?.length ||
              store.state.excelProject.twinslatorMatch.matchedCorpus?.length
          "
        ></label>
      </li>
      <li @click="openRightModel('4')">
        <img src="./qa.png" />
        <span>QA</span>
      </li>
      <li @click="openRightModel('3')">
        <img src="./beizhu.png" />
        <span>备注</span>
      </li>
      <li @click="openRightModel('2')">
        <img src="./rizhi.png" />
        <span>日志</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import {
    defineComponent,
    onMounted,
    watch,
    ref,
    nextTick,
    computed,
    onBeforeUnmount,
    defineEmits,
  } from 'vue'
  const emits = defineEmits(['openHorizontalPaneSize'])
  const store = useStore()
  const tbNavActiveIndex = computed(() => {
    return store.state.rightToolbar.toolbarNav.tbNavActiveIndex
  })
  const replaceShow = computed(() => {
    return store.state.rightToolbar.replaceShow
  })
  const openRightModel = (type) => {
    store.commit('rightToolbar/changeKey', {
      toolbarNav: {
        tbNavActiveIndex: type,
      },
    })
    emits('openHorizontalPaneSize')
  }
  const openHorizontal = () => {
    emits('openHorizontalPaneSize')
  }

  watch(tbNavActiveIndex, () => {
    //有改变直接打开
    emits('openHorizontalPaneSize')
  })

  watch(replaceShow, () => {
    //有改变直接打开
    emits('openHorizontalPaneSize')
  })
</script>

<style scoped lang="less">
  .verticalTabs {
    width: 100%;
    height: 100%;
    padding-top: 12px;
    background: linear-gradient(
      180deg,
      var(--universal-color-right_toolbar-up) 0%,
      var(--universal-color-right_toolbar-down) 100%
    );
    .header {
      text-align: center;
      margin-bottom: 28px;
      font-size: 16px;
      ::v-deep(span) {
        cursor: pointer;
        border-radius: 8px;
        padding: 6px;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        border-radius: 8px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: relative;
        //padding:4px 8px;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        .red-tip {
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #f55c3f;
          top: 0;
          right: 10px;
        }
        img {
          width: 14px;
          margin-bottom: 2px;
        }
        span {
          font-weight: 400;
          color: #4e5969;
          font-size: 12px;
        }
      }
    }
  }
</style>
