import store from '@/store/index'
import { LanguageItem, IndustryItem } from '@/interface/index'

export default function useUtils() {
  // const store = useStore();

  const getLanguageName = (languageId: string | number, all?: string) => {
    const obj = store.state.languageList.find(
      (res: LanguageItem) => languageId === res.languageId
    )
    if (obj) {
      if (all || all === 'all') {
        return obj.languageName
      } else {
        return obj.languageShortName
      }
    } else {
      return ''
    }
  }

  const getIndustryName = (industryId: string | number) => {
    const obj = store.state.industryList.find(
      (res: IndustryItem) => industryId === res.industryId
    )
    return obj.industryName
  }

  const dateFormat = (time: number, fmt?: any) => {
    let _time: any
    //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    if (time) {
      _time = time.toString().length > 10 ? time : time * 1000
    } else {
      return null
    }

    const date = new Date(_time) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let ret: any = null
    const opt: any = {
      'Y+': date.getFullYear().toString(), //年
      'm+': (date.getMonth() + 1).toString(), //月
      'd+': date.getDate().toString(), //日
      'H+': date.getHours().toString(), //时
      'M+': date.getMinutes().toString(), //分
      'S+': date.getSeconds().toString(), //秒
    }
    for (let k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
        )
      }
    }
    return fmt
  }

  const insertHtmlAtCaret = (html: string, sentId?: number) => {
    let sel: any, range: any
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0)
        range.deleteContents()
        let el = document.getElementById('create-html-test-node')
        // el.appendChild(html)
        el.innerHTML = html
        let frag = document.createDocumentFragment(),
          node,
          lastNode
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node)
        }
        range.insertNode(frag)
        if (lastNode) {
          range = range.cloneRange()
          range.setStartAfter(lastNode)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }
    }
  }

  //获取被选中的文本
  const selectText = () => {
    if (document.Selection) {
      //ie浏览器
      return document.selection.createRange().text
    } else {
      //标准浏览器
      return window.getSelection().toString()
    }
  }

  const scrollIntoView = (id: string, parentId?: string) => {
    let sentId: any = document.querySelector(id)
    if (parentId) {
      sentId = document.querySelector(`${parentId} ${id}`)
    }
    // console.log(sentId)
    if (!sentId) {
      return
    }

    if (parentId == '#ps-word-preview-container') {
      sentId = document.querySelector(id).getElementsByTagName('span')[0]
    }

    let container
    if (parentId) {
      container = document.querySelector(parentId)
    } else {
      container = document.getElementsByClassName('new-edit-table-scroll')[0]
    }
    container.scrollTop = 0

    const { left, top, height } = container.getBoundingClientRect()
    const { left: childLeft, top: childTop } = sentId.getBoundingClientRect()

    const diffY = Math.abs(top - childTop)
    if (diffY < height / 2) {
      container.scrollTop = 0
    } else {
      container.scrollTop += diffY - height / 2
    }
  }

  const setFocusEnd = (focusNode: any, isMoveEnd = false) => {
    if (window.getSelection) {
      //ie11 10 9 ff safari
      focusNode.focus() //解决ff不获取焦点无法定位问题
      const range: any = window.getSelection() //创建range
      // console.log(isMoveEnd)
      if (isMoveEnd) {
        range.selectAllChildren(focusNode) //range 选择obj下所有子内容
        range.collapseToEnd() //光标移至最后
      }
      // range.selectAllChildren(obj); //range 选择obj下所有子内容
      // range.collapseToEnd(); //光标移至最后
    }
    // else if (document.selection) {
    //   //ie10 9 8 7 6 5
    //   const range: any = document.selection.createRange(); //创建选择对象
    //   //var range = document.body.createTextRange();
    //   range.moveToElementText(obj); //range定位到obj
    //   range.collapse(false); //光标移至最后
    //   range.select();
    // }
  }

  //节流
  const throttle = (fn: Function, wait: number) => {
    let timeout: any = null
    return function(this: any, ...args: any[]) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        fn.apply(this, args)
      }, wait)
    }
  }

  return {
    getLanguageName,
    getIndustryName,
    insertHtmlAtCaret,
    selectText,
    scrollIntoView,
    setFocusEnd,
    dateFormat,
    throttle,
  }
}
