import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-346b5c2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-text-color" }
const _hoisted_2 = { class: "empty-text-color margin-t-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createBlock(_component_a_empty, {
    image: require('@/images/default.png'),
    "image-style": {
      height: '50px',
      marginTop: '20px',
    }
  }, {
    description: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString($props.description || '暂无数据'), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString($props.description2 || ''), 1)
    ]),
    _: 1
  }, 8, ["image"]))
}