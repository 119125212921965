import useUtils from '@/plugins/utils'
import { useStore } from 'vuex'
import { computed, reactive, ComputedRef, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import useProject from '@/components/source/useProject'

export default function sourceEditJS() {
  const { appContext } = getCurrentInstance()
  const globalProxy = appContext.config.globalProperties
  const { setFocusEnd, insertHtmlAtCaret } = useUtils()
  const { afterTargetMouseDown, targetMousedown } = useProject()
  const { t } = useI18n()
  const store = useStore()
  const taskDetail = computed(() => store.state.taskDetail)
  const docData = computed({
    set: (val) => {
      store.commit('excelProject/changeKey', {
        docData: val,
      })
    },
    get: () => store.state.excelProject.docData,
  })

  const checkedDetail = computed(() => store.state.checkedDetail)

  const tabActiveIndex = computed({
    set: (val) => {
      store.commit('excelProject/changeKey', {
        tabActiveIndex: val,
      })
    },
    get: () => store.state.excelProject.tabActiveIndex,
  })

  const insertImgToTarget = (e, item, index) => {
    if (tabActiveIndex.value != index) {
      return
    }
    //如果不是点击IMG 则拒绝
    if (e.srcElement.nodeName != 'IMG') {
      return
    }
    let sentId = item.sentId
    let classList = document.activeElement.classList.value
    //如果焦点不在对应的译文上面 则拒绝
    if (
      classList.indexOf(`target-sent-${sentId}`) < 0 ||
      classList.indexOf(`target-txt`) < 0
    ) {
      return
    }

    store.commit('excelProject/changeKey', {
      keyQueue: 'stop',
    })

    let sent = window.$getTargetDom(item.sentId)

    let childNodes = sent.querySelectorAll(`img`)
    // console.log(childNodes);
    childNodes.forEach((item) => {
      if (item.id == e.srcElement.id) {
        item.parentNode.removeChild(item)
      }
    })

    let D = document.getElementById('create-html-test-node')
    D.innerHTML = ''
    D.appendChild(e.srcElement.cloneNode(true))
    let innerHtml = D.innerHTML
    insertHtmlAtCaret(innerHtml)
    D = null
    //
    setTimeout(() => {
      store.commit('excelProject/changeKey', {
        keyQueue: '',
      })
      setFocusEnd(sent)
      sent = null
    }, 100)
  }

  const getIMGHtml = () => {
    let selectionObj = window.getSelection()
    let rangeObj = selectionObj.getRangeAt(0)
    let docFragment = rangeObj.cloneContents()
    let tempDiv = document.getElementById('create-html-test-node')
    tempDiv.innerHTML = ''
    tempDiv.appendChild(docFragment)
    let selectedHtml = tempDiv.innerHTML
    if (selectedHtml.match(/(<\/?img.*?>)/gi)) {
      return selectedHtml.match(/(<\/?img.*?>)/gi)?.join('')
    }
    return ''
  }

  const sourceKeyupEnter = (e) => {
    //当前选中项数据
    if (e.shiftKey && e.which == 13) {
      let docNameArr = store.state.taskDetail.docName.split('.')
      if (docNameArr[docNameArr.length - 1] != 'srt') {
        e.preventDefault()
      }
      return
    }

    if (e.key == 'Backspace') {
      let selectionObj = window.getSelection()
      //单个选中
      if (selectionObj.type != 'Range') {
        if (
          selectionObj.anchorNode.childNodes[selectionObj.anchorOffset - 1]
            ?.localName == 'img'
        ) {
          e.preventDefault()
        }
        return
        //批量选中
      } else {
        let imgHtml = getIMGHtml()
        setTimeout(() => {
          document.execCommand('insertHTML', false, `${imgHtml}`)
        }, 0)
      }
      return
    }

    if (e.keyCode === 13) {
      // const list = docData.value.sentenceDTOList;
      // //上一项数据
      // let localItem;
      // if (tabActiveIndex.value >= list.length - 1) {
      //   store.commit("excelProject/changeKey", {
      //     tabActiveIndex: list.length - 1
      //   });
      //   localItem = list[tabActiveIndex.value];
      // } else {
      //   store.commit("excelProject/changeKey", {
      //     tabActiveIndex: (tabActiveIndex.value += 1)
      //   });
      //   localItem = list[tabActiveIndex.value - 1];
      // }
      // //下一项数据
      // const item = list[tabActiveIndex.value];
      // //当前数据
      //
      // //让上一句失去焦点 但是key 设置为enter 就保证了不会提交 提交操作在下面
      // document.querySelector(`#source-sent-${localItem.id}`).blur();
      //
      // if (item) {
      //   afterTargetMouseDown(item);
      //
      //   //当前选中句得到焦点，并放置到最后
      //   setFocusEnd(document.querySelector(`#source-sent-${item.id}`), true);
      // }
      e.preventDefault()
      return
    }
  }

  const clickSourceBox = (item, index) => {
    //顺序不可颠倒 且失去焦点事件提交一定在click之后执行的
    targetMousedown(item, index)
  }

  return {
    clickSourceBox,
    sourceKeyupEnter,
    insertImgToTarget,
    t,
    taskDetail,
    docData,
    //按键队列
    checkedDetail,
  }
}
