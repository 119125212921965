<template>
  <a-dropdown placement="bottomCenter">
    <a-button
      class="li"
      type="link"
      :class="{
        disabled: disabled || (checkedDetail.locked && checkedList.length == 0),
      }"
    >
      <icon-font class="font-size-16 icon-box" type="icon-runse1"></icon-font>
      <div class="li-txt">
        润色
      </div>
    </a-button>
    <template #overlay>
      <ul class="runse-options">
        <li
          v-for="(item, index) in levelObj"
          @click="decorationClick(index)"
          :key="index"
        >
          {{ levelObj[index] }}
        </li>
      </ul>
    </template>
  </a-dropdown>

  <a-modal
    :visible="true"
    title="提示"
    okText="确认润色"
    cancelText="取消"
    centered
    @ok="confirmOk"
    @cancel="confirmCancel"
    v-if="confirmVisible"
  >
    <div class="modal">
      <div class="title">
        <img src="./images/runse.png" />
      </div>
      <div class="content">
        已勾选
        <span style="color:#00B4FA">{{ checkedList?.length }}</span>
        个句段，确认批量润色？
      </div>
      <div class="footer">
        按要求批量对译文进行智能润色，原有译文将被替换。
      </div>
      <saveSnapshotCheck
        v-model:saveSnapshotValue="saveSnapshotValue"
      ></saveSnapshotCheck>
    </div>
  </a-modal>
</template>

<script>
  import {
    computed,
    ComputedRef,
    reactive,
    createVNode,
    toRefs,
    ref,
    getCurrentInstance,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import { CheckCircleFilled } from '@ant-design/icons-vue'
  import { batchAiDecorate } from '@/http/api'
  import useUtils from '@/plugins/utils'
  import mitt from '@/plugins/bus'
  import saveSnapshotCheck from '@/components/saveSnapshotCheck/index.vue'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      saveSnapshotCheck,
      CheckCircleFilled,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const levelObj = computed(() => {
        return store.state.decorationLevelObj
      })
      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      const state = reactive({
        type: '',
        confirmVisible: false,
        saveSnapshotValue: false,
      })

      const startDecorate = (sentIdsArr, model = 'single') => {
        //获取存储快照的变量
        let saveSnapshot = false
        if (model == 'batch') {
          saveSnapshot = sessionStorage.getItem('saveSnapshotValue') || false
          if (saveSnapshot) {
            //更改快照信息
            store.commit('optionsBar/changeKey', {
              allowSaveSnapshot: true,
            })
          }
        }
        batchAiDecorate({
          docId: taskDetail.value.docId,
          sentIds: sentIdsArr,
          decorate: {
            code: state.type,
            description: levelObj.value[state.type],
          },
          saveSnapshot: saveSnapshot,
        })
      }

      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex]
      }

      const checkedList = computed(() => store.state.excelProject.checkedList)

      //Gpt批量润色 //默认为单句
      const GPTDecoration = async (model = 'single') => {
        // const { mtId, transMode } = store.state.taskDetail;
        const item = getTableActiveItem()
        let sentIds = item.sentId.toString()
        if (checkedList.value.length > 0) {
          sentIds = checkedList.value
            .map((citem) => {
              return citem.sentId
            })
            .toString()
        }
        if (!sentIds) {
          message.warning('请选中某一句或者勾选句子')
          return
        }

        let sentIdsArr = sentIds.split(',')
        //判断是否有译文 有的话 提示出来

        let sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        let index = sentIdsArr.findIndex((item) => {
          if (
            sentenceDTOList[store.state.excelProject.ArrMap.get(item)]
              .targetPlaceholder
          ) {
            return true
          }
        })
        if (index == -1) {
          message.warning('请选择有译文的句子')
          return
        }
        //
        mitt.emit('openCostModal', {
          costType: 'GptDecorate',
          sentIds: sentIdsArr,
          ok: (e) => {
            startDecorate(sentIdsArr, model)
          },
        })
      }

      const decorationClick = (level) => {
        state.type = level
        if (checkedList.value.length > 0) {
          state.confirmVisible = true
        } else {
          GPTDecoration()
        }
      }

      const confirmOk = () => {
        state.confirmVisible = false
        // confirmMt();
        GPTDecoration('batch')
      }
      const confirmCancel = () => {
        state.confirmVisible = false
      }

      return {
        decorationClick,
        confirmOk,
        confirmCancel,
        checkedList,
        levelObj,
        GPTDecoration,
        ...toRefs(state),
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
</script>

<style scoped lang="less">
  .runse-options {
    padding: 10px;
    background: white;
    box-shadow: 0px 2px 14px 0px rgba(11, 149, 192, 0.2);
    border-radius: 8px;
    li {
      width: 88px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-weight: 400;
      color: #333333;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: #eeeeee;
      }
    }
  }

  .modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      color: #1c2129;
      line-height: 22px;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }
</style>
