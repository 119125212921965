import useUtils from '@/plugins/utils'
import { useStore } from 'vuex'
import { computed, reactive, ComputedRef, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import filterStrings from '@/plugins/filterString'
import mitt from '@/plugins/bus'
import {getFactTargetText, isFocusPositionSource, isFocusPositionTarget} from '@/plugins/comm.js'
import { message, Modal } from 'ant-design-vue'

export default function useProject() {
  const { appContext } = getCurrentInstance()
  const globalProxy = appContext.config.globalProperties

  const { t } = useI18n()
  const store = useStore()
  const taskDetail: any = computed(() => store.state.taskDetail)
  const docData: any = computed({
    set: (val) => {
      store.commit('excelProject/changeKey', {
        docData: val,
      })
    },
    get: () => store.state.excelProject.docData,
  })

  const { selectText } = useUtils()

  const checkedDetail: any = computed(() => store.state.checkedDetail)

  //选择译文之后的事件
  const afterTargetMouseDown = (item: any) => {
    if (
      store.state.continuedMt &&
      !filterStrings.filterSpace(window.$getTargetDom(item.sentId).innerText)
    ) {
      store
        .dispatch('twinslatorMt', {
          sentIds: item.sentId,
        })
        .then((res) => {
          if (res.data.result == '0') {
            //展示弹窗
            message.error(res.data.errorMsg)
            return
          }
        })
    }

    //此处事件需要优化处理 以后有空就处理
    if (checkedDetail.value.sentId != item.sentId) {
      //关闭推荐历史弹窗
      // store.dispatch("excelProject/sourceTextTermMatch");
      store.commit('changeKey', {
        checkedDetail: { ...item },
      })

      //清空所有的高亮
      let nodeList = document.getElementsByClassName('QA_Error_HighLight')
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].classList.remove('QA_Error_HighLight');
        i--
      }
    }
  }

  //译文框点击事件
  const targetMousedown = (item: any, index?: any) => {
    //其余术语提示高亮取消

    //获取激活某一项数据
    if (index > -1) {
      store.commit('excelProject/changeKey', {
        tabActiveIndex: index,
      })
    } else {
      store.commit('excelProject/setTabActiveIndex', {
        sentId: item.sentId,
      })
    }

    afterTargetMouseDown(item)
  }

  const initKeyQueue = () => {
    store.commit('excelProject/changeKey', {
      keyQueue: '',
    })
  }

  const setKeyQueue = (str: string) => {
    store.commit('excelProject/changeKey', {
      keyQueue: str,
    })
  }

  //新版占位符打平
  const imgRemoveFormat = (targetNode) => {
    function getImgIndex(imgNode) {
      let parentNode = imgNode.parentNode
      let imgIndex = 0
      for (let i = 0; i < parentNode.childNodes.length; i++) {
        if (parentNode.childNodes[i] === imgNode) {
          // console.log('等于');
          imgIndex = i
          break
        }
      }
      return imgIndex
    }

    let node = targetNode
    let imgList = targetNode.getElementsByTagName('img')
    for (let i = 0; i < imgList.length; i++) {
      if (imgList[i].parentNode === node) {
        continue
      }
      const range = document.createRange()
      // console.log(imgList[i]);
      let imgNode = imgList[i]
      let oneimgParentNode = imgList[i].parentNode
      range.setStart(node, 0)
      range.setEnd(oneimgParentNode, getImgIndex(imgNode))

      let frag = range.extractContents()
      range.insertNode(frag)

      let twoimgParentNode = imgList[i].parentNode
      range.setStart(twoimgParentNode, getImgIndex(imgNode) + 1)
      range.setEnd(node, node.childNodes.length)
      let twofrag = range.extractContents()
      range.insertNode(twofrag)

      while (imgNode.parentNode !== node) {
        range.setStart(
          imgNode.parentNode.parentNode,
          getImgIndex(imgNode.parentNode)
        )
        range.setEnd(
          imgNode.parentNode.parentNode,
          getImgIndex(imgNode.parentNode) + 1
        )
        range.extractContents()
        range.insertNode(imgNode)
      }
    }

    //移除所有的样式标签不带text；
    let tagList = targetNode.getElementsByTagName('*');
    for (let i = 0;i<tagList.length;i++) {
      let tag = tagList[i];
      if (tag.nodeName!="IMG") {
        if (!tag.innerHTML.includes('<img') && !tag.innerText) {
          tag.parentNode.removeChild(tag);
          i--
        }
      }
    }
  }

  //提交译文事件
  const sendCommitTargetText = (item: any, isEnter: boolean) => {
    let sentDom: any = window.$getTargetDom(item.sentId)

    //编辑原文状态下，所有的都变未确认
    if (store.state.openEditSourceText) {
      item.isConfirm = false
    }

    //普通带富文本编辑的HTML字符串
    imgRemoveFormat(sentDom)

    initKeyQueue()

    let innerText = filterStrings.getString(sentDom.innerHTML)

    //如果原文编辑打开了 改造传到Map结构里面前去
    if (store.state.openEditSourceText) {
      store.dispatch('sourceEdit/sentTargetCommitMap', {
        sentId: item.sentId,
        target: filterStrings.getPureString(innerText),
        targetPlaceholder: innerText,
        sourcePlaceholder: window.$removeValueToSrc(window.$getSourceDom(item.sentId).innerHTML),
        // sourcePlaceholder: document.getElementById(`source-sent-${item.id}`).innerHTML,
        isSync: item.isSync,
        sourceRepeatHash: item.sourceRepeatHash,
      })
      sentDom.innerHTML = innerText
      item.targetPlaceholder = innerText
      return
    }

    //不同重新赋值 （改动）

    store
      .dispatch('sentCommit', {
        docId: item.docId,
        sentId: item.sentId,
        target: filterStrings.getPureString(innerText),
        targetPlaceholder: innerText,
        confirm: isEnter,
        sentIndex: item.sentIndex,
      })
      .then((res) => {
        //现在只判断是否为空 还有判断根据返回值是否为机翻还是人翻
        // sentDom = null;
      })

    if (sentDom.innerHTML != innerText) {
      sentDom.innerHTML = innerText
    }
  }

  //判断是否达到提交条件
  const ifSendCommit = (item: any): boolean => {
    let sentDom: any = window.$getTargetDom(item.sentId)
    //如果差异对比开关打开，则比对差异字段
    if (store.state.excelProject.differentHighLightModel) {
      let innerHTML = sentDom.innerHTML
      return innerHTML != item.diffCompare
    }
    //如果非差异对比模式，则对比compareTargetHTML
    else {
      //不行是因为有span标签，是否需要提交之后将span 标签给移除？ ，但是这样做就会
      let compareTarget = item.compareTargetHTML
      let innerHTML = sentDom.innerHTML.replaceAll(' QA_Error_HighLight', '')
      // console.log(compareTarget != innerHTML);
      // console.log(item.compareTargetHTML,innerHTML);
      return compareTarget != innerHTML
    }
    // 如果是点击译文状态处的标签 ，直接return，走单独保存操作；
    // //当前译文框的译文；
    // let innerText = sentDom.innerHTML;
    // //当前渲染的译文
    // let target: string = item.compareTargetHTML;
    // //多了del标签 流程需要重写 确认是否提交的条件变量
    // let isSendCommit = false;
    // if (target != innerText) {
    //     isSendCommit = true;
    // }
    // // 只过滤差异对比标签的译文;
    // // 如果不相同 ，则说明在开头或者结尾增加了换行或者空格，属于无效和编辑不提交，我们修改译文，过滤掉即可；
    // if (innerHTML != innerText) {
    //     sentDom.innerHTML = innerText;
    // }
    // sentDom = null;
    // return isSendCommit;
  }

  const resetTarget = (item: any) => {
    // console.log(item)
    // QA模式 判断有无QA
    // let dom = document.getElementById(item.targetDomId);
    // let sourceDom = document.getElementById(`source-qa-${item.id}`);
  }

  //译文失去焦点事件
  const targetBlur = (item: any) => {
    try {
      let keyQueue = store.state.excelProject.keyQueue
      // //不提交事件条件 除了这两件事件以外 其余全部走正常流程 v8.11
      const noSendCommitCondition = ['stop']
      if (noSendCommitCondition.indexOf(keyQueue) > -1) {
        return
      }

      const trueConfirm = ['confirmSelected']

      let isEnter = trueConfirm.indexOf(keyQueue) > -1

      // console.log(ifSendCommit(item))
      if (ifSendCommit(item)) {
        //记录存值；需要等待接口结束；记录updateTime的值；设置为未保存；提交成功的时候再触发一次
        sendCommitTargetText(item, isEnter)
        //
      }

      // ...
    } catch (error) {
      console.log(error)
      message.error(
        '捕捉到保存译文异常状况，请刷新页面后再重试，仍然失败请联系右上角客服'
      )
      // 捕获到的异常被传递给错误处理程序
      // console.error(error);
    }
  }

  //备注按钮点击
  const remarkClick = () => {
    // console.log('remarkClick)
  }

  //添加术语弹窗开启按钮 (可监听)
  const isAddTermDialog: any = computed({
    set: (val) => {
      store.commit('changeKey', {
        isAddTermDialog: val,
      })
    },
    get: () => store.state.isAddTermDialog,
  })
  //获取原文、译文复制信息 在添加术语的时候调用,可以直接复制
  const getRangeText = () => {
    let selection = window.getSelection();
    //如果是选中 需要判断DOM节点是否属于原文
    if (selection.type=='Range') {
      const rangeText = selectText()
      if (isFocusPositionSource()) {
        store.commit('changeKey', {
          rangeText: {
            sourceText: rangeText,
            targetText: store.state.rangeText.targetText,
          },
        })
      }
      if (isFocusPositionTarget()) {
        store.commit('changeKey', {
          rangeText: {
            sourceText: store.state.rangeText.sourceText,
            targetText: rangeText,
          },
          // searchReplaceText:rangeText
        })
      }
    }
  }

  //单句或者多句确认-取消
  const confirmSentences = (sentIds: any, isConfirm: boolean) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('excelProject/confirmSentences', {
          sentIds: sentIds,
          isConfirm: isConfirm,
        })
        .then(() => {
          setTimeout(() => {
            docData.value.sentenceDTOList.forEach((res1: any) => {
              String(sentIds)
                .split(',')
                .forEach((res2: any) => {
                  if (res1.sentId == res2) {
                    res1.isConfirm = isConfirm
                  }
                })
            })
          }, 0)

          resolve(1)
        })
        .catch(() => {
          reject()
        })
    })
  }

  //checkbox选中的数据
  const checkedList: any = computed({
    set: (val) => {
      store.commit('excelProject/changeKey', {
        checkedList: val,
      })
    },
    get: () => store.state.excelProject.checkedList,
  })
  //单选

  return {
    t,
    taskDetail,
    docData,
    isAddTermDialog,
    //点击切换事件
    targetMousedown,
    //失去焦点事件
    targetBlur,
    //按键队列
    setKeyQueue,
    // fullMatchMouseenter,
    // fullMatchMouseleave,
    remarkClick,
    getRangeText,
    checkedDetail,
    confirmSentences,
    checkedList,
    sendCommitTargetText,
    initKeyQueue,
    ifSendCommit,
    afterTargetMouseDown,
  }
}
