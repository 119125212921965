
  import { useStore } from 'vuex'
  import { computed, reactive, toRefs, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n' //国际化
  import { message } from 'ant-design-vue'
  import { addScore, existsScoreByDocId } from '@/http/api'

  export default {
    name: 'enginegrade',
    components: {},
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        confirmLoading: false,
        twinslatorName: '',
        value: 5,
      })
      // engineGradeVisible

      const reload = () => {
        if (!store.state.taskDetail.isSelfWork) {
          window.location.reload()
        }
      }

      const handleOk = () => {
        let params = {
          docId: store.state.taskDetail.docId,
          twinslatorId: store.state.taskDetail.twinslatorId,
          score: state.value,
        }
        addScore(params).then(() => {
          store.commit('changeKey', {
            engineGradeVisible: false,
          })
          message.success('评分成功')
          reload()
        })
      }

      const onChange = () => {
        // console.log(888)
      }
      const handleCancel = () => {
        store.commit('changeKey', {
          engineGradeVisible: false,
        })
        reload()
      }
      onMounted(() => {
        let params = {
          docId: store.state.taskDetail.docId,
          twinslatorId: store.state.taskDetail.twinslatorId,
        }
        existsScoreByDocId(params).then((res) => {
          state.twinslatorName = res.data.data.twinslatorName
        })
      })

      return {
        t,
        state,
        ...toRefs(state),
        handleOk,
        onChange,
        handleCancel,
        engineGradeVisible: computed(() => store.state.engineGradeVisible),
      }
    },
  }
