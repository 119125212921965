<template>
  <a-modal
    title="标签(Tag)介绍"
    :visible="true"
    centered
    okText="我知道了"
    cancelText="返回"
    :width="640"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="Placeholder">
      <div class="title">
        <div class="title-one">
          为保障译稿的图片、文本颜色等标签(Tag) 与原稿一致，
        </div>
        <div class="title-two">
          您需将原文框中的标签符，点击插入至译文框中对应的位置。
        </div>
      </div>
      <div class="content">
        <a-carousel autoplay arrows :afterChange="onArrowChange">
          <template #prevArrow>
            <div
              class="custom-slick-arrow custom-slick-arrow-left"
              v-if="current > 0"
            >
              <LeftOutlined class="font-size-14" />
            </div>
          </template>
          <template #nextArrow>
            <div
              class="custom-slick-arrow custom-slick-arrow-right"
              v-if="current < 3"
            >
              <RightOutlined class="font-size-14" />
            </div>
          </template>
          <div>
            <img src="./images/1.png" />
          </div>
          <div>
            <img src="./images/2.png" />
          </div>
          <div>
            <img src="./images/3.png" />
          </div>
          <div>
            <img src="./images/4.png" />
          </div>
        </a-carousel>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
  export default {
    name: 'index',
    components: {
      LeftOutlined,
      RightOutlined,
    },
    data() {
      return {
        current: 0,
      }
    },
    methods: {
      handleOk() {
        this.$store.state.modal.placeHolderVisible = false
      },
      handleCancel() {
        this.$store.state.modal.placeHolderVisible = false
      },
      onArrowChange(e) {
        this.current = e
      },
    },
  }
</script>

<style scoped lang="less">
  .custom-slick-arrow {
    width: 32px !important;
    height: 48px !important;
    background: #4e5969 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    top: 90px !important;
    ::v-deep(span) {
      color: #fff;
    }
  }
  .custom-slick-arrow-left {
    z-index: 1;
    left: 0 !important;
    //left: 10px !important;
  }
  .custom-slick-arrow-right {
    right: 0 !important;
  }
  .Placeholder {
    text-align: center;
    .title {
      margin-bottom: 16px;
      img {
        height: 40px;
        margin-bottom: 8px;
      }
      span {
        color: #999999;
        line-height: 18px;
        height: 18px;
        font-size: 12px;
      }
    }
    .title-one {
      line-height: 28px;
      font-size: 16px;
      color: #1c2129;
    }
    .title-two {
      line-height: 28px;
      font-size: 16px;
      color: #1c2129;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .content {
      width: 580px;
      margin: 0 auto;
      text-align: center;
      overflow: hidden;
      img {
        border-radius: 8px;
        width: 580px;
      }
    }
  }
</style>
