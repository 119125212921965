<template>
  <div id="project-container">
    <!--//taskDetail出来的时候才开始渲染，不然请求文档会报错-->
    <div v-if="store.state.taskDetail.docId">
      <sourceEditHeader></sourceEditHeader>
      <Header></Header>
      <!--预览模式下不展示操作按钮 减少渲染-->
      <div class="project-container">
        <splitpanes @resize="onPaneResize">
          <pane :size="horizontalPaneSize" min-size="60">
            <excel></excel>
            <projectWord></projectWord>
          </pane>
          <pane :size="100 - horizontalPaneSize" :min-size="minHorizontalSize">
            <div
              class="vertical-tabs"
              id="project-vertical-tabs"
              style="width: 100%;height: 100%"
              v-if="
                Number(minHorizontalSize) ==
                  keepTwoDecimal(100 - horizontalPaneSize)
              "
            >
              <verticalTabs
                @openHorizontalPaneSize="openHorizontalPaneSize"
              ></verticalTabs>
            </div>

            <div
              style="height: 100%"
              v-show="
                keepTwoDecimal(100 - horizontalPaneSize) >
                  Number(minHorizontalSize)
              "
            >
              <replace v-if="store.state.rightToolbar.replaceShow"></replace>
              <div
                v-show="!store.state.rightToolbar.replaceShow"
                style="height: 100%"
              >
                <RightToolBar
                  @closeHorizontalPaneSize="closeHorizontalPaneSize"
                ></RightToolBar>
              </div>
            </div>
          </pane>
        </splitpanes>
      </div>
    </div>

    <!--//项目引导-->
    <guide></guide>
  </div>
</template>

<script>
  import { defineComponent, computed, reactive, toRefs, onMounted } from 'vue'
  import replace from '@/components/right_toolbar/tabs/replace.vue'
  import Header from '@/components/header/index.vue'
  import sourceEditHeader from '@/components/header/sourceEditHeader.vue'
  import RightToolBar from '@/components/right_toolbar/index.vue'
  import projectWord from '@/components/source/projectWord.vue'
  import init from '@/init'
  import { Splitpanes, Pane } from 'splitpanes'
  import 'splitpanes/dist/splitpanes.css'
  import Excel from '@/components/pagination/excel.vue'
  import { useStore } from 'vuex'
  import guide from '@/components/guide/index.vue'
  import verticalTabs from './verticalTabs/index.vue'
  import { message } from 'ant-design-vue'
  import useProject from '@/components/source/useProject'
  import useUtils from '@/plugins/utils'
  // import useProject from "@/components/source/useProject.ts";
  // import useUtils from "@/plugins/utils";
  import { positionSentence } from '@/plugins/comm'
  import { confirmSentences } from '@/http/api'
  import filterStrings from '@/plugins/filterString'
  import store from "@/store";
  export default defineComponent({
    name: 'project',
    components: {
      verticalTabs,
      guide,
      replace,
      Excel,
      Splitpanes,
      Pane,
      sourceEditHeader,
      Header,
      RightToolBar,
      projectWord,
    },
    setup() {
      const store = useStore()
      const state = reactive({
        horizontalPaneSize: 75,
        minHorizontalSize: 20,
      })

      const { setFocusEnd, insertHtmlAtCaret } = useUtils()

      const { docData, afterTargetMouseDown } = useProject()
      const initFunc = () => {
        init(store)
      }

      //子组件传递更改值
      const openHorizontalPaneSize = () => {
        state.horizontalPaneSize = 75
        localStorage.setItem('horizontalPaneSize', state.horizontalPaneSize)
      }
      const closeHorizontalPaneSize = () => {
        state.horizontalPaneSize = keepTwoDecimal(100 - state.minHorizontalSize)
        localStorage.setItem('horizontalPaneSize', state.horizontalPaneSize)
      }

      const setExcelWidth = () => {
        //增加excel的判断
        let width = document.body.clientWidth
        let excelWidth = Math.floor((width * state.horizontalPaneSize) / 100)
        store.commit('optionsBar/changeKey', {
          excelWidth,
        })
      }

      const onPaneResize = (e) => {
        state.horizontalPaneSize = e[0].size
        localStorage.setItem('horizontalPaneSize', state.horizontalPaneSize)
      }

      const setPaneSize = () => {
        let horizontalPaneSize = localStorage.getItem('horizontalPaneSize')
        if (horizontalPaneSize) {
          state.horizontalPaneSize = keepTwoDecimal(horizontalPaneSize)
        }
        setExcelWidth()
        //增加excel的判断
      }

      //保留两位小数
      const keepTwoDecimal = (num) => {
        let result = parseFloat(num)
        if (isNaN(result)) {
          return false
        }
        result = Math.round(num * 100) / 100
        return result.toFixed(2)
      }

      const initHorizontalPaneSize = () => {
        let width = document.body.clientWidth
        let minHorizontalSize = keepTwoDecimal((56 / width) * 100)
        state.minHorizontalSize = minHorizontalSize
      }

      const OSnow = () => {
        let agent = navigator.userAgent.toLowerCase()
        let isMac = /macintosh|mac os x/i.test(navigator.userAgent)
        if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
          return true
        }
        if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
          return true
        }
        if (isMac) {
          return false
        }
        return true
      }

      setPaneSize()
      initFunc()

      const macCutKeys = (e, firstKey, secondKey, thirdKey) => {
        // e.preventDefault();
        //撤销
        if (firstKey && e.code == 'KeyZ') {
          // console.log("按下了CTRL+A")
          if (
            store.getters.$isPreviewMode ||
            store.state.checkedDetail.isLocked
          ) {
            return
          }
          store.dispatch('revokeSentence/backLast')
        }
        //恢复
        else if (firstKey && e.code == 'KeyY') {
          if (
            store.getters.$isPreviewMode ||
            store.state.checkedDetail.isLocked
          ) {
            return
          }
          store.dispatch('revokeSentence/forwardNext')
          // console.log("按下了CTRL+A")
        }
        //复制原文
        else if (firstKey && e.code == 'ArrowRight') {
          if (
            store.getters.$isPreviewMode ||
            store.state.checkedDetail.isLocked
          ) {
            return
          }
          store.dispatch('optionsBar/copySource')
          e.preventDefault()
          // console.log("按下了CTRL+A")
        }
        //上移
        else if (firstKey && e.code == 'ArrowUp') {
          store.dispatch('optionsBar/lastSentence')
          e.preventDefault()
        }
        //下移
        else if (firstKey && e.code == 'ArrowDown') {
          store.dispatch('optionsBar/nextSentence')
        }
        //添加术语
        else if (firstKey && e.code == 'KeyT') {
          store.dispatch('openAddTerm')
        }
        //查找替换
        else if (firstKey && e.code == 'KeyH') {
          store.dispatch('openFindReplace')
        }
        //查词
        else if (firstKey && e.code == 'KeyF') {
          store.dispatch('openSearchWord')
        }
        //加粗
        else if (secondKey && e.code == 'KeyB') {
          document.execCommand('bold', false, null)
        }
        //斜体
        else if (secondKey && e.code == 'KeyI') {
          document.execCommand('Italic', false, null)
        }
        //下划线
        else if (secondKey && e.code == 'KeyU') {
          document.execCommand('Underline', false, null)
        }
        //上标
        else if (thirdKey && e.code == 'Equal') {
          document.execCommand('Superscript', false, null)
        }
        //下标
        else if (secondKey && e.code == 'Equal') {
          document.execCommand('Subscript', false, null)
        }
        //锁定句段
        else if (firstKey && e.code == 'KeyL') {
          if (store.state.taskDetail.taskClassify == 8) {
            store.dispatch('optionsBar/lockSentence')
          }
          // else {
          //   if (store.state.taskDetail.allowTranslatorUnLock) {
          //     if (!store.state.checkedDetail.isLocked) {
          //       store.dispatch(
          //         "optionsBar/lockSentence",
          //         store.state.checkedDetail
          //       );
          //     }
          //   } else {
          //     message.warning("暂无解锁/锁定权限");
          //   }
          // }
        }
        //解锁
        else if (thirdKey && e.code == 'KeyL') {
          if (store.state.taskDetail.taskClassify == 8) {
            store.dispatch('optionsBar/unLockSentence')
          }
          // else {
          //   if (store.state.taskDetail.allowTranslatorUnLock) {
          //     if (store.state.checkedDetail.isLocked) {
          //       store.dispatch(
          //         "optionsBar/unLockSentence",
          //         store.state.checkedDetail
          //       );
          //     }
          //   } else {
          //     message.warning("暂无解锁/锁定权限");
          //   }
          // }
        }
        // //锁定句段
        // else if (secondKey && e.code =="Digit2") {
        //   if (store.state.taskDetail.taskClassify == 8) {
        //     store.dispatch("optionsBar/lockSentence");
        //   } else {
        //     if (store.state.taskDetail.allowTranslatorUnLock) {
        //       if (store.state.checkedDetail.isLocked){
        //         store.dispatch("optionsBar/lockSentence",store.state.checkedDetail);
        //       }
        //     } else {
        //       message.warning("暂无解锁/锁定权限");
        //     }
        //   }
        // }
        // //锁定句段
        // else if (firstKey && e.code == "Digit2") {
        //   store.dispatch()
        // }
        // //解锁句段
        // else if (secondKey && e.code == "Digit2") {
        //
        // }
      }

      const windowShortKeys = (e, firstKey, secondKey) => {
        // e.preventDefault();
        //撤销
        if (firstKey && e.code == 'KeyZ') {
          // console.log("按下了CTRL+A")
          if (
            store.getters.$isPreviewMode ||
            store.state.checkedDetail.isLocked
          ) {
            return
          }
          store.dispatch('revokeSentence/backLast')
        }
        //恢复
        else if (firstKey && e.code == 'KeyY') {
          if (
            store.getters.$isPreviewMode ||
            store.state.checkedDetail.isLocked
          ) {
            return
          }
          store.dispatch('revokeSentence/forwardNext')
          // console.log("按下了CTRL+A")
        }
        //复制原文
        else if (firstKey && e.code == 'ArrowRight') {
          if (
            store.getters.$isPreviewMode ||
            store.state.checkedDetail.isLocked
          ) {
            return
          }
          store.dispatch('optionsBar/copySource')
          e.preventDefault()
          // console.log("按下了CTRL+A")
        }
        //上移
        else if (firstKey && e.code == 'ArrowUp') {
          store.dispatch('optionsBar/lastSentence')
          e.preventDefault()
        }
        //下移
        else if (firstKey && e.code == 'ArrowDown') {
          store.dispatch('optionsBar/nextSentence')
        }
        //添加术语
        else if (firstKey && e.code == 'KeyT') {
          store.dispatch('openAddTerm')
        }
        //查找替换
        else if (firstKey && e.code == 'KeyH') {
          store.dispatch('openFindReplace')
        }
        //查词
        else if (firstKey && e.code == 'KeyF') {
          e.preventDefault()
          store.dispatch('openSearchWord')
        }
        //加粗
        else if (firstKey && e.code == 'KeyB') {
          document.execCommand('bold', false, null)
        }
        //斜体
        else if (firstKey && e.code == 'KeyI') {
          document.execCommand('Italic', false, null)
        }
        //下划线
        else if (firstKey && e.code == 'KeyU') {
          document.execCommand('Underline', false, null)
        }
        //上标
        else if (secondKey && e.code == 'Equal') {
          document.execCommand('Superscript', false, null)
        }
        //下标
        else if (firstKey && e.code == 'Equal') {
          document.execCommand('Subscript', false, null)
        }
        //锁定
        else if (firstKey && e.code == 'KeyL') {
          if (store.state.taskDetail.taskClassify == 8) {
            store.dispatch('optionsBar/lockSentence')
          }
        }
        //解锁
        else if (secondKey && e.code == 'KeyL') {
          if (store.state.taskDetail.taskClassify == 8) {
            store.dispatch('optionsBar/unLockSentence')
          }
        }
        // else if ()
      }

      const macShortcutKeys = (e) => {
        //mac 系统中 e.ctrlKey 为 control e.altKey 为 option
        let firstKey = (e.ctrlKey && e.altKey) || false //按住ctrl + alt
        let secondKey = e.ctrlKey && !e.altKey && !e.shiftKey //只按住ctrl
        let thirdKey = e.ctrlKey && e.shiftKey //按住ctrl + shift

        macCutKeys(e, firstKey, secondKey, thirdKey)
        // let secondKey = e.altKey || false
      }

      const windowShortcutKeys = (e) => {
        let firstKey = e.altKey && !e.shiftKey
        let secondKey = e.altKey && e.shiftKey
        // let secondKey = e.
        windowShortKeys(e, firstKey, secondKey)
      }

      const tabActiveIndex = computed(
        () => store.state.excelProject.tabActiveIndex
      )

      onMounted(() => {
        initHorizontalPaneSize()
        let isWindow = OSnow() //true 为window false 为mac 默认为window
        store.commit('changeKey', {
          isWindow: isWindow,
        })
        //增加快键键事件处理
        document.body.addEventListener('keyup', (e) => {
          if (e.key == 'Shift' && store.state.optionsBar.shiftKeyStatus) {
            //取消Shift按键状态 重置lastIndex
            store.commit('optionsBar/changeKey', {
              shiftKeyStatus: false,
            })
          }
        })
        //增加快捷键事件
        document.body.addEventListener('keydown', (e) => {
          if (store.state.openEditSourceText) {
            return true
          }
          if (store.getters.$isPreviewMode) {
            return true
          }
          if (store.state.isDisabledEnter) {
            return true
          }

          if (e.key == 'Shift' && !store.state.optionsBar.shiftKeyStatus) {
            //增加shift按键状态
            store.commit('optionsBar/changeKey', {
              shiftKeyStatus: true,
            })
          }

          //enter 键触发切换句子 确认提前 //要考虑其他快捷键的情况 所以只有enter 被按住触发
          if (e.keyCode === 13 && !e.ctrlKey && !e.altKey && !e.shiftKey) {
            // 判断是否有语料存储库拦截
            // if (store.state.taskDetail.corpusLibrary?) {
            //
            // }
            if (
              !store.state.taskDetail.corpusLibrary?.length &&
               store.state.taskDetail.taskClassify != 11  &&
              !localStorage.getItem(`${store.state.taskDetail.docId}`)
            ) {
              store.commit('modal/changeKey', {
                corpusRemindVisible: true,
              })
              e.preventDefault()
              return
            }

            const list = docData.value.sentenceDTOList
            let item = null

            if (list.length==0) {
              return
            }
            //如果当前据非锁定句； 则走强确认
            if (!list[tabActiveIndex.value].locked) {
              //如果这句话是保存失败的句子，则依旧走正常提交流程；将译文传入
              if (list[tabActiveIndex.value].saveFailStatus) {
                let innerText = filterStrings.getString(
                  document.getElementById(
                    list[tabActiveIndex.value].targetDomId
                  ).innerHTML
                )
                store.dispatch('sentCommit', {
                  docId: list[tabActiveIndex.value].docId,
                  sentId: list[tabActiveIndex.value].sentId,
                  saveFailStatus: true,
                  target: filterStrings.getPureString(innerText),
                  targetPlaceholder: innerText,
                  sentIndex: list[tabActiveIndex.value].sentIndex,
                })
              } else {
                let innerText = filterStrings.getString(
                  document.getElementById(
                    list[tabActiveIndex.value].targetDomId
                  ).innerHTML
                )
                store.dispatch('sentCommit', {
                  docId: list[tabActiveIndex.value].docId,
                  sentId: list[tabActiveIndex.value].sentId,
                  onlyUpdateConfirmStatus: true,
                  isConfirm: true,
                  target: filterStrings.getPureString(innerText),
                  targetPlaceholder: innerText,
                })
              }
              //走强失焦
              document
                .getElementById(list[tabActiveIndex.value].targetDomId)
                .blur()
            }

            if (tabActiveIndex.value < list.length - 1) {
              store.commit('excelProject/changeKey', {
                tabActiveIndex: tabActiveIndex.value + 1,
              })
              item = list[tabActiveIndex.value]
              let whileIndex = tabActiveIndex.value
              while (item && item.locked && whileIndex < list.length - 1) {
                whileIndex++
                item = list[whileIndex]
              }
              store.commit('excelProject/changeKey', {
                tabActiveIndex: whileIndex,
              })

              //如果最后一句是锁定句 ，需要定位
              if (item.locked) {
                positionSentence(item.sentId)
              }
            }

            //如果下一条非锁定句；则正常触发操作；
            if (item && !item.locked) {
              afterTargetMouseDown(item)
              //如果是被折叠了，需要放出来；
              if (store.state.collapseGroup.includes(item.groupParentId)) {
                positionSentence(item.sentId,true)
              } else {
                //当前选中句得到焦点，并放置到最后
                setFocusEnd(window.$getTargetDom(item.sentId), true)
              }
            } else if (!item) {
              //如果当页已无数据，则到下一页；重新走流程；还需要判断下一页是否有数据
              if (docData.value.pageNum < docData.value.pageCount) {
                store.commit('changeKey', {
                  pageNum: docData.value.pageNum + 1,
                })
                store.dispatch('getSentence', {
                  isBackTop: true,
                  loading: true,
                })
              } else {
                setTimeout(() => {
                  //没有切换的时候增加术语重新调用
                  store.dispatch('excelProject/twinslatorMatch')
                }, 2500)
              }
            }
            e.preventDefault()
            return
          }

          isWindow ? windowShortcutKeys(e) : macShortcutKeys(e)
        })
      })

      return {
        closeHorizontalPaneSize,
        openHorizontalPaneSize,
        keepTwoDecimal,
        onPaneResize,
        ...toRefs(state),
        store,
        ...toRefs(store.state.rightToolbar),
        taskDetail: computed(() => store.state.taskDetail),
      }
    },
  })
</script>

<style lang="scss">
  .project-container {
    height: calc(100vh - 48px);
    overflow-y: hidden;
    min-width: 1300px;
    .splitpanes {
      //background-color: white;
    }

    .splitpanes__splitter {
      background: #ffffff;
      box-shadow: inset 1px 0px 0px 0px #e5f4f8;
      //background-color: #dddddd;
      position: relative;
    }
    .splitpanes__splitter:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transition: opacity 0.4s;
      background-color: var(--universal-color-border);
      opacity: 0;
      z-index: 1;
    }
    .splitpanes__splitter:hover:before {
      opacity: 1;
    }
    .splitpanes--vertical > .splitpanes__splitter:before {
      left: 0px;
      right: -2px;
      height: 100%;
    }
    .splitpanes--horizontal > .splitpanes__splitter:before {
      top: -2px;
      bottom: -2px;
      width: 100%;
    }
  }
  .vertical-tabs {
    width: 48px;
    //background: red;
  }
</style>
