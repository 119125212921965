<template>
  <div>
    <a-tooltip>
      <template #title>
        <span v-if="allowSaveSnapshot">恢复快照</span>
        <span v-else>
          未保存快照，不可恢复快照
          <QuestionCircleOutlined
            style="color: #fff"
            class="font-size-12 cursor-pointer margin-l-6"
            @click="openDialog"
          />
        </span>
      </template>
      <div>
        <a-button
          class="li"
          type="link"
          :class="{
            disabled: props.disabled || !allowSaveSnapshot,
          }"
          @click="openModal"
        >
          <icon-font
            class="font-size-16 icon-box"
            :style="{ color: '#333' }"
            type="icon-kuaizhao"
          ></icon-font>
          <p class="li-txt">
            快照
          </p>
        </a-button>
      </div>
    </a-tooltip>

    <a-modal
      title="提示"
      v-model:visible="state.visible"
      @ok="handleOk"
      okText="确认恢复"
      @cancel="handleCancel"
      centered
    >
      <div class="text-center">
        <div class="margin-b-20">
          <img src="@/assets/warning_1.png" width="48" />
        </div>
        <div class="margin-b-8" v-if="state.extSentIdMap.snapshotCount > 1">
          已保存
          <span class="color-F55C3F">
            {{ state.extSentIdMap.startIndex }}~{{
              state.extSentIdMap.endIndex
            }}等{{ state.extSentIdMap.snapshotCount }}句
          </span>
          的译文快照，是否恢复？
        </div>
        <div class="margin-b-8" v-else>
          是否恢复第
          <span class="color-F55C3F">{{ state.extSentIdMap.startIndex }}</span>
          句的译文快照？
        </div>
        <div class="color-F55C3F">
          【已选句段的译文】将恢复成【快照译文】，请谨慎操作！
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { computed, reactive, onMounted } from 'vue'
  import { postCheckExistCount, postRecoverSnapshot } from '@/http/api'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import { message } from 'ant-design-vue'

  const props = defineProps(['disabled'])
  const store = useStore()
  const checkedList = computed(() => store.state.excelProject.checkedList)
  const allowSaveSnapshot = computed(
    () => store.state.optionsBar.allowSaveSnapshot
  )
  const state = reactive({
    visible: false,
    extSentIdMap: {
      startIndex: '',
      endIndex: '',
      snapshotCount: '',
    },
  })

  const handleCancel = () => {
    state.visible = false
  }

  const openDialog = () => {
    store.commit('modal/changeKey', {
      saveSnapshotVisible: true,
    })
  }
  const openModal = () => {
    postCheckExistCount().then((res) => {
      let { startIndex, endIndex, snapshotCount } = res.data.data
      //根据数字传值改变
      state.extSentIdMap = {
        startIndex,
        endIndex,
        snapshotCount,
      }
      if (snapshotCount > 0) {
        state.visible = true
      } else {
        store.commit('optionsBar/changeKey', {
          allowSaveSnapshot: false,
        })
        message.warning('暂无快照可恢复')
      }
    })
    //获取句子快照信息
  }

  //点击确定恢复快照
  const handleOk = () => {
    handleCancel()
    store.commit('changeKey', {
      screenLoading: true,
    })
    //增加延时是为了防止译文更新不及时
    setTimeout(() => {
      postRecoverSnapshot().then((res) => {
        store.commit('optionsBar/changeKey', {
          allowSaveSnapshot: false,
        })
        store.dispatch('getSentence', { loading: true })
      })
    }, 500)
  }

  onMounted(() => {
    postCheckExistCount().then((res) => {
      let { startIndex, endIndex, snapshotCount } = res.data.data
      if (snapshotCount > 0) {
        store.commit('optionsBar/changeKey', {
          allowSaveSnapshot: true,
        })
      }
    })
  })
</script>

<style scoped lang="less"></style>
