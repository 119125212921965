<template>
    <span class="levelMessage">
      <CheckCircleFilled style="color:#52c41a;font-size: 16px;margin-right: 6px"/>
      <span class="msg-text">PM已预翻译完成，请刷新页面，按最新译文继续翻译</span>
      <span class="level-light" @click="refresh">立即刷新</span>
    </span>
</template>

<script setup>
import {useStore} from "vuex";
import { message } from 'ant-design-vue'
import {onMounted} from "vue"
import {CheckCircleFilled} from '@ant-design/icons-vue'

//type 要变化 ，根据不同类型去做，有可能正在进行；
const store = useStore()
const refresh=()=>{
  // store.dispatch("getSentence",{loading:true});
  window.location.reload();
}
</script>

<style scoped lang="less">
.levelMessage {
  display: flex;
  align-items: center;
  span {
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
  }
  .msg-text {
    margin-right: 16px;
  }
  .level-light {
    color: #00befa;
    margin-right: 16px;
    cursor: pointer;
  }
  ::v-deep(svg) {
    height: 20px;
    line-height: 20px;
  }
  .icon-tip {
    color: #52c41a;
    font-size: 18px;
    //fill:#F55C3F;
    //width: 16px;
    //height: 16px;
  }
  .icon-close {
    font-size: 18px;
    cursor: pointer;
  }
}
</style>