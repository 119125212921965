<template>
  <a-dropdown placement="bottomRight" v-model:visible="visible" :trigger="['click']">
      <a-button
          class="li"
          type="link"
          :class="{ disabled: disabled }"
      >
        <a-badge
            :count="groupBy?2:0"
            :offset="[5, 4]"
            :number-style="{
            backgroundColor: '#F55c3F',
            padding: '0 4px',
            minWidth: '16px',
            height: '16px',
            lineHeight: '15px',
            marginTop:'6px'
          }"
        >
          <icon-font class="font-size-16" type="icon-fenzu"></icon-font>
        </a-badge>
        <p class="li-txt">分组</p>
      </a-button>
    <template #overlay>
      <div class="splitGroup">
        <header>
          <div class="left">
            设置分组条件
          </div>
          <div class="right">
            <a-space :size="12">
              <label class="cursor-pointer" :class="{active:isCollapsed}" @click="changeCollapsedStatus(true)">折叠所有</label>
              <label class="cursor-pointer" :class="{active:!isCollapsed}"  @click="changeCollapsedStatus(false)">展开所有</label>
            </a-space>
          </div>
        </header>
        <section>
          <div class="left">
            <a-space :size="12">
              <a-select style="width: 140px" placeholder="选择方式" v-model:value="groupBy">
                <a-select-option value="RepeatSentence">重复句句段</a-select-option>
              </a-select>
              <a-select style="width: 140px" v-model:value="groupSort" v-if="groupBy">
                <a-select-option value="desc">
                  从多到少
                </a-select-option>
                <a-select-option value="asc">
                  从少到多
                </a-select-option>
              </a-select>
            </a-space>
          </div>
          <div class="right" @click="clearSplitGroup">
            清空条件
          </div>
        </section>
        <footer>
          <div>
            注：重复句段分组是基于重复率分析后「重复同步标记」进行的分组；<span style="color:#00B4FA">历史项目需重新分析后支持分组。</span>
          </div>
        </footer>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
import {QuestionCircleOutlined} from "@ant-design/icons-vue";
import {useI18n} from "vue-i18n";
import {computed, getCurrentInstance, ref, watch,reactive,toRefs} from "vue";
import {useStore} from "vuex";
import useUtils from "@/plugins/utils";
import {message} from "ant-design-vue";
import {getLastModifiedSent} from "@/http/api";
import {positionSentence} from "@/plugins/comm";

export default {
  props: ['disabled'],
  components: {
    QuestionCircleOutlined
  },
  setup() {
    const { t } = useI18n()
    const { appContext } = getCurrentInstance()
    const globalProxy = appContext.config.globalProperties
    const store = useStore()
    const { setFocusEnd } = useUtils()
    const checkedDetail = computed(() => {
      return store.state.checkedDetail
    })
    const isCollapsed=computed(()=>{
      return store.state.excelProject.isCollapsed;
    })
    const taskDetail = computed(()=>{
      return store.state.taskDetail
    })


    const groupBy=computed({
      get() {
        return store.state.excelProject.groupBy
      },
      set(val) {
        // console.log(val)
        store.commit('excelProject/changeKey', {
          groupBy: val
        })
        store.commit('excelProject/changeKey', {
          groupSort: 'desc'
        })
        store.dispatch("getSentence",{loading:true,backTop:true})
      },
    });

    const groupSort=computed({
      get() {
        return store.state.excelProject.groupSort
      },
      set(val) {
        store.commit('excelProject/changeKey', {
          groupSort: val
        })
        store.dispatch("getSentence",{loading:true,backTop:true})
      },
    })

    const state =reactive({
      visible:false,
    })

    const clearSplitGroup=()=>{
      store.commit('excelProject/changeKey', {
        groupBy: null
      })
      store.commit('excelProject/changeKey', {
        groupSort: null
      })
      store.dispatch("getSentence",{loading:true,backTop:true})
    }

    const changeCollapsedStatus=(status)=>{
      if (status == isCollapsed.value) {
        return
      }

      store.commit("excelProject/changeKey",{
        isCollapsed:status
      })

      if (status) {
        store.dispatch("addCollapseGroup");
      } else {
        store.dispatch("removeCollapseGroup")
      }

      //立即折叠/收起当前页面所有的信息；

    }



    const selectFunc = () => {}

    return {
      groupBy,
      groupSort,
      changeCollapsedStatus,
      isCollapsed,
      clearSplitGroup,
      ...toRefs(state),
      t,
      store,
      taskDetail:taskDetail,
      selectFunc,
      checkedDetail,
    }
  },
}
</script>

<style scoped lang="less">
.splitGroup{
  width: 400px;
  padding:16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
  border-radius: 8px;
  border: 1px solid #F2F3F5;
  header{
    color:#666;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .left{
      font-size: 14px;
    }
    .right{
      font-size: 12px;
      .active{
        color:#00B4FA;
      }
    }
  }
  section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    .right{
      cursor: pointer;
      font-size: 12px;
      color: #00B4FA;
    }
  }
  footer{
    font-size: 12px;
    color: #BBBBBB;
    line-height: 18px;
    font-style: normal;
  }
}

</style>
