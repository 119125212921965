<template>
  <div
    class="target-box display-flex justify-center new-edit-box-hover"
    :class="{
      'new-edit-box-border':
        checkedDetail.sentId &&
        tabActiveIndex == index &&
        activeBorder == 'right',
      'new-edit-box-locked': item.locked || item.sourceEditRepeatLock,
    }"
  >
    <div class="display-flex justify-center target-box-position">
      <!--// 原文编辑 纯展示-->
      <div
        v-if="store.state.excelProject.differentHighLightModel"
        :id="item.targetDomId"
        :contenteditable="
          !store.getters.$isPreviewMode &&
            !item.locked &&
            !item.sourceEditRepeatLock
        "
        class="target-txt-different target-txt target-edit-area target-language-text-style"
        :class="[
          `target-sent-${item.sentId}`,
          tabActiveIndex == index && !store.getters.$isPreviewMode
            ? 'target-txt-focus'
            : '',
        ]"
        isListener="false"
        autocapitalize="off"
        @blur.native="targetBlur(item)"
        spellcheck="true"
        v-html="$assignValueToSrc(item.diffCompare)"
        @drop="onTargetDrop($event, item)"
        @dragstart="onTargetDragstart($event, item)"
      ></div>

      <div
        v-else
        :id="item.targetDomId"
        :contenteditable="
          !store.getters.$isPreviewMode &&
            !item.locked &&
            !item.sourceEditRepeatLock
        "
        class="target-txt target-edit-area qa-node-position target-language-text-style"
        :data-sentId="item.sentId"
        :class="[`target-sent-${item.sentId}`]"
        isListener="false"
        autocapitalize="off"
        @blur.native="targetBlur(item)"
        spellcheck="true"
        v-html="item.renderTargetHTML"
        @drop="onTargetDrop($event, item)"
        @dragstart="onTargetDragstart($event, item)"
      ></div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { computed, getCurrentInstance } from 'vue'
  import useProject from '@/components/source/useProject'
  import useUtils from '@/plugins/utils'
  import { getFactTargetText } from '@/plugins/comm.js'
  import { message } from 'ant-design-vue'

  export default {
    name: 'index',
    props: ['item', 'index'],
    setup(props) {
      const store = useStore()
      const { setFocusEnd } = useUtils()
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const checkedDetail = computed(() => store.state.checkedDetail)
      const { t, getRangeText, targetMousedown, targetBlur } = useProject()

      const tabActiveIndex = computed({
        set: (val) => {
          store.commit('excelProject/changeKey', {
            tabActiveIndex: val,
          })
        },
        get: () => store.state.excelProject.tabActiveIndex,
      })

      const openDialog = () => {
        store.commit('modal/changeKey', {
          lockDialogVisible: true,
        })
      }

      const onTargetDragstart = (ev, item) => {
        ev.dataTransfer.dropEffect = 'move'
        ev.dataTransfer.effectAllowed = 'move'
        ev.dataTransfer.setData('origin', `isTarget`)
        ev.dataTransfer.setData('sent-id', `${item.sentId}`)
      }

      const onTargetDrop = (ev, item) => {
        // console.log(ev)
        // ev.preventDefault();
        let sentId = ev.dataTransfer.getData('sent-id')

        if (sentId != item.sentId) {
          ev.preventDefault()
          return false
        }

        let imgIds = ev.dataTransfer.getData('img-id')
          ? ev.dataTransfer.getData('img-id').split('&')
          : []
        let origin = ev.dataTransfer.getData('origin')

        if (origin == 'isTarget') {
        } else {
          let childNodes = document
            .getElementById(item.targetDomId)
            .querySelectorAll(`img`)
          childNodes.forEach((item) => {
            if (imgIds.includes(item.id)) {
              item.parentNode.removeChild(item)
            }
          })
          store.dispatch('excelProject/renderSourceHtml', { sentId: sentId })
        }
        //强制失焦
        setTimeout(() => {
          let focusDom = document.getElementById(item.targetDomId)
          // console.log(rangeObj);
          // let docFragment = rangeObj.cloneContents();
          // console.log(docFragment);
          let tagList = focusDom.querySelectorAll('*')
          // console.log(imgList)
          tagList.forEach((item) => {
            if (item.attributes['style']) {
              item.removeAttribute('style')
            }
            //如果src 包含地址，则替换地址
            if (item.src && item.src.includes(location.origin)) {
              item.src = item.src.split(location.origin)[1]
            }
          })
          // 原文直接走提交
          if (origin != 'isTarget') {
            window.getSelection().removeAllRanges()
            //检查Dom节点 查看Src 路径如何；同步移除img style样式。

            focusDom.blur()
          }
        }, 0)
      }

      return {
        onTargetDragstart,
        onTargetDrop,
        checkedDetail,
        openDialog,
        targetBlur,
        tabActiveIndex,
        props,
        targetMousedown,
        t,
        getFactTargetText,
        store,
        activeBorder: computed(() => store.state.sourceEdit.activeBorder),
        taskDetail: computed(() => store.state.taskDetail),
        getRangeText,
      }
    },
  }
</script>

<style scoped lang="less">
  .target-box {
    position: relative;
    flex: 1;
    height: 100%;
    padding: 8px 24px 8px 10px;
    border-width: 2px;
    border-color: transparent;
    border-style: solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .target-box-position {
      position: relative;
      flex: 1;
    }
    ::v-deep(.qaError) {
      border-bottom: 2px solid #f55c3f;
    }
    .target-txt-different {
      ::v-deep(a) {
        cursor: auto;
      }
      //当前句子译员修改过，没有审校修改 译员显示颜色
      ::v-deep(.peadd) {
        color: #333333;
      }
      //当前审校修改过 自己添加的显示颜色
      ::v-deep(.pfadd) {
        color: #f9752e;
      }
      //删除显示颜色
      ::v-deep(del) {
        color: #ccc;
        &.pfdelete {
          color: #fbcbb1;
        }
      }
      //当前句子审校修改过 普通译文显示颜色
      ::v-deep(.pfcommon) {
        color: #333333;
      }
      //当前句子译员修改过，没有审校修改 普通译文显示颜色 以及机翻普通颜色
      ::v-deep(.pecommon) {
        color: #078ed9;
      }
      //100%匹配
      ::v-deep(.complete_match) {
        color: #078ed9;
      }
      //聚集焦点后 过滤删除一些代码
      &.target-txt-focus {
        ::v-deep(a) {
          color: #078ed9;
        }
        ::v-deep(span) {
          color: #078ed9;
        }
        ::v-deep(del) {
          display: none;
        }
      }
    }
    .target-txt {
      width: 100%;
      word-break: break-word;
      white-space: pre-wrap;
      //无差异对比 显示默认颜色
      color: #333;
      font-size: var(--universal-font-size);
      line-height: 1.38;
    }
    .target-edit-area {

    }
  }
</style>
