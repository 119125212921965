import { h } from 'vue'
import { message } from 'ant-design-vue'
import importantTips from '../images/important-tips.png'

const msgOpen = (contentMsg, name) => {
  return message.open({
    content: h(
      'span',
      {
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      [
        h('img', {
          style: {
            fontSize: '16px',
            color: '#F55C3F',
            marginRight: '5px',
            width: '16px',
            height: '16px',
            animation: 'myAction 0.8s infinite',
          },
          src: importantTips,
        }),
        h(
          'span',
          {
            style: {
              fontSize: '14px',
              color: '#333',
              marginRight: '20px',
            },
          },
          contentMsg
        ),
        h(
          'span',
          {
            style: {
              padding: 0,
              fontSize: '14px',
              color: '#F55C3F',
              cursor: 'pointer',
            },
            onClick: () => {
              window.location.reload()
            },
          },
          `${name}`
        ),
      ]
    ),
    duration: 0,
  })
}

export default msgOpen
