<template>
  <div class="qa-box">
    <div class="qa-box-commonQA" v-if="store.state.rightToolbar.sameQAOpen">
      <commonQA @commonQABack="commonQABack"></commonQA>
    </div>
    <div class="qa-tool padding-tb-12">
      <div class="display-flex justify-between qa-tool-filter-one">
        <div class="left">
          <span
            :class="{ isSelected: qaModel == 2 }"
            @click="onQAModelClick(2)"
          >
            单句（{{ singleNumber }}）
          </span>
          |&nbsp;
          <span
            :class="{ isSelected: qaModel == 1 }"
            @click="onQAModelClick(1)"
          >
            全文（{{ fullNumber || 0 }}）
          </span>
        </div>
        <div class="right">
          <a-button
            size="small"
            style="width: 70px; border-radius: 12px"
            @click="allCheck"
            :disabled="store.getters.$isPreviewMode"
            type="primary"
          >
            全文检查
          </a-button>
          <icon-font
            type="icon-setting"
            class="font-size-16 margin-l-8"
            @click="openQAModal"
            style="position: relative;top: 1px"
          ></icon-font>
          <!--          <a-dropdown>-->
          <!--            <a-button size="small" shape="circle" class="margin-l-8">-->
          <!--              <template #icon>-->
          <!--                <icon-font-->
          <!--                  class="font-size-14"-->
          <!--                  type="icon-gengduo"-->
          <!--                  style="position: relative;right: 7px"-->
          <!--                ></icon-font>-->
          <!--              </template>-->
          <!--            </a-button>-->
          <!--            <template #overlay>-->
          <!--              <a-menu>-->
          <!--                <a-menu-item>-->
          <!--                  <a href="javascript:;" class="font-size-12" @click="ignore(0)"-->
          <!--                    >忽略全部</a-->
          <!--                  >-->
          <!--                </a-menu-item>-->
          <!--                <a-menu-item>-->
          <!--                  <a href="javascript:;" class="font-size-12" @click="ignore(1)"-->
          <!--                    >恢复全部</a-->
          <!--                  >-->
          <!--                </a-menu-item>-->
          <!--              </a-menu>-->
          <!--            </template>-->
          <!--          </a-dropdown>-->
        </div>
      </div>
    </div>

    <div v-show="qaLoading" style="text-align: center;padding-top: 20px">
      <a-spin :spinning="qaLoading" />
    </div>
    <div v-show="!qaLoading">
      <div v-show="qaModel == 1">
        <Full ref="FullQA" v-model:fullNumber="fullNumber"></Full>
      </div>
      <div v-show="qaModel == 2">
        <Single ref="SingleQA" v-model:singleNumber="singleNumber"></Single>
      </div>
    </div>
  </div>

  <a-modal
    :visible="checkVisible"
    title="提示"
    centered
    :width="640"
    @cancel="checkVisible = false"
  >
    <div class="checkModal">
      <div class="checkModal-type2" v-if="checkRules?.length">
        <div class="title">
          <img src="./qa/qa-tip.png" />
        </div>
        <div class="content">
          即将按照以下QA类型进行全文检查
        </div>
        <div class="footer">
          <a-checkbox-group v-model:value="qaCheckedList" style="width: 100%">
            <a-row>
              <a-col :span="8" class="margin-b-10">
                <a-checkbox value="TERM_CONSISTENCY_CHECK">术语漏译</a-checkbox>
              </a-col>
              <!--            <a-col :span="8" class="margin-b-10">-->
              <!--              <a-checkbox value="FORMAT_CHECK">译文漏译</a-checkbox>-->
              <!--            </a-col>-->
              <a-col :span="8" class="margin-b-10">
                <a-checkbox value="FORMAT_CHECK">标签错误</a-checkbox>
              </a-col>
              <a-col :span="8" class="margin-b-10">
                <a-checkbox value="SYNTAX_CHECK">
                  语法词法错误
                  <img
                    src="./qa/beta.png"
                    width="24"
                    class="margin-l-4"
                    style="position: relative;top:-1px"
                  />
                </a-checkbox>
              </a-col>
              <a-col :span="8" class="margin-b-10">
                <a-checkbox value="SPELL_CHECK">拼写错误</a-checkbox>
              </a-col>
              <a-col :span="8" class="margin-b-10">
                <a-checkbox value="RULE_LOWE_CHECK">
                  低错检查
                  <img
                    src="./qa/beta.png"
                    width="24"
                    class="margin-l-4"
                    style="visibility: hidden"
                  />
                </a-checkbox>
              </a-col>
<!--              <a-col :span="8" class="margin-b-10">-->
<!--                <a-checkbox value="RULE_LOWE_CHECK">-->
<!--                  一致性检查-->
<!--                  <QuestionCircleOutlined />-->
<!--                </a-checkbox>-->
<!--              </a-col>-->
            </a-row>
          </a-checkbox-group>
          <!--          <span v-for="(item, index) in qaCheckedList" :key="index">-->
          <!--            {{ checkRule[item]}}{{ index == qaCheckedList.length - 1 ? "" : "、" }}-->
          <!--          </span>-->
        </div>
      </div>
      <div class="checkModal-type1" v-else>
        <div class="title">
          <img src="@/assets/warning_1.png" />
        </div>
        <div class="content">
          未设置QA检查项，请至少勾选一个检查项后再全文检查
        </div>
      </div>
    </div>
    <template #footer>
      <a-space v-if="checkRules?.length">
        <a-button @click="closeCheckModal">取消</a-button>
        <a-button @click="openQASetting">QA设置</a-button>
        <a-button type="primary" @click="startCheck">开始检查</a-button>
      </a-space>

      <a-space v-else>
        <a-button @click="closeCheckModal">取消</a-button>
        <!--        <a-button type="primary" @click="openQASetting">前往QA设置</a-button>-->
      </a-space>
    </template>
  </a-modal>
</template>

<script>
  import {
    defineComponent,
    computed,
    ref,
    reactive,
    toRefs,
    createVNode,
    getCurrentInstance,
    nextTick,
    watch,
    onMounted,
  } from 'vue'
  import {
    EllipsisOutlined,
    DownOutlined,
    StopOutlined,
    RollbackOutlined,
    SwapOutlined, QuestionCircleOutlined,
  } from '@ant-design/icons-vue'
  import empty from '@/components/empty/empty.vue'
  import { useStore } from 'vuex'
  import useUtils from '@/plugins/utils'
  import { useI18n } from 'vue-i18n'
  import mitt from '@/plugins/bus'
  import {
    sentenceCheckAll,
    setQASettingApi,
  } from '@/http/api'
  import Full from './qa/full.vue'
  import Single from './qa/single.vue'
  import commonQA from './commonQA/index.vue'

  export default defineComponent({
    components: {
      QuestionCircleOutlined,
      commonQA,
      Full,
      Single,
      SwapOutlined,
      EllipsisOutlined,
      DownOutlined,
      StopOutlined,
      RollbackOutlined,
      empty,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const { dateFormat } = useUtils()
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const activeKey = ref(0)

      const taskDetail = computed(() => store.state.taskDetail)
      //全文一块单独索引(要变),单句单独索引(可以不变 根据checked); 页面模板也将变为两套 增加缓存处理; qa点击做防抖节流; 筛选分页功能开发

      const state = reactive({
        // 1 : 全部qa 2： 单句qa
        qaModel: computed({
          set: (val) => {
            store.commit('rightToolbar/changeKey', {
              qaModel: val,
            })
          },
          get: () => store.state.rightToolbar.qaModel,
        }),
        visible: false,
        fullNumber: 0,
        singleNumber: 0,
        qaLoading: false,
        ignoreResult: {
          data: 0,
        },
        dropdownVisible: false,
        checkVisible: false,
      })
      const checkedDetail = computed(() => store.state.checkedDetail)
      const SingleQA = ref(null)
      const FullQA = ref(null)

      const checkRule = computed(() => store.state.rightToolbar.checkRule)

      const checkRules = computed(() => store.state.rightToolbar.qaCheckedList)

      let timer = 0

      mitt.on('mittQA', (e) => {
        //'skipAll'跳转全部QA 去解决QA 提交任务触发
        if (e == 'skipAll') {
          state.qaModel = 1
          setTimeout(() => {
            //做下兼容
            FullQA.value?.httpAllSentenceLogs()
          }, 0)
          //'search'搜索全部QA
        } else if (e == 'search') {
          //此处做防抖
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            FullQA.value?.httpAllSentenceLogs()
            SingleQA.value?.httpAllSentenceLogs()
          }, 800)
        }
      })

      const onQAModelClick = (model) => {
        state.qaModel = model
        store.dispatch('getAllSentenceLogs')
        // store.dispatch("getAllSentenceLogs");
      }

      const closeCheckModal = () => {
        store.dispatch('rightToolbar/getQASetting')
        state.checkVisible = false
      }

      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex] || {}
      }

      const openQAModal = () => {
        store.commit('modal/changeKey', {
          qaCheckVisible: true,
        })
      }

      const openQASetting = () => {
        openQAModal()
      }

      const startCheck = () => {
        let params = {
          qaRuleFilter: checkRules.value.sort().join(','),
        }
        setQASettingApi(params).then((res) => {
          //设置完成则重新赋值
          store.dispatch('getSentence', { loading: true, isBackTop: true })
          sentenceCheckAll({}).then(() => {
            store.commit('changeKey', {
              qaProgressVisible: true,
            })
            store.dispatch('checkProgress') //请求qa预览进度
          })
        })
        state.checkVisible = false
      }

      const commonQABack = () => {
        //console.log()
      }

      const allCheck = () => {
        store.dispatch('rightToolbar/getQASetting')
        state.checkVisible = true
        //全文检查开启弹窗
      }

      watch(
        () => store.state.checkedDetail.sentId,
        (n) => {
          if (store.state.checkedDetail.sentId) {
            FullQA.value?.httpAllSentenceLogs()
            SingleQA.value?.httpAllSentenceLogs()
          }
        }
      )

      onMounted(() => {
        // getListQATypesApi({sentIds:"cmeg2qjfflakogsp0h3g"})
      })

      return {
        closeCheckModal,
        commonQABack,
        startCheck,
        openQASetting,
        openQAModal,
        ...toRefs(store.state.rightToolbar),
        onQAModelClick,
        SingleQA,
        FullQA,
        checkRules,
        checkedDetail,
        ...toRefs(state),
        disabled: computed(() => store.state.openEditSourceText),
        t,
        activeKey,
        dateFormat,
        store,
        checkRule,
        allCheck,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .checkModal {
    .title {
      text-align: center;
      img {
        width: 56px;
      }
      margin-bottom: 20px;
    }
    .content {
      text-align: center;
      font-size: 16px;
      color: #1c2129;
    }
    .footer {
      padding: 10px 20px 0 20px;
      //text-align: center;
      margin-top: 8px;
      font-size: 14px;
      color: #4e5969;
    }
    .button {
      border-top: 1px red solid;
      text-align: right;
    }
  }
  .qa-box {
    padding: 0 16px;
    //background: white;
    height: 100%;
    position: relative;
    .qa-box-commonQA {
      position: absolute;
      left: 1px;
      top: -50px;
      width: 100%;
      height: calc(100% + 50px);
      background: white;
      z-index: 1;
    }
    .qa-tool {
      .qa-tool-filter-one {
        .left {
          font-size: 13px;
          color: #e6f1f5;
          span {
            cursor: pointer;
            color: #4e5969;
            &.isSelected {
              font-weight: 600;
              color: #1c2129;
            }
          }
        }
      }
    }
  }
</style>
