<template>
  <a-modal
    :visible="true"
    title="提示"
    centered
    :closable="false"
    :maskClosable="false"
  >
    <div class="corpus-remind">
      <div><img src="@/images/fail.png" /></div>
      <div class="text">语料存储库未设置或被您删除，请重新设置语料存储库</div>
    </div>
    <template #footer>
      <a-button @click="foreverClose">
        本文档不再提醒
      </a-button>
      <a-button @click="cancel">
        取消
      </a-button>
      <a-button @click="ok" type="primary">
        前往设置
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
  import { defineEmits } from 'vue'
  import { useStore } from 'vuex'

  const emits = defineEmits(['opentranslationset'])
  const store = useStore()
  const ok = () => {
    emits('opentranslationset', 3)
    cancel()
  }

  const foreverClose = () => {
    localStorage.setItem(store.state.taskDetail.docId, 'true')
    cancel()
  }

  const cancel = () => {
    store.commit('modal/changeKey', {
      corpusRemindVisible: false,
    })
  }
</script>

<style scoped lang="less">
  .corpus-remind {
    text-align: center;
    img {
      width: 56px;
    }
    .text {
      height: 28px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      margin: 12px 0 4px;
    }
    .remind {
      height: 22px;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
    }
  }
</style>
