
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })
      //获取当前激活项item
      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex] || {}
      }

      const quitQA = () => {
        if (store.state.rightToolbar.toolbarNav.tbNavActiveIndex == 4) {
          store.commit('rightToolbar/changeKey', {
            toolbarNav: {
              tbNavActiveIndex: 0,
            },
          })
        }
      }

      //编辑原文模式
      const editSourceText = () => {
        store.commit('changeKey', {
          screenLoading: true,
          continuedMt: false,
        })

        store.commit('sourceEdit/changeKey', {
          activeBorder: 'left',
        })

        setTimeout(() => {
          store.commit('excelProject/changeKey', {
            differentHighLightModel: false,
          })
          quitQA()
          store.commit('changeKey', {
            openEditSourceText: !store.state.openEditSourceText,
          })

          store.dispatch('getSentence').then(() => {
            let item = getTableActiveItem()
            store.commit('changeKey', {
              checkedDetail: { ...item },
            })
            store.dispatch('excelProject/twinslatorMatch')
            store.commit('changeKey', {
              screenLoading: false,
            })
          })
        }, 300)
      }

      return {
        editSourceText,
        store,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
