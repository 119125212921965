<template>
  <a-tooltip>
    <template #title>
      {{ isWindow ? 'Alt + L' : 'Control + Option + L' }}
    </template>
    <a-button
      class="li"
      type="link"
      style="height: 22px; line-height: 22px;"
      :class="{ disabled: disabled }"
      @mousedown="locked"
    >
      <icon-font
        class="font-size-16 margin-r-4"
        type="icon-suoding1"
      ></icon-font>
      <span class="li-txt">锁定</span>
    </a-button>
  </a-tooltip>
  <a-tooltip>
    <template #title>
      {{ isWindow ? 'Alt + Shift + L' : 'Control + Shift + L' }}
    </template>
    <a-button
      class="li"
      type="link"
      style="height: 22px; line-height: 22px;"
      :class="{ disabled: disabled }"
      @mousedown="unlock"
    >
      <icon-font class="font-size-16 margin-r-4" type="icon-jiesuo"></icon-font>
      <span class="li-txt">解锁</span>
    </a-button>
  </a-tooltip>
</template>

<script>
  import differentModel from '@/components/header/components/differentModel/index.vue'
  import { computed, ComputedRef } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      differentModel,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const checkedList = computed(() => store.state.excelProject.checkedList)

      const locked = () => {
        store.dispatch('optionsBar/lockSentence')
      }

      // const memberUnLock=()=>{
      //
      // }
      // const administratorUnLock=()=>{
      //   // if (checkedList) {
      //   //
      //   // }
      // }

      const unlock = () => {
        store.dispatch('optionsBar/unLockSentence')
        //解锁句子 判断是译员还是管理员 如果是译员只有单句解锁的功能，跟复制原文同理
        // if (store.state.taskDetail.taskClassify == 8) {
        //   administratorUnLock();
        // } else {
        //   memberUnLock();
        // }
      }
      return {
        locked,
        unlock,
        t,
        taskDetail,
        isWindow: store.state.isWindow,
      }
    },
  }
</script>

<style scoped lang="less">
  .li {
    display: flex;
    align-items: center;
    padding: 0 !important;
    .li-txt {
      position: relative;
      top: -1px;
    }
  }
</style>
