import { onMounted, reactive } from 'vue'

import msgOpen from './customUpdateMsg'

const updateCompareFunc = () => {
  const state = reactive({
    oldScript: [],
    newScript: [],
  })

  // 获取当前html
  const getHtml = async () => {
    try {
      const html = await fetch(`/?t=${+new Date()}`).then((res) => res.text()) // 读取index html
      return html || ''
    }catch (e) {
      return ''
    }
  }

  // 匹配script
  const parserScript = (html) => {
    const reg = new RegExp(/<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/gi) // script正则
    return html.match(reg) // 匹配script标签
  }

  // 浏览器html同服务器html进行对比
  const compareFunc = (oldArr, newArr) => {
    const base = oldArr.length
    const arr = Array.from(new Set(oldArr.concat(newArr)))
    // 如果新旧length 一样无更新
    if (arr.length === base) {
      // 无更新
    } else {
      // 更新了
      msgOpen('新版本更新，请务必刷新页面', '立即刷新')
    }
  }

  // 初始化浏览器html
  const scriptInitFunc = async () => {
    const html = await getHtml()
    state.oldScript = parserScript(html)
  }

  onMounted(() => {
    // 每1分钟轮询一次，判断浏览器是否有缓存
    scriptInitFunc()
    setInterval(async () => {
      const newHtml = await getHtml()
      state.newScript = parserScript(newHtml)
      compareFunc(state.oldScript, state.newScript)
    }, 60000)
  })
}

export default updateCompareFunc
