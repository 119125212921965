<template>
  <div class="guide-mock" v-if="trialTranslation">
    <img src="./images/left.png" alt="" class="left-img" />
    <img
      src="./images/center.png"
      alt=""
      class="center-img"
      @click="closeGuide"
    />
    <img src="./images/right.png" alt="" class="right-img" />
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import {
    defineComponent,
    onMounted,
    watch,
    ref,
    nextTick,
    computed,
  } from 'vue'
  const store = useStore()
  const trialTranslation = ref(false)
  const closeGuide = () => {
    trialTranslation.value = false
    localStorage.setItem('trialTranslationGuide', true)
  }

  if (
    store.state.taskDetail.taskClassify == 11 &&
    store.state.taskDetail.webcatPattern != 'RecruitAuditView' &&
    store.state.taskDetail.taskStatus != 2 &&
    store.state.taskDetail.webcatPattern != 'TranslatorView' &&
    !localStorage.getItem('trialTranslationGuide')
  ) {
    trialTranslation.value = true
  }
</script>

<style scoped lang="less">
  .guide-mock {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
    .left-img {
      width: 528px;
      position: absolute;
      left: 8px;
      top: 56px;
    }
    .center-img {
      cursor: pointer;
      width: 560px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .right-img {
      position: absolute;
      right: 192px;
      top: 0;
      width: 130px;
    }
  }
</style>
