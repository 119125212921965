import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-447dd842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "li diffMatch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_differentModel = _resolveComponent("differentModel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_differentModel, {
      class: "margin-b-4",
      disabled: $props.disabled
    }, null, 8, ["disabled"]),
    _createElementVNode("p", {
      class: _normalizeClass(["li-txt", { disabled: $props.disabled }])
    }, " 差异对比 ", 2)
  ]))
}