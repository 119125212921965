<template>
  <div class="toolbar">
    <div class="tabs">
      <div class="horizontal-tabs">
        <div class="left" @click="closeHorizontal">
          <icon-font type="icon-shou" class="font-size-16"></icon-font>
        </div>
        <div class="right">
          <a-tabs v-model:activeKey="toolbarNav.tbNavActiveIndex">
            <a-tab-pane key="0" tab="智能提示"></a-tab-pane>
            <a-tab-pane key="4" tab="QA检查"></a-tab-pane>
            <a-tab-pane key="3" tab="备注"></a-tab-pane>
            <a-tab-pane key="2" tab="日志"></a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

    <!--    <div class="toolbar-commonQA" v-if="toolbarNav.tbNavActiveIndex == 5">-->
    <!--      <commonQA></commonQA>-->
    <!--    </div>-->

    <div v-if="toolbarNav.tbNavActiveIndex == 0" style="height: 100%">
      <tip ref="tipRef"></tip>
    </div>
    <div v-if="toolbarNav.tbNavActiveIndex == 4">
      <div v-if="store.getters.$isPreviewMode">
        <empty description="预览模式下，不显示QA检查提示" />
      </div>
      <qa v-else></qa>
    </div>
    <search v-else-if="toolbarNav.tbNavActiveIndex == 9"></search>
    <div class="padding-lr-16">
      <remark v-if="toolbarNav.tbNavActiveIndex == 3"></remark>
      <log v-else-if="toolbarNav.tbNavActiveIndex == 2"></log>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
  import { reactive, toRefs, onMounted, computed, watch, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n' //国际化
  import {
    DoubleRightOutlined,
    CaretRightOutlined,
    EyeOutlined,
    CloseOutlined,
  } from '@ant-design/icons-vue'
  import tip from './tabs/tip.vue'
  import search from './tabs/search.vue'
  import replace from './tabs/replace.vue'
  import remark from './tabs/remark.vue'
  import log from './tabs/log.vue'
  import qa from './tabs/qa.vue'
  import { message } from 'ant-design-vue'
  import mitt from '@/plugins/bus'
  import commonQA from './tabs/commonQA/index.vue'
  import empty from '@/components/empty/empty.vue'

  export default {
    components: {
      commonQA,
      EyeOutlined,
      DoubleRightOutlined,
      CaretRightOutlined,
      CloseOutlined,
      tip,
      search,
      replace,
      remark,
      log,
      qa,
      empty,
    },
    emits: ['openpreview', 'closepreview'],
    setup(props, ctx) {
      const store = useStore()
      const { t, locale } = useI18n()
      const tbNavActiveIndex = computed(() => {
        return store.state.rightToolbar.toolbarNav.tbNavActiveIndex
      })

      const tipRef = ref(null)

      const checkedDetail = computed(() => {
        return store.state.checkedDetail
      })

      const state = reactive({
        loading: true,
      })

      const tabsClick = (index) => {
        if (index == 4 && store.state.openEditSourceText) {
          message.warning('原文编辑模式下，无法使用QA功能')
          return
        }
        store.commit('rightToolbar/changeKey', {
          toolbarNav: {
            tbNavActiveIndex: index,
          },
        })
      }

      const closeHorizontal = () => {
        ctx.emit('closeHorizontalPaneSize')
      }

      // watch(tbNavActiveIndex, async (n, oldn) => {
      // if (n == 4 || oldn == 4) {
      //   if (n == 4 && store.state.excelProject.differentHighLightModel) {
      //     message.warning("若切回孪生译员，则保留差异对比的设置，自动再次开启");
      //   }
      //   await store.dispatch("getSentence", { loading: true });
      // }
      // });
      watch(checkedDetail, (n) => {
        // console.log("执行次数");
        //永恒加载；
        if (n.sentId) {
          store.dispatch('excelProject/twinslatorMatch')
        }
      })

      onMounted(() => {
        // setTimeout(()=>{
        //   state.loading = true;
        // },2000)
      })

      return {
        tipRef,
        closeHorizontal,
        ...toRefs(state),
        store,
        ...toRefs(store.state.rightToolbar),
        t,
        locale,
        tabsClick,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .toolbar {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      var(--universal-color-right_toolbar-up) 0%,
      var(--universal-color-right_toolbar-down) 100%
    );
    .toolbar-commonQA {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 1;
    }
    .tabs {
      padding: 0 12px;
    }
    .horizontal-tabs {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e6f1f5;
      .left {
        cursor: pointer;
        span {
          padding: 4px;
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            border-radius: 6px;
          }
          position: relative;
          top: 2px;
        }
        margin-right: 16px;
      }
      .right {
        ::v-deep(.ant-tabs-tab) {
          padding: 14px 0;
          margin-right: 24px;
          color: #1c2129;
        }
        ::v-deep(.ant-tabs-bar) {
          border-bottom: 0 transparent solid;
        }
        ::v-deep(.ant-tabs-bar) {
          margin-bottom: 0;
        }
        ::v-deep(.ant-tabs-ink-bar) {
          bottom: 2px;
        }
        ::v-deep(.ant-tabs-tab-active) {
          font-weight: bold;
        }
      }
    }
  }
</style>
