<template>
  <div class="header display-flex align-center justify-between">
    <div class="header-logo margin-l-20">
      <img :src="store.state.settings.logo" alt="" @click="openPe" />
    </div>
    <div
      class="manage-header-logo isPreview margin-l-16"
      style="cursor: pointer"
      @click="previewVisible = true"
      v-if="store.getters.$isPreviewMode"
    >
      <span class="text">
        预览模式
        <icon-font type="icon-bianzu" class="margin-l-4"></icon-font>
      </span>
    </div>
    <div
      class="manage-header-logo margin-l-16"
      v-else-if="store.state.taskDetail.taskClassify == 8"
    >
      <span class="text">管理员模式</span>
    </div>
    <documentList></documentList>
    <!--头部大按钮控制由后端控制 -->
    <div class="header-button--group flex-1">
      <a-button
        class="margin-r-16 border-r-16"
        :class="item.frontResourceText == '提交任务' ? 'major' : ''"
        v-for="item in headerOptions"
        :key="item.frontResourceKey"
        @click="optionsBtn(item.frontResourceKey)"
      >
        {{
          locale === 'zh' ? item.frontResourceText : item.frontResourceEnText
        }}
      </a-button>
      <a-space
        :size="12"
        v-if="
          store.state.taskDetail.webcatPattern == 'RecruitAuditView' &&
            store.state.taskDetail.taskStatus == 2
        "
      >
        <a-button @click="openVisible(2)">
          审核不通过
        </a-button>
        <a-button type="primary" @click="openVisible(1)" class="margin-r-12">
          审核通过
        </a-button>
      </a-space>
    </div>
    <a-space :size="16" style="position: relative;top: 2px">
      <helpTip></helpTip>
      <options></options>
      <customerService></customerService>
<!--      <locale></locale>-->
      <ImMessage></ImMessage>
    </a-space>
    <div class="header-user display-flex align-center margin-l-16">
      <img
        v-if="taskDetail.userImgUrl"
        class="header-avatar-img"
        :src="taskDetail.userImgUrl"
        @error="defaultImg"
        alt=""
      />
      <img
        v-else
        class="header-avatar-img"
        src="./images/pe_default_photo.png"
        alt=""
      />
    </div>
  </div>
  <addTerm></addTerm>
  <export-dialog
    :exportType = "exportType"
    v-model:visible="exportVisible"
    v-if="exportVisible"
  ></export-dialog>
  <!--  <after-preview v-model:visible="afterPreviewVisible"></after-preview>-->
  <check-list-dialog
    v-model:visible="checkListVisible"
    :title="checkListTitle"
    :type="checkListType"
  ></check-list-dialog>
  <targetTextView
    v-if="targetTextViewShow"
    @changeTargetTextViewShow="changeTargetTextViewShow"
  ></targetTextView>
  <remarkTip></remarkTip>
  <!--  审核弹窗-->
  <a-modal
    v-model:visible="visible"
    title="审核"
    @ok="handleOk"
    okText="确认"
    cancelText="取消"
    centered
    :width="560"
  >
    <div class="auditModal">
      <div v-if="visibleType == 1">
        <div class="auditModal-img">
          <img src="./images/pass.png" />
        </div>
        <div class="auditModal-text">
          审核通过
        </div>
        <div class="auditModal-editText">
          <div class="left"><label>备注</label></div>
          <a-textarea
            v-model:value="successText"
            placeholder="请输入备注"
            :auto-size="{ minRows: 2, maxRows: 5 }"
          />
        </div>
      </div>
      <div v-else>
        <div class="auditModal-img">
          <img src="@/images/fail.png" />
        </div>
        <div class="auditModal-text">
          审核不通过
        </div>
        <div class="auditModal-editText">
          <div class="left">
            <span>*</span>
            <label>不通过原因</label>
          </div>
          <a-textarea
            v-model:value="failText"
            placeholder="请输入不通过原因"
            :auto-size="{ minRows: 2, maxRows: 5 }"
          />
        </div>
      </div>
    </div>
  </a-modal>

  <a-modal
    v-model:visible="previewVisible"
    title="预览模式"
    okText="我知道了"
    cancelText="返回"
    @ok="previewVisible = false"
    centered
    :width="720"
  >
    <div class="previewModal">
      <div class="title">
        <img src="./images/preview.png" alt="" />
      </div>
      <div class="content">
        预览模式下仅可预览，不可翻译哦
      </div>
      <div class="footer">
        <div class="footer-title">
          我领取了任务，为什么还是预览模式？
        </div>
        <div class="footer-content">
          <div class="item">
            <div class="left">
              1
            </div>
            <div class="center">
              您当前为翻译任务
            </div>
            <div class="right">
              前面还有预翻译任务，请提示PM前往处理或跳过
            </div>
          </div>
          <div class="item">
            <div class="left">
              2
            </div>
            <div class="center">
              您当前为审校任务
            </div>
            <div class="right">
              前面还有翻译任务未提交，提交后即可开启任务
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>

  <a-modal
    v-model:visible="submitVisible"
    title="提交任务"
    @ok="submitOk"
    okText="确认提交"
    cancelText="取消"
    centered
    :width="560"
  >
    <div class="submitModal">
      <div class="title">
        <img src="./images/submitSuccess.png" />
      </div>
      <div class="content margin-b-8">
        <div v-if="submitResult.qaFailCount > 0">
          检测到
          <label>{{ submitResult.qaFailCount }}</label>
          处QA提醒，确定提交吗？
        </div>
        <div v-else>确定提交任务？</div>
      </div>
      <div class="footer">
        <div class="margin-b-8" v-if="submitResult.validFormatCheckRuleCount">
          （其中包含
          <label>{{ submitResult.validFormatCheckRuleCount }}处标签错误</label>
          ，可能导致译文样式丢失）
        </div>
        <div>
          全文共{{ submitResult.totalSentences }}句，修改了{{
            submitResult.updateSentences
          }}句，全文与MT相似度为{{ submitResult.avgSimilar }}%
        </div>
      </div>
    </div>

    <template #footer>
      <a-button @click="submitVisible = false">取消</a-button>
      <a-button @click="toQa" v-if="submitResult.qaFailCount > 0">
        前往QA
      </a-button>
      <a-button type="primary" @click="submitOk">继续提交</a-button>
    </template>
  </a-modal>

  <a-modal
      v-model:visible="emptyTextVisible"
      title="提示"
      okText="继续导出"
      cancelText="取消"
      centered
      :width="560"
      @cancel="emptyTextVisible = false"
      @ok="exportVisible = true;emptyTextVisible = false;"
  >
    <div class="text-center">
      <div class="margin-b-20">
        <img src="@/assets/warning_1.png" width="48">
      </div>
      <div class="font-size-16 margin-b-8 color-333 margin-b-8">
        导出的文件中存在<span class="color-F55C3F">{{emptyTextNumber}}句空译文</span>，是否继续导出？
      </div>
      <div class="color-666 font-size-14">
        您可忽略并继续导出，或筛选空译文进行确认哦
      </div>
    </div>
  </a-modal>
</template>

<script>
  import options from '@/components/header/components/options/index.vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n' //国际化
  import locale from '@/components/header/components/locale/locale.vue'
  import documentList from '@/components/header/components/documentList/documentList.vue'
  import targetTextView from './components/targetTextView/targetTextView.vue'
  import addTerm from './components/addTerm/index.vue'
  import CheckListDialog from '@/components/dialog/check/list.vue'
  import ExportDialog from '@/components/dialog/export/export.vue'
  import customerService from './components/customerService/customerService.vue'
  import ImMessage from './components/Im/index.vue'
  import helpTip from './components/helpTip/helpTip.vue'
  import {
    defineComponent,
    reactive,
    toRefs,
    createVNode,
    computed,
    nextTick,
  } from 'vue'
  import {
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
  } from '@ant-design/icons-vue'
  import { message, Modal } from 'ant-design-vue'
  import {
    infoAuditTask,
    setTaskStatus,
    checkLeaved,
    getScoreObj,
    getSubmitTaskPrompt,
    ignoreDocAll,
    getSentence, postGetNoTranslateSentCountApi,
  } from '@/http/api'
  import successLogo from './components/success/index.vue'
  import remarkTip from '../dialog/remarkTip/remarkTip.vue'
  import mitt from '@/plugins/bus'
  import {
    indexDbGetAllVal,
    deleteIndexDb,
    indexDbDeleteVal,
  } from '@/indexDB/indexDB'
  import store from '@/store'
  import { autoCommit } from '@/indexDB/autoCommit'
  import { getFactSourceText, positionSentence } from '@/plugins/comm'

  export default defineComponent({
    components: {
      ImMessage,
      options,
      QuestionCircleOutlined,
      locale,
      helpTip,
      documentList,
      targetTextView,
      addTerm,
      CheckListDialog,
      customerService,
      remarkTip,
      ExportDialog,
    },
    setup() {
      const { t, locale } = useI18n()
      const store = useStore()

      const headerOptions = computed(() => store.state.headerOptions)
      const state = reactive({
        exportType:2,
        emptyTextNumber:0,
        emptyTextVisible:false,
        previewVisible: false,
        submitVisible: false,
        visible: false,
        visibleType: 1,
        taskDetail: store.state.taskDetail,
        targetTextViewShow: false,
        exportVisible: false,
        checkListTitle: '',
        checkListType: '1',
        checkListVisible: false,
        failText: '',
        successText: '',
        submitResult: {},
      })

      const openPe = () => {
        // window.open("/go/pe_pc/power");
      }

      const changeTargetTextViewShow = () => {
        state.targetTextViewShow = false
      }

      const openVisible = (type) => {
        state.visibleType = type
        state.visible = true
      }

      const toQa = () => {
        state.submitVisible = false
        store.commit('rightToolbar/changeKey', {
          toolbarNav: {
            tbNavActiveIndex: 4,
          },
        })
        nextTick(() => {
          mitt.emit('mittQA', 'skipAll')
        })
      }

      const fail = () => {
        if (!state.failText) {
          message.error('请输入不通过原因')
          return
        }
        let params = {
          taskId: store.state.taskDetail.taskId,
          taskStatus: 4,
          remark: state.failText,
        }
        infoAuditTask(params).then((res) => {
          if (res.data == 'fail') {
            message.warning('该试译已被审核')
          }
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
      }

      const pass = () => {
        let params = {
          taskId: store.state.taskDetail.taskId,
          taskStatus: 3,
          remark: state.successText,
        }
        infoAuditTask(params).then((res) => {
          if (res.data == 'fail') {
            message.warning('该试译已被审核')
          }
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
      }

      const handleOk = () => {
        if (state.visibleType == 1) {
          pass()
        } else {
          fail()
        }
      }

      const submitOk = async () => {
        try {
          //删除数据库
          // deleteIndexDb()
        } catch (rej) {

        } finally {
          //提交任务
          let result = await setTaskStatus({
            status: 3,
            taskId: state.taskDetail.taskId,
          })
          //试译任务的原因加上
          if (result) {
            window.location.reload()

            // let params = {
            //   docId: state.taskDetail.docId,
            //   twinslatorId: state.taskDetail.twinslatorId
            // };
            // getScoreObj(params).then(res => {
            //   const { allowEngineScore, allowTranslatorScore } = res.data.data;
            //   if (!allowEngineScore && !allowTranslatorScore) {
            //     window.location.reload();
            //   }
            //   store.commit("changeKey", {
            //     engineGradeVisible: allowEngineScore,
            //     interpreterEvaluateVisible: allowTranslatorScore
            //   });
            // });
          }
        }
      }

      const nextSubmit = () => {
        checkLeaved({
          sentenceListSize: store.state.pageSize,
        }).then((res) => {
          const list = res.data.data.list
          if (list && list.length > 0) {
            store.commit('changeKey', {
              mistakeList: list,
            })
            state.checkListTitle = '未确认句子'
            state.checkListType = '1'
            state.checkListVisible = true
          } else {
            commitModal()
          }
        }).finally(()=>{
          store.commit('changeKey', {
            screenLoading: false,
          })
        })
      }

      const commitModal = async () => {
        getSubmitTaskPrompt({ taskId: state.taskDetail.taskId }).then((res) => {
          store.commit('changeKey', {
            screenLoading: false,
          })
          if (res.data.result == '1') {
            let result = res.data.data
            state.submitResult = result
            state.submitVisible = true
          }
        })
      }

      const getNoSaveSentence = (idList) => {
        let idsMap = {}
        let ids = idList.map((item) => {
          idsMap[item.sentId] = { ...item }
          return item.sentId
        })
        getSentence({ sentIds: ids }).then((res) => {
          //重置
          store.commit('revokeSentence/resetRevokeList')
          //获取 有QA错误的 sentId
          if (res.data.result == 1) {
            store.commit('excelProject/changeKey', {
              checkedList: [],
              checkedAll: false,
              ArrMap: new Map(),
            })
            //重置
            store.commit('optionsBar/changeKey', {
              lastShiftKeyCheckedIndex: -1,
            })
            let data = { ...res.data.data }
            data.sentenceDTOList.forEach((el, index) => {
              el.targetDomId = `target-${el.sentId}`
              el.sourceDomId = `source-sent-${el.sentId}`
              el.tableIndex = index
              //渲染译文HTML
              el.renderTargetHTML = idsMap[el.sentId].targetPlaceholder
              //赋值比较字段
              el.compareTargetHTML = el.targetPlaceholder
              //全部等于false；
              el.isConfirm = false
              //
              el.saveFailStatus = true
              el.qaInfoList = []
              el.qaCheckRules = []
              //判断有无占位符图片
              if (el.sourcePlaceholder.indexOf('<img') > -1) {
                el.hasImg = true
              }
            })

            store.commit('excelProject/changeKey', {
              docData: data,
            })

            // console.log(data)

            nextTick(() => {
              data.sentenceDTOList.forEach((item) => {
                let copyHTML = idsMap[item.sentId].targetPlaceholder
                let node = document.getElementById(item.targetDomId)
                if (node.innerHTML != copyHTML) {
                  //强行赋值
                  node.innerHTML = copyHTML
                }
              })
            })
          }
        })
      }

      const optionsBtn = (key) => {
        switch (key) {
          case 'HEADER_TASK_COMMIT':
            // let resultList = false
            store.commit('changeKey', {
              screenLoading: true,
            })

            nextSubmit();
            break
          case 'HEADER_EXPORT':
            //调用接口 出全局滚动条；然后结果回来结束；
              //生成docNames
            let docTypes = store.state.taskDetail.docTypes;
            let xliffArr = ['xliff','sdlxliff','mqxliff'];
            let dxfArr = ['dxf','json','properties','yaml','yml'];
            //分隔docTypes 形成数组
            let hasXliff = false;
            let hasTwoYu = false;
            let hasDxf = false;
            let hasCommon = false;
            docTypes.split(',').forEach(item=>{
                if (xliffArr.includes(item)) {
                  hasXliff = true
                } else if (dxfArr.includes(item)) {
                  hasDxf = true
                }
            })

            //是否含有双语文件需要单独判定
            if (store.state.taskDetail.isBatchDoc) {
              //批量打开的话需要判断businessTypes
              hasTwoYu = store.state.taskDetail.businessTypes.includes(2);
              hasCommon = store.state.taskDetail.businessTypes.includes(1);
            } else {
              hasXliff = false;
              hasDxf = false;
              hasCommon = false;
              //非批量打开的话判断docParseType 为5 是双语解析
              //hasCommon
              hasCommon = store.state.taskDetail.docParseType!= 5;
              hasTwoYu = store.state.taskDetail.docParseType == 5;
            }

            if (hasXliff && (hasDxf || hasCommon)) {
              message.warning("Xliff格式暂不支持和其他文件一起导出哦");
              return;
            }
            if (hasTwoYu && (hasDxf || hasCommon)) {
              message.warning("双语格式暂不支持和其他文件一起导出哦");
              return;
            }

            if (hasCommon) {
              state.exportType = 1;
            }

            if (hasCommon && hasDxf) {
              state.exportType = 2;
            }

            if (hasXliff || hasTwoYu) {
              state.exportType = 3;
            }


            // let docNames = store.state.taskDetail.docTypes
            //如果是xliff
            store.commit("changeKey",{
              screenLoading:true
            })
            postGetNoTranslateSentCountApi().then(res=>{
              //导出前先查接口有多少句空译文;//有空译文的话出弹窗继续导出
              if (res.data.data>0) {
                state.emptyTextNumber = res.data.data
                state.emptyTextVisible = true;
              } else {
                state.exportVisible = true
              }
            }).finally(()=>{
              store.commit("changeKey",{
                screenLoading:false
              })
            })
            break
          case 'HEADER_PREVIEW':
            if (state.taskDetail.docParseType == 5) {
              message.warning('当前稿件不支持译后预览')
              return
            }
            state.targetTextViewShow = true
            break
        }
      }

      const defaultImg = (event) => {
        let img = event.srcElement
        img.src = require('./images/pe_default_photo.png')
        img.onerror = null //解绑onerror事件
      }

      return {
        toQa,
        submitOk,
        openPe,
        store,
        handleOk,
        openVisible,
        ...toRefs(state),
        headerOptions,
        t,
        locale,
        optionsBtn,
        defaultImg,
        changeTargetTextViewShow,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .submitModal {
    label {
      color: #f55c3f;
    }
    .title {
      text-align: center;
      margin-bottom: 16px;
      img {
        width: 56px;
      }
    }
    .content {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
    }
    .footer {
      text-align: center;
      //height: 22px;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
    }
  }

  .previewModal {
    display: flex;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    .title {
      img {
        width: 56px;
      }
    }
    .content {
      font-size: 16px;
      font-weight: 600;
      color: #1c2129;
      margin: 20px 0;
    }
    .footer {
      text-align: center;
      width: 580px;
      background: #f2f3f5;
      border-radius: 8px;
      padding: 20px;
      margin: 0 auto;
      .footer-title {
        color: #1c2129;
        font-size: 14px;
        margin-bottom: 16px;
      }
      .footer-content {
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .left {
            flex-shrink: 0;
            width: 32px;
            height: 32px;
            background: #ffffff;
            box-shadow: inset 0px 0px 0px 0px #c9cdd4;
            line-height: 32px;
            text-align: center;
            border-radius: 50%;
            font-weight: bold;
            color: #1c2129;
          }
          .center {
            flex-shrink: 0;
            line-height: 22px;
            font-size: 14px;
            color: #4e5969;
            margin-left: 14px;
            margin-right: 20px;
          }
          .right {
            line-height: 22px;
            color: #86909c;
            font-size: 14px;
            text-align: left;
          }
        }
      }
    }
  }

  .auditModal {
    .auditModal-img {
      text-align: center;

      img {
        width: 56px;
      }

      margin-bottom: 10px;
    }

    .auditModal-text {
      text-align: center;
      font-weight: 600;
      color: #333333;
      font-size: 18px;
      margin-bottom: 12px;
    }

    .auditModal-editText {
      display: flex;
      align-items: center;

      .left {
        flex-shrink: 0;
        margin-right: 20px;

        span {
          color: red;
          margin-right: 10px;
        }

        label {
          font-weight: 600;
          color: #333333;
          font-size: 14px;
        }
      }
    }
  }

  .header {
    position: relative;
    top: 0;
    width: 100%;
    //height: 56px;
    height: 48px;
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    //box-shadow: 0px 2px 4px 0px rgba(2, 73, 89, 0.05);
    min-width: 1000px;
    transition: all 0.4s;

    .manage-header-logo {
      height: 24px;
      background: linear-gradient(90deg, #f84e22 0%, #fe9a9a 100%);
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0 12px;

      &.isPreview {
        background: linear-gradient(180deg, #008cfa 0%, #99d6ff 100%);
      }

      .text {
        line-height: 24px;
        color: #ffffff;
        font-size: 13px;
        font-weight: 600;
      }
    }

    .header-logo {
      cursor: pointer;
      img {
        width: 28px;
        height: 28px;
      }
    }

    .header-button--group {
      text-align: right;

      .major {
        background: #00c6f3;
        color: white;
        border: 1px transparent solid;
      }
    }

    .header-user {
      flex: 0 0 48px;

      .header-avatar-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .header-user--name {
        margin: 0 10px;
        font-size: 14px;
      }
    }

    ::v-deep(.ant-modal-wrap) {
      width: 620px;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);

      //.ant-modal-header {
      //  cursor: move;
      //}
    }
  }
  .border-r-16 {
    border-radius: 16px;
  }
</style>
