
  import { useStore } from 'vuex'
  import { computed, reactive, toRefs, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n' //国际化
  import { message } from 'ant-design-vue'
  import { getTranslatorInfo, setScoreObj } from '@/http/api'
  export default {
    name: 'enginegrade',
    components: {},
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        flow: '',
        nickname: '',
        realName: '',
        profilePhoto: '',
        text: '',
        value1: 5,
        value2: 5,
        value3: 5,
        confirmLoading: false,
      })
      // engineGradeVisible

      const reload = () => {
        if (!store.state.taskDetail.isSelfWork) {
          window.location.reload()
        }
      }

      const handleOk = () => {
        state.confirmLoading = true
        let params = {
          scoreComment: state.text,
          translationQualityScore: state.value1,
          timelyDeliveryScore: state.value2,
          industryKnowledgeScore: state.value3,
        }
        setScoreObj(params).then(() => {
          message.success('评分成功')
          store.commit('changeKey', {
            interpreterEvaluateVisible: false,
          })
          reload()
        })
      }
      const onChange = () => {
        // console.log(888)
      }

      const handleCancel = () => {
        store.commit('changeKey', {
          interpreterEvaluateVisible: false,
        })
        reload()
      }
      onMounted(() => {
        let params = {}
        getTranslatorInfo(params).then((res) => {
          const { nickname, realName, profilePhoto } = res.data.data.user
          const flow = res.data.data.flow
          state.nickname = nickname
          state.realName = realName
          state.flow = flow
          state.profilePhoto =
            profilePhoto ||
            'https://static.wordpower.top/default/default_user_head.png'
        })
      })

      return {
        t,
        state,
        ...toRefs(state),
        handleOk,
        onChange,
        handleCancel,
        interpreterEvaluateVisible: computed(
          () => store.state.interpreterEvaluateVisible
        ),
      }
    },
  }
