
  import {
    defineComponent,
    ref,
    reactive,
    toRefs,
    createVNode,
    computed,
    watchEffect,
    nextTick,
    watch,
    onMounted,
    onBeforeMount,
  } from 'vue'
  import { useStore } from 'vuex'
  import {
    DeleteOutlined,
    FormOutlined,
    ExclamationCircleOutlined,
  } from '@ant-design/icons-vue'
  import { message, Modal } from 'ant-design-vue'
  import { useI18n } from 'vue-i18n' //国际化
  // import TableView from "./lib/table.vue";
  import useProject from '@/components/source/useProject'
  import EmptyData from '@/components/empty/empty.vue'
  import {
    getAllDetailList,
    getDetailList,
    postReComputePage,
  } from '@/http/api'
  import { useRoute, useRouter } from 'vue-router'
  import { getQueryString } from '@/plugins/prototype'
  import { positionSentence } from '@/plugins/comm'

  export default defineComponent({
    components: {
      EmptyData,
      DeleteOutlined,
      FormOutlined,
      // TableView
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const state = reactive({
        taskDetail: store.state.taskDetail,
        remarkValue: '',
        putRemarkVisible: false,
        putRemarkDetail: {} as any,
        putRemarkValue: '',
        allList: [],
        totalNum: 0,
      })

      const remarkPageNum = computed({
        set: (v) => {
          store.commit('rightToolbar/remark/changeKey', {
            remarkPageNum: v,
          })
        },
        get: () => {
          return store.state.rightToolbar.remark.remarkPageNum
        },
      })

      const activeKey = computed({
        set: (v) => {
          store.commit('rightToolbar/remark/changeKey', {
            activeKey: v,
          })
        },
        get: () => {
          return store.state.rightToolbar.remark.activeKey
        },
      })

      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })
      const { docData } = useProject()

      let pageNum = 1
      const storeRemarkDetail = computed(() => {
        return store.state.rightToolbar.remark
      })

      const getPostilList: any = (pageNum = 1) => {
        store.dispatch('rightToolbar/remark/getPostilList', pageNum)
      }

      const httpGetAllDetailList = () => {
        getAllDetailList({
          docId: store.state.taskDetail.docId,
          pageNum: remarkPageNum.value,
          pageSize: 20,
          sentenceListSize: store.state.pageSize,
          splitIndex: getQueryString('splitIndex'),
          // splitIndex:router.query.splitIndex,
        }).then((res) => {
          let result = res.data.data
          state.allList = result.data
          // console.log(state.allList);
          state.totalNum = result.total
        })
      }

      const goToPage = (item) => {
        if (store.state.openEditSourceText) {
          return true
        }
        if (store.state.excelProject.ArrMap.has(item.sentId)) {
          positionSentence(item.sentId)
        } else {
          postReComputePage({
            pageSize: store.state.pageSize,
            taskId: store.state.taskDetail.taskId,
            docId: store.state.taskDetail.docId,
            sentId: item.sentId,
          }).then((res) => {
            store.commit('changeKey', {
              pageNum: res.data.data.pageIndex,
            })
            store.commit('excelProject/changeKey', {
              serialNum:'',
              sourceText: '',
              targetText: '',
            })
            store.commit('excelProject/resetFilterValue')
            store
              .dispatch('getSentence', {
                loading: true,
                sentencePosition: item.sentId,
              })
              .then(() => {
                if (store.state.excelProject.ArrMap.has(item.sentId)) {
                  positionSentence(item.sentId)
                } else {
                  message.warning('该句已经被隐藏')
                }
              }) //请求译文
          })
        }
      }

      const onPageChange = () => {
        httpGetAllDetailList()
      }

      const onTabChange = (e) => {
        activeKey.value = e
      }

      const prevPage = () => {
        pageNum--
        store.commit('rightToolbar/remark/changeKey', {
          loading: true,
          postilList: [],
        })
        getPostilList(pageNum)
      }
      const nextPage = () => {
        pageNum++
        store.commit('rightToolbar/remark/changeKey', {
          loading: true,
          postilList: [],
        })
        getPostilList(pageNum)
      }

      // getPostilList(1);

      const back = () => {
        store.commit('rightToolbar/remark/changeKey', {
          isOpenTable: true,
        })
        getPostilList(pageNum)
      }

      const clearRemarkStatus = () => {
        state.remarkValue = ''
        state.putRemarkDetail = ''
        state.putRemarkValue = ''
        state.putRemarkVisible = false
      }

      const addRemark = () => {
        if (!state.remarkValue.replace(/(^\s*)|(\s*$)/g, '')) {
          message.error('内容不能为空')
          return
        }

        store
          .dispatch('rightToolbar/remark/addPostilOrUpdate', {
            content: state.remarkValue,
            sentId: checkedDetail.value.sentId,
            postilId: null,
          })
          .then(() => {
            clearRemarkStatus()
            httpGetAllDetailList()
            //excel项目任务
            if (
              state.taskDetail.transMode === 3 ||
              state.taskDetail.transMode === 5
            ) {
              // store.dispatch("getSentence"); //请求译文
              docData.value.sentenceDTOList.forEach((res: any) => {
                if (res.sentId === checkedDetail.value.sentId) {
                  res.hasComment = true
                }
              })
            }
          })
      }

      const delRemark = (item: any) => {
        Modal.confirm({
          title: '提示',
          icon: createVNode(ExclamationCircleOutlined),
          content: '确定删除备注吗',
          okText: '确定',
          cancelText: '取消',
          centered: true,
          onOk() {
            store
              .dispatch('rightToolbar/remark/delPostil', {
                sentId: checkedDetail.value.sentId,
                postilId: item.postilId,
              })
              .then((list) => {
                if (list.length === 0) {
                  docData.value.sentenceDTOList.forEach((res: any) => {
                    if (res.sentId === checkedDetail.value.sentId) {
                      res.hasComment = false
                    }
                  })
                }
                httpGetAllDetailList()
              })
          },
        })
      }
      const putRemark = (item: any) => {
        state.putRemarkDetail = item
        state.putRemarkValue = item.content
        state.putRemarkVisible = true
      }

      const putOk = () => {
        store.dispatch('rightToolbar/remark/addPostilOrUpdate', {
            content: state.putRemarkValue,
            sentId: checkedDetail.value.sentId,
            postilId: state.putRemarkDetail.postilId,
          })
          .then(() => {
            clearRemarkStatus()
            httpGetAllDetailList()
          })
      }

      const getDetailList = () => {
        if (store.state.checkedDetail.sentId) {
          store.dispatch('rightToolbar/remark/getDetailList')
        }
      }

      watch(
        () => store.state.checkedDetail.sentId,
        (n: string) => {
          if (n) {
            getDetailList()
          }
        }
      )
      onBeforeMount(() => {
        getDetailList()
        httpGetAllDetailList()
      })

      return {
        goToPage,
        remarkPageNum,
        onPageChange,
        onTabChange,
        activeKey,
        store,
        ...toRefs(state),
        ...toRefs(storeRemarkDetail.value),
        checkedDetail,
        t,
        prevPage,
        nextPage,
        back,
        addRemark,
        delRemark,
        putRemark,
        putOk,
      }
    },
  })
