import { confirmSentences, twinslatorMatch } from '@/http/api'
import useUtils from '@/plugins/utils'
import {
  getCursorPosition,
  positionSelection,
  qaFormatText,
  imgFormatText,
} from '@/plugins/range.js'

const state = {
  //触发提交事件的时候 标识符（点击译文状态触发，和普通失去焦点触发）
  keyQueue: '',
  //原文
  sourceText: '',
  //译文
  targetText: '',
  // sentIds查询
  serialNum:'',
  //是否正则匹配
  sourceRegexSearch:false,
  targetRegexSearch:false,
  docData: {
    sentenceDTOList: [],
  },
  activityWorkflowKey: {
    PE: 'PEConfirm',
    PF: 'PFConfirm',
    PF1: 'PFConfirm',
    PF2: 'PF2Confirm',
    PF3: 'PF3Confirm',
  },
  ArrMap: new Map(),
  checkedList: [],
  checkedAll: false, //全选
  //原文预览设置位置（写好了本地存储实例）
  twinslatorMatch: {},
  //差异对比开关 （控制开关处写好了本地存储实例）
  differentHighLightModel: false,
  //译文表格选中项
  tabActiveIndex: 0,
  //译文状态
  status: '',
  //译文来源
  targetFrom: '',
  //原文内容
  sourceTags: '',
  //带标记
  withFlag: '',
  //质检分数
  qualityScores: '',
  //是否折叠所有 / //是否展开所有,
  isCollapsed:false,
  //是否分组
  groupBy:null,
  groupSort:null,
  //筛选
  filterArr: [
    {
      title: '状态',
      key:'status',
      children: [
        { label: '已确认', isChecked: false, value: 'Confirm' },
        { label: '未确认', isChecked: false, value: 'UnConfirm' },
        { label: 'PE确认', isChecked: false, value: 'PEConfirm' },
        { label: 'PF确认', isChecked: false, value: 'PFConfirm',selfWorkHidden:true },
        { label: '空译文', isChecked: false, value: 'EmptyTranslation' },
      ],
    },
    {
      title: '译文来源',
      key:'targetFrom',
      children: [
        { label: 'MT(机器翻译)', isChecked: false, value: 'MT' },
        { label: 'PE(人工翻译)', isChecked: false, value: 'PE' },
        { label: 'PF(人工审校)', isChecked: false, value: 'PF',selfWorkHidden:true },
        { label: 'PM(项目经理)', isChecked: false, value: 'PM',selfWorkHidden:true },
        { label: 'UT(非译内容)', isChecked: false, value: 'NonTranslate',selfWorkHidden:true },
        { label: 'REP(重复)', isChecked: false, value: 'REPEAT',selfWorkHidden:true },
        { label: 'SRC(原文)', isChecked: false, value: 'SOURCE',selfWorkHidden:true },
        { label: '99%~100%匹配', isChecked: false, value: 'Match99_100'},
        { isChecked: false,value:'Match',rangeValue:[50,98],selfWorkHidden:true,type:'rangeInput',},
        // { label: '50%~84%匹配', isChecked: false, value: 'PERCENT_99_MATCHING',selfWorkHidden:true},
      ],
    },
    {
      title: '原文内容',
      selfWorkHidden:true,
      key:'sourceTags',
      children: [
        { label: '原文重复', isChecked: false, value: 'SELF_REPEAT' },
        { label: '交叉文件重复', isChecked: false, value: 'CROSS_REPEAT' },
        { label: '挖空重复', isChecked: false, value: 'HAS_URT_REPEAT',tip: '单句段由文本和数值等非译内容组成，文本内容完全一致，仅数值等的非译内容不同',icon:true,isSupportUrtLock:true },
        { label: '不重复', isChecked: false, value: 'NO_REPEAT' },
      ],
    },
    {
      title: '带标记',
      key:'withFlag',
      children: [
        { label: '带备注', isChecked: false, value: 'hasComment' },
        { label: '已锁定', isChecked: false, value: 'isLocked', docLock: true },
        {
          label: '未锁定',
          isChecked: false,
          value: 'isUnLocked',
          docLock: true,
        },
        {
          label: '已解锁',
          isChecked: false,
          value: 'isTranslatorUnLock',
          docLock: true,
        },
        {
          label: '含样式',
          isChecked: false,
          value: 'hasTag',
        },
        {
          label: '带QA提示',
          isChecked: false,
          value: 'hasQA',
          disabled: true,
          tip: '功能升级中',
        },
      ],
    },
    {
      title: '质检',
      key:'qualityScores',
      children: [
        { label: '未打分', isChecked: false, value: -1 },
        { label: '0分', isChecked: false, value: 0 },
        { label: '1分', isChecked: false, value: 1 },
        { label: '2分', isChecked: false, value: 2 },
        { label: '3分', isChecked: false, value: 3 },
        { label: '4分', isChecked: false, value: 4 },
        { label: '5分', isChecked: false, value: 5 },
      ],
    },
  ],
}

const getters = {}
const mutations = {
  changeKey(state, payload) {
    for (let i in payload) {
      state[i] = null
      state[i] = payload[i]
    }
  },
  setTabActiveIndex(state, param) {
    state.tabActiveIndex = state.ArrMap.get(param.sentId)
  },

  //重复句填充
  repeatSentenceAdd(state, param) {
    let list = state.docData.sentenceDTOList
    // let ids = param.ids;
    //推送回来的数据
    let selection = window.getSelection();
    param.forEach((item) => {
      // console.log(item)
      if (state.ArrMap.has(item.sentId)) {
        let node = window.$getTargetDom(item.sentId);
        //是否进入提交保存操作?
        let listItem = list[state.ArrMap.get(item.sentId)]
        //重复句
        item.targetPlaceholder = item.targetPlaceholder || "";
        item.targetPlaceholder = window.$assignValueToSrc(item.targetPlaceholder);
        item.diffCompare = window.$assignValueToSrc(item.diffCompare);
        node.innerHTML = item.targetPlaceholder || ''
        listItem.targetType = item.targetType
        listItem.targetPlaceholder = item.targetPlaceholder
        listItem.diffCompare = item.diffCompare
        listItem.saveFailStatus = false
        listItem.compareTargetHTML = item.targetPlaceholder
        //填充的时候走判断流程 如果是当前句；则将光标移至末尾
        const { setFocusEnd, insertHtmlAtCaret } = useUtils()
        if (selection.type=='Caret' && node.contains(selection.focusNode)) {
          setFocusEnd(node,true);
        }
        // if ()
      }
    })
  },
  getFilterValue(state) {
    // 新值
    // status targetFrom sourceTags withFlag qualityScores
    state.filterArr.forEach((item) => {
      let arr = []
      item.children.forEach((citem) => {
        if (citem.isChecked) {
          if (citem.type=='rangeInput') {
            arr.push(`${citem.value}${citem.rangeValue[0]}_${citem.rangeValue[1]}`)
          } else {
            arr.push(citem.value)
          }
          // state.status = arr.join(",");
          // state.status =
          // state.sourceTags = state.sourceTags + `${state.sourceTags ? "," : ""}` + citem.value;
        }
      })
      state[item.key] = arr.join(',')
    })
  },
  replaceFilterArr(state, param) {
    let addArr = param.activityWorkflow.map((item) => {
      return {
        label: `${item}确认`,
        isChecked: false,
        value: state.activityWorkflowKey[item],
      }
    })
    addArr.push({
      label: '空译文',
      isChecked: false,
      value: 'EmptyTranslation',
    })
    addArr.unshift({ label: '未确认', isChecked: false, value: 'UnConfirm' })
    state.filterArr[0].children = [...addArr]
  },
  //重置过滤选项条件
  resetFilterValue(state) {
    state.sourceTags = ''
    state.qualityScores = ''
    state.targetFrom = ''
    state.withFlag = ''
    state.status = ''
    state.filterArr.forEach((item) => {
      item.children.forEach((citem) => {
        citem.isChecked = false
      })
    })
  },
  //重置过滤选项条件
  resetNoConfirm(state) {
    state.sourceTags = ''
    state.qualityScores = ''
    state.targetFrom = ''
    state.withFlag = 'isUnLocked'
    state.status = 'UnConfirm'
    state.filterArr.findIndex((item) => {
      item.children.findIndex((citem) => {
        if (citem.label == '未确认') {
          citem.isChecked = true
        }
        if (citem.label == '未锁定') {
          citem.isChecked = true
        }
      })
    })
  },
}
const actions = {
  //加载译文QA
  renderQATargetHtml({ rootState }, params) {
    let sentId = params.sentId
    if (!sentId) {
      //先取出一遍原文HTML //开始判断是否加载了术语？ 还是直接赋值？术语的值我应该存着？
      return
    }
    let sentenceIndex = rootState.excelProject.ArrMap.get(params.sentId)
    let sentenceItem =
      rootState.excelProject.docData.sentenceDTOList[sentenceIndex]
    // console.log(sentenceItem);

    if (!sentenceItem) {
      return
    }
    // console.log("执行QA画线");
    let targetNode = window.$getTargetDom(sentenceItem.sentId)

    if (sentenceItem.qaInfoList?.length) {
      //换成QAInfoList;遍历查找属于原文节点. 然后重新计算。
      let arr = sentenceItem.qaInfoList.filter((item) => {
        return item.direction == 'Target'
      })
      if (rootState.excelProject.differentHighLightModel) {
        arr = []
      }
      let allowLine = ['SPELL_CHECK', 'RULE_LOWE_CHECK', 'SYNTAX_CHECK'];
      arr.forEach((item) => {
        if (
          allowLine.includes(item.checkRule) &&
          item.errorCode != '2101' &&
          item.errorStartPos <= item.errorEndPos
        ) {
          if (document.getElementsByClassName(item.id).length) {
            //存在则不划线；
          } else {
            let errorStartPos = item.errorStartPos
            let errorEndPos = item.errorEndPos + 1
            qaFormatText(targetNode, errorStartPos, errorEndPos, item.id, 1)
          }

        }


        // console.log(item.checkRule)
        if (item.checkRule == 'FORMAT_CHECK') {

          item.businessList?.forEach(citem=>{
            imgFormatText(targetNode,citem,item.id);
          })
          // let imgNode = document.getElementById('m1_1-shp-1');
        }
        //需要加上标签画线； 缺少标签画原文上面 ； 标签交叉 画译文，标签反向画译文；
        // if (item.checkRule)
        // let errorStartPos = item.errorStartPos == 0?item.errorStartPos:item.errorStartPos+1;
      })
    }
  },
  //加载原文QA
  renderSourceHtml({ rootState }, params) {
    let sentId = params.sentId
    if (!sentId) {
      //先取出一遍原文HTML //开始判断是否加载了术语？ 还是直接赋值？术语的值我应该存着？
      return
    }
    let sentenceIndex = rootState.excelProject.ArrMap.get(params.sentId)
    let sentenceItem =
      rootState.excelProject.docData.sentenceDTOList[sentenceIndex]
    // console.log(sentenceItem);

    if (!sentenceItem) {
      return
    }
    let sourceNode = window.$getSourceDom(sentenceItem.sentId);
    //先重新赋值；再进行改动；
    sourceNode.innerHTML =
      sentenceItem.termTagSource || sentenceItem.sourcePlaceholder
    if (sentenceItem.qaInfoList?.length) {
      //换成QAInfoList;遍历查找属于原文节点. 然后重新计算。
      let arr = sentenceItem.qaInfoList.filter((item) => {
        return item.direction == 'Source'
      })
      if (rootState.excelProject.differentHighLightModel) {
        arr = []
      }
      // console.log("执行qa",arr)
      arr.forEach((item) => {
        if (document.getElementsByClassName(item.id).length) {
          //存在则不划线；
          return
        }
        if (item.checkRule == 'FORMAT_CHECK') {
          item.businessList?.forEach(citem=>{
            imgFormatText(sourceNode,citem,item.id);
          })
        } else {
          let errorStartPos = item.errorStartPos
          let errorEndPos = item.errorEndPos + 1
          qaFormatText(sourceNode, errorStartPos, errorEndPos, item.id, 1);
        }
      })
    }

  },
  confirmSentences({ rootState }, params) {
    return new Promise((resolve, reject) => {
      confirmSentences(
        Object.assign(
          {
            docId: rootState.taskDetail.docId,
          },
          params
        )
      )
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  twinslatorMatch({ commit, rootState, dispatch }, params) {
    return new Promise((resolve, reject) => {
      let excelProject = rootState.excelProject
      let tabActiveItem
      if (params?.id) {
        let sentenceDTOListLength = excelProject.docData.sentenceDTOList.length
        for (let i = 0; i < sentenceDTOListLength; i++) {
          if (excelProject.docData.sentenceDTOList[i].id == params.id) {
            tabActiveItem = excelProject.docData.sentenceDTOList[i]
            break
          }
        }
      } else {
        tabActiveItem =
          excelProject.docData.sentenceDTOList[excelProject.tabActiveIndex]
      }

      if (!tabActiveItem) {
        return
      }
      let sourceNode = window.$getSourceDom(tabActiveItem.sentId);

      let sentId = tabActiveItem?.sentId
      let cursorIndex = -1
      try {
        cursorIndex = getCursorPosition(sourceNode)
      } catch (rej) {}

      twinslatorMatch(
        Object.assign({
          twinslatorId: rootState.taskDetail.twinslatorId,
          docId: rootState.taskDetail.docId,
          sentId: tabActiveItem.sentId,
          markType: tabActiveItem.targetType,
        })
      )
        .then((res) => {
          let result = res.data.data
          //如果选中非调用请求的句子，则停止只赋值
          if (sentId != rootState.checkedDetail.sentId) {
            return
          }
          //如果术语提示存在未替换的 将译文内容变为带标签的提示 保存下来 不变回去
          const { setFocusEnd, insertHtmlAtCaret } = useUtils()
          //如果打开原文编辑 ，则停止更新值
          if (rootState.openEditSourceText) {
            return false
          }

          //进行一遍转化赋值
          result.sentWithTag = window.$assignValueToSrc(result.sentWithTag);

          commit('changeKey', {
            twinslatorMatch: result,
          })

          let termSource = result.sentWithTag
          //判断上一次是否已经加载过了。加载过则不需要再次加载 未加载则需要重新加载 并且重新赋值术语。
          if (
            tabActiveItem.termTagSource &&
            termSource === tabActiveItem.termTagSource
          ) {
            return
          }

          //如果是第一次加载 且没有匹配术语也不执行
          if (!tabActiveItem.termTagSource && !result.matchedTerm?.length) {
            return
          }

          // if (termSource != tabActiveItem.termTagSource && )

          tabActiveItem.termTagSource = termSource
          tabActiveItem.matchedTerm = [...result.matchedTerm]

          //更换新方法加载；
          sourceNode.innerHTML = termSource

          //需要对比 术语可能更换，也可能未更换，直接实时查，不取缓存数据。
          dispatch('renderSourceHtml', {
            sentId: tabActiveItem.sentId,
            termHTML: termSource,
          })

          resolve(res)

          if (cursorIndex > -1) {
            positionSelection(sourceNode, cursorIndex)
          }

          // //开始加载QA
          // let sentWithTag = result.sentWithTag;
          //
          //
          // let arr = [
          //   { startIndex: 0, lastIndex: 2, qaId: result.sentId + "1" },
          //   { startIndex: 7, lastIndex: 8, qaId: result.sentId + "2" }
          // ];
          // arr.forEach(item => {
          //   // sentWithTag.
          //   sentWithTag = caseAlgorithmHtml(
          //     sentWithTag,
          //     item.startIndex,
          //     item.lastIndex,
          //     item.qaId
          //   );
          // });
          //
          // sentWithTag = sentWithTag.replaceAll("<span", "<strong");
          // sentWithTag = sentWithTag.replaceAll("</span>", "</strong>");
          //
          // //原文QAnode节点。现在全部都有，隐藏在下方
          // let sourceQADom = document.getElementById(`source-qa-${result.sentId}`);
          // sourceQADom.innerHTML = sentWithTag;
          // sentWithTag = sentWithTag.replaceAll("<label","<em");
          // sentWithTag = sentWithTag.replaceAll("</label>","</em>")
          // sentWithTag = sentWithTag.replaceAll("<span","<strong");
          //取出所有节点，term 将节点提出将节点替换
          // console.log(sentWithTag);

          // let innerHTML = sentNode.innerHTML;
          // innerHTML = innerHTML.replaceAll("<strong", "<span");
          // innerHTML = innerHTML.replaceAll("</strong>", "</span>");
          // innerHTML = innerHTML.replaceAll("<em", "<span");
          // innerHTML = innerHTML.replaceAll("</em>", "</span>");
          // sentNode.innerHTML = innerHTML;
          //生成之后 将 strong 替换为 span；

          //           sentWithTag = sentWithTag.replace(
          //             /(<\/span>)/g,
          //             `</span>
          //                                 </Tooltip>`
          //           );
          //           sentWithTag = sentWithTag.replace(
          //             /<span class="f-term">/g,
          //             `<Tooltip overlayClassName="term-tooltip">
          //                                              <template #title>
          //                                               <div class="term-tooltip-list">
          //                                                 <div v-for="(item,index) in list" class="term-tooltip-item">
          //                                                      <label>{{item.target}}</label>
          // <!--                                                     <span>来源：{{item.libName}}</span>-->
          //                                                      <button class="design-apply-button" @mousedown="insertTerm(item)">应用</button>
          // <!--                                                     <Button size="small" type="primary" @mousedown="insertTerm(item)">-->
          // <!--                                                        <span>应用</span>-->
          // <!--                                                     </Button>-->
          //                                                 </div>
          //                                               </div>
          //                                              </template>
          //                                              <span class="f-term" @mouseenter="termEnter($event)">`
          //           );
          //           sentWithTag = sentWithTag.replace(
          //             /<span class="f-term2">/g,
          //             `<Tooltip overlayClassName="term-tooltip">
          //                                              <template #title>
          //                                               <div class="term-tooltip-list">
          //                                                 <div v-for="(item,index) in list" class="term-tooltip-item">
          //                                                      <label>{{item.target}}</label>
          // <!--                                                     <span class="libName">来源：{{item.libName}}</span>-->
          //                                                      <button class="design-apply-button" @mousedown="insertTerm(item)">应用</button>
          // <!--                                                     <Button size="small" type="primary"  @mousedown="insertTerm(item)">-->
          // <!--                                                        <span>应用</span>-->
          // <!--                                                     </Button>-->
          //                                                 </div>
          //                                               </div>
          //                                              </template>
          //                                              <span class="f-term2" @mouseenter="termEnter($event)">`
          //           );
          //
          //           // tabActiveItem.sourceText = result.sentWithTag;
          //           function appendComponent() {
          //             let template = ``;
          //             template = sentWithTag;
          //
          //             let componentInstance = createVNode({
          //               template,
          //               data: () => {
          //                 return {
          //                   sourceList: JSON.parse(JSON.stringify(result.matchedTerm)),
          //                   index: rootState.excelProject.tabActiveIndex,
          //                   sentId: tabActiveItem.sentId,
          //                   list: []
          //                 };
          //               },
          //               methods: {
          //                 insertTerm(item) {
          //                   let activeNode = document.activeElement;
          //                   // if (activeNode.classList)
          //                   if (activeNode.id.indexOf("target-") == -1) {
          //                     return;
          //                   }
          //                   rootState.excelProject.keyQueue = "stop";
          //                   insertHtmlAtCaret(item.target);
          //                   setTimeout(() => {
          //                     rootState.excelProject.keyQueue = "";
          //                     activeNode.focus();
          //                     activeNode = null;
          //                   }, 200);
          //                 },
          //                 httpTermMatch(source) {
          //                   this.list = this.sourceList.filter(item => {
          //                     return (
          //                       item.source.toLowerCase() == source.toLowerCase() ||
          //                       item.sourceActual.toLowerCase() == source.toLowerCase()
          //                     );
          //                   });
          //                 },
          //                 termEnter($event) {
          //                   this.list = [];
          //                   // console.log(rootState.excelProject.tabActiveIndex,this.index)
          //                   setTimeout(() => {
          //                     this.httpTermMatch($event.srcElement.innerText);
          //                   }, 0);
          //                 }
          //               },
          //               components: {
          //                 Button,
          //                 Tooltip
          //               }
          //             });
          //             // 可替换成你想挂载的任意dom节点
          //             let fragment = document.createElement("div");
          //             fragment.className = "wrap-test source-sent-wrap-test";
          //             render(componentInstance, fragment);
          //             // console.dir(fragment)
          //             let source = document.getElementsByClassName(
          //               `source-sent-${tabActiveItem.id}`
          //             )[0];
          //             source.innerHTML = null;
          //             source.appendChild(fragment);
          //             componentInstance = null;
          //             fragment = null;
          //             source = null;
          //           }
          //
          //           appendComponent();
          // commit("changeKey", {
          //   twinslatorMatch: result
          // });
          // resolve(res);
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // replaceTwinslatorTerm({ commit, rootState, dispatch }, params) {
  //   return new Promise((resolve, reject) => {
  //     replaceTwinslatorTerm(
  //       Object.assign(
  //         {
  //           sentId: rootState.checkedDetail.sentId,
  //           twinslatorId: rootState.taskDetail.twinslatorId
  //         },
  //         params
  //       )
  //     )
  //       .then(res => {
  //         res.data.result == "1" && message.success("操作成功");
  //         dispatch("twinslatorMatch", {
  //           sentId: rootState.checkedDetail.sentId
  //         });
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  // replaceTwinslatorCorpus({ commit, rootState, dispatch }, params) {
  //   return new Promise((resolve, reject) => {
  //     replaceTwinslatorCorpus(
  //       Object.assign(
  //         {
  //           sentId: rootState.checkedDetail.sentId,
  //           twinslatorId: rootState.taskDetail.twinslatorId
  //         },
  //         params
  //       )
  //     )
  //       .then(res => {
  //         res.data.result == "1" && message.success("操作成功");
  //         dispatch("twinslatorMatch", {
  //           sentId: rootState.checkedDetail.sentId
  //         });
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  // addTwinslatorCorpus({ commit, rootState, dispatch }, params) {
  //   return new Promise((resolve, reject) => {
  //     addTwinslatorCorpus(params)
  //       .then(res => {
  //         res.data.result == "1" && message.success("操作成功");
  //         dispatch("twinslatorMatch", {
  //           sentId: rootState.checkedDetail.sentId
  //         });
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
