
  import { computed, getCurrentInstance, nextTick } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import useUtils from '@/plugins/utils'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    setup() {
      const { t } = useI18n()
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const store = useStore()
      const { setFocusEnd } = useUtils()
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })

      const openReplace = () => {
        // console.log(window.getSelection());
        store.commit('rightToolbar/changeKey', {
          replaceShow: false,
        })
        nextTick(() => {
          store.commit('rightToolbar/changeKey', {
            replaceShow: true,
          })
        })
      }
      return {
        openReplace,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
