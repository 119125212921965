<template>
  <div>
    <a-modal
      :visible="state.explainVisible"
      title="重复句同步说明"
      centered
      okText="我知道了"
      @cancel="handleCancel('explainVisible')"
      @ok="handleOk('explainVisible')"
      wrapClassName="ant-modal-reset-one-button"
    >
      <div>
        <div class="color-1C2129 text-center font-size-16">
          重复率分析后的文档，会默认自动同步重复句间的译文。
        </div>
        <div
          style="border-bottom: 1px solid #F2F3F5"
          class="text-center margin-b-20 padding-b-20"
        >
          <div class="display-flex align-center status-box">
            <div class="left">
              <icon-font
                type="icon-tongbu"
                style="color:#69D5F7;font-size: 36px"
              ></icon-font>
            </div>
            <div class="right">
              <div class="color-1C2129 margin-b-4">
                此句已
                <span class="color-00B4FA">开启</span>
                自动同步
              </div>
              <div class="color-86909C">
                此句译文将跟随重复句的修改而同步修改，可点击图标取消同步。
              </div>
            </div>
          </div>
          <div class="display-flex align-center status-box">
            <div class="left">
              <icon-font
                type="icon-guanbitongbu"
                style="font-size: 36px"
              ></icon-font>
            </div>
            <div class="right">
              <div class="color-1C2129 margin-b-4">
                此句已
                <span class="color-00B4FA">关闭</span>
                自动同步
              </div>
              <div class="color-86909C">
                修改此句将不会影响其他对应重复句的译文，可点击图标恢复同步。
              </div>
            </div>
          </div>
        </div>

        <div class="text-center color-C9CDD4 font-size-13">
          * 若解锁无权限，可联系PM前往项目详情-成员处，找到该译员设置权限
        </div>
      </div>
    </a-modal>

    <a-modal
      :visible="state.unLockVisible"
      title="提示"
      centered
      okText="确定解锁"
      @ok="handleOk('unLockVisible')"
      @cancel="handleCancel('unLockVisible')"
    >
      <div>
        <div class="font-size-16 color-1C2129 text-center margin-b-20">
          此句为【重复锁定句】，请选择解锁方式：
        </div>
        <div>
          <a-radio-group v-model:value="setValue.isCancelSync">
            <div class="display-flex margin-b-16">
              <a-radio :value="true"></a-radio>
              <div>
                <div class="color-1C2129">解锁且取消同步</div>
                <div class="color-86909C">可编辑本句，不影响其他重复句段</div>
              </div>
            </div>
            <div class="display-flex">
              <a-radio :value="false"></a-radio>
              <div>
                <div class="color-1C2129">仅解锁，保持同步</div>
                <div class="color-86909C">
                  可编辑本句，且本句译文
                  <span class="color-F55C3F">
                    将同步到其他重复句段，请谨慎操作！
                  </span>
                </div>
              </div>
            </div>
          </a-radio-group>
        </div>
      </div>
    </a-modal>

    <a-modal
      :visible="state.unSyncVisible"
      title="提示"
      centered
      @ok="handleOk('unSyncVisible')"
      @cancel="handleCancel('unSyncVisible')"
    >
      <div class="text-center">
        <div class="margin-b-20">
          <img src="@/assets/warning-blue.png" alt="" width="56" />
        </div>
        <div>
          <div class="color-1C2129 font-size-16 margin-b-8">
            此句为重复句，关闭同步后会解锁？
          </div>
          <div class="color-86909C font-size-14">
            关闭同步后，您可编辑本句，且不影响其他重句
          </div>
        </div>
      </div>
      <template #footer>
        <div class="display-flex justify-between align-center">
          <div>
            <a-checkbox v-model:checked="setValue.unSyncCloseTip">
              不再提示
            </a-checkbox>
          </div>
          <div>
            <a-button @click="handleCancel('unSyncVisible')">
              取消
            </a-button>
            <a-button type="primary" @click="handleOk('unSyncVisible')">
              关闭同步并解锁
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      :visible="state.syncVisible"
      title="恢复同步"
      centered
      okText="确认恢复同步"
      cancelText="取消"
      @ok="handleOk('syncVisible')"
      @cancel="handleCancel('syncVisible')"
    >
      <div class="text-center">
        <div>
          <div class="color-1C2129 font-size-16 margin-b-8">
            请选择译文恢复模式：
          </div>
          <div class="margin-b-16 text-center">
            <a-radio-group v-model:value="setValue.syncMethod">
              <a-radio :value="'FillSelfByOther'">
                <span>本句译文将被其他重复句填充</span>
              </a-radio>
              <a-radio :value="'FillOther'">
                <span>将本句译文同步到其他重复句</span>
              </a-radio>
            </a-radio-group>
          </div>
          <div class="text-area">
            <div class="header"></div>
            <el-scrollbar class="content">
              <div
                class="content-text"
                v-if="setValue.syncMethod == 'FillSelfByOther'"
              >
                <div>即将覆盖此句的译文：</div>
                <div v-html="setValue.sentenceSyncInfo.targetText"></div>
              </div>
              <div class="content-text" v-else>
                <div>
                  注意：本句即将覆盖
                  <span class="color-00B4FA">
                    {{ setValue.sentenceSyncInfo.repeatedSentIds?.length }}
                  </span>
                  个重复句。此操作会影响到其他句段，请谨慎操作！
                </div>
                <div class="color-F55C3F">
                  此操作会影响到其他句段，请谨慎操作！
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="color-C9CDD4">
            * 若需保持译法不一致，则无需恢复同步和备份
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { reactive } from 'vue'
  import mitt from '@/plugins/bus'

  const state = reactive({
    unLockVisible: false,
    unSyncVisible: false,
    syncVisible: false,
    explainVisible: false,
  })

  const callBack = reactive({
    okCallBack: null,
    cancelCallBack: null,
  })

  const setValue = reactive({
    //取消同步
    isCancelSync: true,
    //不再提示
    unSyncCloseTip: false,
    //句子同步信息
    sentenceSyncInfo: {},
    //
    syncMethod: 'FillSelfByOther',
  })

  const handleOk = (type) => {
    switch (type) {
      case 'explainVisible':
        state[type] = false
        break
      case 'unLockVisible':
        state[type] = false
        callBack.okCallBack(setValue.isCancelSync)
        break
      case 'unSyncVisible':
        state[type] = false
        if (setValue.unSyncCloseTip) {
          sessionStorage.setItem('unSyncCloseTip', 'true')
        }
        callBack.okCallBack()
        break
      case 'syncVisible':
        state[type] = false
        callBack.okCallBack(setValue.syncMethod)
        break
      default:
        break
    }
    initValue()
  }

  const initValue = () => {
    //值重置
    callBack.okCallBack = null
    callBack.cancelCallBack = null
    setValue.sentenceSyncInfo = {}
    setValue.isCancelSync = true
    setValue.unSyncCloseTip = false
  }

  const handleCancel = (type) => {
    state[type] = false
    callBack.cancelCallBack()
    initValue()
  }

  const emptyFunc = (e) => {}

  mitt.on('openSyncLockSentenceModal', (params) => {
    let type = params.type
    switch (type) {
      case 'explainVisible':
        state[params.type] = true
        callBack.okCallBack = params.okCallBack || emptyFunc
        callBack.cancelCallBack = params.cancelCallBack || emptyFunc
        break
      case 'unLockVisible':
        state[params.type] = true
        callBack.okCallBack = params.okCallBack || emptyFunc
        callBack.cancelCallBack = params.cancelCallBack || emptyFunc
        break
      case 'unSyncVisible':
        //需要判断是否存储了不再提示;
        if (sessionStorage.getItem('unSyncCloseTip')) {
          params.okCallBack()
        } else {
          state[params.type] = true
          callBack.okCallBack = params.okCallBack || emptyFunc
          callBack.cancelCallBack = params.cancelCallBack || emptyFunc
        }
        break
      case 'syncVisible':
        state[params.type] = true
        callBack.okCallBack = params.okCallBack || emptyFunc
        callBack.cancelCallBack = params.cancelCallBack || emptyFunc
        let { sentenceSyncInfo } = params
        //赋值展示
        setValue.sentenceSyncInfo = sentenceSyncInfo
        break
      default:
        break
    }
  })
</script>

<style scoped lang="less">
  .color-F55C3F {
    color: #f55c3f;
  }
  .color-86909C {
    color: #86909c;
  }
  .color-C9CDD4 {
    color: #c9cdd4;
  }
  .color-00B4FA {
    color: #00b4fa;
  }
  .color-1C2129 {
    color: #1c2129;
  }
  .text-area {
    margin-bottom: 20px;
    background: #fafafa;
    border-radius: 8px;
    border: 1px solid #e5e6eb;
    text-align: left;
    padding: 10px 0;
    font-size: 14px;
    color: #86909c;
    .header {
      padding: 0 20px;
    }
    .content {
      height: 70px;
      .content-text {
        padding: 0 20px;
      }
    }
  }
  .status-box {
    margin-top: 20px;
    .left {
      flex-basis: 36px;
      margin-right: 20px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    .right {
      text-align: left;
    }
  }
</style>
