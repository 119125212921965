
  export default {
    props: {
      description: {
        type: String,
        default: '',
      },
      description2: {
        type: String,
        default: '',
      },
    },
  }
