import { createVNode, watchEffect, nextTick, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { getFactTargetText } from '@/plugins/comm.js'
import { entryDeleteBatch, entryEdit } from '@/http/api'
import useUtils from '@/plugins/utils'
import useProject from '@/components/source/useProject'

export default function useTip() {
  const store = useStore()
  const { appContext } = getCurrentInstance()
  const globalProxy = appContext.config.globalProperties
  let sentId = 0
  watchEffect(() => {
    sentId = store.state.checkedDetail.sentId
  })

  const del = (item: any) => {
    // Modal.confirm({
    //   title: "提示",
    //   icon: createVNode(ExclamationCircleOutlined),
    //   content: "确定删除吗？",
    //   okText: "确定",
    //   cancelText: "取消",
    //   centered: true,
    //   onOk() {
    //     deleteBatch(item);
    //   }
    // });
  }

  const put = async (item: any) => {
    return new Promise((resolve, reject) => {
      entryEdit({
        entryId: item.id,
        libType: 1,
        libraryId: item.libId,
        source: item.source,
        target: item.target,
        comment: item.comment,
        isAudit: 0,
      })
        .then((res: any) => {
          if (res.data.result == '1') {
            message.success('修改成功')
            resolve('true')
          }
        })
        .catch(() => {
          reject()
        })
    })
  }

  const { insertHtmlAtCaret, setFocusEnd } = useUtils()

  //获取当前激活项item
  const getTableActiveItem = () => {
    const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
    const tabActiveIndex = store.state.excelProject.tabActiveIndex
    return sentenceDTOList[tabActiveIndex]
  }

  const apply = (item: any, key?: string) => {
    let activeItem = getTableActiveItem()
    if (activeItem.locked) {
      message.error('当前句子被锁定，禁止做任何操作')
      return
    }

    const sent: any = window.$getTargetDom(activeItem.sentId);
    if (key == 'insert') {
      //需要拦截非译文插入
      console.dir(document.activeElement)
      if (document.activeElement.id.indexOf('target-') < 0) {
        return
      }
      store.state.excelProject.keyQueue = 'stop'
      insertHtmlAtCaret(item.target, sentId)
      setTimeout(() => {
        store.state.excelProject.keyQueue = ''
        setFocusEnd(sent)
      }, 200)
    } else if (key == 'corpus') {
      sent.innerHTML = window.$assignValueToSrc(getFactTargetText(item) || '')
      setTimeout(() => {
        setFocusEnd(sent, true)
      }, 200)
    } else {
      // activeItem.targetPlaceholder = getFactTargetText(item);
      sent.innerHTML = window.$assignValueToSrc(getFactTargetText(item) || '');
      setTimeout(() => {
        setFocusEnd(sent, true)
      }, 200)
    }
  }

  return { del, put, apply }
}
