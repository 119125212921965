import {
  fetchTranslationLogs,
  getQASettingApi,
  ignoreDocAll,
  ignoreQAErrorApi,
  searchQualityResult,
  spellJoinDicApi,
} from '@/http/api'
import remark from './remark'
import { ref } from 'vue'

const state = {
  toolbarNav: {
    tbNavActiveIndex: '0',
  },
  replaceShow: false,

  logList: [],

  qaCheckedList: [
    'TERM_CONSISTENCY_CHECK',
    'FORMAT_CHECK',
    'SPELL_CHECK',
    'RULE_LOWE_CHECK',
  ],
  checkRule: {
    SPELL_CHECK: '拼写错误',
    RULE_LOWE_CHECK: '低错检查',
    TERM_CONSISTENCY_CHECK: '术语漏译',
    FORMAT_CHECK: '标签错误',
    SYNTAX_CHECK: '语法词法错误',
  },
  sameQADetail: {
    checkRule: [],
  },
  qaModel: 2,
}
const getters = {}
const mutations = {
  changeKey(state, payload) {
    for (let i in payload) {
      if (i == 'replaceShow') {
        state.replaceShow = false
      }
      state[i] = null
      state[i] = payload[i]
    }
  },
}
const actions = {
  fillSentenceScore({ commit, rootState, dispatch, state }, params) {
    let score = params.score
    //填充
    let index = rootState.excelProject.ArrMap.get(params.sentId)
    if (index > -1) {
      rootState.excelProject.docData.sentenceDTOList[index].qualityScore =
        params.score
    }
  },

  fetchTranslationLogs({ commit, rootState }) {
    if (!rootState.checkedDetail.sentId) {
      return
    }
    fetchTranslationLogs({
      docId: rootState.taskDetail.docId,
      sentId: rootState.checkedDetail.sentId,
    }).then((res) => {
      commit('changeKey', {
        logList: res.data.data.list,
      })
    })
  },
  getQASetting({ commit, rootState, state }) {
    return new Promise((resolve, reject) => {
      getQASettingApi().then((res) => {
        let result = res.data.data
        //赋值
        state.qaCheckedList = [...result.qaRuleFilter]
        resolve(res)
      })
    })
  },

  //只忽略单句错误
  ignoreQAError({ commit, rootState, dispatch }, params) {
    return new Promise((resolve, reject) => {
      let qaItem = params.qaItem
      if (!qaItem.id) {
        return
      }
      if (qaItem.direction == 'Source') {
        //如果QA是原文。检查原文是否有对应的划线。有划线则取消划线。等待推送
        let sentDom = window.$getSourceDom(qaItem.sentId)
        if (sentDom) {
          let domList = sentDom.getElementsByClassName(qaItem.id)
          for (let i = 0; i < domList.length; i++) {
            domList[i].id = ''
            domList[i].className = ''
            i--
          }
        }
      } else {
        //如果QA是译文
        let sentDom = window.$getTargetDom(qaItem.sentId);
        if (sentDom) {
          let domList = sentDom.getElementsByClassName(qaItem.id)
          for (let i = 0; i < domList.length; i++) {
            domList[i].id = ''
            domList[i].className = ''
            i--
          }
          //重新赋值CompareHTML
          let sentenceItem =
            rootState.excelProject.docData.sentenceDTOList[
              rootState.excelProject.ArrMap.get(qaItem.sentId)
            ]
          if (sentenceItem) {
            sentenceItem.compareTargetHTML = sentDom.innerHTML
          }
        }
      }
      ignoreQAErrorApi({ sentenceQALogId: qaItem.id }).then((res) => {
        resolve(true)
        dispatch(
          'getAllSentenceLogs',
          {},
          {
            root: true,
          }
        )
      })
    })
  },
  //qa全文忽略术语漏译不会有全文忽略
  allIgnore({ commit, rootState, dispatch }, params) {
    return new Promise((resolve, reject) => {
      let qaItem = params.qaItem
      if (!qaItem.id) {
        return
      }

      if (qaItem.direction == 'Source') {
        //如果QA是原文。检查原文是否有对应的划线。有划线则取消划线。等待推送
        let sentDom = window.$getSourceDom(qaItem.sentId)
        if (sentDom) {
          let domList = sentDom.getElementsByClassName(qaItem.id)
          for (let i = 0; i < domList.length; i++) {
            domList[i].id = ''
            domList[i].className = ''
            i--
          }
        }
      } else {
        //如果QA是译文
        let sentDom =  window.$getTargetDom(qaItem.sentId)
        if (sentDom) {
          let domList = sentDom.getElementsByClassName(qaItem.id)
          for (let i = 0; i < domList.length; i++) {
            domList[i].id = ''
            domList[i].className = ''
            i--
          }
          //重新赋值CompareHTML
          let sentenceItem =
            rootState.excelProject.docData.sentenceDTOList[
              rootState.excelProject.ArrMap.get(qaItem.sentId)
            ]
          if (sentenceItem) {
            sentenceItem.compareTargetHTML = sentDom.innerHTML
          }
        }
      }

      ignoreDocAll({ sentenceQALogId: qaItem.id }).then((res) => {
        resolve(true)
        dispatch(
          'getAllSentenceLogs',
          {},
          {
            root: true,
          }
        )
      })
    })
  },
  spellConfirm({ commit, rootState, dispatch }, params) {
    let qaItem = params.qaItem
    if (!qaItem.id) {
      return
    }

    if (qaItem.direction == 'Source') {
      //如果QA是原文。检查原文是否有对应的划线。有划线则取消划线。等待推送
      let sentDom = window.$getSourceDom(qaItem.sentId)
      if (sentDom) {
        let domList = sentDom.getElementsByClassName(qaItem.id)
        for (let i = 0; i < domList.length; i++) {
          domList[i].id = ''
          domList[i].className = ''
        }
      }
    } else {
      //如果QA是译文
      let sentDom =  window.$getTargetDom(qaItem.sentId)
      if (sentDom) {
        let domList = sentDom.getElementsByClassName(qaItem.id)
        for (let i = 0; i < domList.length; i++) {
          domList[i].id = ''
          domList[i].className = ''
        }
      }
    }

    spellJoinDicApi({ sentenceQALogId: qaItem.id }).then((res) => {
      dispatch(
        'getAllSentenceLogs',
        {},
        {
          root: true,
        }
      )
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
  modules: {
    remark,
  },
}
