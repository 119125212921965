<template>
  <a-modal
    title="提示"
    okText="我知道了"
    cancelText="返回"
    :visible="true"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="balanceNoEnough-Modal">
      <div class="title">
        <img src="@/assets/warning_1.png" />
      </div>
      <div class="center">
        PM账号译力额度不足，暂不可使用孪生译员
      </div>
      <div class="footer">
        您可提醒PM申请额度
      </div>
    </div>
  </a-modal>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { reactive } from 'vue'
  const state = reactive({
    type: 1,
  })
  const store = useStore()
  const handleOk = () => {
    store.commit('modal/changeKey', {
      PMLimitVisible: false,
    })
  }
  const handleCancel = () => {
    store.commit('modal/changeKey', {
      PMLimitVisible: false,
    })
  }
</script>

<style scoped lang="less">
  .balanceNoEnough-Modal {
    text-align: center;
    .title {
      img {
        width: 56px;
      }
      margin-bottom: 20px;
    }
    .center {
      font-weight: 400;
      color: #1c2129;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
    }
    .footer {
      color: #4e5969;
      line-height: 22px;
      font-size: 14px;
    }
  }
</style>
