<template>
  <div class="table-footer display-flex align-center justify-between">
    <div>
      <a-pagination
        v-model:current="docData.pageNum"
        v-model:pageSize="store.state.pageSize"
        :disabled="disabled"
        size="small"
        :total="store.state.excelProject.docData.total"
        show-quick-jumper
        :show-total="(total) => `共 ${total} 条`"
        @change="paginationChange"
      />
    </div>
    <div v-if="!disabled" class="margin-l-6">
      <a-select v-model:value="pageSize" size="small" @change="changePageSize">
        <a-select-option
          :value="item.value"
          v-for="(item, index) in pageSizeList"
          :key="index"
        >
          {{ item.value }}条/页
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
  import { computed, ref, toRefs, watch, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import useUtils from '@/plugins/utils'
  import {
    FilterFilled,
    DownOutlined,
    LeftOutlined,
    RightOutlined,
  } from '@ant-design/icons-vue'

  export default {
    components: {
      FilterFilled,
      DownOutlined,
      LeftOutlined,
      RightOutlined,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const docData = computed(() => store.state.excelProject.docData)
      const state = reactive({
        pageSizeList: [
          { label: '10 条/页', value: 10 },
          { label: '20 条/页', value: 20 },
          { label: '30 条/页', value: 30 },
          { label: '50 条/页', value: 50 },
          { label: '100 条/页', value: 100 },
          { label: '200 条/页', value: 200 },
        ],
      })

      const pager = ref('')

      const pageSize = computed({
        set: (val) => {
          store.commit('changeKey', {
            pageSize: val,
          })
        },
        get: () => store.state.pageSize,
      })

      const plusMinusPage = (pageNum) => {
        store.commit('changeKey', {
          pageNum: pageNum,
        })
        store.dispatch('getSentence', { isBackTop: true, loading: true })
      }

      const changePageSize = (num) => {
        store.commit('changeKey', {
          pageNum: 1,
          pageSize: num,
        })
        store.dispatch('getSentence', { isBackTop: true, loading: true })
      }

      const paginationChange = (page, pageSize) => {
        plusMinusPage(page)
      }

      const setPageSize = (index) => {
        if (state.pageSizeList[index].value != store.state.pageSize) {
          // store.commit("changeKey", {
          //   pageSize: state.pageSizeList[index].value,
          // });
          changePageSize(state.pageSizeList[index].value)
        }
      }

      const goToPage = () => {
        if (!pager.value || pager.value == 0) {
          return
        }
        if (pager.value > docData.value.pageCount) {
          message.error('不能超过总页数')
          return
        }
        //判断输入的页数是否大于当前获取的页数
        plusMinusPage(Number(pager.value))
      }

      const getPopupContainer = (trigger) => {
        return trigger.parentElement
      }

      return {
        pageSize,
        setPageSize,
        ...toRefs(state),
        getPopupContainer,
        disabled: computed(() => store.state.openEditSourceText),
        t,
        store,
        docData,
        taskDetail: computed(() => store.state.taskDetail),
        goToPage,
        pager,
        paginationChange,
        changePageSize,
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.ant-pagination-options) {
    .ant-select {
      width: 100px;
    }
  }
  ::v-deep(.ant-pagination.mini) {
    .ant-pagination-options-quick-jumper input {
      padding: 0 7px;
    }
  }
  .pagination-pageSize-select {
    ul {
      li {
        text-align: center;
        font-size: 14px;
        color: #333;
        width: 100px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          background: #f6f6f6;
        }
        &.select {
          background: #f1fbfe;
        }
      }
    }
  }
</style>
