<template>
  <a-modal
    :visible="visible"
    :centered="true"
    :width="640"
    :maskClosable="false"
    @cancel="cancel"
  >
    <template #title>
      <div class="price-title">
        <div class="left">孪生译员费用</div>
        <div class="right" @click="knowWeb">快速了解孪生译员</div>
      </div>
    </template>

    <div class="price-content">
      <div class="price-box">
        <div class="price-box-left">
          <img src="./images/GPTPrice.png" />
          <div class="font-size-12">费用明细单</div>
        </div>
        <div class="price-box-right">
          <div class="price-box-item">
            <label>支付账户：</label>
            <div>{{ detail.accountName }}</div>
          </div>
          <div class="price-box-item">
            <label>类型：</label>
            <div>{{ keyObjNew[detail.costType]?.name }}</div>
          </div>
          <div class="price-box-item">
            <label>单价：</label>
            <div>
              {{ detail.unitPrice }}译力/千字
              <span class="font-size-12 color-999">
                （约合{{ (detail.unitPrice / 1000).toFixed(2) }}元千字）
              </span>
            </div>
          </div>
          <div class="price-box-item">
            <label>{{ keyObjNew[detail.costType]?.label }}总字数：</label>
            <div>
              {{ detail.wordCount }} {{ keyObjNew[detail.costType]?.word }}
            </div>
          </div>
          <div class="price-box-item">
            <label>扣除字数包：</label>
            <div>
              <span class="payPrice">{{ detail.costCharPackage || '-' }}</span>
            </div>
          </div>
          <div class="price-box-item">
            <label>扣除译力：</label>
            <div>
              <span class="payPrice">{{ detail.costPower || '-' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="margin-t-20">
        <a-checkbox v-model:checked="checked">
          <span style="color:#333">
            下次使用孪生译员时，若译力足够
            <span style="color:#00BEFA">自动扣费</span>
            ，不再提醒
          </span>
        </a-checkbox>
      </div>
    </div>

    <template #footer>
      <div class="price-footer">
        <div class="left">
          <img src="./images/yili.png" />
          <span>当前译力余额：{{ detail.restPower }}</span>
          <img src="./images/zishubao.png" />
          <span>当前字数包余额：{{ detail.restCharPackage }}</span>
        </div>
        <div class="right" v-if="detail.paySource == 'enterprise'">
          <a-button key="back" size="default" @click="cancel">
            取消
          </a-button>
          <a-button
            v-if="detail.costType"
            key="submit"
            type="primary"
            size="default"
            class="margin-l-10"
            @click="confirm"
          >
            确定
          </a-button>
        </div>

        <div class="right" v-else>
          <a-button key="back" size="default" @click="cancel">
            取消
          </a-button>
          <a-button
            v-if="detail.costType"
            key="submit"
            type="primary"
            size="default"
            class="margin-l-10"
            @click="confirm"
          >
            {{ detail.enough ? '确定' : '前往充值' }}
          </a-button>
        </div>
      </div>
    </template>
    <!--    </div>-->
  </a-modal>
</template>

<script>
  import {
    QuestionCircleFilled,
    QuestionCircleOutlined,
  } from '@ant-design/icons-vue'
  import { computed, ref } from 'vue'
  import { $storageUser } from '@/plugins/proxyStorage'
  import { useStore } from 'vuex'
  import mitt from '@/plugins/bus'
  import {
    getTwinsSetStatus,
    postTwinsCalculate,
    postTwinsSetStatus,
    updateSourceTarget,
  } from '@/http/api'
  import { Decimal } from 'decimal.js'
  export default {
    components: {
      QuestionCircleOutlined,
      QuestionCircleFilled,
    },
    props: [],
    setup(props, ctx) {
      const loading = ref(false)
      const visible = ref(false)
      const detail = ref({})
      const callback = ref(null)
      const store = useStore()

      const checked = computed({
        set(val) {
          store.commit('modal/changeKey', {
            autoPayChecked: val,
          })
        },
        get() {
          return store.state.modal.autoPayChecked
        },
      })

      const autoRecommendChecked = computed({
        set(val) {
          store.commit('modal/changeKey', {
            autoRecommendChecked: val,
          })
        },
        get() {
          return store.state.modal.autoRecommendChecked
        },
      })

      const cancel = () => {
        getTwinsSetStatus().then((res) => {
          let result = res.data.data
          // 自动支付
          checked.value = result.autoPay
        })
        visible.value = false
        callback.value = null
      }

      const callbackFunctionEmit = () => {
        if (callback.value && typeof callback.value === 'function') {
          callback.value()
        }
      }

      const confirm = (type) => {
        //需要判断是否哪个角色权限不够
        if (type == 'skipSet') {
          visible.value = false
          callbackFunctionEmit()
          return
        }

        // if (detail.value.enough) {
        //   //余额充足 就直接执行回调，并存下选择值。
        //   postTwinsSetStatus({
        //     autoPay: checked.value,
        //     autoRecommend: autoRecommendChecked.value
        //   });
        //   visible.value = false;
        //   callbackFunctionEmit();
        // } else {
        //   //不够则重新走流程 弹窗余额不足。
        //   cancel();
        //   store.commit("changeKey", {
        //     rechargeVisible: true
        //   });
        // }

        // if (detail.value.paySource == "enterprise") {
        //
        //   //如果是企业需要判断哪种不够
        //   if (!detail.value.pmEnough) {
        //     // PM额度设置不够
        //     cancel();
        //     store.commit("modal/changeKey",{
        //       PMLimitVisible:true
        //     })
        //   } else if (!detail.value.enterpriseEnough) {
        //     // 企业余额不够
        //     cancel();
        //     store.commit("modal/changeKey",{
        //       balanceNoEnoughVisible:true
        //     })
        //   }
        // }
        //
        // // 支付方为个人 直接打开弹窗即可
        // else if (detail.value.paySource == "personal") {
        //   // detail.value = { ...res.data.data };
        //   // callback.value = event.ok;
        // }

        // 支付方为企业
        if (detail.value.paySource == 'enterprise') {
          //如果是企业需要判断哪种不够
          if (!detail.value.pmEnough) {
            // PM额度设置不够
            cancel()
            store.commit('modal/changeKey', {
              PMLimitVisible: true,
            })
          } else if (!detail.value.enterpriseEnough) {
            // 企业余额不够
            cancel()
            store.commit('modal/changeKey', {
              balanceNoEnoughVisible: true,
            })
          } else {
            //执行回调
            postTwinsSetStatus({
              autoPay: checked.value,
              autoRecommend: autoRecommendChecked.value,
            })
            visible.value = false
            callbackFunctionEmit()
          }
        }

        // 支付方为个人
        else if (detail.value.paySource == 'personal') {
          if (detail.value.enough) {
            //余额充足 就直接做处理。 存值 判断
            postTwinsSetStatus({
              autoPay: checked.value,
              autoRecommend: autoRecommendChecked.value,
            })
            visible.value = false
            callbackFunctionEmit()
          } else {
            cancel()
            store.commit('changeKey', {
              rechargeVisible: true,
            })
          }
        }
      }
      const knowWeb = () => {
        window.open('https://mp.weixin.qq.com/s/teQjmO-yjslAy5Df8H8wlw')
      }
      // 去往充值页面
      const toRecharge = () => {
        window.open(`/go/wealth_pc_login/recharge?tabs=2&openModal=1`, '_blank')
      }

      const keyObjNew = {
        TranslateRecommend: {
          word: '（仅统计原文）',
          label: '推荐',
          name: '译文推荐',
        },
        GptDecorate: {
          word: '（仅统计译文）',
          label: '润色',
          name: '译文润色',
        },
        GptQualityCheck: {
          word: '（统计原文+译文）',
          label: '质检',
          name: '译文质检',
        },
        TermReTranslate: {
          word: '（仅统计原文）',
          label: '重译',
          name: '术语重译',
        },
      }

      mitt.on('openCostModal', (event) => {
        callback.value = null
        //自动支付开启
        if (store.state.modal.autoPayChecked) {
          callback.value = event.ok
          confirm('skipSet')
          return
        }

        //自动支付未开启
        let params = {
          costType: event.costType,
          sentIds: event.sentIds,
        }
        visible.value = true

        postTwinsCalculate(params).then((res) => {
          detail.value = { ...res.data.data }
          callback.value = event.ok
          // 支付方为企业
        })
      })

      return {
        keyObjNew,
        cancel,
        callback,
        detail,
        visible,
        knowWeb,
        loading,
        confirm,
        toRecharge,
        checked,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .price-title {
    display: flex;
    justify-content: space-between;
    .left {
    }
    .right {
      height: 24px;
      padding: 0 12px;
      line-height: 24px;
      color: #00befa;
      font-size: 12px;
      background: #e0f7ff;
      cursor: pointer;
      margin-right: 26px;
      border-radius: 12px;
    }
  }
  .price-box {
    display: flex;
    .price-box-left {
      flex-shrink: 0;
      margin-right: 30px;
      text-align: center;
      color: #333333;
      img {
        margin-bottom: 8px;
        width: 48px;
      }
    }
    .price-box-right {
      flex-grow: 1;
      padding: 10px 20px;
      background: #f6f6f6;
      border-radius: 8px;
    }
    .price-box-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 8px 0;
      label {
        color: #666666;
        flex-basis: 90px;
        margin-right: 30px;
        flex-shrink: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px dashed #dddddd;
      }
      & > div {
        color: #333333;
      }
      .payPrice {
        color: #00befa;
        margin-right: 20px;
      }
      .rate {
        font-size: 12px;
        color: #999999;
      }
      .black {
        color: #333;
      }
    }
  }
  .price-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
    span {
      color: #666;
      margin-right: 20px;
    }
  }
</style>
