
  import TranslateSettingDialog from '@/components/dialog/translateSetting/setting.vue'
  import { computed, ComputedRef, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      TranslateSettingDialog,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const translateSettingVisible = ref(false)
      const propsType = ref(1)
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })

      const openTransitionSettingModal = (type = 1) => {
        propsType.value = type
        translateSettingVisible.value = true
      }
      return {
        propsType,
        openTransitionSettingModal,
        translateSettingVisible,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
