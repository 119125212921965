let $storageUser: any = {
  userId: '',
}

$storageUser = new Proxy($storageUser, {
  get(target: any, key: any) {
    //初始化赋值
    let userItem = JSON.parse(localStorage.getItem('userInfo') as string)
    if (userItem == null) {
      userItem = target
    }
    //所有操作在这里即可
    return userItem[key]
  },
  set(target: any, key: any, value: any): boolean {
    let userItem = JSON.parse(localStorage.getItem('userInfo') as string)
    if (userItem == null) {
      userItem = target
    }
    //如果是设置userId的话 因为代表不同账户 说明要初始化值 //其余随意赋值
    if (key == 'userId') {
      localStorage.clear()
    }
    userItem[key] = value
    localStorage.setItem('userInfo', JSON.stringify(userItem))
    return true
  },
})

export { $storageUser }
