
  import { useStore } from 'vuex'
  import EditTermModal from './modal/editTerm.vue'
  import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    reactive,
    toRefs,
    watch,
  } from 'vue'
  import { message } from 'ant-design-vue'
  import { libEntrySearch, getYouDaoTrans } from '@/http/api'
  import {
    RightOutlined,
    LeftOutlined,
    InfoCircleOutlined,
  } from '@ant-design/icons-vue'
  // import term from "./lib/term.vue";
  // import corpus from "./lib/corpus.vue";
  import empty from '@/components/empty/empty.vue'
  import { useI18n } from 'vue-i18n'
  import useUtils from '@/plugins/utils'
  import useTis from '@/components/right_toolbar/tabs/useTip'

  export default defineComponent({
    components: {
      EditTermModal,
      InfoCircleOutlined,
      RightOutlined,
      LeftOutlined,
      // term,
      // corpus,
      empty,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const { dateFormat } = useUtils()
      const { del, apply } = useTis()
      const state = reactive({
        taskDetail: store.state.taskDetail,
        searchType: 2,
        keywords: computed({
          set: (val) => {
            store.commit('changeKey', {
              searchWordText: val,
            })
          },
          get: () => store.state.searchWordText,
        }),
        entryList: [] as any[],
        corpusList: [] as any[],
        searchData: {},
        editTermVisible: false,
        termDetail: {} as any,
        tabsValue: 1,
        term: true,
        corpus: true,
      })
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })

      function getLibEntrySearch(libType: number) {
        state.entryList = []
        state.corpusList = []

        libEntrySearch({
          libType: libType,
          key: state.keywords,
          searchType: state.searchType,
          sourceLangId: state.taskDetail.sourceLangId,
          targetLangId: state.taskDetail.targetLangId,
          pageNum: 1,
          pageSize: 20,
        }).then((res) => {
          if (res.data.result == '1') {
            const list = res.data.data.list

            if (libType === 1) {
              // list.forEach((item1: any) => {
              //   item1.entryList.forEach((item2: any) => {
              //     item2.libraryName = item1.libraryName;
              //     entryList.push(item2);
              //   });
              // });
              state.entryList = [...list]
            } else if (libType === 2) {
              // list.forEach((item1: any) => {
              //   item1.entryList.forEach((item2: any) => {
              //     item2.libraryName = item1.libraryName;
              //     corpusList.push(item2);
              //   });
              // });
              state.corpusList = [...list]
            }
          }
        })
      }

      function youDaoTrans(text: string) {
        if (!text) {
          return
        }
        getYouDaoTrans({
          text: text,
        }).then((res) => {
          if (res.data.result == '1') {
            state.searchData = res.data.data
          }
        })
      }

      const openCorpus = () => {
        state.corpus = !state.corpus
      }

      const openTerm = () => {
        state.term = !state.term
      }

      const onTabChange = () => {
        if (state.tabsValue == 2) {
          youDaoTrans(state.keywords)
        }
      }

      const onSearch = (e?: any, r?: any) => {
        if (r) {
          r.stopPropagation && r.stopPropagation();
        }
        //键盘enter直接进入，否侧要判断是点击搜索还是清空
        if (!r.keyCode) {
          //清空
          if (r.target && r.target.tagName == 'INPUT') {
            return
          }
        }
        if (!state.keywords) {
          message.error('请输入搜索内容')
          return
        }
        getLibEntrySearch(1)
        getLibEntrySearch(2)
        if (state.tabsValue == 2) {
          youDaoTrans(state.keywords)
        }

        //神策埋点-查词
        // window.sensors.track("PEPCWebCATWordSearch", {
        //   from_type: "PE-PC"
        // });
      }

      const editTerm = (item: any) => {
        nextTick(() => {
          state.termDetail = item
          state.editTermVisible = true
        })
      }

      const retranslation = (item) => {
        if (!store.state.taskDetail.newTwinslatorId) {
          message.warning(
            '当前并未设置机翻引擎，无法进行术语重译功能，请先在翻译设置中设置引擎'
          )
          return
        }
        store.commit('modal/changeKey', {
          retranslationTermExplain: {
            visible: true,
            termSource: item.source,
            termTarget: item.target,
          },
        })
      }

      const toLsyy = () => {
        store.commit('rightToolbar/changeKey', {
          toolbarNav: {
            tbNavActiveIndex: 0,
          },
        })
        state.entryList = []
        state.corpusList = []
      }

      const toolbarNav = computed(() => store.state.rightToolbar.toolbarNav)
      // state.keywords = toolbarNav.value.searchValue
      if (state.keywords){
        onSearch('', '')
      }

      onMounted(() => {
        document.getElementById('toolbar-search-input').focus()
      })

      return {
        openCorpus,
        openTerm,
        onTabChange,
        retranslation,
        editTerm,
        disabled: computed(() => store.state.openEditSourceText),
        apply,
        checkedDetail,
        dateFormat,
        t,
        ...toRefs(state),
        onSearch,
        toLsyy,
      }
    },
  })
