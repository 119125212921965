<template>
  <a-tooltip>
    <template #title>
      该句有{{ infoList.length }}类提示：
      <span v-if="infoList.includes('TERM_CONSISTENCY_CHECK')">术语漏译，</span>
      <span v-if="infoList.includes('SYNTAX_CHECK')">语法词法错误，</span>
      <span v-if="infoList.includes('FORMAT_CHECK')">标签错误，</span>
      <span v-if="infoList.includes('SPELL_CHECK')">拼写错误，</span>
      <span v-if="infoList.includes('RULE_LOWE_CHECK')">低错检查，</span>
      点击可查看QA详情
    </template>
    <div style="text-align: center;" class="qaInfo-tag">
      <span v-if="infoList.includes('TERM_CONSISTENCY_CHECK')">
        术
        <label>/</label>
      </span>
      <span v-if="infoList.includes('SYNTAX_CHECK')">
        语
        <label>/</label>
      </span>
      <span v-if="infoList.includes('FORMAT_CHECK')">
        标
        <label>/</label>
      </span>
      <span v-if="infoList.includes('SPELL_CHECK')">
        拼
        <label>/</label>
      </span>
      <span v-if="infoList.includes('RULE_LOWE_CHECK')">
        低
        <label>/</label>
      </span>
    </div>
  </a-tooltip>

  <!--SPELL_CHECK: "拼写错误",-->
  <!--RULE_LOWE_CHECK: "低错检查",-->
  <!--TERM_CONSISTENCY_CHECK: "术语漏译",-->
  <!--FORMAT_CHECK: "标签错误",-->
  <!--SYNTAX_CHECK: "语法词法错误"-->
</template>

<script setup>
  import {
    defineComponent,
    onMounted,
    watch,
    ref,
    nextTick,
    computed,
  } from 'vue'
  const props = defineProps(['item'])
  const infoList = computed(() => {
    return props.item.qaCheckRules
  })
</script>

<style scoped lang="less">
  .qaInfo-tag {
    span {
      color: #86909c;
      label {
        cursor: pointer;
        color: #e5e6eb;
      }
    }
    span:last-child {
      label {
        display: none;
      }
    }
  }
</style>
