import '../config'
import axios from 'axios'
import md5 from 'js-md5'
import store from '../store/index'
import { createI18n } from 'vue-i18n' //国际化
import { getQueryString } from '@/plugins/prototype'
import { Modal, message } from 'ant-design-vue'
import qs from 'qs'

const win = window
// location.origin.indexOf("local.dev.wordpower.xin")>-1?'https://webcat-dl.dev.wordpower.xin':location.origin
// 创建axios实例
const service = axios.create({
  baseURL: '/api',
  timeout: 60000, // 请求超时时间
  withCredentials: true, // 跨域
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    const ck = getQueryString('ck')
    config.headers[
      'Authorization'
    ] = `Basic d2ViY2F0LXNlcnZlcjplNmx0b2plRlVVRG0=`
    config.headers['ck'] = ck

    if (config.method == 'get') {
      let newObj = {}
      for (let key in config.data) {
        if (config.data[key]) {
          newObj[key] = config.data[key]
        }
      }
      config.url = `${config.url}?${qs.stringify(newObj)}`
      // conn
    }

    for (let key in config.data) {
      if (
        config.data[key] === '' ||
        config.data[key] === null ||
        config.data[key] === undefined
      ) {
        delete config.data[key]
      }
    }
    // config.headers["Content-Type"]="application/json";
    if (config.headers['Content-Type'] == 'application/json') {
      // config.data = qs.parse(qs.stringify(config.data));
      return config
    }

    config.data = qs.stringify(config.data)
    return config
  },
  (err) => {
    // 接口请求出错可在此处统一处理
    return Promise.resolve(err)
  }
)

// respone拦截器
service.interceptors.response.use(
  (data) => {
    // 数据统一校验处理
    const res = data.data
    if (res.errorCode == '420021') {
      //添加术语的时候检查是否重复术语 此处前端做的拦截处理
      return res
    } else if (res.errorCode == '000005') {
      store.commit('modal/changeKey', {
        tokenErrdialogVisible: true,
      })
      return false
    } else if (res.errorCode == '510013') {
      //关闭连续机翻按钮
      store.commit('changeKey', {
        continuedMt: false,
      })
      //关闭连续机翻按钮
      store.commit('modal/changeKey', {
        balanceNoEnoughVisible: true,
      })
      return false
    } else if (res.errorCode == '510012') {
      //关闭连续机翻按钮
      store.commit('changeKey', {
        continuedMt: false,
      })
      store.commit('modal/changeKey', {
        PMLimitVisible: true,
      })
      return false
    } else if (res.errorCode == '000008') {
      window.$levelMessage({
        msg: res.errorMsg,
      })
      return false
    } else if (res.errorCode == '510010') {
      //小译被关闭
      store.dispatch('getTaskDetail')
      message.error(res.errorMsg)
      return false
    } else if (res.errorCode == '510005') {
      Modal.confirm({
        title: '提示',
        content: res.errorMsg,
        okText: '去充值',
        cancelText: '取消',
        centered: true,
        onOk() {
          store.commit('changeKey', {
            rechargeVisible: true,
          })
        },
      })
      return false
    } else if (res.errorCode == '610101') {
      Modal.confirm({
        title: '提示',
        content: res.errorMsg,
        okText: '去充值',
        cancelText: '取消',
        centered: true,
        onOk() {
          store.commit('changeKey', {
            rechargeVisible: true,
          })
        },
      })
      return false
    } else if (res.errorCode) {
      message.error(res.errorMsg)
      return false
    }
    return data
  },
  (err) => {
    const headers = err.response?.headers || {}
    const traceId = headers['X-Trace-Id'] || headers['x-trace-id'] || ''
    const strTraceId = traceId ? `【${traceId}】` : ''
    if (err.response.status >= 500) {
      message.error(
        (err.response.data.message || '服务器或网络异常，请稍后重试') +
          strTraceId
      )
      return Promise.reject(err)
    }

    message.error(`服务器或网络异常，请稍后重试`)
    // return Promise.reject(err);
  }
)

export default service
