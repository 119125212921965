<script setup>
import {onBeforeMount,reactive} from "vue";
import {CloseOutlined} from "@ant-design/icons-vue"
const state = reactive({
  checked:false,
  visible:false,
})

const ok=()=>{
  console.log(state.checked);
  if (state.checked) {
    localStorage.setItem("app-notification",1)
  } else {
    localStorage.removeItem("app-notification")
  }
  state.visible = false;
}

const cancel=()=>{
  state.visible = false;
}

onBeforeMount(()=>{
  //检查时间是否出现
  // 查看当前时间戳是否小于明天6点前
  // 1726005600000
  const timestamp = new Date().getTime();
  if (timestamp<1726005600000 && !localStorage.getItem("app-notification")) {
    state.visible = true
  }
})
</script>

<template>
<div class="app-notification" v-show="state.visible">
  <header>
    <div class="left">
      <img src="./images/tip.png" width="16">
      <b>重要通知</b>
    </div>
    <div>
      <CloseOutlined  style="color:#000;" @click="cancel"/>
    </div>
  </header>
  <div class="content">
    于北京时间 <b style="color:#00B4FA">2024年9月11日（明天）00:00-06:00</b>，进行运营商专线升级，期间平台会有服务不稳定现象。
    为不影响您的工作进度，请提前安排好项目时间。给您造成不便敬请谅解，感谢您的支持与信任！
  </div>
  <footer>
    <div class="left">
      <a-checkbox v-model:checked="state.checked"><span style="color:#666">不再提醒</span></a-checkbox>
    </div>
    <div class="right">
      <a-button type="primary" @click="ok">我知道了</a-button>
    </div>
  </footer>
</div>
</template>

<style scoped lang="less">
.app-notification{
  position: fixed;
  bottom: 0;
  right: 0;
  background: linear-gradient( 180deg, #EAF3FF 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.12);
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  z-index: 99999;
  header {
    display: flex;
    justify-content:space-between;
    align-items: center;
    .left{
      display: flex;
      align-items: center;
      img{
        position: relative;
        top: 1px;
        margin-right: 8px;
      }
      b{
        color:#333;
        font-size: 14px;
      }
    }
    .right{
      cursor: pointer;
    }
  }

  .content{
    margin-top: 12px;
    color:#333;
    font-size: 14px;
    line-height: 20px;
  }

  footer{
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-top: 12px;
    .left{

    }
  }
}
</style>