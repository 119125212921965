<template>
  <div>
    <ElScrollbar
      class="full-list-height"
      style="margin-bottom: 8px"
      v-if="qaList.length"
      ref="QAScrollRef"
    >
      <listItem
        :qaList="qaList"
        :type="'full'"
        @ignoreClick="ignoreClick"
      ></listItem>
    </ElScrollbar>
    <div style="text-align: right;padding-bottom: 7px">
      <a-pagination
        v-model:current="pageNum"
        :total="total"
        size="small"
        :pageSize="20"
        :hideOnSinglePage="true"
        @change="onPageChange"
      />
    </div>
    <div
      class="log-list-empty full-list-empty-height"
      v-if="qaList.length === 0"
    >
      <empty description="暂无QA检查提示哦！" />
    </div>
  </div>
</template>

<script>
  import {positionSentence} from "@/plugins/comm";
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import {
    computed,
    getCurrentInstance,
    reactive,
    ref,
    toRefs,
    onMounted,
  } from 'vue'
  import useProject from '@/components/source/useProject'
  import useUtils from '@/plugins/utils'
  import { allSentenceLogs, ignoreQAErrorApi } from '@/http/api'
  import empty from '@/components/empty/empty.vue'
  import listItem from './listItem.vue'

  export default {
    name: 'full',
    props: ['checkRules', 'status'],
    components: {
      empty,
      listItem,
    },
    setup(props, { emit }) {
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        pageNum: 1,
        qaList: [],
        qaIndex: 0,
        total: 0,
      });
      const QAScrollRef = ref(null);

      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex] || {}
      }

      const httpAllSentenceLogs = () => {
        allSentenceLogs({
          pageSize: 20,
          pageIndex: state.pageNum,
          sentenceListSize: store.state.pageSize,
        })
          .then((res) => {
            const result = res.data.data
            state.qaList = [...result.data]
            //QAlIST 需要根据hash 过滤节点；
            state.total = result.total
            emit('update:fullNumber', state.total)

            //需要没数据自动跳页
            if (state.pageNum > 1 && result.data.length == 0) {
              state.pageNum = state.pageNum - 1
              httpAllSentenceLogs()
            }
          })
          .catch((err) => {})
      }

      const onPageChange = (page) => {
        QAScrollRef.value.setScrollTop(0);
        state.pageNum = page
        httpAllSentenceLogs()
      }

      const toSentence = (item) => {
        // 还需要去做选中 错误的位置高亮
        positionSentence(item.sentId);
      }

      const listClick = (item, index) => {
        state.qaIndex = item.sentId
        // console.log(item,index)
        if (store.state.excelProject.ArrMap.has(item.sentId)) {
          toSentence(item)
          return
        }

        store.commit('changeKey', {
          pageNum: item.pageIndex,
        })
        store.commit('excelProject/changeKey', {
          sourceText: '',
          targetText: '',
        })
        store.commit('excelProject/resetFilterValue')
        store.dispatch('getSentence').then(() => {
          toSentence(item)
        }) //请求译文
      }
      //忽略，恢复

      const ignoreClick = () => {
        httpAllSentenceLogs()
      }

      onMounted(() => {
        httpAllSentenceLogs()
      })
      return {
        QAScrollRef,
        ignoreClick,
        onPageChange,
        httpAllSentenceLogs,
        ...toRefs(state),
        listClick,
      }
    },
  }
</script>

<style scoped lang="less">
  .log-list-empty {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .full-list-height {
    height: calc(100vh - 200px);
  }
  .full-list-empty-height {
    height: calc(100vh - 180px);
  }
  //.qa-box {
  //  .qa-list {
  //    position: relative;
  //    margin-bottom: 12px;
  //    padding: 12px;
  //    border-radius: 6px;
  //    word-break: break-all;
  //
  //    &.not {
  //      background: rgba(0, 198, 243, 0.08);
  //    }
  //
  //    &.yes {
  //      background: #f6f6f6;
  //    }
  //
  //    &.is-active {
  //      border: 1px solid #00c6f3;
  //    }
  //
  //    &:last-child {
  //      margin-bottom: 0;
  //    }
  //
  //  }
  //}
</style>
