import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-420ac0a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    columns: _ctx.columns,
    "data-source": $props.dataSource,
    rowKey: (record) => record.sentId,
    pagination: false,
    bordered: false,
    scroll: { y: 300 }
  }, {
    extSentId: _withCtx(({text}) => [
      _createTextVNode(_toDisplayString(text), 1)
    ]),
    source: _withCtx(({text, record}) => [
      _createElementVNode("div", {
        class: "col-source text-ellipsis-1",
        onClick: ($event: any) => ($setup.handleClick(record))
      }, _toDisplayString(text), 9, _hoisted_1)
    ]),
    _: 1
  }, 8, ["columns", "data-source", "rowKey"]))
}