export default function buildCssText1(){
  return `.target-language-text-1 .target-language-text-style [src*="L1"] {
  position: relative;
  background-image: url('/tri/1-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R1"] {
  position: relative;
  background-image: url('/tri/1-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs1_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re1_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m1_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L2"] {
  position: relative;
  background-image: url('/tri/2-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R2"] {
  position: relative;
  background-image: url('/tri/2-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs2_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re2_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m2_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L3"] {
  position: relative;
  background-image: url('/tri/3-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R3"] {
  position: relative;
  background-image: url('/tri/3-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs3_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re3_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m3_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L4"] {
  position: relative;
  background-image: url('/tri/4-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R4"] {
  position: relative;
  background-image: url('/tri/4-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs4_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re4_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m4_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L5"] {
  position: relative;
  background-image: url('/tri/5-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R5"] {
  position: relative;
  background-image: url('/tri/5-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs5_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re5_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m5_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L6"] {
  position: relative;
  background-image: url('/tri/6-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R6"] {
  position: relative;
  background-image: url('/tri/6-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs6_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re6_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m6_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L7"] {
  position: relative;
  background-image: url('/tri/7-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R7"] {
  position: relative;
  background-image: url('/tri/7-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs7_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re7_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m7_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L8"] {
  position: relative;
  background-image: url('/tri/8-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R8"] {
  position: relative;
  background-image: url('/tri/8-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs8_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re8_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m8_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L9"] {
  position: relative;
  background-image: url('/tri/9-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R9"] {
  position: relative;
  background-image: url('/tri/9-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs9_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re9_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m9_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L10"] {
  position: relative;
  background-image: url('/tri/10-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R10"] {
  position: relative;
  background-image: url('/tri/10-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs10_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re10_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m10_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L11"] {
  position: relative;
  background-image: url('/tri/11-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R11"] {
  position: relative;
  background-image: url('/tri/11-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs11_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re11_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m11_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L12"] {
  position: relative;
  background-image: url('/tri/12-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R12"] {
  position: relative;
  background-image: url('/tri/12-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs12_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re12_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m12_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L13"] {
  position: relative;
  background-image: url('/tri/13-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R13"] {
  position: relative;
  background-image: url('/tri/13-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs13_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re13_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m13_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L14"] {
  position: relative;
  background-image: url('/tri/14-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R14"] {
  position: relative;
  background-image: url('/tri/14-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs14_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re14_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m14_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L15"] {
  position: relative;
  background-image: url('/tri/15-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R15"] {
  position: relative;
  background-image: url('/tri/15-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs15_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re15_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m15_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L16"] {
  position: relative;
  background-image: url('/tri/16-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R16"] {
  position: relative;
  background-image: url('/tri/16-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs16_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re16_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m16_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L17"] {
  position: relative;
  background-image: url('/tri/17-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R17"] {
  position: relative;
  background-image: url('/tri/17-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs17_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re17_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m17_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L18"] {
  position: relative;
  background-image: url('/tri/18-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R18"] {
  position: relative;
  background-image: url('/tri/18-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs18_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re18_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m18_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L19"] {
  position: relative;
  background-image: url('/tri/19-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R19"] {
  position: relative;
  background-image: url('/tri/19-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs19_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re19_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m19_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L20"] {
  position: relative;
  background-image: url('/tri/20-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R20"] {
  position: relative;
  background-image: url('/tri/20-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs20_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re20_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m20_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L21"] {
  position: relative;
  background-image: url('/tri/21-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R21"] {
  position: relative;
  background-image: url('/tri/21-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs21_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re21_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m21_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L22"] {
  position: relative;
  background-image: url('/tri/22-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R22"] {
  position: relative;
  background-image: url('/tri/22-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs22_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re22_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m22_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L23"] {
  position: relative;
  background-image: url('/tri/23-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R23"] {
  position: relative;
  background-image: url('/tri/23-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs23_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re23_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m23_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L24"] {
  position: relative;
  background-image: url('/tri/24-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R24"] {
  position: relative;
  background-image: url('/tri/24-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs24_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re24_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m24_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L25"] {
  position: relative;
  background-image: url('/tri/25-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R25"] {
  position: relative;
  background-image: url('/tri/25-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs25_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re25_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m25_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L26"] {
  position: relative;
  background-image: url('/tri/26-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R26"] {
  position: relative;
  background-image: url('/tri/26-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs26_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re26_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m26_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L27"] {
  position: relative;
  background-image: url('/tri/27-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R27"] {
  position: relative;
  background-image: url('/tri/27-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs27_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re27_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m27_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L28"] {
  position: relative;
  background-image: url('/tri/28-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R28"] {
  position: relative;
  background-image: url('/tri/28-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs28_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re28_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m28_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L29"] {
  position: relative;
  background-image: url('/tri/29-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R29"] {
  position: relative;
  background-image: url('/tri/29-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs29_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re29_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m29_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [src*="L30"] {
  position: relative;
  background-image: url('/tri/30-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.target-language-text-1 .target-language-text-style [src*="R30"] {
  position: relative;
  background-image: url('/tri/30-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.target-language-text-1 .target-language-text-style [id*="rs30_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="re30_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.target-language-text-1 .target-language-text-style [id*="m30_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L1"] {
  position: relative;
  background-image: url('/tri/1-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R1"] {
  position: relative;
  background-image: url('/tri/1-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs1_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re1_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m1_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L2"] {
  position: relative;
  background-image: url('/tri/2-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R2"] {
  position: relative;
  background-image: url('/tri/2-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs2_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re2_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m2_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L3"] {
  position: relative;
  background-image: url('/tri/3-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R3"] {
  position: relative;
  background-image: url('/tri/3-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs3_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re3_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m3_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L4"] {
  position: relative;
  background-image: url('/tri/4-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R4"] {
  position: relative;
  background-image: url('/tri/4-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs4_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re4_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m4_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L5"] {
  position: relative;
  background-image: url('/tri/5-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R5"] {
  position: relative;
  background-image: url('/tri/5-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs5_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re5_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m5_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L6"] {
  position: relative;
  background-image: url('/tri/6-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R6"] {
  position: relative;
  background-image: url('/tri/6-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs6_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re6_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m6_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L7"] {
  position: relative;
  background-image: url('/tri/7-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R7"] {
  position: relative;
  background-image: url('/tri/7-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs7_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re7_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m7_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L8"] {
  position: relative;
  background-image: url('/tri/8-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R8"] {
  position: relative;
  background-image: url('/tri/8-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs8_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re8_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m8_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L9"] {
  position: relative;
  background-image: url('/tri/9-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R9"] {
  position: relative;
  background-image: url('/tri/9-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs9_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re9_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m9_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L10"] {
  position: relative;
  background-image: url('/tri/10-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R10"] {
  position: relative;
  background-image: url('/tri/10-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs10_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re10_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m10_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L11"] {
  position: relative;
  background-image: url('/tri/11-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R11"] {
  position: relative;
  background-image: url('/tri/11-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs11_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re11_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m11_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L12"] {
  position: relative;
  background-image: url('/tri/12-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R12"] {
  position: relative;
  background-image: url('/tri/12-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs12_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re12_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m12_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L13"] {
  position: relative;
  background-image: url('/tri/13-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R13"] {
  position: relative;
  background-image: url('/tri/13-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs13_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re13_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m13_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L14"] {
  position: relative;
  background-image: url('/tri/14-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R14"] {
  position: relative;
  background-image: url('/tri/14-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs14_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re14_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m14_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L15"] {
  position: relative;
  background-image: url('/tri/15-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R15"] {
  position: relative;
  background-image: url('/tri/15-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs15_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re15_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m15_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L16"] {
  position: relative;
  background-image: url('/tri/16-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R16"] {
  position: relative;
  background-image: url('/tri/16-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs16_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re16_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m16_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L17"] {
  position: relative;
  background-image: url('/tri/17-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R17"] {
  position: relative;
  background-image: url('/tri/17-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs17_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re17_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m17_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L18"] {
  position: relative;
  background-image: url('/tri/18-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R18"] {
  position: relative;
  background-image: url('/tri/18-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs18_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re18_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m18_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L19"] {
  position: relative;
  background-image: url('/tri/19-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R19"] {
  position: relative;
  background-image: url('/tri/19-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs19_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re19_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m19_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L20"] {
  position: relative;
  background-image: url('/tri/20-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R20"] {
  position: relative;
  background-image: url('/tri/20-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs20_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re20_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m20_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L21"] {
  position: relative;
  background-image: url('/tri/21-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R21"] {
  position: relative;
  background-image: url('/tri/21-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs21_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re21_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m21_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L22"] {
  position: relative;
  background-image: url('/tri/22-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R22"] {
  position: relative;
  background-image: url('/tri/22-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs22_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re22_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m22_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L23"] {
  position: relative;
  background-image: url('/tri/23-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R23"] {
  position: relative;
  background-image: url('/tri/23-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs23_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re23_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m23_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L24"] {
  position: relative;
  background-image: url('/tri/24-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R24"] {
  position: relative;
  background-image: url('/tri/24-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs24_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re24_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m24_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L25"] {
  position: relative;
  background-image: url('/tri/25-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R25"] {
  position: relative;
  background-image: url('/tri/25-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs25_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re25_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m25_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L26"] {
  position: relative;
  background-image: url('/tri/26-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R26"] {
  position: relative;
  background-image: url('/tri/26-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs26_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re26_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m26_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L27"] {
  position: relative;
  background-image: url('/tri/27-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R27"] {
  position: relative;
  background-image: url('/tri/27-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs27_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re27_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m27_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L28"] {
  position: relative;
  background-image: url('/tri/28-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R28"] {
  position: relative;
  background-image: url('/tri/28-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs28_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re28_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m28_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L29"] {
  position: relative;
  background-image: url('/tri/29-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R29"] {
  position: relative;
  background-image: url('/tri/29-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs29_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re29_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m29_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [src*="L30"] {
  position: relative;
  background-image: url('/tri/30-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.source-language-text-1 .source-language-text-style [src*="R30"] {
  position: relative;
  background-image: url('/tri/30-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.source-language-text-1 .source-language-text-style [id*="rs30_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="re30_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.source-language-text-1 .source-language-text-style [id*="m30_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L1"] {
  position: relative;
  background-image: url('/tri/1-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R1"] {
  position: relative;
  background-image: url('/tri/1-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs1_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re1_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m1_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA1.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L2"] {
  position: relative;
  background-image: url('/tri/2-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R2"] {
  position: relative;
  background-image: url('/tri/2-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs2_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re2_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m2_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L3"] {
  position: relative;
  background-image: url('/tri/3-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R3"] {
  position: relative;
  background-image: url('/tri/3-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs3_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re3_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m3_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L4"] {
  position: relative;
  background-image: url('/tri/4-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R4"] {
  position: relative;
  background-image: url('/tri/4-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs4_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re4_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m4_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA4.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L5"] {
  position: relative;
  background-image: url('/tri/5-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R5"] {
  position: relative;
  background-image: url('/tri/5-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs5_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re5_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m5_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA5.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L6"] {
  position: relative;
  background-image: url('/tri/6-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R6"] {
  position: relative;
  background-image: url('/tri/6-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs6_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re6_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m6_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA6.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L7"] {
  position: relative;
  background-image: url('/tri/7-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R7"] {
  position: relative;
  background-image: url('/tri/7-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs7_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re7_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m7_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA7.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L8"] {
  position: relative;
  background-image: url('/tri/8-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R8"] {
  position: relative;
  background-image: url('/tri/8-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs8_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re8_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m8_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA8.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L9"] {
  position: relative;
  background-image: url('/tri/9-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R9"] {
  position: relative;
  background-image: url('/tri/9-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs9_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re9_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m9_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA9.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L10"] {
  position: relative;
  background-image: url('/tri/10-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R10"] {
  position: relative;
  background-image: url('/tri/10-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs10_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re10_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m10_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA10.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L11"] {
  position: relative;
  background-image: url('/tri/11-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R11"] {
  position: relative;
  background-image: url('/tri/11-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs11_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re11_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m11_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA11.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L12"] {
  position: relative;
  background-image: url('/tri/12-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R12"] {
  position: relative;
  background-image: url('/tri/12-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs12_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re12_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m12_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA12.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L13"] {
  position: relative;
  background-image: url('/tri/13-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R13"] {
  position: relative;
  background-image: url('/tri/13-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs13_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re13_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m13_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA13.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L14"] {
  position: relative;
  background-image: url('/tri/14-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R14"] {
  position: relative;
  background-image: url('/tri/14-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs14_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re14_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m14_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA14.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L15"] {
  position: relative;
  background-image: url('/tri/15-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R15"] {
  position: relative;
  background-image: url('/tri/15-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs15_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re15_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m15_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA15.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L16"] {
  position: relative;
  background-image: url('/tri/16-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R16"] {
  position: relative;
  background-image: url('/tri/16-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs16_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re16_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m16_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA16.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L17"] {
  position: relative;
  background-image: url('/tri/17-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R17"] {
  position: relative;
  background-image: url('/tri/17-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs17_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re17_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m17_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA17.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L18"] {
  position: relative;
  background-image: url('/tri/18-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R18"] {
  position: relative;
  background-image: url('/tri/18-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs18_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re18_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m18_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA18.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L19"] {
  position: relative;
  background-image: url('/tri/19-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R19"] {
  position: relative;
  background-image: url('/tri/19-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs19_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re19_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m19_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA19.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L20"] {
  position: relative;
  background-image: url('/tri/20-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R20"] {
  position: relative;
  background-image: url('/tri/20-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs20_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re20_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m20_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA20.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L21"] {
  position: relative;
  background-image: url('/tri/21-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R21"] {
  position: relative;
  background-image: url('/tri/21-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs21_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re21_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m21_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA21.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L22"] {
  position: relative;
  background-image: url('/tri/22-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R22"] {
  position: relative;
  background-image: url('/tri/22-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs22_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re22_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m22_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA22.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L23"] {
  position: relative;
  background-image: url('/tri/23-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R23"] {
  position: relative;
  background-image: url('/tri/23-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs23_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re23_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m23_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA23.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L24"] {
  position: relative;
  background-image: url('/tri/24-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R24"] {
  position: relative;
  background-image: url('/tri/24-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs24_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re24_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m24_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA24.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L25"] {
  position: relative;
  background-image: url('/tri/25-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R25"] {
  position: relative;
  background-image: url('/tri/25-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs25_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re25_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m25_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA25.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L26"] {
  position: relative;
  background-image: url('/tri/26-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R26"] {
  position: relative;
  background-image: url('/tri/26-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs26_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re26_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m26_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA26.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L27"] {
  position: relative;
  background-image: url('/tri/27-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R27"] {
  position: relative;
  background-image: url('/tri/27-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs27_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re27_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m27_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA27.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L28"] {
  position: relative;
  background-image: url('/tri/28-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R28"] {
  position: relative;
  background-image: url('/tri/28-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs28_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re28_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m28_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA28.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L29"] {
  position: relative;
  background-image: url('/tri/29-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R29"] {
  position: relative;
  background-image: url('/tri/29-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs29_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re29_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m29_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA29.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [src*="L30"] {
  position: relative;
  background-image: url('/tri/30-tr.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding-right: 4px;
}
.img-language-style-1 [src*="R30"] {
  position: relative;
  background-image: url('/tri/30-tl.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 4px;
}
.img-language-style-1 [id*="rs30_"] {
  position: relative;
  background-image: url('/Nimg/NR/NR30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="re30_"] {
  position: relative;
  background-image: url('/Nimg/NL/NL30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.img-language-style-1 [id*="m30_"] {
  position: relative;
  background-image: url('/Nimg/NA/NA30.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
`
}