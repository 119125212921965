<template>
  <div class="talk-list padding-lr-16">
    <div v-for="(item, index) in listData" :key="index" class="list-item">
      <div
          class="talk-dialog font-size-13"
          v-if="item.dialogueType == 'commonTalk'"
      >
        <div class="display-flex justify-between align-center">
          <div class="font-size-13">{{ item.text }}</div>
          <div>
            <a-button @click="item.ok()" v-if="item.isButton">
              {{ item.okText }}
            </a-button>
          </div>
        </div>
      </div>
      <div v-else-if="item.dialogueType == 'UserInstruction'">
        <div class="list-item-right">
          <div class="talk-dialog-self">
            <div class="talk-dialog-self-question">
              {{ item.dialogueResult?.question }}
            </div>
          </div>
        </div>

        <!--//这是回答-->
        <div>
          <div
              class="loading talk-dialog"
              v-if="item.dialogueState == 'Processing'"
          >
            <a-spin :indicator="indicator"/>
          </div>
          <div class="talk-dialog font-size-13" v-else>
            {{ item.dialogueResult?.answer }}
          </div>
        </div>
      </div>
      <div
          class="loading talk-dialog"
          v-else-if="item.dialogueState == 'Processing'"
      >
        <a-spin :indicator="indicator"/>
      </div>
      <div class="talk-dialog" v-else>
        <!--这是添加术语对话-->
        <div v-if="item.isAddTerm">
          <div class="display-flex justify-between align-center margin-b-8">
            <div class="left font-size-13">
              📍 小译检测到您可能想添加的术语:
            </div>
            <div class="right"></div>
          </div>
          <div>
            <div class="display-flex align-center">
              <div class="term-edit-table">
                <div
                    class="table-item"
                    v-for="(citem, cindex) in item.termList"
                    :key="cindex"
                >
                  <div
                      class="item"
                      :class="{ lastItem: cindex == item.termList?.length - 1 }"
                  >
                    <div class="text">
                      <del
                          v-if="citem.isIgnore"
                          v-html="citem.sourceTerm"
                      ></del>
                      <a-input
                          v-model:value="citem.sourceTerm"
                          :disabled="item.disabled || item.isAdd"
                          placeholder="请输入原文"
                          size="small"
                          class="source-language-text-style"
                      />
                    </div>
                    <div class="text">
                      <del
                          v-if="citem.isIgnore"
                          v-html="citem.targetTerm"
                      ></del>
                      <a-input
                          v-model:value="citem.targetTerm"
                          :disabled="item.disabled || item.isAdd"
                          placeholder="请输入译文"
                          size="small"
                          class="target-language-text-style"
                      />
                    </div>
                  </div>
                  <span
                      class="font-size-14 margin-l-8 svg"
                      :class="{ isIgnore: citem.isIgnore || item.disabled }"
                      style="flex-shrink: 0;cursor: pointer;"
                      @click="ignoreTerm(citem, item)"
                      v-if="!citem.isIgnore && !citem.isAdd"
                  >
                    <a-tooltip title="忽略">
                      <MinusCircleOutlined/>
                    </a-tooltip>
                  </span>
                  <span
                      class="font-size-14 margin-l-8 svg"
                      :class="{ isIgnore: citem.isIgnore || item.disabled }"
                      style="flex-shrink: 0;cursor: pointer;"
                      @click="addTerm(citem, item)"
                      v-if="!citem.isIgnore && !citem.isAdd"
                  >
                    <a-tooltip title="添加">
                      <PlusCircleOutlined/>
                    </a-tooltip>
                  </span>
                  <span
                      class="font-size-14 margin-l-8 svg"
                      :class="{ isIgnore: citem.isIgnore || item.disabled }"
                      style="flex-shrink: 0;cursor: pointer;"
                      v-if="citem.isAdd"
                  >
                    <CheckCircleFilled style="color: #0ab923"/>
                  </span>
                </div>
              </div>
            </div>
            <div class="text-left">
              <N-button
                  size="small"
                  shape="round"
                  :disabled="item.disabled"
                  @click="ignoreTermList(item.termList, item)"
              >
                全部忽略
              </N-button>
              <span class="margin-r-10"></span>
              <N-button
                  size="small"
                  shape="round"
                  :disabled="item.disabled"
                  @click="addTermList(item.termList, item)"
              >
                全部添加
              </N-button>
            </div>
          </div>
        </div>
        <div class="answer" v-else>
          <div class="title">
            <div v-if="item.dialogueType == 'TranslationRecommend'">
              <label class="margin-r-2" style="position: relative;top:-1px">
                ✍️
              </label>
              <span v-if="item.dialogueResult.applyType == 'TermReplace'">
                小译通过
                <b style="color:#01b9e4">自主学习</b>
                ，为您生成的新译文：
              </span>
              <span v-else-if="item.dialogueResult.applyType == 'TMT'">
                小译通过
                <b style="color:#01b9e4">自主学习</b>
                ，为您生成的新译文：
              </span>
              <span v-else>
                小译通过
                <b style="color:#01b9e4">机翻引擎</b>
                ，为您输出的机翻译文：
              </span>
            </div>
            <div v-else-if="item.dialogueType == 'GptQualityCheck'">
              <label class="margin-r-2">🔍</label>
              质检评分：
              <label>{{ item.dialogueResult.score }}分</label>
              <span>（仅供参考）</span>
            </div>
            <div v-else-if="item.dialogueType == 'GptDecorate'">
              <label class="margin-r-2">✨</label>
              {{ item.dialogueResult.decorateDescription }}润色结果
            </div>
          </div>
          <div
              class="content target-txt AiItemResult target-language-text-style"
              v-html="$assignValueToSrc(item.dialogueResult.result ||
                item.dialogueResult.suggest ||
                item.dialogueResult.decorateResult ||
                '小译无法处理')
              "
          ></div>
          <div class="footer">
            <div class="right margin-l-4">
              <N-button
                  :disabled="checkedDetail.sentId != item.sentId"
                  size="small"
                  shape="round"
                  v-if="item.dialogueType != 'GptQualityCheck'"
                  @click="applyDialogueResult(item)"
              >
                应用
              </N-button>
            </div>
            <div class="left">
              <span v-if="item.dialogueType == 'GptQualityCheck'">
                已修改？可重新质检评分
              </span>
              <span v-if="item.freeRecommend">
                每日免费建议: {{ item.freeUsed }}/{{ item.freeTotal }}
              </span>
            </div>
          </div>
          <div v-if="item.freeRecommend && item.freeUsed == 9">
            <!--免费次数用完且未用完-->
            <div
                class="footer-supplement"
                v-if="!store.state.modal.autoRecommendChecked"
            >
              小译免费推荐译文即将结束，是否开启自动推荐
              <span @click="openSetting">【开启】</span>
            </div>
            <div class="footer-supplement" v-else>
              小译免费推荐译文即将结束，即将开始付费推荐
              <span @click="openSetting">【调整设置】</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  getCurrentInstance,
  h,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  defineProps,
  defineEmits,
} from 'vue'
import {
  getDialogueNotify,
  getIgnoreTerm,
  postAddTermList,
  postTranslationAdd,
} from '@/http/api'
import {useStore} from 'vuex'
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  CheckCircleFilled,
} from '@ant-design/icons-vue'
import filterString from '@/plugins/filterString'
import useTis from '@/components/right_toolbar/tabs/useTip'
import {message} from 'ant-design-vue'

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '18px',
  },
  spin: true,
})
const {apply} = useTis()
const store = useStore()
const state = reactive({})
const props = defineProps(['listData'])
const emits = defineEmits(['openSetting'])
const checkedDetail = computed(() => {
  return store.state.checkedDetail
})

const openSetting = () => {
  emits('openSetting')
}

const addTermList = (list, item) => {
  let newList = list.filter((item) => {
    if (!item.isIgnore && !item.isAdd) {
      item.isAdd = true
      return true
    }
  })
  if (newList.length == 0) {
    message.warning('暂无术语可添加')
    item.disabled = true
    return
  }

  item.disabled = true
  postAddTermList({
    docId: store.state.taskDetail.docId,
    terms: newList,
  }).then((res) => {
    message.success('添加成功')
  })
}

const ignoreTermList = (list, item) => {
  let newList = list.filter((item) => {
    if (!item.isIgnore && !item.isAdd) {
      return true
    }
  })
  if (newList.length == 0) {
    message.warning('暂无术语可忽略')
    item.disabled = true
    return
  }
  let ids = newList.map((item) => {
    item.isIgnore = true
    return item.id
  })

  // console.log(ids.join(","));

  item.disabled = true
  getIgnoreTerm({ids: ids.join(',')}).then((res) => {
    message.success('忽略成功')
  })
}

const applyDialogueResult = (item) => {
  let result = item.dialogueResult.result || item.dialogueResult.decorateResult || ''
  let applyItem = {
    targetPlaceholder: filterString.retainImportant(result),
    sentId: item.sentId,
  }
  // 接口
  apply(applyItem)
  if (
      item.dialogueType == 'TranslationRecommend' ||
      item.dialogueType == 'GptDecorate'
  ) {
    let params = {
      docId: item.docId,
      sentId: item.sentId,
      sourceText: item.sourceText || item.source,
      targetText: filterString.retainImportant(result),
      applyType: item.dialogueResult.applyType || 'ChatGPT',
      dialogueId: item.id,
    }
    postTranslationAdd(params)
  }
}

const addTerm = (citem, item) => {
  citem.isAdd = true
  let disabled = true
  item.termList.forEach((citem) => {
    if (!citem.isIgnore && !citem.isAdd) {
      disabled = false
    }
  })
  item.disabled = disabled
  postAddTermList({
    docId: store.state.taskDetail.docId,
    terms: [citem],
  })
}

const ignoreTerm = (citem, item) => {
  let disabled = true
  citem.isIgnore = true
  item.termList.forEach((citem) => {
    if (!citem.isIgnore && !citem.isAdd) {
      disabled = false
    }
  })
  item.disabled = disabled
  getIgnoreTerm({ids: citem.id})
}
</script>

<style scoped lang="less">
.talk-dialog {
  display: inline-block;
  padding: 8px 12px;
  background: linear-gradient(90deg, #d0f0ff 0%, #e4f5ff 100%);
  border-radius: 18px;
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  position: relative;

  &::before {
    content: url('BubbleAngle.png');
    background-size: cover;
    position: absolute;
    left: 2px;
    bottom: 4px;
    width: 10px;
    height: 10px;
    //background: url("./dialog.png");
    //background-size: 10px 10px;
  }
}

.talk-dialog-self {
  display: inline-block;
  padding: 8px 12px;
  background: linear-gradient(90deg, #00befa 0%, #74d4f9 100%);
  border-radius: 18px;
  box-shadow: 0 0 0 0;
  position: relative;
  text-align: left;

  &::before {
    content: url('BubbleAngle-right.png');
    background-size: cover;
    position: absolute;
    right: 2px;
    bottom: 4px;
    width: 10px;
    height: 10px;
    //background: url("./dialog.png");
    //background-size: 10px 10px;
  }

  .talk-dialog-self-question {
    color: #fff;
    font-size: 13px;
  }
}

.term-edit-table {
  //border-top:1px #DBF1F9 solid;
  //border-left: 1px #DBF1F9 solid;
  margin-bottom: 8px;

  .table-item {
    display: flex;
    align-items: center;

    .lastItem {
      border-bottom: 1px #dbf1f9 solid;
    }

    .item {
      display: flex;
      border-left: 1px #dbf1f9 solid;
      border-top: 1px #dbf1f9 solid;

      .text {
        border-right: 1px #dbf1f9 solid;
        position: relative;

        del {
          padding: 0 8px;
          background: #f5fcfe;
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 100%;
          line-height: 26px;
          color: #bbbbbb;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .ant-input {
        border-radius: 0;
        border: 1px transparent solid;
        font-size: 12px;
      }

      .ant-input:focus {
        border: 1px transparent solid;
        box-shadow: 0 0 0 0;
      }
    }

    .svg {
      color: #333333;

      &.isIgnore {
        color: #bbbbbb;
        pointer-events: none;
      }
    }
  }
}

.talk-list {
  .list-item-right {
    text-align: right;
    margin-bottom: 8px;
    word-break: break-all;
  }

  .list-item {
    margin-bottom: 8px;
    animation: fadeInUp;
    animation-duration: 0.5s;

    .loading {
      display: flex;
      align-items: center;
      width: 56px;

      .ant-spin {
        position: relative;
        top: 2px;
      }
    }

    .question {
    }

    .answer {
      .title {
        //height: 18px;
        font-size: 13px;
        color: #333333;
        margin-bottom: 4px;
      }

      .content {
        word-break: break-all;
        font-size: 13px;
        color: #333333;
        margin-bottom: 8px;
        white-space: pre-wrap;

        ::v-deep(span) {
          color: #078ed9;
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;

        .left {
          span {
            font-size: 12px;
            line-height: 18px;
            color: #bbbbbb;
          }
        }
      }

      .footer-supplement {
        font-size: 12px;
        color: #333333;
        margin-top: 6px;
        line-height: 18px;

        span {
          line-height: 18px;
          cursor: pointer;
          text-decoration: underline;
          color: #00befa;
        }
      }
    }
  }
}
</style>
