<script setup>
import {reactive,computed} from "vue";
import {SearchOutlined} from "@ant-design/icons-vue";
import {useStore} from "vuex";
const store = useStore();
const state = reactive({
  visible:false,
})
const serialNum = computed({
  set(val) {
    let reg = !isNaN(parseFloat(val)) && isFinite(val);
    if (reg && parseInt(val)>0) {
      store.commit('excelProject/changeKey', {
        serialNum: val,
      })
    } else {
      store.commit('excelProject/changeKey', {
        serialNum: '',
      })
    }
  },
  get() {
    return store.state.excelProject.serialNum
  },
})
// const searchSentIds=computed({
//   get
// })
// const search=(value,e)=>{
//   // console.log(e);
//   e.stopPropagation();
//   //调用getSentence
// }

let timer = null;
//改为为0的时候不再获取getSentence
const onChange=()=>{
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(()=>{
    store.commit('changeKey', {
      pageNum: 1,
    })
    store.commit('excelProject/changeKey', {
      sourceText: '',
      targetText: '',
    })
    store.commit('excelProject/resetFilterValue')
    store.dispatch("getSentence",{backTop:true,loading:true})
  },800)
}
</script>

<template>
  <a-dropdown v-model:visible="state.visible" placement="topCenter">
    <div class="display-flex align-center" style="cursor: pointer">
      句<SearchOutlined class="margin-l-2"></SearchOutlined>
    </div>
    <template #overlay>
      <a-input-search
          placeholder="搜索句对"
          style="width: 120px"
          allowClear
          v-model:value="serialNum"
          @change="onChange()"
      />
    </template>
  </a-dropdown>
</template>

<style scoped lang="less">

</style>