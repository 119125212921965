
  import differentModel from '@/components/header/components/differentModel/index.vue'
  import { computed, ComputedRef } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      differentModel,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })
      const checkedList: ComputedRef<any> = computed(
        () => store.state.excelProject.checkedList
      )
      return {
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
