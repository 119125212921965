//挂载在vue的全局方法
/**
 * @param text 原文
 * @returns {string}
 */
export const fontReplace = function(text: string): string {
  // let target = text.replace(/<span class="fontTxt">/g, "");
  // target = target.replace(/<span class="fontTxt add">/g, "");
  // target = target.replace(/<span class="fontTxt del">/g, "");
  // target = target.replace(/<span class="yello-bg">/g, "");
  // target = target.replace(/(<span id="transResTxt_\d+" class="trans-res-txt">)|(<span class="trans-res-txt" id="transResTxt_\d+">)/g, "");
  // target = target.replace(/(<span class="fontTxt unline" data-correct="\d+">)/g, "");
  // target = target.replace(/(<span style="font-size:([\s\S]*?)px;">)/g, "");
  // target = target.replace(/<\/span>/g, "");
  // target = target.replace(/<br>/g, "");
  // target = target.replace(/sup12f326e/g, "sup");
  // target = target.replace(/sub131f191/g, "sub");
  // target = target.replace(/&lt;b&gt;/g, "<b>");
  // target = target.replace(/&lt;\/b&gt;/g, "</b>");
  // target = target.replace(/&lt;i&gt;/g, "<i>");
  // target = target.replace(/&lt;\/i&gt;/g, "</i>");
  // target = target.replace(/&lt;u&gt;/g, "<u>");
  // target = target.replace(/&lt;\/u&gt;/g, "</u>");
  // target = target.replace(/(^\s*)|(\s*$)/g, "");
  // target = target.replace(/\s+/g, " ");
  // target = target.replace(/&nbsp;/g, " ");
  // target = target.replace(/&amp;/g, "&");
  return text
}

export const fontSupSubReplace = function(text: string) {
  // let target = text.replace(/<sup>/g, "<sup12f326e>");
  // target = target.replace(/<\/sup>/g, "</sup12f326e>");
  // target = target.replace(/<sub>/g, "<sub131f191>");
  // target = target.replace(/<\/sub>/g, "</sub131f191>");
  // target = target.replace(/<b><\/b>/g, "");
  // target = target.replace(/<i><\/i>/g, "");
  // target = target.replace(/<u><\/u>/g, "");
  // target = target.replace(/<sub131f191><\/sub131f191>/g, "");
  // target = target.replace(/<sup12f326e><\/sup12f326e>/g, "");
  // target = target.replace(/(<span style="font-size:([\s\S]*?)px;">)/g, "");
  // target = target.replace(/<span class="yello-bg">/g, "");
  // target = target.replace(/<\/span>/g, "");
  // target = target.replace(/\s+/g, " ");
  return text
}

/**
 * @param name 属性
 * @returns {string}
 */
export const getQueryString = function(name: string): string | null {
  const reg: any = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r: any = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

//获取来源，是个人任务还是小组，还是订单任务
export const setSensorsFrom = function(val: string | number): string | null {
  const actions: any = {
    1: 'personal_task',
    2: 'group_task',
    3: 'order_task',
  }
  return actions[val]
}
