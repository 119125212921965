
  import { computed, ComputedRef } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import { message } from 'ant-design-vue'
  import useProject from '@/components/source/useProject'
  import filterStrings from '@/plugins/filterString'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    setup() {
      const { t } = useI18n()
      const { confirmSentences, sendCommitTargetText } = useProject()
      const store = useStore()
      const taskDetail: any = computed(() => store.state.taskDetail)
      const checkedDetail: any = computed(() => {
        return store.state.checkedDetail
      })
      const checkedList: ComputedRef<any> = computed(
        () => store.state.excelProject.checkedList
      )
      const initCheckedList = () => {
        store.commit('excelProject/changeKey', {
          checkedAll: false,
          checkedList: [],
        })
        store.state.excelProject.docData.sentenceDTOList.forEach((item) => {
          item.checked = false
        })
      }
      const allConfirm = () => {
        if (
          !store.state.taskDetail.corpusLibrary?.length &&
          !localStorage.getItem(`${store.state.taskDetail.docId}`)
          && store.state.taskDetail.taskClassify  != 11
        ) {
          store.commit('modal/changeKey', {
            corpusRemindVisible: true,
          })
          return
        }
        if (checkedList.value.length === 0) {
          message.warning('请勾选句子')
          return
        }
        let newArr: Array<number> = []
        let noSaveList = checkedList.value.filter((res) => {
          return res.saveFailStatus
        })
        let confirmList = checkedList.value.filter((res) => {
          return !res.saveFailStatus
        })
        confirmList.forEach((res: any) => {
          newArr.push(res.sentId)
        })
        confirmSentences(newArr.join(','), true).then(() => {
          initCheckedList()
        })

        noSaveList.forEach((item) => {
          let innerText = filterStrings.getString(
            document.getElementById(item.targetDomId).innerHTML
          )
          store.dispatch('sentCommit', {
            docId: item.docId,
            sentId: item.sentId,
            saveFailStatus: true,
            target: filterStrings.getPureString(innerText),
            targetPlaceholder: innerText,
            sentIndex: item.sentIndex,
          })
        })
      }
      return {
        allConfirm,
        checkedList,
        t,
        checkedDetail,
        taskDetail,
      }
    },
  }
