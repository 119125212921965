<template>
  <div class="table-title-input" v-if="type == 'source'">
    <h6>
      原文：{{
        taskDetail.sourceLangId
          ? getLanguageName(taskDetail.sourceLangId, 'all')
          : ''
      }}
    </h6>
    <div class="table-title-input-search">
      <a-dropdown v-model:visible="state.visible" placement="topCenter">
        <div class="input-box">
          <a-input
              v-model:value="sourceText"
              @pressEnter="pressEnter"
              :disabled="disabled"
              @change="onChange(1)"
              allowClear
              size="small"
              placeholder="搜索原文"
              class="source-language-text-style"
              :maxlength="sourceChecked?100:500"
          >
          </a-input>
          <div class="input-addonAfter">
            <SearchOutlined />
          </div>
        </div>
        <template #overlay>
            <div class="padding-12" style="background: white;box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);border-radius: 6px" v-if="store.state.settings.enable_regex_search =='true'">
              <div class="font-size-12 margin-b-8" style="color: #bbb">搜索设置</div>
              <div>
                <a-checkbox v-model:checked="sourceChecked"><span class="font-size-12" style="color:#666" @change="onSourceCheckChange">正则表达式</span></a-checkbox>
              </div>
            </div>
        </template>
      </a-dropdown>
    </div>
  </div>
  <div class="table-title-input" v-else>
    <h6>
      译文：{{
        taskDetail.targetLangId
          ? getLanguageName(taskDetail.targetLangId, 'all')
          : ''
      }}
    </h6>
    <div class="table-title-input-search">
      <a-dropdown v-model:visible="state.visible" placement="topCenter">
        <div class="input-box">
          <a-input
              v-model:value="targetText"
              @pressEnter="pressEnter"
              :disabled="disabled"
              @change="onChange(2)"
              allowClear
              placeholder="搜索译文"
              size="small"
              class="target-language-text-style"
              :maxlength="targetChecked?100:500"
          >
            <!--          <template #addonAfter>-->
            <!--            <SearchOutlined />-->
            <!--          </template>-->
          </a-input>
          <div class="input-addonAfter">
            <SearchOutlined />
          </div>
        </div>
        <template #overlay>
          <div class="padding-12" style="background: white;box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);border-radius: 6px" v-if="store.state.settings.enable_regex_search =='true'">
            <div class="font-size-12 margin-b-8" style="color: #bbb">搜索设置</div>
            <div>
              <a-checkbox v-model:checked="targetChecked"><span class="font-size-12" style="color:#666" @change="onTargetCheckChange">正则表达式</span></a-checkbox>
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
  <slot name="extra"></slot>
</template>

<script>
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import useUtils from '@/plugins/utils'
  import { computed, ref, getCurrentInstance,reactive } from 'vue'
  import { SearchOutlined } from '@ant-design/icons-vue'
  import { getLastModifiedSent } from '@/http/api'
  import { positionSentence } from '@/plugins/comm'

  export default {
    props: {
      type: {
        type: String,
        default: 'source',
      },
    },
    components: {
      SearchOutlined,
    },
    setup() {
      const { appContext } = getCurrentInstance()
      const { t } = useI18n()
      const store = useStore()
      const { getLanguageName } = useUtils()
      const taskDetail = computed(() => store.state.taskDetail);
      const state = reactive({
        visible:false
      })


      const sourceChecked=computed({
        set: (val) => {
          // console.log(val)
          store.commit("excelProject/changeKey",{
            sourceRegexSearch:val
          })
        },
        get: () => store.state.excelProject.sourceRegexSearch,
      })

      const targetChecked=computed({
        set: (val) => {
          store.commit("excelProject/changeKey",{
            targetRegexSearch:val
          })
        },
        get: () => store.state.excelProject.targetRegexSearch,
      })

      const targetText = computed({
        set: (val) => {
          store.commit('excelProject/changeKey', {
            targetText: val,
          })
        },
        get: () => store.state.excelProject.targetText,
      })
      const sourceText = computed({
        set: (val) => {
          store.commit('excelProject/changeKey', {
            sourceText: val,
          })
        },
        get: () => store.state.excelProject.sourceText,
      })

      const getFilterNumber = () => {
        // store.state.excelProject.adminTargetStatusSet
        let num = 0
        if (store.state.excelProject.adminTargetStatusSet) {
          num++
        }
        store.state.excelProject.filterArr.forEach((item) => {
          if (item.children?.length) {
            item.children.forEach((citem) => {
              if (citem.isChecked) {
                num++
              }
            })
          }
        })
        return num
      }

      const inputSearch = () => {
        //如果译文和原文都没有，则获取最后一次修改的句子信息 以下三个条件同时存在则满足
        if (!targetText.value && !sourceText.value && !getFilterNumber()) {
          getLastModifiedSent({ sentListSize: store.state.pageSize }).then(
            (res) => {
              let result = res.data.data
              if (result.modified) {
                store.commit('changeKey', {
                  pageNum: result.page,
                })
                store
                  .dispatch('getSentence', { sentencePosition: result.sentId })
                  .then((res) => {
                    positionSentence(result.sentId)
                  })
              } else {
                store.commit('changeKey', {
                  pageNum: 1,
                })
                store.dispatch('getSentence')
              }
            }
          )
        } else {
          store.commit('changeKey', {
            pageNum: 1,
          })
          store.dispatch('getSentence', { isBackTop: true, loading: true })
        }
      }

      //回车按下事件
      const pressEnter = () => {
        // inputSearch();
      }
      //input 框失去焦点事件 先空着以免需要
      const closeInput = () => {
        setTimeout(() => {
          inputSearch()
        }, 600)
        // throw new Error("这是触发错误")
      }

      //搜索框防抖
      let timer = null
      //输入框改变事件 key值是判断哪一个输入框
      const onChange = (key) => {
        store.commit('excelProject/changeKey', {
          targetText: targetText.value,
          sourceText: sourceText.value,
        })

        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          inputSearch()
        }, 1000)

        // console.log(key)

        //增加筛选条件是否打开的判断

        // 获取最后一次的改动信息

        // 跳转到最后一次改动的定位去；

        // 包装公用方法

      }

      const onSourceCheckChange=()=>{
        //用于处理先输入再切换的问题
        sourceText.value = "";
      }

      const onTargetCheckChange=()=>{
        //用于处理先输入再切换的问题
        targetText.value = "";
      }

      return {
        onTargetCheckChange,
        onSourceCheckChange,
        store,
        targetChecked,
        sourceChecked,
        state,
        disabled: computed(() => store.state.openEditSourceText),
        t,
        taskDetail,
        closeInput,
        sourceText,
        onChange,
        targetText,
        inputSearch,
        pressEnter,
        getLanguageName,
      }
    },
  }
</script>
<style lang="less">
  .table-title-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 8px;
    h6 {
      color: #333;
      text-align: left;
      font-size: 12px;
      margin-bottom: 0;
      font-weight: normal;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .table-title-input-search {
      width: 140px;
      flex-shrink: 0;
      .input-box{
        display: flex;
        align-items: center;
        .ant-input-group-addon {
          padding: 0 6px;
          background: white;
          border-color: #e5e6eb;
          svg {
            cursor: auto;
          }
        }
        .ant-input{
          font-size: 13px;
        }
        .ant-input-affix-wrapper {
          //border-color: #e5e6eb;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          border-right: transparent solid 1px !important;
          &:hover{
            border-color: #e5e6eb;
          }
          &:focus{
            border-color: #e5e6eb;
          }
        }
        .input-addonAfter{
          height: 26px;
          border:1px #d9d9d9 solid;
          border-left-width: 0px;
          padding-right: 6px;
          padding-left:6px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          line-height:26px;
          //border-left: 0;
          //background: red;
        }
      }
    }
  }
</style>
