<template>
  <a-modal
    title="快照功能介绍"
    :width="720"
    :visible="true"
    centered
    wrapClassName="ant-modal-reset-one-button"
    okText="我知道了"
    @ok="handleOk"
    @cancel="handleOk"
  >
    <div class="modal">
      <div class="margin-b-8">
        <b>批量操作时勾选【保存快照】，后续可【恢复快照】译文内容哦</b>
      </div>
      <div class="color-666 margin-b-20">
        保存新快照时将
        <span class="color-F55C3F">移除</span>
        旧快照；保存快照后若操作了对应句段，
        <span class="color-F55C3F">恢复时可能冲突</span>
      </div>
      <div>
        <img src="./images/snapshot.png" width="640" />
      </div>
    </div>
  </a-modal>
</template>

<script setup>
  import { reactive } from 'vue'
  import { useStore } from 'vuex'
  const store = useStore()

  const state = reactive({})

  const handleOk = () => {
    store.commit('modal/changeKey', {
      saveSnapshotVisible: false,
    })
  }
</script>

<style scoped lang="less">
  .modal {
    text-align: center;
    margin: 0 auto;
    height: 340px;
    padding-top: 20px;
    width: 680px;
    background: url('./images/bg.png');
    border-radius: 12px;
  }
</style>
