import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d51dac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "font-size-12 margin-r-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_list = _resolveComponent("table-list")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: $props.visible,
    class: "dialog-box",
    width: "600px",
    centered: "",
    mask: false,
    maskClosable: false,
    getContainer: $setup.getContainer,
    onCancel: $setup.cancel,
    onOk: $setup.startFilter,
    cancelText: "返回",
    okText: "前往处理所有未确认句子"
  }, {
    title: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createTextVNode(_toDisplayString($props.title) + " ", 1),
        ($props.type === '1' || $props.type === '2')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, " 共计" + _toDisplayString($setup.mistakeList.length) + "句 ", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      ($props.type === '1' || $props.type === '2')
        ? (_openBlock(), _createBlock(_component_table_list, {
            key: 0,
            dataSource: $setup.mistakeList
          }, null, 8, ["dataSource"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "getContainer", "onCancel", "onOk"]))
}