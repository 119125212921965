<template>
  <a-modal
    title="解锁介绍"
    :visible="true"
    centered
    okText="我知道了"
    cancelText="返回"
    :width="720"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="Lock">
      <div class="Lock-title">
        <b>锁定的句子无需翻译，如需解锁，可通过以下2种方式：</b>
      </div>
      <div class="Lock-Item">
        <label>1、全文解锁：</label>
        <span>
          联系项目经理前往【项目统计>重复率分析报告>报表详情】找到该文件解锁。
        </span>
      </div>
      <div>
        <img src="./images/lock-tip.png" height="110" />
      </div>
      <div class="Lock-Item">
        <label>2、单句解锁：</label>
        <span>
          点击锁定按钮即可解锁句子，解锁后再次点击解锁按钮即可锁定译文。
        </span>
      </div>
      <div>
        <img src="./images/lock-tip1.png" height="110" />
      </div>
      <div class="Lock-footer">
        *若解锁无权限，可联系PM前往项目详情-成员处，找到该译员设置权限
      </div>
    </div>
  </a-modal>
</template>

<script>
  export default {
    name: 'index',
    methods: {
      handleOk() {
        this.$store.state.modal.lockDialogVisible = false
      },
      handleCancel() {
        this.$store.state.modal.lockDialogVisible = false
      },
    },
  }
</script>

<style scoped lang="less">
  .Lock {
    width: 580px;
    margin: 0 auto;
    text-align: center;
    .Lock-title {
      font-size: 16px;
    }
    .Lock-footer {
      margin-top: 16px;
      font-size: 12px;
      color: #86909c;
    }
    .Lock-Item {
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: left;
      label {
        font-weight: bold;
        color: rgba(78, 89, 105, 1);
      }
      span {
        color: rgba(78, 89, 105, 1);
      }
    }
  }
</style>
