// import 导入store ；
import store from '@/store/index'
//数据库值和表名都给的是默认值；同时需要存储更新时间；对比更新时间
export const initDB = function() {
  //自动存储队列映射数据
  window.autoCommitSentIdsMap = {}

  //增加自动存储队列
  window.autoCommitQueue = {}

  // 记录此次打开时间；以后一个月更新一次；
  initUpdateDbOperatingTime()
  // 初始化db
  initDbDataList()
}

//初始化数据库
export const initDbDataList = function() {
  let dbName = store.state.taskDetail.taskId
  let tableName = 'dataList'
  const IndexedDB = window.indexedDB.open(dbName)
  IndexedDB.onupgradeneeded = function(event) {
    // 如果指定的版本号，大于数据库的实际版本号，就会发生数据库升级事件upgradeneeded
    // 新建数据库
    const result = event.target.result
    if (!result.objectStoreNames.contains(tableName)) {
      // 先判断库是否存在
      result.createObjectStore(tableName, { keyPath: 'sentId' }) // 表的主键 是 url 比如{url: 'xx', data: {xx}}  主键会自动生成索引
    }
    IndexedDB.result.close()
  }
}

//更新数据库的操作时间
export const initUpdateDbOperatingTime = function() {
  let dbName = store.state.taskDetail.taskId
  const IndexedDB = window.indexedDB.open('dbOperatingTime')
  IndexedDB.onupgradeneeded = function(event) {
    const result = event.target.result
    if (!result.objectStoreNames.contains('operateTime')) {
      // 先判断库是否存在
      result.createObjectStore('operateTime', { keyPath: 'taskId' }) // 表的主键 是 url 比如{url: 'xx', data: {xx}}  主键会自动生成索引
    }
  }
  IndexedDB.onsuccess = function(event) {
    //将此任务更新时间存入进去
    const timestamp = new Date().getTime()
    let needUpdate = {
      timestamp,
      taskId: dbName,
    }
    const db = IndexedDB.result
    //最后登录此进入时间；
    db.transaction(['operateTime'], 'readwrite')
      .objectStore('operateTime')
      .put(needUpdate)
    db.close()
  }
}

//将一个指定的值存入数据库
export const indexDbSetVal = function(setData, dbName, tableName) {
  dbName = dbName || store.state.taskDetail.taskId
  tableName = tableName || 'dataList'
  try {
    const IndexedDB1 = window.indexedDB.open(dbName)
    IndexedDB1.onsuccess = function(event) {
      const db = IndexedDB1.result
      db.transaction([tableName], 'readwrite') // 指定需要访问的 数据库名
        .objectStore(tableName) // 返回数据库对象
        .put(setData)

      db.close()
    }
  } catch (error) {
    console.error(error)
  }
}

//获取数据库的指定一个值；
export const indexDbGetVal = function(key, cb, dbName, tableName) {
  dbName = dbName || store.state.taskDetail.taskId
  tableName = tableName || 'dataList'
  const IndexedDB2 = window.indexedDB.open(dbName)
  IndexedDB2.onsuccess = function(event) {
    const db = IndexedDB2.result
    if (db.objectStoreNames.contains(tableName)) {
      const request = db
        .transaction([tableName], 'readwrite')
        .objectStore(tableName)
        .get(key) // key是: JSON.stringify(opt)
      request.onsuccess = function(event) {
        if (request.result) {
          if (cb) {
            cb(request.result)
          }
          return true
        } else {
          throw `indexDB 获取失败, key为${key}`
        }
      }
    }
    db.close()
  }
}

//获取当前任务下的数据库里面的所有值
export const indexDbGetAllVal = function(cb, dbName, tableName) {
  dbName = dbName || store.state.taskDetail.taskId
  tableName = tableName || 'dataList'
  const IndexedDB2 = window.indexedDB.open(dbName)
  IndexedDB2.onsuccess = function(event) {
    const db = IndexedDB2.result
    if (db.objectStoreNames.contains(tableName)) {
      const request = db
        .transaction([tableName], 'readwrite')
        .objectStore(tableName)
        .getAll() // key是: JSON.stringify(opt)
      request.onsuccess = function(event) {
        if (request.result) {
          if (cb) {
            cb(request.result)
          }
          return true
        } else {
          throw 'indexDB 获取失败, key为获取所有值'
        }
      }
    }
    db.close()
  }
}

//刪除数据库里面存储的值
export const indexDbDeleteVal = function(key, cb, dbName, tableName) {
  dbName = dbName || store.state.taskDetail.taskId
  tableName = tableName || 'dataList'
  const IndexedDB2 = window.indexedDB.open(dbName)
  IndexedDB2.onsuccess = function(event) {
    const db = IndexedDB2.result
    if (db.objectStoreNames.contains(tableName)) {
      const request = db
        .transaction([tableName], 'readwrite')
        .objectStore(tableName)
        .delete(key)
      request.onsuccess = function(event) {
        console.log('删除成功')
      }
    }
    db.close()
  }
}

//删除数据库
export const deleteIndexDb = function(dbname) {
  const dbName = dbname || store.state.taskDetail.taskId
  // 关闭所有与指定数据库相关的连接
  indexedDB.databases().then((databases) => {
    databases.forEach((database) => {
      if (database.name === dbName) {
        const request = indexedDB.deleteDatabase(dbName)

        // 成功删除数据库时的回调
        request.onsuccess = function(event) {
          console.log('数据库已删除。')
        }

        // 删除数据库失败的回调
        request.onerror = function(event) {
          console.error('删除数据库时发生错误:', event.target.errorCode)
        }

        // 删除数据库时的异常处理
        request.onblocked = function(event) {
          console.warn('有其他连接正在使用数据库，删除被阻塞。')
        }
      }
    })
  })
}

//删除一个月以上未使用的数据库
export const deleteNotUsedDb=()=>{
  setTimeout(()=>{
    let dbName = 'dbOperatingTime'
    let tableName = 'operateTime'
    const IndexedDB = window.indexedDB.open(dbName);
    IndexedDB.onsuccess = function(event) {
      const db = IndexedDB.result
      if (db.objectStoreNames.contains(tableName)) {
        const request = db.transaction([tableName], 'readwrite').objectStore(tableName).getAll()
        request.onsuccess = function(event) {
          if (request.result && Array.isArray(request.result)) {
            let copyList = [...request.result];
            copyList.forEach(item=>{
              let nowTime = new Date().getTime();
              //一个月以上未使用过的直接删除
              if (nowTime - item.timestamp > 2592000000) {
                deleteIndexDb(item.taskId);
              }
            })
            copyList.splice(0,copyList.length);
          }
        }
      }
      db.close()
    }
  },5000)
}


