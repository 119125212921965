import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dfee1e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "differentModel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_switch, {
      size: "small",
      checked: $setup.differentHighLightModel,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => (($setup.differentHighLightModel) = $event)),
      disabled: $props.disabled
    }, null, 8, ["checked", "disabled"])
  ]))
}