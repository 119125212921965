
  import { computed, ref } from 'vue'
  import { useStore } from 'vuex'

  export default {
    setup() {
      const store = useStore()
      // const groupDocList = computed(() => {
      //   if (store.state.groupDocList.length == 0) return [];
      //   let newList = store.state.groupDocList.map((item: any) => {
      //     return {
      //       value: item.docId,
      //       docId: item.docId,
      //       label: item.docName
      //     };
      //   });
      //   newList.unshift({
      //     value: "all",
      //     label: "全部稿件",
      //     docId: "all"
      //   });
      //   return newList;
      // });
      const visible = ref(false)
      const docId = ref('all')
      const taskDetail = computed(() => store.state.taskDetail)
      const wordCount = computed(() => store.state.wordCount)

      // const btn = (item: any) => {
      //   visible.value = false;
      //   // docName.value = item.docName;
      //   // const splitIndex = getQueryString("splitIndex");
      //
      //   let newTaskDetail = { ...store.state.taskDetail };
      //   newTaskDetail.docId =
      //     item.docId == "all" ? groupDocList.value[1].docId : item.docId;
      //   //存储taskDetail信息
      //   store.commit("changeKey", {
      //     taskDetail: newTaskDetail,
      //     pageNum: 1,
      //     sourcePageNum: 1
      //   });
      //   // store.commit("excelProject/changeKey", {
      //   //   targetText: "",
      //   //   sourceText: "",
      //   // });
      //   store.dispatch("getSentence"); //请求译文
      // };

      // const handleChange = (e, item: any) => {
      //   store.commit("changeKey", {
      //     corpusAllFile: e == "all",
      //     corpusAllFilePage: [],
      //     corpusAllFileTotal: 0
      //   });
      //   btn(item);
      // };

      return {
        wordCount,
        docId,
        store,
        visible,
        taskDetail,
        // translateProgress
      }
    },
  }
