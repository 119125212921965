<template>
  <!--打开原文编辑的时候 // 开始增加原文下方标错隐藏dom节点-->
  <div
    class="source-box new-edit-box-hover"
    v-if="store.state.openEditSourceText"
    :class="{
      'new-edit-box-border': tabActiveIndex == index && activeBorder == 'left',
      'new-edit-box-locked': item.locked,
    }"
  >
    <div class="source-txt">
      <!--          @click="clickSourceBox($event,index,item)"-->
      <div
        @compositionstart="compositionstartSelect($event, 1)"
        @compositionend="compositionstartSelect($event, 2)"
        @keypress="sourceKeyupPress"
        :contenteditable="!item.sourceLocked"
        autocapitalize="off"
        isListener="false"
        spellcheck="false"
        class="source-language-text-style"
        @blur.native="onEditSourceBlur(item)"
        :id="'source-sent-' + item.sentId"
        :class="'source-sent-' + item.sentId"
        v-html="item.sourcePlaceholder"
        ondrop="return false"
      ></div>
    </div>
  </div>
  <!--未打开原文编辑的时候-->
  <div class="source-box" v-else>
    <div class="source-txt" ondrop="return false">
      <a-tooltip
        :overlayClassName="
          `${item.hasImg ? '' : 'hidden-tooltip'} white-tooltip`
        "
        placement="topRight"
      >
        <template #title>
          <div class="display-flex align-center font-size-14">
            <img src="./images/PH.png" width="16" />
            <span class="margin-l-4" style="color:#333">
              该句含有标签（Tag）
            </span>
            <label style="color:#00BEFA;cursor: pointer" @click="openModal">
              点我了解
            </label>
          </div>
        </template>
        <div
          :id="'source-sent-' + item.sentId"
          :class="
            `source-sent-${item.id} ${item.hasQA ? 'source-sent-qa' : ''}`
          "
          class="qa-node-position source-language-text-style"
          :data-sentId="item.sentId"
          @blur="onCommonSourceBlur(item)"
          @compositionend="keydownCompositionend(item)"
          contenteditable="true"
          @focus="sourceFocus(item, index)"
          @keydown="sourceKeyDown"
          v-html="item.sourcePlaceholder"
          @dragstart="onDragstart($event, item.sentId)"
        ></div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { computed, getCurrentInstance } from 'vue'
  import useProject from '@/components/source/useProject'
  import useUtils from '@/plugins/utils'
  import { getFactTargetText } from '@/plugins/comm.js'
  import filterStrings from '@/plugins/filterString'
  import { updateSourceEdit } from '@/http/api'
  export default {
    name: 'index',
    props: ['item', 'index'],
    setup() {
      const store = useStore()
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const { setFocusEnd, insertHtmlAtCaret } = useUtils()

      const {
        t,
        // state,
        // taskDetail,
        docData,
        getRangeText,
        targetMousedown,
        afterTargetMouseDown,
      } = useProject()

      const tabActiveIndex = computed({
        set: (val) => {
          store.commit('excelProject/changeKey', {
            tabActiveIndex: val,
          })
        },
        get: () => store.state.excelProject.tabActiveIndex,
      })

      const tbNavActiveIndex = computed(() => {
        return store.state.rightToolbar.toolbarNav.tbNavActiveIndex
      })

      // const onDragover=(e)=>{
      //   e.dataTransfer.dropEffect = "copy";
      //   e.dataTransfer.effectAllowed = 'copy';
      //  //  e.dataTransfer.dropEffect = 'copy';
      //  // console.log(e)
      // }

      const onDragstart = (e, sentId) => {
        // console.log(e);
        // let selection=window.getSelection();
        //调用selection对象的toString()方法就可以获取鼠标拖动选中的文本。
        // console.log("选中的文本为：");
        let range, html
        range = window.getSelection().getRangeAt(0)
        html = range.cloneContents()
        // console.dir(html);
        let imgList = []
        function getImgList(node) {
          node.children.forEach((item) => {
            if (item.nodeName == 'IMG') {
              imgList.push(item.id)
            }
            if (item.children?.length) {
              getImgList(item)
            }
          })
        }
        getImgList(html)
        //深度遍历HTMLchildrenNodes

        // e.dataTransfer.dropEffect = "copy";
        e.dataTransfer.setData('img-id', `${imgList.join('&')}`)
        e.dataTransfer.setData('sent-id', `${sentId}`)
      }

      const openModal = () => {
        store.commit('modal/changeKey', {
          placeHolderVisible: true,
        })
      }

      const keydownCompositionend = (item) => {
        // console.log(999)
        let node = window.$getSourceDom(item.sentId);
        let innnerHTML = node.innerHTML
        if (
          filterStrings.getString(innnerHTML) !=
          filterStrings.getString(item.sourcePlaceholder)
        ) {
          //重置
          node.innerHTML = item.sourcePlaceholder
          store.dispatch('excelProject/twinslatorMatch')
        }
      }

      const onCommonSourceBlur = () => {
        store.commit('optionsBar/changeKey', {
          sourceEditFocus: false,
        })
      }

      const sourceFocus = (item, index) => {
        store.commit('optionsBar/changeKey', {
          sourceEditFocus: true,
        })
        //增加可以拆分的标志
        //移除
        setTimeout(() => {
          targetMousedown(item, index)
        }, 100)
      }

      // const clickSourceBox = (e: any, index: any, item: any) => {
      //   //顺序不可颠倒 且失去焦点事件提交一定在click之后执行的
      //   // let domId = `source-sent-${item.id}`;
      //   // changeTabActive(e,index,item,domId);
      //   targetMousedown(item, index);
      // };

      //提交保存操作
      const sourceSendCommit = (item) => {
        // 如果是点击译文状态处的标签 ，直接return，走单独保存操作；
        let sentStr = `#source-sent-${item.id}`
        const sentDom = document.querySelector(sentStr)
        let sentDomHTML = sentDom.innerHTML
        let source = item.sourcePlaceholder

        //相同则不修改
        if (source == sentDomHTML) {
          return
        }

        // let params: any = {
        //   docId: store.state.taskDetail.docId,
        //   sentId: item.sentId,
        //   sourcePlaceholder: innerHTML,
        //   updateTarget: false
        // };

        // //如果原文编辑打开了 改造传到Map结构里面前去
        let innerHTML = filterStrings.getString(sentDomHTML)
        if (store.state.openEditSourceText) {
          let targetText = document.getElementById(item.targetDomId).innerHTML
          store.dispatch('sourceEdit/sentSourceCommitMap', {
            sentId: item.sentId,
            sourcePlaceholder: window.$removeValueToSrc(innerHTML),
            target: filterStrings.getPureString(targetText),
            targetPlaceholder: targetText,
            isSync: item.isSync,
            sourceRepeatHash: item.sourceRepeatHash,
          })
          sentDom.innerHTML = innerHTML
          // item.sourcePlaceholder = innerHTML;
          return
        }

        //走接口提交 赋值数据
      }
      // 原文失去焦点事件
      const onEditSourceBlur = (item) => {
        sourceSendCommit(item)

        return
      }

      const getIMGHtml = () => {
        let selectionObj = window.getSelection()
        let rangeObj = selectionObj.getRangeAt(0)
        let docFragment = rangeObj.cloneContents()
        let tempDiv = document.getElementById('create-html-test-node')
        tempDiv.innerHTML = ''
        tempDiv.appendChild(docFragment)
        let selectedHtml = tempDiv.innerHTML
        if (selectedHtml.match(/(<\/?img.*?>)/gi)) {
          return selectedHtml.match(/(<\/?img.*?>)/gi)?.join('')
        }
        return ''
      }

      let startSelectHtml
      const compositionstartSelect = (e, key) => {
        // console.log(e)
        // key 1 开始 key 2 结束
        if (key == 1) {
          let selectionObj = window.getSelection()
          if (selectionObj.type == 'Range') {
            let selectedHtml = getIMGHtml()
            if (selectedHtml.indexOf('<img') > -1) {
              startSelectHtml = selectedHtml
            }
          }
        } else if (key == 2 && startSelectHtml) {
          //开始插入光标末尾图片 取出HTML中img的占位符
          document.execCommand('insertHTML', false, `${startSelectHtml}`)
          //重置
          startSelectHtml = ''
        }
      }

      const sourceKeyupPress = () => {
        let selectionObj = window.getSelection()
        if (selectionObj.type == 'Range') {
          let imgHtml = getIMGHtml()
          if (imgHtml.indexOf('<img') > -1) {
            setTimeout(() => {
              document.execCommand('insertHTML', false, `${imgHtml}`)
            }, 0)
          }
        }
      }

      // const checkSameIMG = (e, item) => {
      //   let innerHtml = globalProxy.$getTargetDom(item).innerHTML;
      //   if (innerHtml.indexOf(e.target.outerHTML) > -1) {
      //     return true;
      //   }
      //   return false;
      // };

      const insertText = (e, item, index) => {
        if (tabActiveIndex.value != index) {
          return
        }
        //如果不是点击IMG 则拒绝
        if (e.srcElement.nodeName != 'IMG') {
          return
        }
        let sentId = item.sentId
        let classList = document.activeElement.classList.value
        //如果焦点不在对应的译文上面 则拒绝
        if (
          classList.indexOf(`target-sent-${sentId}`) < 0 ||
          classList.indexOf(`target-txt`) < 0
        ) {
          return
        }

        let sent = window.$getTargetDom(item.sentId)

        let childNodes = sent.querySelectorAll(`img`)
        // console.log(childNodes);
        childNodes.forEach((item) => {
          if (item.id == e.srcElement.id) {
            item.parentNode.removeChild(item)
          }
        })

        // let checkResult = false;
        // checkResult = checkSameIMG(e, item);
        // let hiddenImgNode;
        // let parentNode;
        // function getImgHiddenNode(node) {
        //   node.childNodes.forEach(item => {
        //     if (item.nodeType == 1 && item.id == e.srcElement.id) {
        //       hiddenImgNode = item;
        //       parentNode = node;
        //     }
        //     if (item.nodeType == 1 && item.nodeName != "IMG") {
        //       getImgHiddenNode(item);
        //     }
        //   });
        // }
        // if (checkResult) {
        //   getImgHiddenNode(sent);
        // }
        let D = document.getElementById('create-html-test-node')
        D.innerHTML = ''
        D.appendChild(e.srcElement.cloneNode(true))
        let innerHtml = D.innerHTML
        insertHtmlAtCaret(innerHtml)
        D = null

        setTimeout(() => {
          setFocusEnd(sent)
          sent = null
        }, 0)
      }

      const sourceKeyDown = (e) => {
        if (e.ctrlKey && e.code === 'KeyC') {
        } else if (e.metaKey && e.code === 'KeyC') {
        } else {
          e.preventDefault()
        }
      }

      return {
        onDragstart,
        onCommonSourceBlur,
        keydownCompositionend,
        sourceFocus,
        sourceKeyDown,
        tbNavActiveIndex,
        openModal,
        targetMousedown,
        insertText,
        // clickSourceBox,
        tabActiveIndex,
        t,
        getFactTargetText,
        store,
        activeBorder: computed(() => store.state.sourceEdit.activeBorder),
        taskDetail: computed(() => store.state.taskDetail),
        getRangeText,
        compositionstartSelect,
        sourceKeyupPress,
        onEditSourceBlur,
      }
    },
  }
</script>

<style scoped lang="less">
  .source-box {
    position: relative;
    flex: 1;
    height: 100%;
    padding: 8px 10px 8px 10px;
    border-width: 2px;
    border-color: transparent;
    border-style: solid;
    border-radius: 4px;
    //.source-position {
    display: flex;
    align-items: center;
    width: 100%;
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  //z-index: 1;
    //  //position: relative;
    //  //z-index: 1;
    //}
    .source-txt {
      width: 100%;
      color: #333333;
      word-break: break-word;
      white-space: pre-wrap;
      font-size: var(--universal-font-size);
      position: relative;
      line-height: 1.38;
      //.source-sent-qa{
      //  ::v-deep(.qaError) {
      //    border-bottom: 2px red solid;
      //  }
      //}
      ::v-deep(.qaError) {
        border-bottom: 2px red solid;
      }

      ::v-deep(img) {
        user-select: none;
        cursor: pointer;
      }
      //术语已替换 默认进去的时候展示
      ::v-deep(a) {
        cursor: default;
      }
      //术语未替换 ，点击之后切换并且短暂保存
      ::v-deep(.f-term) {
        box-shadow: inset 0 1px 0 #72D940;
        border-top: 1px solid #72D940;
        border-radius: 4px;
      }
      ::v-deep(.f-term2) {
        box-shadow: inset 0 1px 0 #72D940;
        border-top: 1px solid #72D940;
        border-radius: 4px;
      }
    }
    //100%匹配展示
    //.source-txt-complete-matching {
    //  //color:#38B42F;
    //}
  }
</style>
