<template>
  <CheckCircleTwoTone twoToneColor="#52c41a" />
</template>

<script>
  import { CheckCircleTwoTone } from '@ant-design/icons-vue'
  export default {
    name: 'index',
    components: {
      CheckCircleTwoTone,
    },
  }
</script>

<style scoped></style>
