
  import { useI18n } from 'vue-i18n' //国际化
  import { reactive, toRefs, computed } from 'vue'
  import { useStore } from 'vuex'
  import TableList from './table.vue'

  export default {
    components: {
      TableList,
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '原文',
      },
      type: {
        type: String,
        default: '1',
      },
    },
    setup(props: any, ctx: any) {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        taskDetail: store.state.taskDetail,
        data: [],
      })

      const startFilter = () => {
        store.commit('excelProject/changeKey', {
          groupBy: null,
          groupSort: null
        })
        store.commit('excelProject/resetFilterValue')
        store.commit('excelProject/resetNoConfirm')
        store.commit('changeKey', {
          pageNum: 1,
        })
        store.commit('excelProject/changeKey', {
          sourceText: '',
          targetText: '',
        })
        store.dispatch('getSentence', { isBackTop: true ,loading:true})
        ctx.emit('update:visible', false)
      }

      const cancel = () => {
        ctx.emit('update:visible', false)
      }

      return {
        startFilter,
        ...toRefs(state),
        cancel,
        t,
        getContainer: () => document.querySelector('.header'),
        mistakeList: computed(() => store.state.mistakeList),
      }
    },
  }
