<template>
  <div class="IM">
    <a-badge :count="count" v-if="groupInfo.hasGroup">
      <MessageOutlined
          style="cursor: pointer;font-size: 15px;position: relative;top: -1px"
          @click="openIm"
      />
    </a-badge>
  </div>
</template>

<script setup>
import { MessageOutlined } from '@ant-design/icons-vue'
import { onMounted, ref } from 'vue'
import { getIMGroupInfoApi } from '@/http/api'
import Imsdk from '@transn/im-sdk'
import {useRoute} from "vue-router";
const route = useRoute();
const count = ref(false)

let im = null
const groupInfo = ref({})
//IM聊天Css 文件
const openIm = () => {
  if (im) {
    im.toggle()
  }
}
//3秒后出发
onMounted(() => {
  //增加路由参数屏蔽IM;
  // console.log(route.query.IM)
  if (route.query.IM) {
    return
  }
  setTimeout(() => {
    getIMGroupInfoApi().then((res) => {
      // console.log("666");
      //获取群组聊天
      groupInfo.value = { ...res.data.data }
      // console.log(res);
      // group_code: groupInfo.value.groupCode,
      //     user_code: groupInfo.value.userCode,
      if (!groupInfo.value.hasGroup) {
        return
      }
      im = new Imsdk(
          {
            initType: 'group',
            group_code: groupInfo.value.groupCode,
            user_code: groupInfo.value.userCode,
          },
          (message) => {
            // console.log('收到消息', message);
          },
          {
            onSuccess: () => {
              console.log('连接成功')
            },
            onError: (data) => {
              // console.log('连接失败', data);
            },
            onUnreadUpdate: (number) => {
              count.value = number
              console.log('未读数更新', number)
            },
          }
      )
    })
  }, 2000)
})
</script>

<style lang="less" scoped></style>
