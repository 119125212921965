const divId = `create-html-test-node`
//startIndex 开始位置，endIndex 结束位置。node 渲染节点，type  id： qa或者术语的唯一标识符
function startLine(node, start, end, id, type) {
  //  let lastIndex = 0;
  let lastLength = 0
  let localLength = 0

  let startNodeIndex = null
  let startPostitionNode = null
  //  let close = false;
  function searchStartIndex(node, index) {
    //是否需要计算占位符图片的位置；应该是需要的；
    // console.log(node);
    if (node.nodeType == 3 || (node.nodeType == 1 && node.nodeName == 'IMG')) {
      //当前节点长度；
      let nodeLength = 0
      if (node.nodeType == 3) {
        //此处需要判断属于哪种节点 样式占位符则不需要处理，直接跨过，如果是单一占位符则不能跨过
        nodeLength = node.length
      } else {
        nodeLength = 0
      }
      localLength = localLength + nodeLength
      if (index < localLength) {
        startNodeIndex = index - lastLength
        startPostitionNode = node
        return true
      } else {
        lastLength = localLength
      }
    } else {
      //继续遍历 不是文本节点
      for (let i = 0; i < node.childNodes.length; i++) {
        // console.log(i);
        let result = searchStartIndex(node.childNodes[i], index)
        if (startNodeIndex !== null) {
          // console.log("这是执行了break")
          break
        }
      }
    }
  }
  searchStartIndex(node, start)

  lastLength = 0
  localLength = 0
  let endNodeIndex = null
  let endPositionNode = null
  function searchEndIndex(node, index) {
    //是否需要计算占位符图片的位置；应该是需要的；
    // console.log(node);
    if (node.nodeType == 3 || (node.nodeType == 1 && node.nodeName == 'IMG')) {
      //当前节点长度；
      let nodeLength = 0
      // console.log("右侧执行")
      if (node.nodeType == 3) {
        //此处需要判断属于哪种节点 样式占位符则不需要处理，直接跨过，如果是单一占位符则不能跨过
        nodeLength = node.length
      } else {
        nodeLength = 0
      }
      localLength = localLength + nodeLength
      if (index <= localLength) {
        endNodeIndex = index - lastLength
        endPositionNode = node
        return true
      } else {
        lastLength = localLength
      }
    } else {
      //继续遍历 不是文本节点
      for (let i = 0; i < node.childNodes.length; i++) {
        // console.log(i);
        let result = searchEndIndex(node.childNodes[i], index)
        if (endNodeIndex !== null) {
          // console.log("这是执行了break")
          break
        }
      }
    }
  }

  searchEndIndex(node, end)

  //  console.log(startNodeIndex,startPostitionNode);
  // //  console.dir(startPostitionNode)
  //  console.log(endNodeIndex,endPositionNode);
  //  console.dir(endPositionNode);

  const range = document.createRange()
  if (startPostitionNode.nodeName == 'IMG') {
    let startParentNode = startPostitionNode.parentNode
    let imgIndex = 0
    for (let i = 0; i < startParentNode.childNodes.length; i++) {
      if (startParentNode.childNodes[i] === startPostitionNode) {
        // console.log('等于');
        imgIndex = i
        break
      }
    }
    range.setStart(startParentNode, imgIndex)
  } else {
    range.setStart(startPostitionNode, startNodeIndex)
  }

  if (endPositionNode.nodeName == 'IMG') {
    let endParentNode = endPositionNode.parentNode
    let imgIndex = 0
    for (let i = 0; i < endParentNode.childNodes.length; i++) {
      if (endParentNode.childNodes[i] === endPositionNode) {
        // console.log('等于');
        imgIndex = i
        break
      }
    }

    range.setEnd(endParentNode, imgIndex + 1)
    //同时要考虑ImgIndex若在句子最后；
  } else {
    range.setEnd(endPositionNode, endNodeIndex)
  }

  //  range.deleteContents();
  let cloneRange = range.cloneContents()
  range.deleteContents()

  let htmlNode = document.getElementById(divId)
  htmlNode.innerHTML = ''
  htmlNode.appendChild(cloneRange)

  let frag = ''
  if (type == 1) {
    const template = `<span class='qaError ${id}'>${htmlNode.innerHTML}</span>`
    frag = document.createRange().createContextualFragment(template)
  } else if (type == 2) {
    const template = `<span class='f-term' data-id='${id}'>${htmlNode.innerHTML}</span>`
    frag = document.createRange().createContextualFragment(template)
  }
  range.insertNode(frag)
}

//获取node 节点下文本字符长度
function getTextLength(node) {
  let textLength = 0
  function traverseNode(node) {
    //遍历node 获取总长度，判断是否超出
    node.childNodes.forEach((item) => {
      if (item.nodeName == '#text') {
        textLength += item.length
      } else {
        if (item.childNodes) {
          traverseNode(item)
        }
      }
    })
  }
  traverseNode(node)
  return textLength
}

//qaFormatText 是后端算法下标索引的结果；
function qaFormatText(node, startIndex, endIndex, id) {
  if (startIndex < 0) {
    return
  }
  if (endIndex < startIndex) {
    return
  }

  let currentIndex = -1
  try {
    let maxLength = getTextLength(node)
    if (startIndex > maxLength) {
      return
    }
    if (endIndex > maxLength) {
      endIndex = maxLength
    }

    let selection = window.getSelection();
    if (selection.type=='Caret' && node.contains(selection.focusNode)) {
        currentIndex = getCursorPosition(node)
    }
    startLine(node, startIndex, endIndex, id, 1)
    if (currentIndex > -1) {
      positionSelection(node, currentIndex)
    }
  } catch (error) {
    console.log(error)
  } finally {

  }
}

//imgFormatText 是根据标签id下标记性计算画线的结果；
function imgFormatText(node,domId,qaId){
  let imgNodeList = node.querySelectorAll(`#${domId}`);

  let currentIndex = -1
  let selection = window.getSelection();
  if (selection.type=='Caret' && node.contains(selection.focusNode)) {
    currentIndex = getCursorPosition(node)
  }


  for (let i =0; i<imgNodeList.length;i++) {
    let imgNode = imgNodeList[i];
    const range = document.createRange();
    let imgIndex = 0
    for (let i = 0; i < imgNode.parentNode.childNodes.length; i++) {
      if (imgNode.parentNode.childNodes[i] === imgNode) {
        // console.log('等于');
        imgIndex = i
        break
      }
    }
    range.setStart(imgNode.parentNode, imgIndex)
    //同时要考虑ImgIndex若在句子最后；
    range.setEnd(imgNode.parentNode, imgIndex+1)


    range.deleteContents()
    const template = `<span class='qaError ${qaId}'>${imgNode.outerHTML}</span>`
    let frag = document.createRange().createContextualFragment(template)
    range.insertNode(frag);
  }
  if (currentIndex > -1) {
    positionSelection(node, currentIndex)
  }
}

function termFormatText(node, startIndex, endIndex, id) {
  startLine(node, startIndex, endIndex, id, 2)
}

function getCursorPosition(node) {
  let selection = window.getSelection()
  //需要判断光标是否在同一节点；
  if (!selection.containsNode(node, true)) {
    return -1
  }
  //分Range 和 Cart;

  // console.log(selection);
  // selection.focusNode;
  // selection.focusOffset;
  //开启遍历循环
  //要判断是否非图片？
  //   let nodeIndex = -1;
  let nodeIndex = -1
  let isEnd = false

  let factFocusNode = null
  let factFoucesOffset = null
  let isStartOffset = false
  // console.log(selection)
  //获取实际焦距元素； 就是第一个
  if (selection.focusNode?.nodeType == 1 && selection.focusOffset == 0) {
    isStartOffset = true
    // 说明是图片之类的。需要获取实际元素
  } else if (selection.focusNode?.nodeType == 1) {
    factFocusNode = selection.focusNode.childNodes[selection.focusOffset - 1]
    factFoucesOffset = selection.focusOffset
  } else {
    factFocusNode = selection.focusNode
    factFoucesOffset = selection.focusOffset
  }

  if (isStartOffset) {
    nodeIndex = 0
    return nodeIndex
  }

  // console.log(factFocusNode,factFoucesOffset)

  function searchIndex(node) {
    //是否需要计算占位符图片的位置；应该是需要的；
    //需要判断是否是标签节点，还是元素节点
    // console.log(node);
    if (node === factFocusNode) {
      // console.log(node,'当前节点')
      //如果是img 则需要取前面所有节点的长度；
      if (node.nodeName == 'IMG') {
        nodeIndex = nodeIndex + 1
      } else {
        nodeIndex = nodeIndex + factFoucesOffset
      }
      isEnd = true
    } else {
      // console.log(node)
      let needLength = 0
      if (node.nodeName == 'IMG') {
        needLength = 1
      } else if (node.nodeType == 1) {
        //说明是标签；
        // needLength = 2;
      } else {
        needLength = node.length
      }
      nodeIndex = nodeIndex + needLength
      //继续向下查找；
      for (let i = 0; i < node.childNodes.length; i++) {
        if (isEnd) {
          break
        }
        searchIndex(node.childNodes[i])
      }
    }
  }
  searchIndex(node)
  return isEnd ? nodeIndex + 1 : nodeIndex
}

function positionSelection(node, index) {
  let lastLength = 0
  let localLength = 0

  let startNodeIndex = null
  let startPostitionNode = null
  //  let close = false;
  function searchIndex(node, index) {
    //是否需要计算占位符图片的位置；应该是需要的；
    // console.log(node);
    if (node.nodeType == 3 || (node.nodeType == 1 && node.nodeName == 'IMG')) {
      //当前节点长度；
      let nodeLength = 1
      if (node.nodeType == 3) {
        //此处需要判断属于哪种节点 样式占位符则不需要处理，直接跨过，如果是单一占位符则不能跨过
        nodeLength = node.length
      } else {
        nodeLength = 1
      }
      localLength = localLength + nodeLength
      if (index < localLength) {
        startNodeIndex = index - lastLength
        startPostitionNode = node
        return true
      } else {
        lastLength = localLength
      }
    } else {
      //继续遍历 不是文本节点
      for (let i = 0; i < node.childNodes.length; i++) {
        // console.log(i);
        let result = searchIndex(node.childNodes[i], index)
        if (startNodeIndex !== null) {
          // console.log("这是执行了break")
          break
        }
      }
    }
  }
  //  console.log("开始寻找index",index)
  searchIndex(node, index)
  let selection = window.getSelection()
  if (index > 0 && startPostitionNode && startNodeIndex > -1) {
    // console.log(999)
    selection.collapse(startPostitionNode, startNodeIndex)
  } else if (index <= 0) {
    selection.collapse(node, 0)
  } else {
    selection.collapse(node, node.childNodes.length)
  }
}

export {
  imgFormatText,
  getTextLength,
  positionSelection, //光标定位
  getCursorPosition, //获取光标在第几个
  qaFormatText, //QA画线
  termFormatText, //术语画线
}
