<template>
  <!--试译任务且审核状态 （也为预览模式） 或者预览模式-->
  <ul
    class="display-flex align-center excel-ul padding-l-20"
    v-if="store.getters.$isPreviewMode"
  >
    <a-space :size="8">
      <SentenceSelect :disabled="disabled" />
      <DiffMatch :disabled="disabled"></DiffMatch>
    </a-space>
  </ul>

  <!--  &lt;!&ndash;预览模式&ndash;&gt;-->
  <!--  <ul-->
  <!--      class="display-flex align-center excel-ul padding-l-20"-->
  <!--      v-else-if="store.state.taskDetail.taskStatus == 2"-->
  <!--  >-->
  <!--    <a-space :size="8">-->
  <!--      <SentenceSelect :disabled="disabled" />-->
  <!--      <DiffMatch :disabled="disabled"></DiffMatch>-->
  <!--    </a-space>-->
  <!--  </ul>-->

  <ElScrollbar v-else style="height: 56px; overflow-y: hidden" class="excel-ul">
    <ul class="display-flex align-center justify-between">
      <!--管理员编辑状态下也不显示按钮功能 taskDetail.taskClassify==8为管理员  11 应该是试译任务-->
      <a-space
        :size="8"
        align="center"
        style="flex-shrink: 0; overflow-x: auto; overflow-y: hidden"
      >
        <a-space :size="8" align="center">
          <!--确认译文-->
          <a-tooltip>
            <template #title>Enter</template>
            <ConfirmTranslation :disabled="disabled"></ConfirmTranslation>
          </a-tooltip>
          <div
            class="divider"
            v-if="store.state.taskDetail.taskClassify != 8"
          ></div>
          <!--获取机翻-->
          <AcquireMachineTurnover
            :disabled="disabled"
            @opentranslationset="openTranslationSet"
            v-if="store.state.taskDetail.isOpenTwinslator"
          ></AcquireMachineTurnover>
          <QualityTesting
            :disabled="disabled"
            v-if="store.state.taskDetail.isOpenTwinslator"
          ></QualityTesting>
          <!--批量润色-->
          <BatchDecoration
            :disabled="disabled"
            v-if="store.state.taskDetail.isOpenTwinslator"
          ></BatchDecoration>
          <!--清空译文-->
          <ClearTranslation :disabled="disabled"></ClearTranslation>
        </a-space>
        <RevokeSentence :disabled="disabled"></RevokeSentence>
        <div class="divider"></div>
        <!--富文本编辑-->
        <EditText :disabled="disabled"></EditText>
        <!-- 清空样式-->
        <ClearStyle :disabled="disabled"></ClearStyle>
        <div class="divider"></div>

        <!-- 查找替换 -->
        <a-tooltip>
          <template #title>
            （查找替换）{{ isWindow ? 'Alt + H' : 'Control + Option + H' }}
          </template>
          <FindReplace :disabled="disabled" />
        </a-tooltip>
        <!--添加术语-->
        <a-tooltip>
          <template #title>
            {{ isWindow ? 'Alt + T' : 'Control + Option + T' }}
          </template>
          <AddTerm
            :disabled="disabled"
            @opentranslationset="openTranslationSet"
            v-if="store.state.taskDetail.taskClassify != 11"
          ></AddTerm>
        </a-tooltip>

        <!--差异对比开关-->
        <DiffMatch
          :disabled="disabled"
          v-if="store.state.taskDetail.taskClassify != 11"
        ></DiffMatch>
        <div class="divider"></div>

        <div class="flex-center flex-column">
          <!--复制原文-->
          <CopySource :disabled="disabled"></CopySource>
          <a-tooltip
            title="暂无编辑原文权限，可让PM在项目成员列表开启权限"
            :overlayClassName="
              `${store.state.taskDetail.canSourceEdit ? 'hidden-tooltip' : ''}`
            "
          >
            <!--编辑原文-->
            <div>
              <EditSource :disabled="disabled"></EditSource>
            </div>
          </a-tooltip>
        </div>
        <!--        合并拆分暂时屏蔽  11 为试译任务 10 为 TW过来-->
        <div class="flex-center flex-column" v-if="store.state.taskDetail.taskClassify != 11 && store.state.taskDetail.taskClassify != 10">
          <MergeSplit :disabled="disabled"></MergeSplit>
        </div>

        <div
          class="flex-center flex-column"
          v-if="store.state.taskDetail.taskClassify == 8"
        >
          <Locked :disabled="disabled"></Locked>
        </div>
        <div class="divider"></div>
        <saveSnapShot></saveSnapShot>
        <!--翻译设置-->
        <TranslationSet
          :disabled="disabled"
          ref="translationSetRef"
          v-if="store.state.taskDetail.taskClassify != 11"
        ></TranslationSet>
      </a-space>
      <a-space :size="8">
         <!--个人翻译没有分组-->
        <splitGroup :disabled="disabled" v-if="!store.state.taskDetail.isSelfWork"></splitGroup>
        <SentenceSelect :disabled="disabled" class="margin-r-8"/>
      </a-space>
    </ul>
  </ElScrollbar>
  <!--  <perfect-scrollbar style="overflow-x: auto;"  v-else>-->

  <!--  </perfect-scrollbar>-->
  <!--需要唤醒条件-->
  <CorpusRemind
    @opentranslationset="openTranslationSet"
    v-if="store.state.modal.corpusRemindVisible"
  ></CorpusRemind>
</template>

<script>
  import { useStore } from 'vuex'
  import { computed, ref, onMounted } from 'vue'
  import ConfirmTranslation from './module/ConfirmTranslation/index.vue'
  import AcquireMachineTurnover from './module/AcquireMachineTurnover/index.vue'
  import ClearTranslation from './module/ClearTranslation/index.vue'
  import EditText from './module/EditText/index.vue'
  import ClearStyle from './module/ClearStyle/index.vue'
  import CopySource from './module/CopySource/index.vue'
  import EditSource from './module/EditSource/index.vue'
  import saveSnapShot from './module/saveSnapShot/index.vue'
  import AddTerm from './module/AddTerm/index.vue'
  import TranslationSet from './module/TranslationSet/index.vue'
  import DiffMatch from './module/DiffMatch/index.vue'
  import FindReplace from './module/FindReplace/index.vue'
  import SentenceSelect from './module/SentenceSelect/index.vue'
  import CorpusRemind from '@/components/pagination/module/Modal/CorpusRemind/index.vue'
  import QualityTesting from '@/components/pagination/module/QualityTesting/index.vue'
  import RevokeSentence from './module/RevokeSentence/index.vue'
  import BatchDecoration from './module/BatchDecoration/index.vue'
  import Locked from './module/Locked/index.vue'
  // import More from "./more/index.vue";
  import MergeSplit from '@/components/pagination/module/MergeSplit/index.vue'
  import splitGroup from './module/splitGroup/index.vue'

  export default {
    components: {
      splitGroup,
      saveSnapShot,
      MergeSplit,
      Locked,
      BatchDecoration,
      RevokeSentence,
      QualityTesting,
      CorpusRemind,
      DiffMatch,
      TranslationSet,
      AddTerm,
      EditSource,
      CopySource,
      ClearStyle,
      EditText,
      ClearTranslation,
      AcquireMachineTurnover,
      ConfirmTranslation,
      FindReplace,
      SentenceSelect,
      // More
    },
    setup() {
      const store = useStore()
      const translationSetRef = ref(null)
      const openTranslationSet = (type) => {
        translationSetRef.value.openTransitionSettingModal(type)
      }

      const excelWidth = computed(() => {
        return store.state.optionsBar.excelWidth
      })

      onMounted(() => {})
      return {
        excelWidth,
        disabled: computed(() => store.state.openEditSourceText),
        openTranslationSet,
        translationSetRef,
        store,
        isWindow: store.state.isWindow,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .excel-ul {
    background: linear-gradient(
      180deg,
      var(--universal-color-excel) 0%,
      #ffffff 100%
    );
    ::v-deep(.svg-color-AAA) {
      color: #aaa;
    }
    ::v-deep(.ant-btn-link) {
      color: #333;
    }
    padding: 6px 0 6px 10px;
    //padding-bottom: 8px;

    .disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    ::v-deep(.li-edit) {
      width: 90px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .edit-icon {
        cursor: pointer;
        height: 18px;
        text-align: center;
        line-height: 18px;
        border-color: transparent;
        padding: 0;
        border-width: 0;
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        //margin-bottom: 4px;
        svg {
          font-size: 20px;
        }

        .downOutlined {
          position: relative;
          bottom: 2px;
          margin-left: 0;
          //right: -14px;
          //top: 6px;
          svg {
            font-size: 14px;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, 0.04);
          border-radius: 4px;
        }
      }

      .edit-icon:not(:last-child) {
        margin-right: 8px;
        margin-bottom: 4px;
      }

      .edit-icon:last-child {
        position: relative;
        top: -1px;
      }
    }
  }
  .divider {
    width: 1px;
    height: 40px;
    background: #000;
    opacity: 0.08;
  }

  ::v-deep(.li) {
    padding: 0 4px;
    text-align: center;
    height: 100%;
    cursor: pointer;

    .icon-box {
      //width: 18px;
      //height: 18px;
    }
    .li-txt {
      font-size: 12px;
      color: #333333;
      margin: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04);
      //opacity: 0.3;
      border-radius: 6px;
    }

    &.isSelect {
      background: #d9d9d9;
      opacity: 0.7;
    }

    &.disabled {
      //position: relative;
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.3;
    }
  }
  .ant-dropdown {
    .dropdown-box {
      padding: 8px 16px;
      height: 56px;
      background: #ffffff;
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      border: 1px solid #f2f3f5;
    }
  }
</style>
