<template>
  <div>
    <a-checkbox v-model:checked="saveSnapshotValue"></a-checkbox>
    <span class="color-666 margin-l-8">保存已选句段当前「译文快照」</span>
    <QuestionCircleOutlined
      style="color: #000"
      class="font-size-13 cursor-pointer"
      @click="openDialog"
    />
  </div>
</template>

<script setup>
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  //暴露值？ 还是props传？
  import { computed, onUnmounted, onMounted, reactive } from 'vue'

  import { useStore } from 'vuex'

  const store = useStore()

  const props = defineProps({
    saveSnapshotValue: Boolean,
  })

  const emit = defineEmits(['update:saveSnapshotValue'])

  const state = reactive({
    visible: false,
  })

  const openDialog = () => {
    store.commit('modal/changeKey', {
      saveSnapshotVisible: true,
    })
  }

  const saveSnapshotValue = computed({
    set: (val) => {
      //更新外部值
      emit('update:saveSnapshotValue', val)
      if (val) {
        sessionStorage.setItem('saveSnapshotValue', true)
      } else {
        sessionStorage.removeItem('saveSnapshotValue')
      }
    },
    get: () => {
      return props.saveSnapshotValue
    },
  })

  // onUnmounted(() => {
  //   //重置值
  //   if (saveSnapshotValue.value) {
  //     sessionStorage.setItem("saveSnapshotValue", true);
  //   } else {
  //     sessionStorage.removeItem("saveSnapshotValue")
  //   }
  // })

  onMounted(() => {
    if (sessionStorage.getItem('saveSnapshotValue')) {
      saveSnapshotValue.value = true
    }
  })

  // const
</script>

<style scoped lang="less"></style>
