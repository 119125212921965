//自动存储；
import {
  sentCommit as autoSentCommit,
  getSentTarget,
  getSentence,
} from '@/http/api'
import {
  indexDbDeleteVal,
  indexDbGetAllVal,
  indexDbSetVal,
} from '@/indexDB/indexDB'
import {message} from 'ant-design-vue'
import store from '@/store/index'

window.autoCommitWorkStatus = false
//比较实时接口存储
const autoCommit = (keySentId, timer = 0) => {
  return new Promise((resolve, reject) => {
    if (window.autoCommitQueue[keySentId]) {
      //清除此定时器；
      clearTimeout(window.autoCommitQueue[keySentId])
    };
    // 重新给autoCommitSentIdsMap赋值
    window.autoCommitQueue[keySentId] = setTimeout(() => {
      //如果句子信息存在，则执行自动提交
      if (window.autoCommitSentIdsMap[keySentId]?.sentId) {
        autoSentCommit(window.autoCommitSentIdsMap[keySentId])
          .then((res) => {
            // console.log(res);
            if (!res) {
              // deleteAutoCommitQueue(keySentId);
              reject(false)
              return
            }
            let result = res.data.data
            //删掉内存
            deleteAutoCommit(keySentId)
            //找当前句子 // 这个没执行 ,这个有可能没有当前句，所以删不掉内存；
            store.dispatch('updateTargetOrStatus', {
              type: 'commit',
              sentId: keySentId,
              targetHTML: result.targetPlaceholder,
              assignObjItem: {
                diffCompare: result.diffCompare,
                isConfirm: result.isConfirm,
                targetText: result.targetText,
                targetType: result.targetType,
              },
            })

            //离线数据库存储移除；
            resolve(res)
          })
          .catch((rej) => {
            // deleteAutoCommitQueue(keySentId);
            reject(false)
          })
      } else {
        //删掉内存
        deleteAutoCommit(keySentId)
        //返回成功
        resolve(true)
      }
    }, timer * 10000)
  })
}

const addAutoCommitQueue = (params) => {
  // getSentence 触发 initAutoCommit
  //存在说明有提交队列，更新提交队列数据即可；
  if (window.autoCommitQueue.hasOwnProperty(params.sentId)) {
    //由此函数触发的时候肯定是最新值；
    window.autoCommitSentIdsMap[params.sentId] = {...params}
  } else {
    //进入队列
    window.autoCommitQueue[params.sentId] = null
    window.autoCommitSentIdsMap[params.sentId] = {...params}
  }
}

//删除自动提交队列，不删除库
const deleteAutoCommitQueue = (sentId) => {
  if (window.autoCommitQueue.hasOwnProperty(sentId)) {
    clearTimeout(window.autoCommitQueue[sentId])
    delete window.autoCommitQueue[sentId]
    delete window.autoCommitSentIdsMap[sentId]
  }
}

//删除自动提交；同时删库；
const deleteAutoCommit = (sentId) => {
  // console.log("获取机翻执行")
  //需要删除库；
  clearTimeout(window.autoCommitQueue[sentId])
  //删除自动提交队列这个字段
  delete window.autoCommitQueue[sentId]
  //删除自动提交队列信息值；
  delete window.autoCommitSentIdsMap[sentId]
  //删除库
  indexDbDeleteVal(sentId)
}

//初始化自动提交 只在页面进来的时候执行
const initAutoCommit = () => {
  // 在init上面触发; 和getSentence
  indexDbGetAllVal(async (dbResult) => {

    if (dbResult?.length) {
      //将dbResult;
      //获取需要查询的Ids列表；
      for (let i = 0; i < dbResult.length; i++) {
        window.autoCommitSentIdsMap[dbResult[i].sentId] = {...dbResult[i]}
      }
      //根据大成提供接口的值进行对比 ；移除已经更新的值；剩下的记录到window队列中做自动存储
      getSentence({sentIds: Object.keys(window.autoCommitSentIdsMap)}).then(
        async (res) => {
          //根据返回值二次比较；
          let list = res.data.data.sentenceDTOList
          list.forEach((item) => {
            if (!window.autoCommitSentIdsMap[item.sentId]) {
              return
            }
            //如果本地存储的值更新时间大于服务器的存储时间
            if (
              window.autoCommitSentIdsMap[item.sentId].updateTargetTime > item.updateTargetTime
            ) {
              //自动提交当前列 //放入队列中依次执行；
              window.autoCommitQueue[item.sentId] = null
            } else {
              //反之直接删除项；
              indexDbDeleteVal(item.sentId)
            }
          })

          wakeUpAutoCommitQueue()
        }
      )
    }
  })
}

const wakeUpAutoCommitQueue = async () => {
  //正在进程中 就不执行
  if (window.autoCommitWorkStatus == 'progress') {
    return
  }
  while (Object.keys(window.autoCommitQueue).length) {
    window.autoCommitWorkStatus = 'progress'
    // autoSentCommit()
    let timer = 0
    let oneItemSentId = Object.keys(window.autoCommitQueue)[0]
    //加 5 秒 重试  15秒重试，30秒重试 再根据结果来；
    let result = false

    while (!result && timer < 6) {
      try {
        console.log(window.autoCommitQueue)
        // console.log("自动提交触发");//看下框架retry;
        result = await autoCommit(oneItemSentId, timer)
        // console.log(result,"这是提交及而过")
      } catch (rej) {
        result = false
      } finally {
        // console.log("自动提交结束");
        timer = timer + 1
      }
    }

    if (timer >= 6 && !result) {
      //清除所有队列；下次不会再执行
      window.autoCommitQueue = {}
      window.autoCommitSentIdsMap = {}
    }
    // deleteAutoCommit()
  }
  //执行完成后设置false;
  window.autoCommitWorkStatus = false
}

const timeIntervalInspect = () => {
  setInterval(() => {
    if (window.autoCommitWorkStatus == 'progress') {
      //任务状态正在进行中
      //不执行
    } else if (
      window.autoCommitWorkStatus === false && Object.keys(window.autoCommitQueue).length
    ) {
      //任务状态未进行中 且队列有待执行的任务
      //说明需要唤醒提交队列
      wakeUpAutoCommitQueue()
    } else if (
      window.autoCommitWorkStatus == false &&
      !Object.keys(window.autoCommitQueue).length
    ) {
      //如果没有进行，且提交队列无值，获取数据库里面的离线值
      initAutoCommit()
    } else {

    }
  }, 60000)
}

export {
  timeIntervalInspect,
  wakeUpAutoCommitQueue,
  deleteAutoCommitQueue,
  addAutoCommitQueue,
  deleteAutoCommit,
  initAutoCommit,
  autoCommit,
}

//自动提交事件说明；
// 1.初次进入页面的时候，进行一次离线数据库查询，对更新时间不对的句子信息进行自动存储；
// 2.自动存储是进入页面才会执行一次，每句依次执行，如果一句存储失败，则按 5 10 15 20 25 秒后重试，最后一次失败则会不再进行自动存储，下次进入webcat才会执行；
// 3.重新编辑提交的时候，会先只清除队列，此时此句如果有待执行的自动存储任务，则会被清掉，修改成功后则会删除离线保存的内容；其余修改译文的操作例如（机翻，清空等）则会操作成功后再进行清除存储任务；
// 4.获取getSentence句子列表的时候会查询库里有无未提交的真实有效的数据，有效数据先显示在页面上，当前有自动存储的任务则会加入进去，没有的话则不执行（因为有可能是服务器过慢）；
// 5.提交任务的时候检查一遍数据库未提交的有效句子信息；有则提示出来，点击前往查看则更新列表，单独做了提交流程；

//分开 去做定时器唤起，只负责控制队列即可；+ 断网给提示
