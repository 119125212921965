import { createStore } from 'vuex'
import { actions, mutations, getters, state } from './common'
import rightToolbar from './modules/rightToolbar/index.js'
import excelProject from './modules/excelProject/index.js'
import sourceEdit from '@/store/modules/sourceEdit/index.js'
import modal from '@/store/modules/modal/index.js'
import revokeSentence from '@/store/modules/revokeSentence/index.js'
import optionsBar from '@/store/modules/optionsBar/index.js'

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    optionsBar,
    revokeSentence,
    sourceEdit,
    modal,
    rightToolbar,
    excelProject,
  },
})
