
  import { computed } from 'vue'
  import { useStore } from 'vuex'

  export default {
    setup() {
      const store = useStore()
      const refreshErrdialogVisible: any = computed(
        () => store.state.modal.refreshErrdialogVisible
      )

      const confirm = () => {
        // location.href = win.env.login + "/cas/login?service=" + url;
        location.reload()
      }

      return {
        refreshErrdialogVisible,
        confirm,
      }
    },
  }
