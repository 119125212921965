<template>
  <a-modal
    v-model:visible="visible"
    title="充值"
    centered
    :maskClosable="false"
    :footer="null"
    :width="560"
    wrapClassName="RechargeModal"
    @cancel="handleCancel"
  >
    <div class="padding-t-20">
      <!--      <a-tabs-->
      <!--        type="card"-->
      <!--        v-model:activeKey="activeKey"-->
      <!--        class="Recharge-tabs"-->
      <!--        @change="onTabChange"-->
      <!--      >-->
      <!--        <a-tab-pane key="1" tab="译力充值">-->
      <!--          -->
      <!--        </a-tab-pane>-->
      <!--      </a-tabs>-->
      <div class="Recharge-list">
        <ul>
          <li
            v-for="(item, index) in pageEtokenPackages"
            :key="index"
            :class="{ selected: checkedDetail.id == item.id }"
            @click="setCheckDetail(item)"
          >
            <div class="item-amount">{{ item.amount }} 译力</div>
            <div class="item-money">
              {{ transformPrice(item.price) }}元
              <del v-show="item.originalPrice != item.price">
                {{ transformPrice(item.originalPrice) }}元
              </del>
            </div>
            <img
              src="./images/select.png"
              v-show="checkedDetail.id == item.id"
            />
          </li>
          <div class="last-next-button">
            <div v-show="etokenPage > 1" @click="etokenPage--">
              <img src="./images/last.png" />
            </div>
            <div v-show="etokenPage == 1">
              <img src="./images/nolast.png" />
            </div>
            <div v-show="etokenPage < allPage" @click="etokenPage++">
              <img src="./images/next.png" />
            </div>
            <div v-show="allPage == etokenPage">
              <img src="./images/nonext.png" />
            </div>
          </div>
        </ul>
        <div class="Recharge-money">
          <div class="left">
            <div class="pay">
              <span class="label">
                优惠劵：
              </span>
              <a-select
                ref="select"
                style="width: 300px"
                placeholder="请选择译力规格"
                v-model:value="checkedDetail.rechargeCouponReceiveId"
                @change="handleSelectChange"
              >
                <a-select-option
                  v-for="(item, index) in couponList"
                  :key="index"
                  :value="item.rechargeCouponReceiveId"
                  :label="item.couponName"
                  :disabled="item.enable"
                >
                  {{ item.couponName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <div class="Recharge-money">
          <div class="left">
            <div class="pay">
              <span class="label">
                支付金额：
              </span>
              <span>
                ¥
              </span>
              <a>
                {{ transformPrice(checkedDetail.price) }}
              </a>
              <span>
                元
              </span>
              <span
                class="coupon-text"
                v-if="checkedDetail.rechargeCouponReceiveId"
              >
                {{ checkedDetail.couponSubName }}
              </span>
              <span class="tip">（译力充值比例：1元人民币=100译力）</span>
            </div>
          </div>
        </div>
      </div>
      <div class="Recharge-footer">
        <div class="Recharge-tip">
          <img src="./images/tip.png" />
          <span>请勿重复扫码支付，若已支付成功，可前往查看</span>
          <a href="/go/wealth_pc_login/wealth" target="_blank">译力明细</a>
        </div>
        <div class="Recharge-way">
          <div class="left">
            <div class="zifu">
              <a-space>
                <img src="./images/zifu.png" />
                <div>
                  <div class="text">字符包</div>
                  <div class="text">
                    {{ store.state.totalPay.charPackBalance }}
                  </div>
                </div>
              </a-space>
            </div>
            <div class="yili">
              <a-space>
                <img src="./images/yilis.png" />
                <div>
                  <div class="text">译力</div>
                  <div class="text">
                    {{ store.state.totalPay.etokenBalance }}
                  </div>
                </div>
              </a-space>
            </div>
          </div>
          <div class="right">
            <a-tooltip
              trigger="click"
              overlayClassName="white-tooltip"
              v-model:visible="show1"
              @visibleChange="onVisibleChange"
            >
              <template #title>
                <div v-if="result.url">
                  <div ref="qrcode1"></div>
                </div>
              </template>
              <div class="zfb button" @click="openCode(1)">
                <img src="./images/zfb.png" />
                <span>支付宝支付</span>
              </div>
            </a-tooltip>
            <a-tooltip
              trigger="click"
              overlayClassName="white-tooltip"
              v-model:visible="show2"
              @visibleChange="onVisibleChange"
            >
              <template #title>
                <div ref="qrcode2"></div>
              </template>
              <div class="wx button" @click="openCode(2)">
                <img src="./images/wx.png" />
                <span>微信</span>
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import { computed, ref, onMounted, reactive, toRefs, nextTick } from 'vue'
  import { useStore } from 'vuex'
  import {
    getRechargeList,
    rechargeResult,
    createRecharge,
    getTwinslatorMtPrice,
    getPackCoupon,
    postTwinsCalculate,
    getBalance,
  } from '@/http/api'
  import { message } from 'ant-design-vue'
  import QRCode from 'qrcodejs2-fix'

  export default {
    name: 'Recharge',
    setup() {
      const store = useStore()
      const visible = computed(() => store.state.rechargeVisible)
      const activeKey = ref('1')
      const checkedId = ref(1)
      let qrcode1 = ref(null)
      let qrcode2 = ref(null)
      const state = reactive({
        etokenPackages: [],
        charPackages: [],
        checkedDetail: {},
        show1: false,
        show2: false,
        qrcode: null,
        result: {},
        timeout: null,
        etokenPage: 1,
        charPage: 1,
        couponList: [],
      })

      const pageEtokenPackages = computed(() => {
        if (activeKey.value == 1) {
          return state.etokenPackages.slice(
            (state.etokenPage - 1) * 4,
            state.etokenPage * 4
          )
        } else {
          return state.charPackages.slice(
            (state.charPage - 1) * 4,
            state.charPage * 4
          )
        }
      })

      const allPage = computed(() => {
        if (activeKey.value == 1) {
          return Math.ceil(state.etokenPackages.length / 4)
        } else {
          return Math.ceil(state.charPackages.length / 4)
        }
      })

      const getMoney = () => {
        //需要改
        getBalance({
          docId: store.state.taskDetail.docId,
        }).then((res) => {
          store.commit('changeKey', {
            totalPay: { ...res.data.data },
          })
        })
      }

      //定时查询进度任务
      const timeoutRechargeProgress = () => {
        if (!state.result.id) {
          return
        }
        let params = {
          id: state.result.id,
        }
        rechargeResult(params).then((res) => {
          if (res.data.status == 1) {
            state.timeout = setTimeout(() => {
              timeoutRechargeProgress()
            }, 3000)
          } else if (res.data.status == 2) {
            clearTimeout(state.timeout)
            message.success('充值成功')
            store.commit('changeKey', {
              rechargeVisible: false,
              totalPay: {},
            })
          } else if (res.data.status == 2) {
            clearTimeout(state.timeout)
            message.error('充值失败')
          }
        })
      }

      const handleCancel = () => {
        clearTimeout(state.timeout)
        store.commit('changeKey', {
          rechargeVisible: false,
          totalPay: {},
        })
      }

      const httpGetPackCoupon = () => {
        let param = {
          rechargePackId: state.checkedDetail.id,
          rechargePackCategory: 1,
          packageNum: state.checkedDetail.packageNum,
        }
        getPackCoupon(param).then((res) => {
          state.couponList = [...res.data.data]
          if (state.couponList?.length) {
            state.checkedDetail.rechargeCouponReceiveId =
              state.couponList[0].rechargeCouponReceiveId
            state.checkedDetail.price = state.couponList[0].reducedPrice
            state.checkedDetail.couponSubName =
              state.couponList[0].couponSubName
          }
        })
      }

      const setCheckDetail = (item) => {
        if (state.checkedDetail.id != item.id) {
          state.checkedDetail = { ...item }
          httpGetPackCoupon()
        }
      }

      const onTabChange = () => {
        state.checkedDetail = {}
        state.couponList = []
        state.result = {}
      }

      const onVisibleChange = () => {}

      const transformPrice = (val) => {
        //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        if (!val) {
          return 0
        }
        let str = (val / 100).toFixed(2) + ''
        let intSum = str
          .substring(0, str.indexOf('.'))
          .replace(/\B(?=(?:\d{3})+$)/g, ',') //取到整数部分
        let dot = str.substring(str.length, str.indexOf('.')) //取到小数部分搜索
        let ret = intSum + dot
        return parseFloat(Number(ret))
      }
      const httpGetRechargeList = async () => {
        let params = {
          remainingWordCount: 0,
          twinslatorId: 85 || store.state.taskDetail.mtId,
        }
        await getRechargeList(params).then((res) => {
          let { etokenPackages, charPackages } = res.data
          state.etokenPackages = etokenPackages.filter((item) => {
            return item.price
          })
          state.charPackages = charPackages
        })

        // if (!store.state.totalPay.payEtoken) {
        getMoney()
        // }
      }

      const handleSelectChange = (id) => {
        state.couponList.find((item) => {
          if (item.rechargeCouponReceiveId == id) {
            state.checkedDetail.couponSubName = item.couponSubName
            state.checkedDetail.price = item.reducedPrice
            return true
          }
        })
      }

      const openCode = (type) => {
        clearTimeout(state.timeout)
        if (!state.checkedDetail.id) {
          message.warning('请选择充值套餐')
          return
        }
        state[`show${type}`] = true
        let params = {
          packageId: state.checkedDetail.id,
          packageNum: state.checkedDetail.packageNum,
          payMethod: type,
          rechargeCouponReceiveId: state.checkedDetail.rechargeCouponReceiveId,
        }
        nextTick(() => {
          if (state.qrcode) {
            if (type == 1) {
              qrcode1.value.innerHTML = ''
            } else {
              qrcode2.value.innerHTML = ''
            }
          }
        })
        createRecharge(params).then((res) => {
          state.result = { ...res.data }
          nextTick(() => {
            if (type == 1) {
              state.qrcode = new QRCode(qrcode1.value, {
                text: state.result.url, //URL地址
                width: 100,
                height: 100,
                colorDark: '#000', //二维码颜色
                colorLight: '#ffffff', //背景颜色
              })
            } else {
              state.qrcode = new QRCode(qrcode2.value, {
                text: state.result.url, //URL地址
                width: 100,
                height: 100,
                colorDark: '#000', //二维码颜色
                colorLight: '#ffffff', //背景颜色
              })
            }
            setTimeout(() => {
              timeoutRechargeProgress()
            }, 3000)
          })
        })
      }

      onMounted(() => {
        httpGetRechargeList()
      })
      return {
        handleSelectChange,
        allPage,
        pageEtokenPackages,
        getMoney,
        store,
        onVisibleChange,
        setCheckDetail,
        qrcode1,
        qrcode2,
        openCode,
        onTabChange,
        transformPrice,
        ...toRefs(state),
        checkedId,
        handleCancel,
        activeKey,
        visible,
      }
    },
  }
</script>

<style scoped lang="less">
  .Recharge-tabs {
    padding: 0 20px;

    ::v-deep(.ant-tabs-tab) {
      background: transparent !important;
    }

    ::v-deep(.ant-tabs-tab-active) {
      background: #00c6f3 !important;
      color: white !important;
    }
  }

  .Recharge-list {
    margin-bottom: 20px;
    padding: 0 20px;
    min-height: 190px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;

      .last-next-button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
          width: 24px;
          cursor: pointer;
        }
      }

      li.selected {
        border: 1px #00c6f3 solid;
      }

      li {
        cursor: pointer;
        position: relative;
        width: 108px;
        height: 60px;
        background: #f9feff;
        border-radius: 4px;
        border: 1px solid #d4f1f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 16px;

        img {
          position: absolute;
          width: 22px;
          height: 25px;
          right: 0;
          bottom: 0;
        }

        .item-amount {
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: #161b36;
          margin-bottom: 4px;
        }

        .item-money {
          height: 16px;
          font-size: 12px;
          color: #161b36;
          line-height: 16px;

          del {
            color: #bbbbbb;
          }
        }
      }
    }

    .Recharge-money {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 16px;

      .left {
        span {
          color: #333333;
          line-height: 22px;
        }
        .label {
          display: inline-block;
          width: 80px;
        }

        .coupon-text {
          display: inline-block;
          height: 24px;
          line-height: 16px;
          font-size: 12px;
          margin-left: 10px;
          color: #ffffff;
          padding: 4px 8px;
          background: linear-gradient(180deg, #ffb8a4 0%, #ff5037 100%);
          border-radius: 12px 0px 10px 0px;
        }

        a {
          position: relative;
          top: 3px;
          height: 24px;
          font-size: 24px;
          font-weight: 600;
          color: #00c6f3;
          line-height: 24px;
        }

        img {
          position: relative;
          top: -2px;
          width: 63px;
          height: 18px;
          margin-left: 8px;
        }

        .tip {
          margin-left: 16px;
          height: 18px;
          font-size: 12px;
          color: #bbbbbb;
          line-height: 18px;
        }
      }
    }
  }

  .Recharge-footer {
    .Recharge-tip {
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 28px;
      background: #ffecec;
      color: #f55c3f;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      a {
        color: #f55c3f;
        text-decoration: underline;
      }
    }

    .Recharge-way {
      padding: 0 20px;
      height: 56px;
      align-items: center;
      display: flex;
      justify-content: space-between;

      img {
        width: 24px;
        height: 24px;
      }

      .left {
        display: flex;
        align-items: center;
        font-size: 12px;

        .zifu {
          margin-right: 20px;
        }

        .text {
          line-height: 14px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        img {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }

        .button {
          cursor: pointer;
          color: white;
          width: 138px;
          text-align: center;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
        }

        .zfb {
          margin-right: 12px;
          background: #347eff;
        }

        .wx {
          background: #49b33d;
        }
      }
    }
  }
</style>
