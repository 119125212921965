<script setup>
  import { useStore } from 'vuex'
  import { reactive } from 'vue'
  import mitt from '@/plugins/bus'
  import { postCancelSync, postSyncInfo, postOpenSyncStatus } from '@/http/api'
  const props = defineProps(['item', 'index'])
  const store = useStore()
  import useUtils from '@/plugins/utils'
  import { message } from 'ant-design-vue'
  //打开同步弹窗
  const { setFocusEnd } = useUtils()
  //打开说明弹窗
  const openExplainModal = () => {
    mitt.emit('openSyncLockSentenceModal', {
      type: 'explainVisible',
    })
  }

  const httpPostCancelSync = (isIdenticalSent) => {
    postCancelSync({ sentId: props.item.sentId })
      .then((res) => {
        props.item.isSync = false
        props.item.isTranslatorUnLock = true
        props.item.locked = false
        message.success('取消同步成功')
      })
      .finally(() => {
        if (isIdenticalSent) {
          store.commit('excelProject/changeKey', {
            keyQueue: '',
          })
          setFocusEnd(document.getElementById(props.item.targetDomId), true)
        }
      })
  }
  //关闭同步状态
  const cancelSyncStatus = () => {
    if (store.getters.$isPreviewMode) {
      return
    }
    if (store.state.openEditSourceText) {
      return
    }
    //同步无需判断权限，谁都可以；但是需要判断锁定句；
    if (props.item.locked && !store.state.taskDetail.allowTranslatorUnLock) {
      //如果被锁了
      message.warning('暂无权限，可联系PM前往项目详情-成员处设置')
      return
    } else {
    }
    // if (
    //     store.state.taskDetail.allowTranslatorUnLock ||
    //     store.state.taskDetail.taskClassify == 8
    // ) {
    //
    // } else {
    //   message.warning("暂无解锁权限，可联系PM前往统计-重复率分析-报表详情开启");
    //   return;
    // }
    //判断是否同一句；
    let isIdenticalSent = props.index == store.state.excelProject.tabActiveIndex
    if (isIdenticalSent) {
      //阻止提交操作
      store.commit('excelProject/changeKey', {
        keyQueue: 'stop',
      })
    }

    if (
      props.item.isSync &&
      props.item.isLocked &&
      !props.item.isTranslatorUnLock
    ) {
      //打开弹窗2
      mitt.emit('openSyncLockSentenceModal', {
        type: 'unSyncVisible',
        okCallBack: () => {
          // console.log('执行同步回调')
          //取消同步接口
          httpPostCancelSync(isIdenticalSent)
        },
        cancelCallBack: () => {
          if (isIdenticalSent) {
            store.commit('excelProject/changeKey', {
              keyQueue: '',
            })
            console.log(999)
            setFocusEnd(document.getElementById(props.item.targetDomId), true)
          }
        },
      })
    } else {
      //取消同步接口
      httpPostCancelSync(isIdenticalSent)
    }
  }

  const httpOpenSyncStatusFunc = (syncMethod = '') => {
    store.commit('changeKey', {
      screenLoading: true,
    })
    setTimeout(() => {
      //恢复同步接口
      postOpenSyncStatus({ sentId: props.item.sentId, syncMethod: syncMethod })
        .then((res) => {
          //走重复句填充
          props.item.isSync = true
          message.success('恢复同步成功')
        })
        .finally(() => {
          store.dispatch('getSentence', {
            loading: true,
          })
        })
    }, 1000)
  }
  //打开同步状态
  const openSyncStatus = () => {
    if (store.getters.$isPreviewMode) {
      return
    }
    if (store.state.openEditSourceText) {
      return
    }
    // if (
    //     store.state.taskDetail.allowTranslatorUnLock ||
    //     store.state.taskDetail.taskClassify == 8
    // ) {
    //
    // } else {
    //   message.warning("暂无解锁权限，可联系PM前往统计-重复率分析-报表详情开启");
    //   return;
    // }
    //获取同步信息接口
    postSyncInfo({ sentId: props.item.sentId }).then((res) => {
      //获取句子同步信息
      let sentenceSyncInfo = { ...res.data.data }

      //如果存在其他同步
      if (
        sentenceSyncInfo.repeatedSentIds?.length &&
        !sentenceSyncInfo.allTargetSame
      ) {
        // let isIdenticalSent = props.index == store.state.excelProject.tabActiveIndex;
        // if (isIdenticalSent) {
        //   sentenceSyncInfo.targetText = document.getElementById(props.item.targetDomId).innerHTML;
        // }
        mitt.emit('openSyncLockSentenceModal', {
          type: 'syncVisible',
          okCallBack: (syncMethod) => {
            httpOpenSyncStatusFunc(syncMethod)
          },
          sentenceSyncInfo: sentenceSyncInfo,
        })
      } else {
        //不存在其他同步句
        httpOpenSyncStatusFunc()
      }
    })
  }
  //打开同步说明弹窗
  // const openSyncModal=()=>{
  //
  // }
</script>

<template>
  <div class="targetSync">
    <a-tooltip v-if="props.item.isSync === true">
      <template #title>
        重复句同步：已开启。
        <a @click="openExplainModal" style="text-decoration: underline">说明</a>
      </template>
      <icon-font
        type="icon-tongbu"
        style="color:#69D5F7;"
        @mousedown="cancelSyncStatus"
      ></icon-font>
    </a-tooltip>
    <a-tooltip v-else-if="props.item.isSync === false">
      <template #title>
        重复句同步：已关闭。
        <a @click="openExplainModal" style="text-decoration: underline">说明</a>
      </template>
      <icon-font
        type="icon-guanbitongbu"
        @mousedown="openSyncStatus"
        style="color:#86909C"
      ></icon-font>
    </a-tooltip>
  </div>
</template>

<style scoped lang="less">
  .targetSync {
    font-size: 12px;
    position: absolute;
    left: 2px;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }
</style>
