<template>
  <a-modal
    v-model:visible="qaProgressVisible"
    class="dialog-box"
    width="640px"
    title="全文检查"
    okText="确定"
    cancelText="收起"
    centered
    @ok="handleOk"
    @cancel="cancel"
  >
    <div class="content">
      <p class="margin-b-10">
        正在处理，完成
        <span>{{ checkProgressDetail.progress }}%</span>
      </p>
      <a-progress :percent="checkProgressDetail.progress" :showInfo="false" />
      <p class="tip">
        术语库数据过多，可能导致全文检查很慢。
      </p>
    </div>
    <template #footer>
      <a-button size="large" @click="cancel">收起</a-button>
      <a-button
        size="large"
        type="primary"
        :disabled="checkProgressDetail.progress < 100"
        @click="handleOk"
      >
        确定
      </a-button>
    </template>
  </a-modal>
</template>

<script>
  import { reactive, toRefs, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const state = reactive({
        taskDetail: store.state.taskDetail,
        checkedList: [],
      })

      const cancel = () => {
        store.commit('changeKey', {
          qaProgressVisible: false,
        })
      }

      const handleOk = () => {
        store.dispatch('getSentence')
        cancel()
      }

      return {
        t,
        ...toRefs(state),
        handleOk,
        cancel,
        checkProgressDetail: computed(() => store.state.checkProgressDetail),
        qaProgressVisible: computed(() => store.state.qaProgressVisible),
      }
    },
  }
</script>

<style lang="scss" scoped>
  .dialog-box {
    .content {
      p {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        span {
          color: #00c6f3;
        }
      }
      :deep(.ant-progress-bg) {
        background-color: #00c6f3;
      }
      :deep(.ant-progress-status-success .ant-progress-bg) {
        background-color: #52c41a;
      }
      .tip {
        margin-top: 10px;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }
    }
  }
</style>
