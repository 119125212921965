<template>
  <div>
    <ElScrollbar class="single-list-height" v-if="qaList.length">
      <listItem
        :qaList="qaList"
        :type="'single'"
        @ignoreClick="ignoreClick"
      ></listItem>
    </ElScrollbar>
    <div
      class="log-list-empty single-list-height"
      v-if="qaList.length === 0 && !loading"
    >
      <empty
        description="该句暂无QA检查提示哦！"
        description2="可以切换句子或查看全部QA哦"
      />
      <a-button type="primary" class="margin-t-10" @click="openALLQA">
        前往全部QA
      </a-button>
    </div>
  </div>
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import {
    computed,
    getCurrentInstance,
    onBeforeMount,
    ref,
    watch,
    toRefs,
  } from 'vue'
  import useProject from '@/components/source/useProject'
  import useUtils from '@/plugins/utils'
  import { ignoreQAErrorApi, getQaSentenceLogApi } from '@/http/api'
  import empty from '@/components/empty/empty.vue'
  import mitt from '@/plugins/bus'
  import listItem from './listItem.vue'

  export default {
    name: 'full',
    components: {
      empty,
      listItem,
    },
    props: ['checkRules', 'status'],
    setup(props, { emit }) {
      // const {checkRules} = toRefs(props);
      // const {status} = toRefs(props)
      // console.log(props)
      const { appContext } = getCurrentInstance()
      const globalProxy = appContext.config.globalProperties
      const activeKey = ref(0)
      const { t } = useI18n()
      const store = useStore()
      const qaList = ref([])
      const qaIndex = ref(0)
      const loading = ref([])

      const getTableActiveItem = () => {
        const sentenceDTOList = store.state.excelProject.docData.sentenceDTOList
        const tabActiveIndex = store.state.excelProject.tabActiveIndex
        return sentenceDTOList[tabActiveIndex] || {}
      }

      const httpAllSentenceLogs = () => {
        loading.value = true
        let item = getTableActiveItem()
        if (!item.sentId) {
          return
        }
        let params = {
          sentId: item.sentId,
        }
        getQaSentenceLogApi(params).then((res) => {
          loading.value = false
          qaList.value = [...res.data.data]
          emit('update:singleNumber', qaList.value.length)
        })
      }

      //忽略，恢复

      const ignoreClick = () => {
        httpAllSentenceLogs()
      }

      onBeforeMount(() => {
        httpAllSentenceLogs()
      })

      const openALLQA = () => {
        mitt.emit('mittQA', 'skipAll')
      }
      return {
        ignoreClick,
        loading,
        httpAllSentenceLogs,
        qaIndex,
        openALLQA,
        qaList,
        activeKey,
      }
    },
  }
</script>

<style scoped lang="less">
  .log-list-empty {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .single-list-height {
    height: calc(100vh - 180px);
  }

  .qa-box {
    .qa-list {
      position: relative;
      margin-bottom: 12px;
      padding: 12px;
      border-radius: 6px;
      word-break: break-all;

      &.not {
        background: rgba(0, 198, 243, 0.08);
      }

      &.yes {
        background: #f6f6f6;
      }

      &.is-active {
        border: 1px solid #00c6f3;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
