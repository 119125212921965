<template>
  <!--  <table-footer> </table-footer>-->
  <div class="new-edit-table">
    <div class="new-edit-table-header">
      <div class="check thead-right" :class="{ NoClick: frag }">
        <a-checkbox
          :checked="checkedAll"
          @change="rowSelection.onSelectAll"
          :disabled="disabled"
        ></a-checkbox>
      </div>
      <div class="extSentId thead">
        <sentenceIndex></sentenceIndex>
      </div>
      <div class="source-target">
        <!--拖动的值不要直接影响。临时去改即可-->
        <splitpanes :dbl-click-splitter="false" @resize="onPaneResize">
          <pane :min-size="20" :size="sourceHeaderSplitPaneWidth">
            <div><commTh :type="'source'"></commTh></div>
          </pane>
          <pane :min-size="20" :size="targetHeaderSplitPaneWidth">
            <div><commTh :type="'target'"></commTh></div>
          </pane>
        </splitpanes>
      </div>
      <div class="qaInfo">
        QA
      </div>
      <div class="status thead">
        状态
      </div>
    </div>
    <div
      class="new-edit-table-content"
      @mouseover="onMouseOver"
      @mouseout="onMouseLeave"
    >
      <!--//悬浮组件-->
      <floatTip ref="floatTipRef"></floatTip>

      <ElScrollbar
        id="new-edit-table-scroll"
        wrap-class="new-edit-table-scroll"
        ref="newEditTableScrollRef"
      >
        <div
          v-if="docData.sentenceDTOList?.length == 0 && !isHttpsProgress"
          class="text-center padding-t-40 padding-b-30"
        >
          <div><img src="@/assets/common/empty.png" height="120"></div>
          <div class="margin-t-20">当前分组无结果</div>
        </div>
        <div v-else>
          <div v-for="(item, index) in docData.sentenceDTOList" :key="item.sentId">
            <div v-if="item.isGroupFirst" class="new-edit-table-content-item" style="height: 36px">
              <div class="check thead-right">
                <CaretRightOutlined style="color:#000000" v-if="collapseGroup.includes(item.sentId)" @click="removeCollapseGroup(item.groupParentId)"/>
                <CaretDownOutlined style="color:#000000" v-else @click="addCollapseGroup(item.groupParentId)"/>
              </div>
              <div class="extSentId thead">
                <icon-font class="font-size-16" type="icon-fenzu"></icon-font>
              </div>
              <div class="color-bbb font-size-12 display-flex align-center padding-l-8">
                项目内容重复<span style="color:#00B4FA">{{item.repeatNum}}</span>句，当前可见<span style="color:#00B4FA">{{item.groupMemberNumber}}</span>句
              </div>
            </div>
            <transition name="fade">
              <div class="new-edit-table-content-item" v-show="!collapseGroup.includes(item.groupParentId)">
                <div class="check thead-right">
                  <a-checkbox
                      v-model:checked="item.checked"
                      :disabled="item.locked || disabled"
                      @change="rowSelection.onSelect(item)"
                  ></a-checkbox>
                </div>
                <div class="extSentId thead">
                  <div style="position: relative">
                    {{ item.extSentId }}
                    <a-tooltip v-if="item.isMerged" title="合并句">
                      <icon-font class="font-size-12" type="icon-hebing" style="position: absolute;left: -10px;top: -6px"></icon-font>
                    </a-tooltip>
                  </div>
                </div>
                <div class="source-target">
                  <div class="display-flex">
                    <div style="flex-shrink: 0;position: relative" :style="{'flex-basis':`${sourceTextSplitPaneWidth}%`}">
                      <div
                          @mousedown="onSourceMouseDown($event, item, index)"
                          style="height: 100%"
                      >
                        <sourceEdit :item="item" :index="index"></sourceEdit>
                      </div>
                    </div>
                    <div style="flex-shrink: 0;position: relative" :style="{'flex-basis':`${targetTextSplitPaneWidth}%`}">
                      <targetSync :item="item" :index="index"></targetSync>
                      <target-mark :item="item" :index="index"></target-mark>
                      <!--有问题 需要改进-->
                      <div
                          @mousedown="onTargetClick($event, item, index)"
                          style="height: 100%"
                      >
                        <a-tooltip
                            placement="left"
                            arrow-point-at-center
                            :destroyTooltipOnHide="true"
                        >
                          <template #title>
                      <span v-if="item.sourceEditRepeatLock">
                        该句为原文编辑下重复句锁定
                      </span>
                            <span v-else-if="item.locked">
                        该句已锁定，您无需翻译，若需解锁，
                        <u
                            style="cursor: pointer;color:#00c6f3"
                            @click="openDialog"
                        >
                          点我了解
                        </u>
                      </span>
                          </template>
                          <targetEdit :item="item" :index="index"></targetEdit>
                        </a-tooltip>
                        <!--                  <targetEdit :item="item" :index="index"></targetEdit>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    class="qaInfo"
                    @click="openQAModel(item, index)"
                    style="cursor: pointer"
                >
                  <QAInfoTag :item="item"></QAInfoTag>
                </div>
                <div class="status display-flex align-center justify-center">
                  <targetStatus
                      :item="item"
                      :index="index"
                      v-if="taskDetail.taskClassify != 8"
                  ></targetStatus>
                  <adminStatus :item="item" :index="index" v-else></adminStatus>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </ElScrollbar>
    </div>
  </div>

</template>

<script>
  import floatTip from "./float-tip/floatTip.vue"
  import sentenceIndex from "@/components/source/module/tableScroll/sentenceIndex/index.vue"
  import targetMark from '@/components/targetMark/index.vue'
  import targetOrigin from '@/components/targetOrigin/index.vue'
  import targetEdit from '@/components/targetEdit/index.vue'
  import commTh from '@/components/source/module/header/commTh.vue'
  import sourceEdit from '@/components/sourceEdit/index.vue'
  import adminStatus from '@/components/targetStatus/admin.vue'
  import targetStatus from '@/components/targetStatus/index.vue'
  import targetSync from '@/components/targetSync/index.vue'
  import QAInfoTag from '@/components/QAInfoTag/index.vue'
  import {
    toRefs,
    ref,
    watch,
    computed,
    onBeforeMount,
    onMounted,
    nextTick,
    getCurrentInstance,
    reactive,
  } from 'vue'
  import { useStore } from 'vuex'
  import useProject from '../../useProject'
  // import CorpusTipsPopover from "../../module/corpusTipsPopover.vue";
  // import TableFooter from "../../module/footer.vue";
  // import TrScroll from "./scroll.vue";
  // import useUtils from "@/plugins/utils";
  import filterStrings from '@/plugins/filterString'
  import useUtils from '@/plugins/utils'
  import sourceEditJS from '@/components/sourceEdit/sourceEdit.js'
  import targetEditJS from '@/components/targetEdit/targetEdit.js'
  import EditTermModal from '@/components/right_toolbar/tabs/modal/editTerm.vue'
  import useTis from '@/components/right_toolbar/tabs/useTip'
  import { message } from 'ant-design-vue'
  import { SearchOutlined,CaretRightOutlined,CaretDownOutlined } from '@ant-design/icons-vue';
  import { Splitpanes, Pane } from 'splitpanes'
  import 'splitpanes/dist/splitpanes.css'
  export default {
    props: {
      scrollHeight: {
        type: Number,
        default: 0,
      },
    },
    components: {
      CaretDownOutlined,
      CaretRightOutlined,
      floatTip,
      Splitpanes,
      Pane,
      sentenceIndex,
      SearchOutlined,
      targetSync,
      EditTermModal,
      adminStatus,
      commTh,
      targetMark,
      targetStatus,
      targetOrigin,
      targetEdit,
      sourceEdit,
      QAInfoTag,
      // TableFooter
      // TrScroll,
    },
    setup() {
      const { appContext } = getCurrentInstance()
      const store = useStore()
      const checkedAll = computed(() => store.state.excelProject.checkedAll);
      const collapseGroup = computed(()=>store.state.collapseGroup)
      const frag = ref(false)
      const globalProxy = appContext.config.globalProperties
      const { setFocusEnd } = useUtils()
      const activeBorder = computed(() => store.state.sourceEdit.activeBorder)
      const {
        insertImgToTarget,
        sourceKeyupEnter,
        clickSourceBox,
      } = sourceEditJS()
      const { keyupEnter, clickTargetBox } = targetEditJS()
      const { del, apply } = useTis()
      const newEditTableScrollRef = ref(null)
      const floatTipRef = ref(null)
      const checkQARule = ref({
        SPELL_CHECK: '拼写错误',
        RULE_LOWE_CHECK: '低错检查',
        TERM_CONSISTENCY_CHECK: '术语漏译',
        FORMAT_CHECK: '标签错误',
        SYNTAX_CHECK: '语法词法错误',
      })

      const state = reactive({
        sourceHeaderSplitPaneWidth:50,
        targetHeaderSplitPaneWidth:50,
        sourceTextSplitPaneWidth:50,
        targetTextSplitPaneWidth:50,
      })

      const {
        taskDetail,
        docData,
        checkedList,
        checkedDetail,
        initKeyQueue,
        targetMousedown,
        getRangeText,
      } = useProject()

      const openDialog = () => {
        store.commit('modal/changeKey', {
          lockDialogVisible: true,
        })
      }

      const updateNewEditTableScrollRef = () => {
        newEditTableScrollRef.value.update()
      }

      const openQAModel = (item, index) => {
        targetMousedown(item, index)

        store.commit('rightToolbar/changeKey', {
          replaceShow: false,
        })

        let right = document.getElementById('project-vertical-tabs')

        if (
          !right &&
          store.state.rightToolbar.toolbarNav.tbNavActiveIndex == 4
        ) {
          return
        }

        store.commit('rightToolbar/changeKey', {
          toolbarNav: {
            tbNavActiveIndex: '0',
          },
        })
        nextTick(() => {
          store.commit('rightToolbar/changeKey', {
            qaModel: 2,
            toolbarNav: {
              tbNavActiveIndex: '4',
            },
          })
        })
      }


      const rowSelection = {
        onSelect: (e) => {
          //不一定是选中 也有可能是未选中
          frag.value = true
          //检测shfitkey状态 //需要记值；
          let storeCheckedList = store.state.excelProject.checkedList
          let list = docData.value.sentenceDTOList
          let lastIndex = store.state.optionsBar.lastShiftKeyCheckedIndex
          if (store.state.optionsBar.shiftKeyStatus && lastIndex > -1) {
            let checkedIndex = list.findIndex((item) => {
              if (item.sentId == e.sentId) {
                return true
              }
            })
            //判断最后一个选项状态是否相同相同的话则处理成相同 不相同则不处理
            if (lastIndex < checkedIndex) {
              for (let i = lastIndex; i < checkedIndex; i++) {
                if (!list[i].locked) {
                  list[i].checked = e.checked
                }
              }
            }

            if (lastIndex > checkedIndex) {
              for (let i = checkedIndex; i < lastIndex + 1; i++) {
                if (!list[i].locked) {
                  list[i].checked = e.checked
                }
              }
            }

            store.commit('optionsBar/changeKey', {
              lastShiftKeyCheckedIndex: checkedIndex,
            })
            //则勾选最后一个 到 当前选中项的范围所有checked
            // }
          } else {
            let checkedIndex = list.findIndex((item) => {
              if (item.sentId == e.sentId) {
                return true
              }
            })
            store.commit('optionsBar/changeKey', {
              lastShiftKeyCheckedIndex: checkedIndex,
            })
          }

          let filterList = list.filter((item) => {
            return !item.locked
          })
          //下面作法是先重置，再根据checked的值来决定；
          //要区分做法shiftKeyStatus 触发重排
          if (store.state.optionsBar.shiftKeyStatus) {
            let checkedList = []
            filterList.forEach((item) => {
              item.checked && checkedList.push(item)
            })
            store.commit('excelProject/changeKey', {
              checkedList: checkedList,
              checkedAll: checkedList.length == filterList.length,
            })
          } else {
            let copyCheckedList = [...store.state.excelProject.checkedList];
            if (e.checked) {
              copyCheckedList.push(e);
              // copyCheckedList.push(item);
              store.commit('excelProject/changeKey', {
                checkedList: copyCheckedList,
                checkedAll: copyCheckedList.length == filterList.length,
              })
            } else {
              let copyIndex = copyCheckedList.findIndex(item=>{
                return item.sentId == e.sentId;
              })
              copyCheckedList.splice(copyIndex,1);
              store.commit('excelProject/changeKey', {
                checkedList: copyCheckedList,
                checkedAll: copyCheckedList.length == filterList.length,
              })
            }
          }


          setTimeout(() => {
            frag.value = false
          }, 500)
        },
        onSelectAll: (selected) => {
          frag.value = true
          let checked = selected.target.checked
          let list = docData.value.sentenceDTOList
          let checkedList = []
          let filterList = list.filter((item) => {
            return !item.locked
          })
          filterList.forEach((item) => {
            item.checked = checked
            item.checked && checkedList.push(item)
          })
          store.commit('excelProject/changeKey', {
            checkedList: checkedList,
            checkedAll: checkedList.length == filterList.length,
          })
          setTimeout(() => {
            frag.value = false
          }, 1000)
        },
      }
      const onSourceMouseDown = (e, item, index) => {
        if (store.state.openEditSourceText) {
          store.commit('sourceEdit/changeKey', {
            activeBorder: 'left',
          })
          clickSourceBox(item, index)
        } else {
          insertImgToTarget(e, item, index)
        }
      }


      const onTargetClick = (e, item, index) => {
        targetMousedown(item, index)
        if (store.state.openEditSourceText) {
          store.commit('sourceEdit/changeKey', {
            activeBorder: 'right',
          })
        }

        if (e?.target?.nodeName == 'IMG') {
          let selection = window.getSelection()
          // selection.removeAllRanges();
          let imgIndex = 0
          for (let i = 0; i < e.target.parentNode.childNodes.length; i++) {
            if (e.target.parentNode.childNodes[i] === e.target) {
              // console.log('等于');
              imgIndex = i
              break
            }
          }
          selection.collapse(e.target.parentNode, imgIndex + 1)

          // selection.collapse(e.target,0);
        }

        clickTargetBox(e, index, item)

      }


      const onMouseLeave = (e) => {
        //触发子节点方法
        floatTipRef.value.onMouseLeave(e)
      }
      let mouseoverTimer = null;
      const onMouseOver = (e) => {
        //触发子节点方法
        if (mouseoverTimer) {
          clearTimeout(mouseoverTimer)
        }
        mouseoverTimer = setTimeout(()=>{
          floatTipRef.value.onMouseOver(e)
        },300)
      }

      let onPaneResizeTimer = null
      const onPaneResize=(e)=>{
        if (onPaneResizeTimer) {
          clearTimeout(onPaneResizeTimer)
        }
        onPaneResizeTimer = setTimeout(()=>{
          state.sourceHeaderSplitPaneWidth = e[0].size;
          state.targetHeaderSplitPaneWidth = e[1].size;
          // console.log('开始赋值',state.targetHeaderSplitPaneWidth,e)
          state.targetTextSplitPaneWidth = state.targetHeaderSplitPaneWidth;
          state.sourceTextSplitPaneWidth = state.sourceHeaderSplitPaneWidth;
          localStorage.setItem("sourceHeaderSplitPaneWidth",state.sourceHeaderSplitPaneWidth);
          localStorage.setItem("targetHeaderSplitPaneWidth",state.targetHeaderSplitPaneWidth);
        },800)
      }

      const tabActiveIndex = computed({
        set: (val) => {
          store.commit('excelProject/changeKey', {
            tabActiveIndex: val,
          })
        },
        get: () => store.state.excelProject.tabActiveIndex,
      })

      const openQAModal = () => {
        store.commit('modal/changeKey', {
          qaCheckVisible: true,
        })
      }

      const addCollapseGroup=(groupParentId)=>{
        // console.log(groupParentId)
        store.dispatch("addCollapseGroup",{groupParentId})
      }

      const removeCollapseGroup=(groupParentId)=>{
        store.dispatch("removeCollapseGroup",{groupParentId})
      }

      watch(tabActiveIndex, () => {
        setTimeout(() => {
          if (!store.state.excelProject.keyQueue) {
            initKeyQueue()
          }
        }, 100)
      })


      onBeforeMount(()=>{
        state.sourceHeaderSplitPaneWidth = localStorage.getItem("sourceHeaderSplitPaneWidth") || 50;
        state.targetHeaderSplitPaneWidth = localStorage.getItem("targetHeaderSplitPaneWidth") || 50;
        state.sourceTextSplitPaneWidth = state.sourceHeaderSplitPaneWidth;
        state.targetTextSplitPaneWidth = state.targetHeaderSplitPaneWidth;
      })


      onMounted(() => {
        let editDocument = document.getElementById('new-edit-table-scroll')
        editDocument.addEventListener('keydown', function(e) {
          if (activeBorder.value == 'right') {
            keyupEnter(e)
          } else {
            sourceKeyupEnter(e)
          }
        })



        let timer = null
        document.addEventListener("selectionchange",(event)=>{
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(()=>{
            getRangeText()
          },400)
        })


      })

      return {
        isHttpsProgress:computed(()=>store.state.isHttpsProgress),
        addCollapseGroup,
        removeCollapseGroup,
        collapseGroup,
        onPaneResize,
        floatTipRef,
        updateNewEditTableScrollRef,
        newEditTableScrollRef,
        openQAModal,
        ...toRefs(state),
        onMouseLeave,
        onMouseOver,
        openDialog,
        openQAModel,
        onTargetClick,
        onSourceMouseDown,
        pageSize: computed(() => store.state.pageSize),
        disabled: computed(() => store.state.openEditSourceText),
        checkedAll,
        frag,
        rowSelection,
        taskDetail,
        docData,
        checkedList,
        checkedDetail,
        targetMousedown,
        store,
        filterStrings,
      }
    },
  }
</script>

<style lang="scss" scoped>
  //
  .source-target {
    ::v-deep(.splitpanes__pane) {
      background-color: transparent !important;
    }
    ::v-deep(.splitpanes__splitter){
      background: var(--universal-color-table);
      box-shadow:inset 0 0 0 0 #eee;
      border-left: 0 solid #eee !important;
    }
    ::v-deep(.splitpanes__splitter:before){
      width: 0 !important;
      display: none;
    }
    ::v-deep(.splitpanes__splitter:after){
      margin-left: -1px;
      width: 1px;
      height: 26px;
    }
    ::v-deep(.splitpanes__splitter:hover:after){
      background: transparent;
    }
  }
  .NoClick {
    pointer-events: none;
  }
  .qaInfo {
    //background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #4e5969;
  }


  .new-edit-table {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    //background: red;
    .new-edit-table-header {
      height: 40px;
      flex-shrink: 0;
    }
    .new-edit-table-content {
      position: relative;
      flex-grow: 1;
      #new-edit-table-scroll {
        height: 100%;
      }
    }
    .description {
      color: #4e5969;
      font-size: 14px;
    }
  }
</style>
