import { updateSourceTarget } from '@/http/api'
import useUtils from '@/plugins/utils'
import { message, Modal } from 'ant-design-vue'
import filterString from '@/plugins/filterString'
import filterStrings from '@/plugins/filterString'
const { setFocusEnd } = useUtils()
const state = {
  //撤回记录
  revokeList: new Map(),
}
const getters = {}
const mutations = {
  // changeKey(state, payload) {
  //   for (let i in payload) {
  //     state[i] = null;
  //     state[i] = payload[i];
  //   }
  // },
  //不翻页 检测index 对不对即可；
  resetRevokeList(state) {
    // state.revokeList=[];
    state.revokeList.clear()
  },
  pushRevokeList(state, payload) {
    let key = payload.sentId
    payload.isConfirm = false
    state.revokeList.get(key) ||
      state.revokeList.set(key, {
        backList: [],
        forwardList: [],
      })
    state.revokeList.get(key).backList.push(payload)
  },
  // clearRevokeList(state){
  //
  // }
}
const actions = {
  //回退 需要查看当前选中句
  backLast({ commit, rootState, dispatch, state }, params) {
    let selectItem =
      rootState.excelProject.docData.sentenceDTOList[
        rootState.excelProject.tabActiveIndex
      ]
    if (!selectItem) {
      return
    }
    let backList = state.revokeList.get(selectItem.sentId).backList
    let forwardList = state.revokeList.get(selectItem.sentId).forwardList

    let popItem

    // 重新赋值到dom框
    let selectDom = document.getElementById(selectItem.targetDomId)

    //如果已经撤回到最后一项 并且当前句子译文等于最后一项的译文；则不用撤回
    if (
      backList.length == 1 &&
      backList[0].targetPlaceholder ==
        filterStrings.retainImportant(selectDom.innerHTML)
    ) {
      //toast 提示；
      message.warning('暂无撤销，已是撤销的最后一步')
      return
    }

    //如果当前撤回项不等于 当前句子译文 则不入栈出栈 直接替换
    else if (
      backList[backList.length - 1].targetPlaceholder !=
      filterStrings.retainImportant(selectDom.innerHTML)
    ) {
      popItem = backList[backList.length - 1]
    }

    //如果当前撤回项等于当前句子译文 入栈出栈 不撤回当前项；而是撤回当前项的上面一项
    else if (
      backList.length > 1 &&
      backList[backList.length - 1].targetPlaceholder ==
        filterStrings.retainImportant(selectDom.innerHTML)
    ) {
      forwardList.push(backList.pop())
      popItem = backList[backList.length - 1]
    }

    //开始赋值？ 提交失效；  //撤回当前选中句保证不提交;
    rootState.excelProject.keyQueue = 'stop'
    selectDom.innerHTML = popItem.targetPlaceholder
    setTimeout(() => {
      rootState.excelProject.keyQueue = ''
      setFocusEnd(selectDom, true)
    }, 200)
  },
  //前进 需要查看当前选中句
  forwardNext({ commit, rootState, dispatch, state }, params) {
    let selectItem =
      rootState.excelProject.docData.sentenceDTOList[
        rootState.excelProject.tabActiveIndex
      ]
    if (!selectItem) {
      return
    }
    let popItem
    let backList = state.revokeList.get(selectItem.sentId).backList
    let forwardList = state.revokeList.get(selectItem.sentId).forwardList
    // 重新赋值到dom框
    let selectDom = document.getElementById(selectItem.targetDomId)
    if (forwardList.length == 0) {
      //toast 提示；
      message.warning('暂无恢复，已是恢复的最后一步')
      return
    } else if (forwardList.length > 0) {
      popItem = forwardList.pop()
      backList.push(popItem)
    }

    //开始赋值？ 提交失效；  //撤回当前选中句保证不提交;
    rootState.excelProject.keyQueue = 'stop'
    setTimeout(() => {
      rootState.excelProject.keyQueue = ''
      setFocusEnd(selectDom, true)
    }, 200)
    // 重新赋值到dom框
    selectDom.innerHTML = popItem.targetPlaceholder
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
