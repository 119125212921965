import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9770096"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-locale" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_icon_font, {
      type: "icon-zhongwen",
      class: "font-size-18",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLang(_ctx.lang)))
    }, null, 512), [
      [_vShow, _ctx.lang != 'zh']
    ]),
    _withDirectives(_createVNode(_component_icon_font, {
      type: "icon-yingwen",
      class: "font-size-18",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLang(_ctx.lang)))
    }, null, 512), [
      [_vShow, _ctx.lang == 'zh']
    ])
  ]))
}