import {
  getPostilList,
  getDetailList,
  addPostilOrUpdate,
  delPostil,
} from '@/http/api'

const state = {
  isOpenTable: true,
  pageNum: 1,
  loading: true,
  total: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  postilList: [],
  detailList: [],
  activeKey: '1',
  remarkPageNum: 1,
}
const getters = {}
const mutations = {
  changeKey(state: any, payload: any) {
    for (let i in payload) {
      state[i] = null
      state[i] = payload[i]
    }
  },
}
const actions = {
  getPostilList({ commit, rootState }: any, pageNum: number) {
    getPostilList({
      docId: rootState.taskDetail.docId,
      pageNum: pageNum,
      pageSize: 20,
    }).then((res) => {
      const data = res.data.data
      commit('changeKey', {
        pageNum: data.pageNum,
        loading: false,
        total: data.total,
        hasPreviousPage: data.hasPreviousPage,
        hasNextPage: data.hasNextPage,
        postilList: data.list,
      })
    })
  },
  getDetailList({ commit, rootState }: any, sentId: number) {
    return new Promise((resolve, reject) => {
      getDetailList({
        docId: rootState.taskDetail.docId,
        pageNum: 1,
        pageSize: 1000,
        sentId: rootState.checkedDetail.sentId,
      })
        .then((res) => {
          const data = res.data.data
          commit('changeKey', {
            detailList: data.list || [],
          })
          resolve(data.list)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addPostilOrUpdate({ rootState, dispatch }: any, params: any) {
    return new Promise((resolve, reject) => {
      addPostilOrUpdate({
        content: params.content,
        docId: rootState.taskDetail.docId,
        sentId: params.sentId,
        postilId: params.postilId, //为空则新增，有值则修改
      })
        .then((res) => {
          dispatch('getDetailList', params.sentId)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  delPostil({ rootState, dispatch }: any, params: any) {
    return new Promise((resolve, reject) => {
      delPostil({
        postilId: params.postilId,
        docId: rootState.taskDetail.docId,
        sentId: params.sentId,
      })
        .then((res) => {
          dispatch('getDetailList', params.sentId).then((res: any) => {
            resolve(res)
          })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
