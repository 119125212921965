<template>
  <a-modal
    :visible="true"
    class="dialog-box"
    :width="640"
    title="QA提示设置"
    okText="保存设置"
    cancelText="取消"
    centered
    @ok="handleOk"
    @cancel="cancel"
  >
    <div class="content">
      <div class="padding-lr-20">
        <div class="margin-b-26 text-center" style="color: #1C2129;">
          勾选需要提示的QA类型，未勾选的QA将不提示
        </div>
        <a-checkbox-group v-model:value="checkedList" style="width: 100%">
          <a-row>
            <a-col :span="8" class="margin-b-10">
              <a-checkbox value="TERM_CONSISTENCY_CHECK">术语漏译</a-checkbox>
            </a-col>
            <!--            <a-col :span="8" class="margin-b-10">-->
            <!--              <a-checkbox value="FORMAT_CHECK">译文漏译</a-checkbox>-->
            <!--            </a-col>-->
            <a-col :span="8" class="margin-b-10">
              <a-checkbox value="FORMAT_CHECK">标签错误</a-checkbox>
            </a-col>
            <a-col :span="8" class="margin-b-10">
              <a-checkbox value="SYNTAX_CHECK">
                语法词法错误
                <img
                  src="./beta.png"
                  width="24"
                  class="margin-l-4"
                  style="position: relative;top:-1px"
                />
              </a-checkbox>
            </a-col>
            <a-col :span="8" class="margin-b-10">
              <a-checkbox value="SPELL_CHECK">拼写错误</a-checkbox>
            </a-col>
            <a-col :span="8" class="margin-b-10">
              <a-checkbox value="RULE_LOWE_CHECK">
                低错检查
                <img
                  src="./beta.png"
                  width="24"
                  class="margin-l-4"
                  style="visibility: hidden"
                />
              </a-checkbox>
            </a-col>
<!--            <a-col :span="8" class="margin-b-10">-->
<!--              <a-checkbox value="RULE_LOWE_CHECK">-->
<!--                一致性检查-->
<!--                <QuestionCircleOutlined />-->
<!--              </a-checkbox>-->
<!--            </a-col>-->
          </a-row>
        </a-checkbox-group>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import {QuestionCircleOutlined} from '@ant-design/icons-vue'
  import { reactive, toRefs, computed, onMounted, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import {
    sentenceCheckAll,
    setQASettingApi,
    getQASettingApi,
  } from '@/http/api'

  export default {
    components:{
      QuestionCircleOutlined
    },
    setup(props, ctx) {
      const store = useStore()
      const { t } = useI18n()
      const state = reactive({
        taskDetail: store.state.taskDetail,
      })

      const checkedList = computed({
        set: (val) => {
          store.commit('rightToolbar/changeKey', {
            qaCheckedList: val,
          })
        },
        get: () => store.state.rightToolbar.qaCheckedList,
      });


      const cancel = () => {
        store.dispatch('rightToolbar/getQASetting')
        store.commit('modal/changeKey', {
          qaCheckVisible: false,
        })
      }

      const handleOk = () => {
        //获取一遍老值
        getQASettingApi().then((res) => {
          let result = res.data.data
          //判断历史值，如果无修改则不请求列表
          let oldV = result.qaRuleFilter.sort().join(',')
          let newV = store.state.rightToolbar.qaCheckedList.sort().join(',')
          if (oldV == newV) {
            //如果无修改不做任何处理。
          } else {
            let params = {
              qaRuleFilter: newV,
            }
            setQASettingApi(params).then((res) => {
              store.dispatch('rightToolbar/getQASetting')
              //设置完成则重新赋值
              store.dispatch('getSentence', { loading: true, isBackTop: true })
            })
          }
          //如果有修改 ，则刷新列表
          cancel()
        })
      }

      onBeforeMount(() => {
        //获取QA设置值选项
        store.dispatch('rightToolbar/getQASetting')
        // getQASetting(()=>{
        //   let result = res.data.data;
        //   //赋值
        //   store.commit("rightToolbar/changeKey",{
        //     qaCheckedList:result
        //   })
        // })
        //调用接口获取实时值
      })

      return {
        checkedList,
        ...toRefs(state),
        handleOk,
        cancel,
        t,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .dialog-box {
    .content {
      p {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        span {
          color: #00c6f3;
        }
      }
      :deep(.ant-progress-bg) {
        background-color: #00c6f3;
      }
      :deep(.ant-progress-status-success .ant-progress-bg) {
        background-color: #52c41a;
      }
    }
  }
</style>
