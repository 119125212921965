<template>
  <div class="levelMessage">
    <ExclamationCircleFilled class="icon-tip" />
    <span class="msg-text">{{ msgInfo.msg }}</span>
    <span class="level-light" @click="openLevel">去升级</span>
    <CloseCircleFilled class="icon-close" @click="onMClick"></CloseCircleFilled>
  </div>
</template>

<script>
  import {
    ExclamationCircleFilled,
    CloseCircleFilled,
  } from '@ant-design/icons-vue'

  import { message } from 'ant-design-vue'
  // const IconFontLevel = createFromIconfontCN({
  //   scriptUrl: "//at.alicdn.com/t/c/font_3888084_jmm8w9xs4ph.js"
  // });
  export default {
    name: 'levelMessage',
    props: {
      msgInfo: {
        type: Object,
        default: function() {
          return {
            msg: '',
            type: '',
          }
        },
      },
    },
    components: {
      ExclamationCircleFilled,
      CloseCircleFilled,
      // IconFontLevel
    },
    methods: {
      onMClick() {
        message.destroy()
      },
      openLevel() {
        window.open('/go/tsp_pc_login/home/price')
        // message.destroy();
      },
    },
  }
</script>

<style scoped lang="less">
  .levelMessage {
    display: flex;
    align-items: center;
    span {
      height: 22px;
      font-size: 14px;
      line-height: 22px;
      color: #333333;
    }
    .msg-text {
      margin-right: 16px;
    }
    .level-light {
      color: #00befa;
      margin-right: 16px;
      cursor: pointer;
    }
    ::v-deep(svg) {
      height: 20px;
      line-height: 20px;
    }
    .icon-tip {
      color: #faad14;
      font-size: 18px;
      //fill:#F55C3F;
      //width: 16px;
      //height: 16px;
    }
    .icon-close {
      font-size: 18px;
      cursor: pointer;
    }
  }
</style>
