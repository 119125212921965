<template>
  <div class="flex-center flex-column">
    <a-tooltip>
      <template #title>
        撤销：{{ isWindow ? 'Alt + Z' : 'Control + Option + Z' }}
      </template>
      <a-button
        class="li"
        type="link"
        style="height: 22px; line-height: 22px;"
        :class="{ disabled: checkedDetail.locked || disabled }"
        @mousedown="back"
      >
        <icon-font
          class="font-size-14 icon-box margin-r-4"
          type="icon-chexiao"
        ></icon-font>
      </a-button>
    </a-tooltip>
    <a-tooltip>
      <template #title>
        恢复：{{ isWindow ? 'Alt + Y' : 'Control + Option + Y' }}
      </template>
      <a-button
        class="li"
        type="link"
        style="height: 22px; line-height: 22px;"
        :class="{ disabled: checkedDetail.locked || disabled }"
        @mousedown="forward"
      >
        <icon-font
          class="font-size-14 icon-box margin-r-4"
          type="icon-huifu1"
        ></icon-font>
      </a-button>
    </a-tooltip>
    <!--    <icon-font-->
    <!--        class="font-size-16 icon-box"-->
    <!--        type="icon-chexiao"-->
    <!--    ></icon-font>-->
    <!--    <icon-font class="font-size-16" type="icon-huifu1"></icon-font>-->
  </div>
  <!--  <a-space :size="8">-->
  <!--    <a-tooltip>-->
  <!--      <template #title>-->
  <!--        {{ isWindow ? "Alt + Z" : "Control + Option + Z" }}-->
  <!--      </template>-->
  <!--      <div-->
  <!--        class="li"-->
  <!--        @mousedown="back"-->
  <!--        :class="{ disabled: checkedDetail.locked || disabled }"-->
  <!--      >-->
  <!--        <icon-font-->
  <!--          class="font-size-16 icon-box"-->
  <!--          type="icon-chexiao"-->
  <!--        ></icon-font>-->
  <!--        <p class="li-txt">撤回</p>-->
  <!--      </div>-->
  <!--    </a-tooltip>-->
  <!--    <a-tooltip>-->
  <!--      <template #title>-->
  <!--        {{ isWindow ? "Alt + Y" : "Control + Option + Y" }}-->
  <!--      </template>-->
  <!--      <div-->
  <!--        class="li"-->
  <!--        @mousedown="forward"-->
  <!--        :class="{ disabled: checkedDetail.locked || disabled }"-->
  <!--      >-->
  <!--        <icon-font class="font-size-16" type="icon-huifu1"></icon-font>-->
  <!--        <p class="li-txt" :class="{ disabled: disabled }">恢复</p>-->
  <!--      </div>-->
  <!--    </a-tooltip>-->
  <!--  </a-space>-->
</template>

<script>
  import differentModel from '@/components/header/components/differentModel/index.vue'
  import { computed, ComputedRef } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'index.vue',
    props: ['disabled'],
    components: {
      differentModel,
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const taskDetail = computed(() => store.state.taskDetail)
      const checkedDetail = computed(() => store.state.checkedDetail)
      const back = () => {
        store.dispatch('revokeSentence/backLast')
      }
      const forward = () => {
        store.dispatch('revokeSentence/forwardNext')
      }
      return {
        checkedDetail,
        forward,
        back,
        t,
        taskDetail,
        isWindow: store.state.isWindow,
      }
    },
  }
</script>

<style scoped lang="less">
  .li {
    display: flex;
    align-items: center;
    padding: 0 !important;
    .li-txt {
      position: relative;
      top: -1px;
    }
  }

  //.disabled {
  //  cursor: not-allowed;
  //  pointer-events: none;
  //  opacity: 0.3;
  //}
</style>
