<template>
  <div class="newSetting">
    <div class="newSetting-tab">
      <div class="title-tab">
        <h4
          v-if="!store.state.taskDetail.isSelfWork"
          :class="{ select: tabActive == '0' }"
          @click="tabChange('0')"
        >
          项目库
        </h4>
        <h4
          v-if="!store.state.taskDetail.isSelfWork"
          :class="{ select: tabActive == '4' }"
          @click="tabChange('4')"
        >
          企业库
        </h4>
        <h4 @click="tabChange('2')" :class="{ select: tabActive == '2' }">
          我创建的
        </h4>
        <h4 @click="tabChange('1')" :class="{ select: tabActive == '1' }">
          我收藏的
        </h4>
        <h4 @click="tabChange('3')" :class="{ select: tabActive == '3' }">
          协作共建
        </h4>
      </div>
      <div class="search-content">
        <span
          class="createTerm"
          @click="createTerm"
          v-if="store.state.taskDetail.isSelfWork"
        >
          <img src="./images/create.png" />
          创建术语库
        </span>
        <a-input
          v-model:value="searchContent"
          @blur="closeInput"
          @pressEnter="closeInput"
          placeholder="搜索术语库"
        >
          <template #addonAfter>
            <SearchOutlined @click="closeInput" />
          </template>
        </a-input>
      </div>
    </div>
    <div class="newSetting-table">
      <div class="table-header">
        <div
          class="table-header-item selfWork"
          v-if="store.state.taskDetail.isSelfWork"
        >
          存储
        </div>
        <div class="table-header-item selected">
          <span v-if="store.state.taskDetail.isSelfWork">
            参考
          </span>
        </div>
        <div class="table-header-item libraryName">
          术语库名称
        </div>
        <div class="table-header-item languageDirection">
          语言方向
        </div>
        <div class="table-header-item count">
          条目
        </div>
        <div class="table-header-item createUserName">
          来源
        </div>
        <div class="table-header-item time">
          更新时间
          <span class="orderByTimeDesc">
            <CaretUpOutlined @click="orderByTimeSort(true)" />
            <CaretDownOutlined @click="orderByTimeSort(false)" />
          </span>
        </div>
      </div>
      <div class="table-content">
        <ul>
          <template v-if="tableData.length > 0">
            <a-radio-group v-model:value="checkedId" @change="onRadioChange">
              <li
                v-for="(citem, cindex) in tableData"
                :key="cindex"
                class="table-content-li"
              >
                <div
                  class="table-content-item selfWork"
                  v-if="store.state.taskDetail.isSelfWork"
                >
                  <a-radio
                    :value="citem.libraryId"
                    :disabled="!citem.canStorage"
                  ></a-radio>
                </div>
                <div class="table-content-item selected">
                  <a-checkbox
                    v-model:checked="citem.isSelected"
                    @change="onCheckChange(citem)"
                    :disabled="tabActive == 0"
                  ></a-checkbox>
                </div>
                <div
                  class="table-content-item libraryName"
                  :title="citem.libraryName"
                >
                  {{ citem.libraryName }}
                </div>
                <div class="table-content-item languageDirection">
                  {{ citem.languageDirection }}
                </div>
                <div class="table-content-item count">
                  {{ citem.itemCount || 0 }}
                </div>
                <div class="table-content-item createUserName">
                  {{ citem.createUserName }}
                </div>
                <div class="table-content-item time">
                  {{ citem.time }}
                </div>
              </li>
            </a-radio-group>
          </template>

          <template v-if="tableData.length == 0">
            <div class="empty">
              <img src="@/images/emptyContent.png" />
              <div class="empty-text">暂无数据</div>
            </div>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onBeforeMount, computed } from 'vue'
  import {
    getTwinslatorTranslateSettingAll,
    getTermCorpusTicket,
  } from '@/http/api'
  import { useStore } from 'vuex'
  import {
    SearchOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
  } from '@ant-design/icons-vue'
  import { useI18n } from 'vue-i18n'
  import useUtils from '@/plugins/utils'
  export default {
    name: 'newSetting',
    components: {
      SearchOutlined,
      CaretUpOutlined,
      CaretDownOutlined,
    },
    setup() {
      const { dateFormat } = useUtils()
      const { t } = useI18n()
      const store = useStore()
      //源列表数据
      const sourceTableData = ref([])
      //语言方向Map
      const languageMap = new Map()
      //行业方向Map
      const industryMap = new Map()
      //选择的twId
      const checkedId = ref('')
      const tabActive = ref('0')
      //搜索框内容
      const searchContent = ref('')

      const timeout = ref(null)
      const $userLevelPrivilege = computed(() => {
        return store.state.userLevelPrivilege
      })

      store.state.industryList.forEach((item) => {
        industryMap.set(item.industryId, item.industryName)
      })

      store.state.languageList.forEach((item) => {
        languageMap.set(item.languageId, item.languageShortName)
      })

      const tableData = computed(() => {
        return sourceTableData.value.filter(function(item) {
          return (
            item.libraryName.indexOf(searchContent.value) > -1 &&
            tabActive.value == item.libSource
          )
        })
      })

      //搜索框失去焦点之后触发过滤 (留着备用)
      const closeInput = () => {
        // console.log('closeInput')
      }

      const tabChange = (e) => {
        tabActive.value = e
      }

      const checkCount = () => {
        let index = 0
        sourceTableData.value.forEach((item) => {
          if (item.isSelected && checkedId.value != item.libraryId) {
            index++
          }
        })
        let corpusCount = $userLevelPrivilege.value.privilege.dwdgz_corpus_count
        if (corpusCount < index) {
          window.$levelMessage({
            msg: `${$userLevelPrivilege.value.nameTeam ||
              $userLevelPrivilege.value
                .name}术语参考库已达上限${corpusCount}个`,
          })
        }
      }

      const onRadioChange = (e) => {
        sourceTableData.value.find((item) => {
          if (item.libraryId == checkedId.value) {
            item.isSelected = true
            return true
          }
        })
        checkCount()
      }

      const onCheckChange = (citem) => {
        //校验是否超出
        if (citem.isSelected) {
          checkCount()
        }
      }

      const initCheckedId = () => {
        checkedId.value = ''
        if (store.state.taskDetail.termLibrary?.length) {
          checkedId.value = store.state.taskDetail.termLibrary[0].libraryId
        }
      }

      const getSubmitValue = () => {
        let termObj = {
          termMasterLibIds: checkedId.value,
          termReferLibIds: '',
        }
        sourceTableData.value.forEach((item) => {
          if (store.state.taskDetail.isSelfWork) {
            if (item.isSelected) {
              termObj.termReferLibIds =
                termObj.termReferLibIds + ',' + item.libraryId
            }
          } else {
            if (item.isSelected && !item.isMaster && item.libSource != 0) {
              termObj.termReferLibIds =
                termObj.termReferLibIds + ',' + item.libraryId
            }
          }
        })
        termObj.termReferLibIds = termObj.termReferLibIds.slice(1)
        return termObj
      }

      const httpGetTwinslatorTranslateSetting = (orderByTimeDesc = true) => {
        let data = {
          sourceLanguageId: store.state.taskDetail.sourceLangId,
          targetLanguageId: store.state.taskDetail.targetLangId,
          taskId: store.state.taskDetail.taskId,
          libType: 1,
          orderByTimeDesc: orderByTimeDesc,
        }

        let { termReferLibIds } = getSubmitValue()
        let termReferLibIdsArr = []
        if (termReferLibIds) {
          termReferLibIdsArr = termReferLibIds.split(',')
        }

        getTwinslatorTranslateSettingAll(data).then((res) => {
          if (res.data.result == '1') {
            sourceTableData.value = [...res.data.data.libList]
            sourceTableData.value.forEach((item) => {
              item.languageDirection = '多语种'
              if (item.sourceLangId && item.targetLangId) {
                item.languageDirection = `${languageMap.get(
                  item.sourceLangId
                )} - ${languageMap.get(item.targetLangId)}`
              }
              item.time = dateFormat(item.lastModifyTime, 'YYYY-mm-dd HH:MM')

              if (termReferLibIdsArr.indexOf(item.libraryId.toString()) > -1) {
                item.isSelected = true
              }
            })
          }
        })
      }

      const clearCheckInterval = () => {
        clearInterval(timeout.value)
      }

      const createTerm = () => {
        //打开术语语料创建术语库地址
        clearCheckInterval()
        const newWindow = window
        getTermCorpusTicket({}).then((res) => {
          // let url = `${window.env.termUrl}api/v1/redirect/${res.data.data.ticket}?from=pe&libraryType=1&item=1&createLib=1`;
          let url = `/go/pe_pc/library?libType=1`
          // if (store.state.taskDetail.industryId) {
          //   url = `/go/pe_pc/library`;
          // }
          newWindow.open(url)
          timeout.value = setInterval(() => {
            httpGetTwinslatorTranslateSetting()
          }, 5000)
        })
      }

      const orderByTimeSort = (status) => {
        httpGetTwinslatorTranslateSetting(status)
      }

      onBeforeMount(() => {
        if (store.state.taskDetail.isSelfWork) {
          tabActive.value = 2
        }
        initCheckedId()
        httpGetTwinslatorTranslateSetting()
      })

      return {
        onRadioChange,
        clearCheckInterval,
        createTerm,
        sourceTableData,
        tableData,
        checkedId,
        closeInput,
        searchContent,
        getSubmitValue,
        onCheckChange,
        store,
        tabChange,
        tabActive,
        orderByTimeSort,
        httpGetTwinslatorTranslateSetting,
        t,
      }
    },
  }
</script>

<style scoped lang="less">
  .createTerm {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 130px;
    font-size: 14px;
    color: #00c6f3;
    margin-right: 10px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .newSetting-table {
    .selfWork {
      width: 60px;
    }
    .count {
      width: 100px;
    }
    .selected {
      width: 50px;
    }
    .libraryName {
      width: 220px;
    }
    .languageDirection {
      width: 120px;
    }
    .createUserName {
      width: 100px;
    }
    .time {
      width: 260px;
    }
  }
</style>
